import { createSlice } from "@reduxjs/toolkit";

const initialState:CommentState = {
    comments: []
}

export interface CommentState {
    comments: Comment[]
}

export interface Comment {
    ideaId: string,
    comment: string,
    createdBy:string,
    createAt: Date
}

const commentSlice = createSlice({
    name: 'comment',
    initialState,
    reducers: {
        addComment(state:CommentState, actions) {
            state.comments.push(actions.payload);
        }
    }
})

export const { addComment } = commentSlice.actions
export const commentReducer = commentSlice.reducer