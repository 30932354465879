'use client'

import type React from 'react'
import { useEffect, useCallback, useMemo, useState } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation, useQuery } from '@apollo/client'
import { Card, CardContent } from '@/components/ui/card'
import { ScrollArea } from '@/components/ui/react-scroll-area'
import { useToast } from '@/components/ui/use-toast'
import type { RootState } from '@/store/store'
import { GetCollaboratorsByOrgID } from '@/graphql/userQuery'
import { hideLoader, showLoader } from '@/store/idea/loaderSlice'
import {
  ADD_IDEA,
  EDIT_IDEA,
  GET_ALL_IDEAS,
  GET_IDEAS,
} from '@/graphql/ideaQuery'
import { GET_CATEGORIES_BY_ORG_ID } from '@/graphql/orgQuery'
import { checkFormIsValid, clearEvent, CLICK } from '@/store/idea/ideaSlice'
import { encodeFileWithMetadata } from '@/service/ideas'
import type { Idea, IdeaFormData } from '@/types/idea'
import type { ScoresProps } from '../../views/SuhrtScoreParams'
import { BasicInfo } from './BasicInfo'
import { Description } from './Description'
import { AdditionalOptions } from './AdditionalOptions'
import { ScoreSection } from './ScoreSection'
import { Tags } from './Tags'
import { GET_TOKEN_DETAILS_BY_ORG_ID } from '@/graphql/tokenQuery'
import { ScoreSectionOverlay } from './ScoreSectionOverlay'
import IdeaVisibilityRadio from './IdeaVisibilityRadio'
import { useNavigate } from 'react-router-dom'

interface IdeaSubmitFormProps {
  onClose: () => void
  idea?: Idea
  aiIdeaData?: any
  ideaScores?: ScoresProps
  onFormChange?: (hasChanges: boolean) => void
  bcGenerated?: string
}

interface UseIdeaFormProps {
  idea?: Idea
  aiIdeaData?: any
  setExistingAttachments: React.Dispatch<React.SetStateAction<string[]>>
  setBcAttachment: React.Dispatch<React.SetStateAction<string>>
}

// Custom hook for form state management
const useIdeaForm = ({
  idea,
  aiIdeaData,
  setExistingAttachments,
  setBcAttachment,
}: UseIdeaFormProps) => {
  const { register, handleSubmit, control, reset, watch, setValue } =
    useForm<IdeaFormData>()

  useEffect(() => {
    if (idea) {
      const {
        idea_title,
        idea_categories,
        idea_collaborators,
        business_value_prop,
        description,
        tags,
        attachments,
        business_canvas_file,
      } = idea

      reset({
        title: idea_title || '',
        categories:
          idea_categories?.map((category: any) => ({
            value: category.category.category_id,
            label: category.category.category_name,
          })) || [],
        collaborators:
          idea_collaborators?.map((collaborator: any) => ({
            value: collaborator.user.id,
            label: collaborator.user?.username,
          })) || [],
        businessJustification: business_value_prop || '',
        description: description || '',
        tags: tags || [],
      })
      setExistingAttachments(attachments || [])
      setBcAttachment(business_canvas_file || '')
    } else if (aiIdeaData) {
      reset({
        title: aiIdeaData.title || '',
        description: aiIdeaData.description || '',
        tags: aiIdeaData.tags || [],
        businessJustification: aiIdeaData.valueprop || '',
      })
    }
  }, [idea, aiIdeaData, reset, setExistingAttachments, setBcAttachment])

  return { register, handleSubmit, control, reset, watch, setValue }
}

// Custom hook for queries
const useIdeaQueries = (currentUser: any) => {
  const { data: categoriesData } = useQuery(GET_CATEGORIES_BY_ORG_ID, {
    variables: { org_id: currentUser?.org_id },
    skip: !currentUser?.org_id,
  })

  const { data: collaboratorsData, loading } = useQuery(
    GetCollaboratorsByOrgID,
    {
      variables: { org_id: currentUser?.org_id, user_id: currentUser?.id },
      skip: !currentUser?.org_id,
    }
  )

  return {
    categoriesData,
    collaboratorsData,
    loading,
  }
}

const IdeaSubmitForm: React.FC<IdeaSubmitFormProps> = ({
  onClose,
  idea,
  aiIdeaData,
  ideaScores,
  onFormChange,
  bcGenerated = '',
}) => {
  const { toast } = useToast()
  const dispatch = useDispatch()
  const currentUser = useSelector((state: RootState) => state.auth.currentUser)
  const step = useSelector((state: RootState) => state.idea.step)
  const click = useSelector((state: RootState) => state.idea.click)
  const [visibility, setVisibility] = useState(
    idea ? idea.visibility.toString() : '1'
  )
  //const [isSubmitDisabled, setSubmitDisabled] = useState(true)
  const [bcAttachment, setBcAttachment] = useState<string>(
    idea?.business_canvas_file || bcGenerated || ''
  )
  const [existingAttachments, setExistingAttachments] = useState<string[]>(
    idea?.attachments || []
  )
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false)
  const [showScoreOverlay, setShowScoreOverlay] = useState(false)

  // Prepare idea data for ScoreSection
  const [preparedIdeaData, setPreparedIdeaData] = useState<any>(null)
  const navigate = useNavigate()
  useEffect(() => {
    if (idea && ideaScores) {
      // For editing existing ideas
      setPreparedIdeaData({
        title: idea.idea_title,
        description: idea.description,
        valueprop: idea.business_value_prop,
        tags: idea.tags,
        // Extract mind map data from idea if available
        mind_map: idea?.mindmap ? JSON.parse(idea.mindmap) : null,
        scores: {
          feasibility_score: ideaScores.scores.feasibility_score,
          impact_score: ideaScores.scores.impact_score,
          originality_score: ideaScores.scores.originality_score,
          relevance_score: ideaScores.scores.relevance_score,
          reuse_score: ideaScores.scores.reuse_score,
          weighted_avg_score: ideaScores.weighted_avg_score,
        },
        notes: {
          feasibility_note: ideaScores.notes.feasibility_note,
          impact_note: ideaScores.notes.impact_note,
          originality_note: ideaScores.notes.originality_note,
          relevance_note: ideaScores.notes.relevance_note,
          reuse_note: ideaScores.notes.reuse_note,
        },
      })
    } else if (aiIdeaData) {
      // For new ideas from AI Ideator
      setPreparedIdeaData(aiIdeaData)
    }
  }, [idea, ideaScores, aiIdeaData])

  const { categoriesData, collaboratorsData, loading } =
    useIdeaQueries(currentUser)

  const { register, handleSubmit, control, reset, watch, setValue } =
    useIdeaForm({
      idea,
      aiIdeaData,
      setExistingAttachments,
      setBcAttachment,
    })

  const [addNewIdea] = useMutation(idea ? EDIT_IDEA : ADD_IDEA, {
    onCompleted: () => {
      toast({
        variant: 'success',
        duration: 3000,
        description:
          click === CLICK.SAVE
            ? 'Idea saved successfully.'
            : idea
            ? 'Idea submitted successfully.'
            : 'New idea created successfully.',
      })
    },
    onError: (error) => {
      toast({
        variant: 'destructive',
        duration: 3000,
        description: idea
          ? `Error updating idea: ${error.message}`
          : `Error submitting new idea: ${error.message}`,
      })
    },
    refetchQueries: [
      currentUser?.role_id === 1
        ? { query: GET_ALL_IDEAS }
        : {
            query: GET_IDEAS,
            variables: {
              org_id: currentUser?.org_id,
              user_id: currentUser?.id,
            },
          },
    ],
  })

  const categories = useMemo(
    () =>
      categoriesData?.suhrt_category.map((c: any) => ({
        value: c.category_id,
        label: c.category_name,
      })),
    [categoriesData]
  )

  const collaborators = useMemo(() => {
    return collaboratorsData?.suhrt_users
      .filter(
        (user: any) =>
          user.invitation_status === 'accepted' ||
          (user.invitation_status === 'active' &&
            // Filter out example email domains
            !(user.email && user.email.includes('@example.com')))
      )
      .map((u: any) => ({
        value: u.id,
        label: u.username,
      }))
  }, [collaboratorsData])

  const isIdeaOwner = idea?.idea_by === currentUser?.id

  useEffect(() => {
    dispatch(loading ? showLoader() : hideLoader())
  }, [loading, dispatch])

  // Add query for token details
  const { data: tokenData } = useQuery<{
    suhrt_tokens: Array<{
      remaining_tokens: number
      total_tokens: number
      used_tokens: number
    }>
  }>(GET_TOKEN_DETAILS_BY_ORG_ID, {
    variables: { org_id: currentUser?.org_id },
    fetchPolicy: 'network-only',
  })

  const maxTokens = tokenData?.suhrt_tokens[0]?.total_tokens ?? 0

  // Check token availability on component mount
  useEffect(() => {
    const checkTokenAvailability = async () => {
      const remainingTokens = tokenData?.suhrt_tokens[0]?.remaining_tokens
      if (typeof remainingTokens === 'number' && remainingTokens <= 0) {
        setShowScoreOverlay(true)
      }
    }

    checkTokenAvailability()
  }, [tokenData])

  const onSubmit: SubmitHandler<IdeaFormData> = async (data) => {
    try {
      dispatch(showLoader())
      const base64Attachment: string[] = []

      if (data.attachment?.length) {
        await Promise.all(
          Array.from(data.attachment).map(async (file) => {
            const encoded = await encodeFileWithMetadata(file)
            base64Attachment.push(encoded)
          })
        )
      }

      const combinedAttachments = [...existingAttachments, ...base64Attachment]

      // Create scores data from preparedIdeaData if available
      const scoresData = preparedIdeaData?.scores
        ? {
            weight_id: ideaScores?.weight_id || 1, // Use existing weight ID or default
            ...preparedIdeaData.scores,
            ...preparedIdeaData.notes,
            weighted_avg_score: preparedIdeaData.scores.weighted_avg_score,
          }
        : null

      const mutationVariables = idea
        ? {
            idea_id: idea.id,
            idea_by: idea.idea_by,
            business_value_prop: data.businessJustification,
            description: data.description,
            visibility: Number.parseInt(visibility),
            tags: data.tags,
            idea_title: data.title,
            status: click === CLICK.SAVE ? 1 : 2,
            categories: data.categories?.map((cat: any) => ({
              idea_id: idea.id,
              category_id: cat.value,
            })),
            collaborators: data.collaborators?.map((collaborator: any) => ({
              idea_id: idea.id,
              user_id: collaborator.value,
            })),
            scores: scoresData
              ? [
                  {
                    idea_id: idea.id,
                    ...scoresData,
                  },
                ]
              : [],
            new_notes: data.note
              ? [
                  {
                    idea_id: idea.id,
                    note: data.note,
                    user_id: currentUser?.id,
                    action: 'update_idea',
                  },
                ]
              : [],
            attachments: combinedAttachments,
            business_canvas_file: bcAttachment,
            mindmap: JSON.stringify(preparedIdeaData?.mind_map) || null,
          }
        : {
            object: {
              idea_title: data.title,
              status: click === CLICK.SAVE ? 1 : 2,
              description: data.description,
              visibility: Number.parseInt(visibility),
              business_value_prop: data.businessJustification,
              tags: data.tags,
              idea_by: currentUser?.id,
              idea_categories: {
                data: data.categories.map((category: any) => ({
                  category_id: category.value,
                })),
              },
              idea_collaborators: {
                data:
                  data.collaborators?.map((collaborator: any) => ({
                    user_id: collaborator.value,
                  })) || [],
              },
              idea_scores: scoresData
                ? {
                    data: scoresData,
                  }
                : { data: [] },
              idea_notes: data.note
                ? {
                    data: {
                      user_id: currentUser?.id,
                      note: data.note,
                      action: 'create',
                    },
                  }
                : null,
              attachments: base64Attachment,
              business_canvas_file: bcAttachment,
              mindmap: JSON.stringify(preparedIdeaData?.mind_map) || null,
            },
          }

      await addNewIdea({
        variables: mutationVariables,
      })
      onClose()
    } catch (error) {
      console.error('Error submitting idea:', error)
      toast({
        duration: 3000,
        variant: 'destructive',
        description: 'Failed to submit idea. Please try again.',
      })
    } finally {
      dispatch(hideLoader())
      dispatch(clearEvent())
    }
  }

  const handleReset = useCallback(() => {
    reset({
      title: '',
      categories: [],
      collaborators: [],
      businessJustification: '',
      description: '',
      tags: [],
      attachment: null,
      note: '',
    })
    setExistingAttachments([])
  }, [reset])

  const handleRemoveExistingFile = useCallback((index: number) => {
    setExistingAttachments((prev) => prev.filter((_, i) => i !== index))
  }, [])

  const formValues = watch()

  // Form validation
  useEffect(() => {
    const areFieldsFilled =
      !!formValues.title &&
      !!formValues.categories?.length &&
      !!formValues.businessJustification &&
      !!formValues.description

    //setSubmitDisabled(!areFieldsFilled)
    dispatch(checkFormIsValid(areFieldsFilled))
    onFormChange?.(areFieldsFilled)
  }, [formValues, dispatch, onFormChange])

  // Click handler
  useEffect(() => {
    switch (click) {
      case CLICK.SAVE:
      case CLICK.SUBMIT:
        handleSubmit(onSubmit)()
          // Only clear the event after submission
          .then(() => {
            dispatch(clearEvent())
          })
        break
      case CLICK.RESET:
        handleReset()
        dispatch(clearEvent())
        break
    }
  }, [click, dispatch, handleReset, handleSubmit])

  const handleUpgradeClick = () => {
    navigate('/pricing')
    onClose()
  }

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-4 items-stretch'>
      <form className=''>
        <div className='h-full border border-border p-4 pr-0 rounded-md'>
          <ScrollArea
            className={`pr-4 h-full ${
              idea
                ? 'md:max-h-[calc(100vh-272px)]'
                : step === 'Ideate'
                ? 'md:max-h-[calc(100vh-403px)]'
                : 'md:max-h-[calc(100vh-310px)]'
            }`}
          >
            <BasicInfo
              register={register}
              control={control}
              categories={categories}
              collaborators={collaborators}
            />
            <Description register={register} />
            <Tags control={control} />
            <IdeaVisibilityRadio value={visibility} onChange={setVisibility} />
            <AdditionalOptions
              register={register}
              showAdditionalOptions={showAdditionalOptions}
              setShowAdditionalOptions={setShowAdditionalOptions}
              existingAttachments={existingAttachments}
              watch={watch}
              setValue={setValue}
              handleRemoveExistingFile={handleRemoveExistingFile}
              disabled={idea ? !isIdeaOwner : false}
            />
          </ScrollArea>
        </div>
      </form>

      <Card className='shadow-none relative'>
        <ScrollArea
          className={`pr-4 overflow-auto h-full min-h-[400px] !static ${
            idea
              ? 'md:max-h-[calc(100vh-272px)]'
              : step === 'Ideate'
              ? 'md:max-h-[calc(100vh-364px)]'
              : 'md:max-h-[calc(100vh-310px)]'
          }`}
        >
          <CardContent className='p-4'>
            <ScoreSection
              aiIdeaData={preparedIdeaData}
              bcGenerated={bcAttachment}
            />

            {showScoreOverlay && (
              <ScoreSectionOverlay
                onUpgradeClick={handleUpgradeClick}
                maxScores={maxTokens}
              />
            )}
          </CardContent>
        </ScrollArea>
      </Card>
    </div>
  )
}

export default IdeaSubmitForm
