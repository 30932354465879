import { useState } from 'react'
import { Input } from '@/components/ui/input'

export interface Tag {
  id: string
  text: string
}

interface TagsInputProps {
  value: Tag[]
  onChange: (tags: Tag[]) => void
  placeholder?: string
}

export const TagsInput: React.FC<TagsInputProps> = ({
  value,
  onChange,
  placeholder,
}) => {
  const [inputValue, setInputValue] = useState<string>('')

  const handleAddTag = () => {
    if (inputValue.trim()) {
      onChange([...value, { id: Date.now().toString(), text: inputValue }])
      setInputValue('')
    }
  }

  const handleRemoveTag = (tagId: string) => {
    onChange(value.filter((tag) => tag.id !== tagId))
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault() // Prevent form submission on Enter
      handleAddTag()
    }
  }

  return (
    <div className='space-y-2'>
      <div className='flex gap-2'>
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown} // Listen for Enter key
          placeholder={placeholder}
          className='input flex-grow bg-background text-foreground/60'
        />
      </div>
      <div className='flex gap-2 flex-wrap'>
        {value.map((tag) => (
          <span
            key={tag.id}
            className='bg-muted py-1 px-4 rounded-full flex items-center text-foreground/60'
          >
            {tag.text}{' '}
            <button
              type='button'
              onClick={() => handleRemoveTag(tag.id)}
              className='ml-2 text-red-600 hover:text-red-800'
            >
              ×
            </button>
          </span>
        ))}
      </div>
    </div>
  )
}
