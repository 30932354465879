import * as React from 'react'

import { cn } from '@/lib/utils'

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          'flex min-h-[70px] w-full  bg-background border border-input shadow-sm placeholder-slate-400::placeholder disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 focus:outline-none focus:border-primary rounded-md sm: focus:ring-1 invalid:border-destructive-500 invalid:text-destructive-600 focus:invalid:border-destructive-500 focus:invalid:ring-destructive-500 disabled:shadow-none  bg-background px-3 py-2  ring-offset-background',
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
Textarea.displayName = 'Textarea'

export { Textarea }
