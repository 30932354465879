import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { useState, useEffect } from 'react'
import { MdDelete, MdEdit } from 'react-icons/md'
import { Textarea } from '@/components/ui/textarea'
import { useMutation, useQuery } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import { useToast } from '@/components/ui/use-toast'
import { RootState } from '@/store/store'
import {
  CREATE_CATEGORY,
  DELETE_CATEGORY_BY_ID,
  GET_CATEGORIES_BY_ORG_ID,
  UPDATE_CATEGORY_BY_ID,
} from '@/graphql/orgQuery'
import { hideLoader, showLoader } from '@/store/idea/loaderSlice'

const CategoryForm = () => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state: RootState) => state.auth)
  const [newCategory, setNewCategory] = useState({ name: '', description: '' })
  const [editingId, setEditingId] = useState<number | null>(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { toast } = useToast()

  // Query to fetch categories based on org_id
  const {
    data: categoriesData,
    loading: loadingCategories,
    refetch,
  } = useQuery(GET_CATEGORIES_BY_ORG_ID, {
    variables: { org_id: currentUser?.org_id },
    skip: !currentUser?.org_id,
  })

  const [createCategory] = useMutation(CREATE_CATEGORY, {
    onCompleted: () => {
      toast({
        variant: 'success',
        description: 'Category created successfully.',
      })
      refetch()
      resetForm()
      setIsDialogOpen(false)
    },
    onError: (error) => {
      toast({
        variant: 'destructive',
        description: `Error creating category: ${error.message}`,
      })
    },
  })

  const [updateCategory] = useMutation(UPDATE_CATEGORY_BY_ID, {
    onCompleted: () => {
      toast({
        variant: 'success',
        description: 'Category updated successfully.',
      })
      refetch()
      resetForm()
      setIsDialogOpen(false)
    },
    onError: (error) => {
      toast({
        variant: 'destructive',
        description: `Error updating category: ${error.message}`,
      })
    },
  })

  const [deleteCategory] = useMutation(DELETE_CATEGORY_BY_ID, {
    onCompleted: () => {
      toast({
        variant: 'success',
        description: 'Category deleted successfully.',
      })
      refetch()
    },
    onError: (error) => {
      toast({
        variant: 'destructive',
        description: `Error deleting category: ${error.message}`,
      })
    },
  })

  const handleAddOrUpdateCategory = () => {
    const { name, description } = newCategory
    if (!name.trim() || !description.trim()) {
      toast({
        variant: 'destructive',
        description: 'Both name and description are required.',
      })
      return
    }

    dispatch(showLoader())
    if (editingId) {
      updateCategory({
        variables: {
          category_id: editingId,
          category_name: name.trim(),
          category_description: description.trim(),
        },
      }).finally(() => dispatch(hideLoader()))
    } else {
      createCategory({
        variables: {
          category_name: name.trim(),
          org_id: currentUser?.org_id,
          category_description: description.trim(),
        },
      }).finally(() => dispatch(hideLoader()))
    }
  }

  const resetForm = () => {
    setNewCategory({ name: '', description: '' })
    setEditingId(null)
  }

  const handleEditCategory = (category: any) => {
    setNewCategory({
      name: category.category_name,
      description: category.category_description,
    })
    setEditingId(category.category_id)
    setIsDialogOpen(true)
  }

  const handleDeleteCategory = (category_id: number) => {
    dispatch(showLoader())
    deleteCategory({
      variables: { category_id },
    }).finally(() => dispatch(hideLoader()))
  }

  useEffect(() => {
    if (loadingCategories) {
      dispatch(showLoader())
    } else {
      dispatch(hideLoader())
    }
  }, [loadingCategories, dispatch])

  if (loadingCategories) return <p>Loading categories...</p>

  const categories = categoriesData?.suhrt_category || []
  return (
    <div className='pt-0'>
      <h3 className='text-base font-medium mb-4'>Category Management</h3>
      <div className='flex md:justify-end mb-4'>
        <Dialog
          open={isDialogOpen}
          onOpenChange={(open) => {
            if (!open) {
              resetForm() // Reset the form when the dialog is closed
            }
            setIsDialogOpen(open)
          }}
        >
          <DialogTrigger asChild>
            {currentUser?.role_id !== 3 && (
              <Button
                onClick={() => setIsDialogOpen(true)}
                className='grid items-end'
              >
                Add Category
              </Button>
            )}
          </DialogTrigger>
          <DialogContent className='md:max-w-[425px] w-[90%]'>
            <DialogHeader>
              <DialogTitle>
                {editingId ? 'Edit Category' : 'Add Category'}
              </DialogTitle>
            </DialogHeader>
            <Input
              placeholder='Category Name'
              value={newCategory.name}
              onChange={(e) =>
                setNewCategory({ ...newCategory, name: e.target.value })
              }
              className='mb-2'
            />
            <Textarea
              placeholder='Description'
              value={newCategory.description}
              onChange={(e) =>
                setNewCategory({ ...newCategory, description: e.target.value })
              }
              className='mb-4'
            />
            <DialogFooter>
              <Button
                onClick={handleAddOrUpdateCategory}
                disabled={loadingCategories}
              >
                {editingId ? 'Update Category' : 'Add Category'}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Description</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {categories.map((category: any) => (
            <TableRow key={category.category_id}>
              <TableCell>{category.category_name}</TableCell>
              <TableCell>{category.category_description}</TableCell>
              <TableCell className='flex items-center justify-center cursor-pointer'>
                {currentUser?.role_id !== 3 && (
                  <>
                    <MdEdit
                      onClick={() => handleEditCategory(category)}
                      className='mr-2 text-xl hover:text-primary'
                    />
                    <MdDelete
                      className='text-xl hover:text-destructive'
                      onClick={() => handleDeleteCategory(category.category_id)}
                    />
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default CategoryForm
