import React from 'react'
import { Input } from '@/components/ui/input'
import { UseFormRegister, UseFormWatch, UseFormSetValue } from 'react-hook-form'
import { FileIcon, X, Download } from 'lucide-react'
import {
  cleanBase64String,
  handleDownload,
  decodeMetadata,
} from '../idea-summery/DownloadAttachment'

interface AttachmentProps {
  register: UseFormRegister<any>
  disabled?: boolean
  attachments?: string[]
  watch: UseFormWatch<any>
  setValue: UseFormSetValue<any>
  onRemoveFile?: (index: number) => void
  onRemoveExistingFile?: (index: number) => void
}

export const Attachment: React.FC<AttachmentProps> = ({
  disabled = false,
  attachments = [],
  watch,
  setValue,
  onRemoveExistingFile,
}) => {
  const currentFiles = watch('attachment')

  const handleRemoveFile = (index: number) => {
    if (!currentFiles || !Array.isArray(currentFiles)) return

    const newFiles = currentFiles.filter((_, i) => i !== index)
    setValue('attachment', newFiles.length > 0 ? newFiles : null)
  }

  const getFileSize = (size: number) => {
    if (size < 1024) return `${size} B`
    if (size < 1024 * 1024) return `${(size / 1024).toFixed(1)} KB`
    return `${(size / (1024 * 1024)).toFixed(1)} MB`
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const maxFileSize = 1 * 1024 * 1024 // 1MB in bytes
      const filesArray = Array.from(e.target.files)
      const validFiles = filesArray.filter((file) => {
        if (file.size > maxFileSize) {
          alert(
            `${file.name} exceeds the 1MB size limit and will not be uploaded.`
          )
          return false
        }
        return true
      })

      setValue('attachment', validFiles.length > 0 ? validFiles : null)
    }
  }

  return (
    <div className='space-y-4'>
      <Input
        type='file'
        multiple
        disabled={disabled}
        onChange={handleFileChange}
        className='input dark:text-white border border-input text-foreground/60'
      />

      {/* Display new files being uploaded */}
      {currentFiles &&
        Array.isArray(currentFiles) &&
        currentFiles.length > 0 && (
          <div className='space-y-2'>
            <div className='text-sm font-medium'>New Files:</div>
            <div className='space-y-2'>
              {currentFiles.map((file: File, index: number) => (
                <div
                  key={`new-${index}`}
                  className='flex items-center justify-between p-2 rounded-md border bg-muted/40'
                >
                  <div className='flex items-center space-x-2'>
                    <FileIcon className='h-4 w-4' />
                    <span className='text-sm'>{file.name}</span>
                    <span className='text-xs text-muted-foreground'>
                      ({getFileSize(file.size)})
                    </span>
                  </div>
                  <button
                    type='button'
                    onClick={() => handleRemoveFile(index)}
                    className='text-destructive hover:text-destructive/80 transition-colors'
                  >
                    <X className='h-4 w-4' />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

      {/* Display existing attachments */}
      {attachments.length > 0 && (
        <div className='space-y-2'>
          <div className='text-sm font-medium'>Existing Files:</div>
          <div className='space-y-2'>
            {attachments.map((file, index) => {
              const cleanedBase64 = cleanBase64String(file)
              const metadata = decodeMetadata(cleanedBase64)

              return (
                <div
                  key={`existing-${index}`}
                  className='flex items-center justify-between p-2 rounded-md border bg-muted/40'
                >
                  <div className='flex items-center space-x-2'>
                    <FileIcon className='h-4 w-4' />
                    <span className='text-sm'>{metadata.filename}</span>
                  </div>
                  <div className='flex items-center space-x-2'>
                    <button
                      type='button'
                      onClick={() => handleDownload(cleanedBase64)}
                      className='text-blue-500 hover:text-blue-600 transition-colors'
                      title='Download file'
                    >
                      <Download className='h-4 w-4' />
                    </button>
                    {onRemoveExistingFile && (
                      <button
                        type='button'
                        onClick={() => onRemoveExistingFile(index)}
                        className='text-destructive hover:text-destructive/80 transition-colors'
                        title='Remove file'
                      >
                        <X className='h-4 w-4' />
                      </button>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
