import React, { useState, useEffect } from 'react'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { useQuery, useMutation } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store/store'
import {
  GET_SCORE_WEIGHTS_BY_ORGID,
  UPDATE_SCORE_WEIGHTS_BY_ORGID,
} from '@/graphql/orgQuery'
import { toast } from '@/components/ui/use-toast'
import { hideLoader, showLoader } from '@/store/idea/loaderSlice'
import DonutChart from './DonutChart'

interface ReviewValues {
  relevance: string
  originality: string
  impact: string
  reusability: string
  feasibility: string
}

const FIELD_CONFIG = [
  { param: 'relevance', label: 'Relevance' },
  { param: 'originality', label: 'Originality' },
  { param: 'impact', label: 'Impact' },
  { param: 'reusability', label: 'Reusability' },
  { param: 'feasibility', label: 'Feasibility' },
] as const

const ReviewForm: React.FC = () => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state: RootState) => state.auth)

  // Change state to store values as strings
  const [formValues, setFormValues] = useState<ReviewValues>({
    relevance: '0',
    originality: '0',
    impact: '0',
    reusability: '0',
    feasibility: '0',
  })
  const [total, setTotal] = useState(0)
  const [isEditable, setIsEditable] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { data, loading, error, refetch } = useQuery(
    GET_SCORE_WEIGHTS_BY_ORGID,
    {
      variables: { org_id: currentUser?.org_id },
      skip: !currentUser?.org_id,
    }
  )

  const [updateScoreWeights, { loading: updating }] = useMutation(
    UPDATE_SCORE_WEIGHTS_BY_ORGID,
    {
      onCompleted: () => {
        toast({
          variant: 'success',
          description: 'Score weights updated successfully.',
        })
        refetch() // Refetch the weights after updating
        setIsEditable(false) // Disable form editing after successful update
        dispatch(hideLoader()) // Hide loader
      },
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: `Error updating score weights: ${error.message}`,
        })
        dispatch(hideLoader()) // Hide loader in case of an error
      },
    }
  )

  useEffect(() => {
    if (data?.suhrt_org_score_weights?.length > 0) {
      const {
        relevance_weight,
        originality_weight,
        impact_weight,
        reuse_weight,
        feasibility_weight,
      } = data.suhrt_org_score_weights[0]

      setFormValues({
        relevance: (relevance_weight * 100).toString(), // Convert to string
        originality: (originality_weight * 100).toString(),
        impact: (impact_weight * 100).toString(),
        reusability: (reuse_weight * 100).toString(),
        feasibility: (feasibility_weight * 100).toString(),
      })
    }
  }, [data])

  useEffect(() => {
    // Recalculate total whenever form values change
    const calculatedTotal = Object.values(formValues).reduce(
      (acc, val) => acc + Number(val),
      0
    )
    setTotal(calculatedTotal)

    // Check if the total equals 100 and set error message accordingly
    if (calculatedTotal !== 100) {
      setErrorMessage('Total weights must equal 100%.')
    } else {
      setErrorMessage(null) // Clear error message if total is 100
    }
  }, [formValues]) // Run this effect when formValues change

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    // Convert empty string to '0'
    if (value === '') {
      setFormValues((prev) => ({
        ...prev,
        [name]: '0',
      }))
      return
    }

    // Only allow whole numbers
    const parsedValue = parseInt(value, 10)

    // Check if it's a valid number and within range
    if (!isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 100) {
      setFormValues((prev) => ({
        ...prev,
        [name]: parsedValue.toString(),
      }))
    }
  }

  const handleEditClick = () => {
    setIsEditable(true)
  }

  const handleUpdateClick = async () => {
    dispatch(showLoader()) // Show loader before mutation
    if (total === 100) {
      setErrorMessage(null) // Clear any existing error message
      await updateScoreWeights({
        variables: {
          org_id: currentUser?.org_id,
          relevance_weight: Number(formValues.relevance) / 100, // Convert back to number for mutation
          originality_weight: Number(formValues.originality) / 100,
          impact_weight: Number(formValues.impact) / 100,
          reuse_weight: Number(formValues.reusability) / 100,
          feasibility_weight: Number(formValues.feasibility) / 100,
        },
      })
    } else {
      setErrorMessage('Total weights must equal 100%.') // Set error message if total is not 100
    }
  }
  // Add new handler for keydown events
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement
    const currentValue = parseInt(input.value) || 0

    // Handle up/down arrow keys
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault() // Prevent default arrow key behavior

      const increment = e.key === 'ArrowUp' ? 1 : -1
      const newValue = currentValue + increment

      // Ensure the new value is within bounds (0-100)
      if (newValue >= 0 && newValue <= 100) {
        setFormValues((prev) => ({
          ...prev,
          [input.name]: newValue.toString(),
        }))
      }
    }

    // Prevent decimal point
    if (e.key === '.' || e.key === ',') {
      e.preventDefault()
    }
  }
  return (
    <div className=''>
      <h3 className='text-base font-medium mb-4'>Review Parameters</h3>

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error loading weights: {error.message}</p>
      ) : (
        <div className='grid md:grid-cols-[400px_1fr] gap-6'>
          <Card className='p-6 space-y-2'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
              {FIELD_CONFIG.map(({ param, label }) => (
                <div key={param}>
                  <label className='block mb-2'>{label}</label>
                  <Input
                    type='number'
                    name={param}
                    value={formValues[param as keyof ReviewValues]}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    readOnly={!isEditable} // Make fields editable only if `isEditable` is true
                    className={`mb-2 disabled:opacity-50 ${
                      !isEditable ? 'cursor-not-allowed' : ''
                    }`}
                    max={100}
                    min={0}
                    step={1}
                    disabled={!isEditable}
                  />
                </div>
              ))}
            </div>

            <div className='mt-4 text-lg font-bold'>Total: {total}%</div>

            {errorMessage && (
              <div className='mt-2 text-red-500'>{errorMessage}</div>
            )}

            {currentUser?.role_id !== 3 && (
              <>
                {!isEditable ? (
                  <Button onClick={handleEditClick}>Edit</Button> // Edit button to enable form
                ) : (
                  <Button
                    onClick={handleUpdateClick}
                    disabled={updating || total !== 100}
                  >
                    {updating ? 'Updating...' : 'Update'}
                  </Button>
                )}
              </>
            )}
          </Card>

          {/* Radial Chart with Custom Values */}
          <div className='flex-1'>
            <DonutChart
              reviewParameters={{
                relevance: Number(formValues.relevance),
                originality: Number(formValues.originality),
                impact: Number(formValues.impact),
                reusability: Number(formValues.reusability),
                feasibility: Number(formValues.feasibility),
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default ReviewForm
