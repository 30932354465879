import { formatDate } from '@/helpers/utils'
import React from 'react'

interface Note {
  action: string
  created_dt: string
  note: string
  note_id: number
}

interface StepsProps {
  notes: Note[]
}

const Steps: React.FC<StepsProps> = ({ notes }) => {
  if (!notes) {
    return <p>No notes available</p> // Handle empty notes gracefully
  }

  return (
    <div className='flex flex-col space-y-2'>
      {notes.map((note, index) => (
        <div key={note.note_id} className='flex items-start space-x-4'>
          {/* Circle Indicator with Line */}
          <div className='relative flex flex-col items-center'>
            <div className='w-4 h-4 rounded-full flex items-center justify-center bg-primary mt-[3px]'>
              <div className='w-1.5 h-1.5 rounded-full '></div>
            </div>

            {/* Vertical Line (auto-height based on content) */}
            {index !== notes.length - 1 && (
              <div className='flex-grow w-[2px] bg-muted h-10 inset-y-3 relative'></div>
            )}
          </div>

          {/* Note Content */}
          <div className='relative flex-1 p-2 px-4 bg-muted rounded-md'>
            {/* Arrow pointing to the circle */}
            <div className='absolute -left-1 inset-y-1 w-4 h-4 bg-muted  transform rotate-45'></div>

            <h3 className='text-sm foreground'>
              <span className='font-medium'>{note.action}</span>
              {' • '}
              {formatDate(note.created_dt)}
            </h3>
            <p className='mt-1 text-sm text-muted-foreground'>
              {note.note || note.action}
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Steps
