import { useEffect } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../../ui/button'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../ui/select'
import { RootState } from '@/store/store'
import { updateFilterQuery } from '@/store/idea/ideaSlice'
import { Tag, TagsInput } from '../TagsInput' // Import Tag type from TagsInput
import { Label } from '@/components/ui/label'
import { Category } from '@/types/idea'

interface FilterFormData {
  category: string
  tags: Tag[] // Form data uses string array
}
export default function FilterForm() {
  const dispatch = useDispatch()
  const filterQuery = useSelector((state: RootState) => state.idea.filterQuery)
  const categoriesData: Category[] = useSelector(
    (state: RootState) => state.idea.categories
  )

  const { control, handleSubmit, reset, watch } = useForm<FilterFormData>({
    defaultValues: {
      category: filterQuery.category || '',
      tags: Array.isArray(filterQuery.tags) ? filterQuery.tags : [], // Type guard
    },
  })

  useEffect(() => {
    reset({
      category: filterQuery.category || '',
      tags: Array.isArray(filterQuery.tags) ? filterQuery.tags : [],
    })
  }, [filterQuery, reset])

  const formValues = watch()
  const isSubmitDisabled = !(formValues.category || formValues.tags.length > 0)

  const onSubmit: SubmitHandler<FilterFormData> = (data) => {
    dispatch(updateFilterQuery(data))
  }

  const clearFilter = () => {
    const emptyFilter: FilterFormData = {
      category: '',
      tags: [],
    }
    reset(emptyFilter)
    dispatch(updateFilterQuery(emptyFilter))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='space-y-2'>
      {/* Category Field */}
      <div>
        <Label htmlFor='category' className='block'>
          Category
        </Label>
        <Controller
          control={control}
          name='category'
          render={({ field }) => (
            <Select onValueChange={field.onChange} value={field.value}>
              <SelectTrigger className='width-[200px]'>
                <SelectValue
                  placeholder='Select a category'
                  className='text-muted-foreground relative -left-2'
                />
              </SelectTrigger>
              <SelectContent>
                {categoriesData.map((category: Category) => (
                  <SelectItem
                    key={category.category_id}
                    value={category.category_name}
                  >
                    {category.category_name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
        />
      </div>

      {/* Tags Field */}
      <div>
        <Label className=''>Tags</Label>
        <Controller
          control={control}
          name='tags'
          render={({ field: { value, onChange } }) => {
            const handleTagChange = (tags: Tag[]) => {
              const tagStrings = tags.map((tag) => tag.text)
              onChange(tagStrings)
            }

            const tagObjects: any = (value || []).map((tagText, index) => ({
              id: index.toString(),
              text: tagText,
            }))

            return (
              <TagsInput
                value={tagObjects}
                onChange={handleTagChange}
                placeholder='Enter a tag'
              />
            )
          }}
        />
      </div>

      {/* Action Buttons */}
      <div className='flex flex-col sm:flex-row md:items-center md:justify-end gap-4 pt-4'>
        <Button
          type='button'
          size='sm'
          variant='outline'
          onClick={clearFilter}
          className='text-xs'
        >
          Clear
        </Button>
        <Button
          type='submit'
          size='sm'
          variant='outline'
          className='text-xs'
          disabled={isSubmitDisabled}
        >
          Apply
        </Button>
      </div>
    </form>
  )
}
