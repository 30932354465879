import React from 'react'
import { Button } from '@/components/ui/button' // Adjust import path for your ShadCN button component
import { useNavigate } from 'react-router-dom'

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate()

  return (
    <div className='flex flex-col items-center justify-center min-h-screen bg-background text-foreground'>
      <h1 className='text-6xl font-extrabold mb-4'>404</h1>
      <p className='text-lg mb-6 text-muted-foreground'>
        Oops! The page you're looking for doesn't exist.
      </p>
      <Button onClick={() => navigate('/')} className='px-6 py-3'>
        Go Back Home
      </Button>
    </div>
  )
}

export default NotFoundPage
