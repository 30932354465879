import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import '../app/globals.css'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store/store.ts'
import { Auth0Provider } from '@auth0/auth0-react'
import { ApolloProvider } from '@apollo/client'
import client from './graphql/apolloClient.ts'
import { Toaster } from './components/ui/toaster'
import { DialogProvider } from './components/global-dialog/DialogContext.tsx'

// import { ApolloProvider } from "@apollo/react-hooks"

// Mock Data Setup
// const loadMockServiceWorker = async () => {
//   try {
//     const { worker } = await import("./mocks/browser.ts")
//     await worker.start()
//     console.log("Mock Service Worker loaded successfully")
//   } catch (error) {
//     console.error("Error loading Mock Service Worker:", error)
//   }
// }

const renderApp = async () => {
  // const api = localStorage.getItem("api")
  // if (api === "mock") await loadMockServiceWorker()
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <BrowserRouter>
      <Provider store={store}>
        <Auth0Provider
          domain='dev-x2c72wz1wu73m6jw.us.auth0.com'
          clientId='UKRyf1BXEAigzmTalqssV1QOAuWkaQfP'
          authorizationParams={{
            redirect_uri: window.location.origin,
          }}
        >
          <ApolloProvider client={client}>
            <DialogProvider>
              <App />
            </DialogProvider>
            <Toaster />
          </ApolloProvider>
        </Auth0Provider>
      </Provider>
    </BrowserRouter>
  )
}

renderApp()
