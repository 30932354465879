import React from 'react'
import {
  Paperclip,
  FileIcon,
  ImageIcon,
  AlertCircle,
  Download,
} from 'lucide-react'
import { FcDocument } from 'react-icons/fc'

interface Attachment {
  filename: string
  mimeType: string
  data: string
}

interface DecodedAttachment {
  name: string
  url: string
  mimeType: string
  data: string
}

function hexToString(hex: string): string {
  hex = hex.replace(/^\\x/, '')
  const hexArray = hex.match(/.{1,2}/g)
  const byteArray = new Uint8Array(
    hexArray ? hexArray.map((byte) => parseInt(byte, 16)) : []
  )
  let binary = ''
  byteArray.forEach((byte) => (binary += String.fromCharCode(byte)))
  return binary
}

export function decodeAttachment(
  encodedAttachment: string
): DecodedAttachment | null {
  try {
    let decodedString: string

    if (encodedAttachment.startsWith('\\x')) {
      const hexString = encodedAttachment.slice(2)
      decodedString = hexToString(hexString)
    } else {
      decodedString = encodedAttachment
    }

    const jsonString = atob(decodedString)
    const attachmentData = JSON.parse(jsonString) as Attachment

    if (!attachmentData.filename || !attachmentData.data) {
      throw new Error('Missing required attachment fields')
    }

    return {
      name: attachmentData.filename,
      url: attachmentData.data,
      mimeType: attachmentData.mimeType || 'application/octet-stream',
      data: attachmentData.data,
    }
  } catch (error) {
    console.error('Error decoding attachment:', error, {
      originalString: encodedAttachment,
      isHex: encodedAttachment.startsWith('\\x'),
    })
    return null
  }
}

const handleDownload = (attachment: DecodedAttachment) => {
  try {
    const byteCharacters = atob(attachment.data)
    const byteNumbers = new Array(byteCharacters.length)

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: attachment.mimeType })

    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = attachment.name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    URL.revokeObjectURL(link.href)
  } catch (error) {
    console.error('Error downloading attachment:', error)
  }
}

interface AttachmentsProps {
  attachments?: string[]
}

export const Attachments: React.FC<AttachmentsProps> = ({
  attachments = [],
}) => {
  const getFileIcon = (filename: string) => {
    const extension = filename.split('.').pop()?.toLowerCase()

    switch (extension) {
      case 'pdf':
        return <FileIcon className='mr-2 h-4 w-4' />
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return <ImageIcon className='mr-2 h-4 w-4' />
      default:
        return <FcDocument className='mr-2 h-4 w-4' />
    }
  }

  return (
    <div>
      <h3 className='font-semibold mb-2 flex items-center'>
        <Paperclip className='mr-2 h-4 w-4' />
        Attachments
      </h3>

      {attachments && attachments.length > 0 ? (
        <div className='rounded-lg border p-2 bg-muted/40'>
          {attachments.map((encodedAttachment, index) => {
            const attachment = decodeAttachment(encodedAttachment)

            if (attachment) {
              return (
                <div
                  key={`${attachment.name}-${index}`}
                  className='text-sm flex items-center justify-between mb-2 last:mb-0'
                >
                  <div className='flex items-center'>
                    {getFileIcon(attachment.name)}
                    <span className='mr-4'>{attachment.name}</span>
                  </div>
                  <button
                    onClick={() => handleDownload(attachment)}
                    className='flex items-center text-muted-foreground hover:text-primary transition-colors'
                    title={`Download ${attachment.name}`}
                  >
                    <Download className='h-4 w-4' />
                  </button>
                </div>
              )
            }

            return (
              <div
                key={`error-${index}`}
                className='text-sm text-red-500 flex items-center gap-2'
              >
                <AlertCircle className='h-4 w-4' />
                Error loading attachment
              </div>
            )
          })}
        </div>
      ) : (
        <p className='text-sm text-muted-foreground'>No attachments</p>
      )}
    </div>
  )
}
