import { motion } from 'framer-motion'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'

const innovationPackages = [
  {
    name: 'Suhrt Innovation Starter',
    features: ['Basic Business Plan', 'Basic Wireframes', 'Persona Maps'],
    audience:
      'Individuals or teams with a new idea looking for foundational steps to conceptualize their vision.',
  },
  {
    name: 'Suhrt Innovation Premium',
    features: [
      'Includes all Starter features',
      'Detailed Business Plan',
      'Interactive Wireframes',
      'User Journey Maps',
      'Market Research Insights',
      'Logo and Branding Concepts',
    ],
    audience:
      'Innovators ready to refine their ideas and prepare for potential pitching or development phases.',
  },
  {
    name: 'Suhrt Innovation Advanced',
    features: [
      'Includes all Premium features',
      'Comprehensive Business Plan',
      'Working Proof of Concept (PoC)',
      'Financial Feasibility Analysis',
      'Marketing Strategy Framework',
      'Launch Plan',
    ],
    audience:
      'Entrepreneurs aiming to convert their idea into a market-ready solution.',
  },
]

export function InnovationPackagesTable() {
  return (
    <div className='w-full overflow-x-auto'>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Package Name</TableHead>
            <TableHead>Features Included</TableHead>
            <TableHead>Target Audience</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {innovationPackages.map((pkg, packageIndex) => (
            <motion.tr
              key={pkg.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: packageIndex * 0.2 }}
              className='border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted'
            >
              <TableCell className='font-medium'>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: packageIndex * 0.2 + 0.1 }}
                >
                  {pkg.name}
                </motion.div>
              </TableCell>
              <TableCell>
                <div className='flex flex-wrap gap-2'>
                  {pkg.features.map((feature, index) => (
                    <motion.span
                      key={index}
                      initial={{ opacity: 0, x: -10 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{
                        duration: 0.3,
                        delay: packageIndex * 0.2 + index * 0.1,
                      }}
                      className='inline-flex items-center space-x-1'
                    >
                      <span className='w-1.5 h-1.5 bg-foreground/70 rounded-full' />
                      <span className='text-sm text-muted-foreground'>
                        {feature}
                      </span>
                    </motion.span>
                  ))}
                </div>
              </TableCell>
              <TableCell>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: packageIndex * 0.2 + 0.3 }}
                  className='text-sm text-muted-foreground'
                >
                  {pkg.audience}
                </motion.div>
              </TableCell>
            </motion.tr>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}
