import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useMemo, useCallback } from 'react'
import { UTILITY } from '@/util/utility'
import { useDataTable } from '@/hooks/useDataTable'
import { DataTableFacetedFilter } from '@/components/shared/data-table/DataTableFacetedFilter'
import { DataTable } from '@/components/shared/data-table/DataTable'
import { RootState } from '@/store/store'
import { ColumnDef } from '@tanstack/react-table'
import { List, listColumns } from './components/listColumns'

// Define custom column types with proper filtering
const getUpdatedColumns = (columns: ColumnDef<List>[]) => {
  return columns.map((column) => {
    // Use the id property to identify columns
    if (column.id === 'status') {
      return {
        ...column,
        filterFn: (row: any, columnId: string, filterValue: string[]) => {
          if (!filterValue.length) return true
          const statusMap: Record<number, string> = {
            1: 'draft',
            2: 'in review',
            3: 'approved',
          }
          const rowStatus = statusMap[row.getValue(columnId)]
          return filterValue.includes(rowStatus)
        },
      }
    }

    if (column.id === 'idea_categories') {
      return {
        ...column,
        filterFn: (row: any, columnId: string, filterValue: string[]) => {
          if (!filterValue.length) return true
          const categories = row.getValue(columnId)
          if (!categories || !Array.isArray(categories)) return false
          const categoryNames = categories.map((c) => c.category.category_name)
          return filterValue.some((val) => categoryNames.includes(val))
        },
      }
    }

    return column
  })
}

const ListView = () => {
  const navigate = useNavigate()

  const { ideas, filteredIdeas, filterQuery } = useSelector(
    (state: { idea: any }) => state.idea
  )

  const categoriesData = useSelector(
    (state: RootState) => state.idea.categories
  )

  // Memoize the displayed ideas
  const displayedIdeas = useMemo(() => {
    return filterQuery.category || filterQuery.tags.length
      ? filteredIdeas
      : ideas
  }, [filterQuery.category, filterQuery.tags.length, filteredIdeas, ideas])

  // Memoize formatted ideas
  const formattedIdeas = useMemo(() => {
    return displayedIdeas?.map((idea: any) => ({
      ...idea,
      category: idea?.category?.[0] ?? '-',
    }))
  }, [displayedIdeas])

  // Memoize status options
  const statusOptions = useMemo(
    () => [
      { label: 'Draft', value: 'draft' },
      { label: 'In Review', value: 'in review' },
      { label: 'Approved', value: 'approved' },
    ],
    []
  )

  // Memoize category options
  const categoryOptions = useMemo(
    () =>
      categoriesData.map((c) => ({
        label: c.category_name,
        value: c.category_name,
      })),
    [categoriesData]
  )

  // Memoize row click handler
  const handleRowClick = useCallback(
    (rowData: any) => {
      navigate(`/ideas/${UTILITY.encryptedId(rowData.id)}`)
    },
    [navigate]
  )

  // Get columns with updated filter functions
  const updatedColumns = useMemo(() => getUpdatedColumns(listColumns), [])

  // Initialize table with memoized data and updated columns
  const listViewTable = useDataTable({
    data: formattedIdeas as List[],
    columns: updatedColumns,
  })

  const statusColumn = listViewTable.table.getColumn('status')
  const categoriesColumn = listViewTable.table.getColumn('idea_categories')

  return (
    <div className='p-6'>
      <DataTable
        table={listViewTable.table}
        columns={updatedColumns}
        filterColumn='idea_title'
        filterTitle='Search idea_title...'
        rowClick={handleRowClick}
        showAddUser={false}
        toolbarFilters={
          <div className='filters md:mt-0 mt-4'>
            {statusColumn && (
              <DataTableFacetedFilter
                column={statusColumn}
                title='Status'
                options={statusOptions}
              />
            )}
            {categoriesColumn && (
              <DataTableFacetedFilter
                column={categoriesColumn}
                title='Categories'
                options={categoryOptions}
              />
            )}
          </div>
        }
      />
    </div>
  )
}

export default ListView
