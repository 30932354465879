import React, { useCallback } from 'react'
import { Table } from '@tanstack/react-table'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { X } from 'lucide-react'
import { DataTableViewOptions } from './DataTableViewOptions'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { useDialog } from '@/components/global-dialog/DialogContext'
import { AddUser } from '@/components/organisation/users/components/AddUser'

interface DataTableToolbarProps<TData> {
  table: Table<TData>
  filterColumn?: string
  filterTitle?: string
  children?: React.ReactNode
  showAddUser?: boolean
}
const userColumnsLabels = {
  username: 'User Name',
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
  role: 'Role',
  last_logged_in: 'Last Logged In',
  invitation_status: 'Invitation status',
  invitation_sent_time: 'Invitation Sent Time',
  invitation_expiry: 'Invitation Expiry',
}
const listColumnsLabels = {
  idea_title: 'Idea Title',
  idea_categories: 'Idea Categories',
  status: 'Status',
  idea_scores: 'Idea Scores',
  idea_dt: 'Idea Date',
}
export function DataTableToolbar<TData>({
  table,
  filterColumn,
  filterTitle,
  children,
  showAddUser,
}: DataTableToolbarProps<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0
  const { currentUser } = useSelector((state: RootState) => state.auth)
  const { openDialog, closeDialog } = useDialog()

  const handleAddUser = useCallback(() => {
    openDialog({
      title: 'Add User',
      description: '',
      content: <AddUser initialData={undefined} />,
      confirmAction: () => {
        closeDialog()
      },
      width: 'md:w-96',
      height: 'h-auto',
    })
  }, [openDialog, closeDialog])

  return (
    <div className='flex items-center justify-between flex-col md:flex-row mb-4 md:m-0'>
      <div className='flex md:flex-1 items-center space-x-2 flex-col md:flex-row mb-4 md:m-0 w-full md:w-[550px]'>
        {filterColumn && (
          <Input
            placeholder={filterTitle || `Filter ${filterColumn}...`}
            value={
              (table.getColumn(filterColumn)?.getFilterValue() as string) ?? ''
            }
            onChange={(event) =>
              table.getColumn(filterColumn)?.setFilterValue(event.target.value)
            }
            className='h-8 w-full lg:w-[250px]'
          />
        )}
        {children}
        {isFiltered && (
          <Button
            variant='ghost'
            onClick={() => table.resetColumnFilters()}
            className='h-8 px-2 lg:px-3'
            size='sm'
          >
            Reset
            <X className='ml-2 h-4 w-4' />
          </Button>
        )}
      </div>

      <div className='flex items-center space-x-2'>
        {showAddUser && currentUser?.role_id !== 3 && (
          <Button
            variant='outline'
            onClick={handleAddUser}
            className='h-8 px-2 lg:px-3'
            size='sm'
          >
            Add User
          </Button>
        )}
        {showAddUser ? (
          <DataTableViewOptions
            table={table}
            columnLabels={userColumnsLabels}
          />
        ) : (
          <DataTableViewOptions
            table={table}
            columnLabels={listColumnsLabels}
          />
        )}
      </div>
    </div>
  )
}
