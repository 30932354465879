import React from 'react'
import { Card } from '@/components/ui/card'
import { ScrollArea } from '@/components/ui/react-scroll-area'
import { FaExpand } from 'react-icons/fa6'
import { Idea } from '@/types/idea'
import { IdeaCard } from './IdeaCard'

interface MonthCardProps {
  ideas: Idea[]
  newlyAddedIdeas: Set<string>
  onIdeaClick: (id: string | number) => void
  onMaximizeClick: () => void
}

export const MonthCard: React.FC<MonthCardProps> = ({
  ideas,
  newlyAddedIdeas,
  onIdeaClick,
  onMaximizeClick,
}) => (
  <Card className='flex flex-col border-primary dark:bg-transparent dark:border-primary/30 relative h-[24rem]'>
    <div className='relative -top-5 bg-background flex self-center justify-self-center justify-center w-8 h-8 p-1 rounded-full shadow-outline shadow-primary shadow-[0px_0px_5px_0px_#38bdff]'>
      {ideas.length}
    </div>
    <FaExpand
      className='absolute top-0 right-0 pr-1 pt-1 text-xl text-slate-300 cursor-pointer hover:text-primary'
      onClick={onMaximizeClick}
    />
    <ScrollArea className='h-dvh'>
      <div className='grid md:grid-cols-4 grid-cols-2 gap-2'>
        {ideas.map((idea) => (
          <IdeaCard
            key={idea.id}
            {...idea}
            newlyAdded={newlyAddedIdeas.has(idea.id)}
            onClick={onIdeaClick}
          />
        ))}
      </div>
    </ScrollArea>
  </Card>
)
