import { gql } from '@apollo/client'

export const GET_USERS = gql`
  query GET_USERS {
    suhrt_users {
      id
      username
      first_name
      last_name
      email
      org_id
      role_id
      picture
      isActive
    }
  }
`

export const GET_USER_BY_EMAIL = gql`
  query GET_USER_BY_EMAIL($email: String!) {
    suhrt_users(where: { email: { _eq: $email } }) {
      id
      username
      first_name
      last_name
      email
      org_id
      role_id
      picture
      isActive
    }
  }
`

export const CREATE_USER = gql`
  mutation CREATE_USER($object: suhrt_users_insert_input!) {
    insert_suhrt_users_one(object: $object) {
      id
      username
      first_name
      last_name
      email
      org_id
      role_id
      picture
      isActive
      invitation_status
      invitation_sent_time
      invitation_expiry
    }
  }
`
export const UPDATE_USER_ACTIVE_STATUS = gql`
  mutation UpdateUserActiveStatus($user_id: Int!, $isActive: Boolean!) {
    update_suhrt_users_by_pk(
      pk_columns: { id: $user_id }
      _set: { isActive: $isActive }
    ) {
      id
      isActive
    }
  }
`
export const DELETE_USER = gql`
  mutation DELETE_USER($id: Int!) {
    delete_suhrt_users_by_pk(id: $id) {
      id
    }
  }
`

// export const UPDATE_USER_INFO = gql`
//   mutation UPDATE_USER_INFO(
//     $id: Int!
//     $first_name: String
//     $last_name: String
//   ) {
//     update_suhrt_users_by_pk(
//       pk_columns: { id: $id }
//       _set: { first_name: $first_name, last_name: $last_name }
//     ) {
//       id
//       first_name
//       last_name
//       email
//       username
//       role_id
//       org_id
//       picture
//       isActive
//     }
//   }
// `
export const UPDATE_USER_INFO = gql`
  mutation UpdateUser($id: Int!, $input: suhrt_users_set_input!) {
    update_suhrt_users(where: { id: { _eq: $id } }, _set: $input) {
      returning {
        id
        email
        first_name
        last_name
        picture
        username
        organisation {
          name
          id
          org_details
        }
        role {
          id
          role
        }
      }
    }
  }
`
export const UPDATE_USER_ORG = gql`
  mutation UPDATE_USER_ORG($email: String!, $org_id: Int!) {
    update_suhrt_users(
      where: { email: { _eq: $email } }
      _set: { org_id: $org_id }
    ) {
      returning {
        id
        email
        org_id
        organisation {
          name
        }
      }
    }
  }
`
export const GetCollaboratorsByOrgID = gql`
  query GetUsersByOrgID($org_id: Int!, $user_id: Int!) {
    suhrt_users(where: { org_id: { _eq: $org_id }, id: { _neq: $user_id } }) {
      id
      email
      username
      first_name
      last_name
      role_id
      invitation_status
    }
  }
`
export const GET_ALL_USERS_BY_ORGID = gql`
  query GetUsersByOrgID($org_id: Int!) {
    suhrt_users(where: { org_id: { _eq: $org_id } }) {
      id
      first_name
      last_name
      email
      username
      last_logged_in
      invitation_status
      invitation_sent_time
      invitation_expiry
      role {
        id
        role
      }
    }
  }
`
export const GET_ROLES = gql`
  query GetRoles {
    suhrt_roles(where: { id: { _in: [2, 3] } }) {
      id
      role
    }
  }
`
export const UPDATE_LASTLOGGED_IN = gql`
  mutation UpdateLastLoggedIn($user_id: Int!) {
    update_suhrt_users_by_pk(
      pk_columns: { id: $user_id }
      _set: {
        invitation_status: "accepted"
        invitation_expiry: null
        last_logged_in: "now()"
      }
    ) {
      id
      invitation_status
      invitation_expiry
      last_logged_in
      username
    }
  }
`
export const UPDATE_USER_INVITATION = gql`
  mutation UpdateUserInvitation($user_id: Int!, $expiry_time: timestamptz!) {
    update_suhrt_users_by_pk(
      pk_columns: { id: $user_id }
      _set: {
        invitation_status: "sent"
        invitation_sent_time: "now()" # You can use now() here to set current timestamp
        invitation_expiry: $expiry_time # Send precomputed expiry time here
      }
    ) {
      id
      invitation_status
      invitation_sent_time
      invitation_expiry
    }
  }
`

export const BOOK_DEMO = gql`
  mutation demo_request($input: suhrt_demo_request_insert_input!) {
    insert_suhrt_demo_request(objects: [$input]) {
      returning {
        id
        name
        email
        company
        phone
        preferred_date
        preferred_time
        message
      }
    }
  }
`
