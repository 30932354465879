'use client'

import { motion } from 'framer-motion'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'

interface FeatureCardProps {
  icon: React.ReactNode
  title: string
  description: string
}

export function FeatureCard({ icon, title, description }: FeatureCardProps) {
  return (
    <Card className='relative bg-background border border-primary/10 overflow-hidden group md:h-[320px] h-[340px] transition-all duration-300 hover:border-primary/30 hover:shadow-md hover:shadow-primary/5 flex flex-col'>
      <CardHeader>
        <motion.div
          whileHover={{ scale: 1.05 }}
          className='rounded-full border-2 border-primary/20 h-16 w-16 flex items-center justify-center bg-primary/5 relative'
        >
          <div className='text-primary'>{icon}</div>
        </motion.div>
      </CardHeader>

      <CardContent className='space-y-4 relative flex flex-col flex-grow'>
        <CardTitle className='text-xl text-foreground group-hover:text-primary transition-colors duration-300'>
          {title}
        </CardTitle>
        <CardDescription className='text-muted-foreground text-base leading-relaxed flex-grow'>
          {description}
        </CardDescription>
        {/* <a
          href='#'
          className='flex justify-center text-primary hover:text-primary/80 transition-colors duration-300 flex items-center gap-2 mt-auto text-center'
        >
          Learn More <FaArrowRight className='text-sm' />
        </a> */}
      </CardContent>
      <div className='absolute bottom-0 left-0 w-full h-1 bg-primary scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left'></div>
    </Card>
  )
}
