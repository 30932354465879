'use client'

import { motion } from 'framer-motion'
import { Card, CardContent } from '@/components/ui/card'
import { CheckCircle2 } from 'lucide-react'

const benefits = [
  'Access to skilled gig professionals',
  'Rapid idea implementation',
  'Curated Suhrt Innovation Packages',
  'Quality-assured talent pool',
  'Cost-effective solutions',
  'Seamless collaboration',
]

export function PartnershipSection() {
  return (
    <div className='container mx-auto px-4'>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5 }}
        className='text-center mb-16'
      >
        <h5 className='text-3xl md:text-4xl font-bold text-foreground mb-4'>
          Strategic Partnership with Workflexi.in
        </h5>
        <p className='text-muted-foreground max-w-2xl mx-auto text-base'>
          Our partnership with Workflexi offers quick access to skilled gig
          professionals, rapidly turning your ideas into tangible outcomes.
        </p>
      </motion.div>

      <div className='mb-16'>
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <Card className='relative bg-background border border-primary/10 overflow-hidden group  h-auto transition-all duration-300 hover:border-primary/30 hover:shadow-md hover:shadow-primary/5 flex flex-col'>
            <div className='absolute inset-0 rounded-lg opacity-100 group-hover:opacity-50 transition-opacity duration-300' />
            <CardContent className='relative p-6 flex flex-col items-center space-y-6 h-full'>
              <div className='flex items-center justify-center w-full'>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  className='relative cursor-pointer'
                  onClick={() =>
                    window.open('https://www.workflexi.in/', '_blank')
                  }
                >
                  <div className='p-5 w-32 h-32 bg-primary/5 rounded-full flex items-center justify-center border-2 border-primary/20 group-hover:border-primary/30 transition-colors duration-300'>
                    <img
                      src='https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-60puHQrHEZh4gjLOj8xbRDaH9kjlkE.png'
                      alt='Workflexi Logo'
                      className='w-24 h-auto'
                    />
                  </div>
                </motion.div>
              </div>

              <div className='w-full grid grid-cols-2 gap-4 flex-grow'>
                {benefits.map((benefit, index) => (
                  <motion.div
                    key={benefit}
                    initial={{ opacity: 0, y: 10 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    className='flex items-start space-x-2 group-hover:transform group-hover:translate-y-0 transition-transform duration-300'
                  >
                    <CheckCircle2 className='w-5 h-5 text-emerald-500 shrink-0 mt-0.5 group-hover:text-primary transition-colors duration-300' />
                    <span className='text-sm text-muted-foreground group-hover:text-foreground/80 transition-colors duration-300'>
                      {benefit}
                    </span>
                  </motion.div>
                ))}
              </div>
            </CardContent>
            <div className='absolute bottom-0 left-0 w-full h-1 bg-primary scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left'></div>
          </Card>
        </motion.div>
      </div>
    </div>
  )
}
