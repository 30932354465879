import React, { ReactNode } from 'react';
interface ContainerProps {
  children: ReactNode;
  className:string;
  onClick?: () => void;
}
const IconWrapper: React.FC<ContainerProps>= ({children,className,onClick}) => {
  return (
    <div className={`rounded-full flex items-center justify-center transition-all ${className}`} onClick={onClick}>{children}
</div>
  ) 
}

export default IconWrapper
