import { Separator } from '@/components/ui/separator'
// import { NotificationsForm } from './NotificationForm'

export default function Notification() {
  return (
    <div className='space-y-6'>
      <div>
        <h3 className='text-lg font-medium'>Notification</h3>
        <p className=' text-muted-foreground'>
          Configure how you receive notifications.
        </p>
      </div>
      <Separator />
      <div className='flex items-center justify-center h-[300px]'>
        This Page is coming soon
      </div>
      {/* <NotificationsForm /> */}
    </div>
  )
}
