import { gql } from '@apollo/client'
export const CONTACT_US = gql`
  mutation contacts($input: suhrt_contacts_insert_input!) {
    insert_suhrt_contacts(objects: [$input]) {
      returning {
        id
        name
        email
        subject
        message
      }
    }
  }
`
