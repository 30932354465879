'use client'

import { motion } from 'framer-motion'
import { Button } from '@/components/ui/button'
import { useDialog } from '../global-dialog/DialogContext'
import { DemoForm } from './DemoForm'

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
}

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
}

export function CTASection() {
  const { openDialog, closeDialog } = useDialog()
  const openDemoForm = () => {
    openDialog({
      title: `Request for a Demo`,
      description: '',
      content: <DemoForm />,
      confirmAction: () => {
        closeDialog()
      },
      width: 'md:w-[800px]',
      height: 'md:h-[550px]',
    })
  }
  return (
    <section className='container mx-auto px-4'>
      <motion.div
        variants={containerVariants}
        initial='hidden'
        whileInView='visible'
        viewport={{ once: true }}
        className='max-w-4xl mx-auto text-center space-y-8'
      >
        <motion.h6
          variants={itemVariants}
          className='text-3xl md:text-4xl font-bold text-foreground leading-tight'
        >
          Ready to Make More Innovation Happen, Ready to Fuel Innovation and
          Drive Growth?
        </motion.h6>
        <motion.p
          variants={itemVariants}
          className='text-base text-muted-foreground'
        >
          Discover the power of Suhrt Innovation! Schedule a demo for a
          personalized walkthrough
        </motion.p>
        <motion.div variants={itemVariants} className='pt-4'>
          <Button
            size='lg'
            variant='default'
            className='text-primary-foreground min-w-[200px] text-base  px-12'
            onClick={openDemoForm}
          >
            Book a demo now
          </Button>
        </motion.div>
      </motion.div>
    </section>
  )
}
