import { useState, useEffect } from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'

interface ReviewParameters {
  relevance: number
  originality: number
  impact: number
  reusability: number
  feasibility: number
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8']

const calculatePercentages = (values: ReviewParameters) => {
  const total = Object.values(values).reduce((sum, value) => sum + value, 0)
  return Object.entries(values).map(([key, value], index) => ({
    name: key.charAt(0).toUpperCase() + key.slice(1),
    value: parseFloat(((value / total) * 100).toFixed(1)),
    color: COLORS[index % COLORS.length],
  }))
}

export default function DonutChart({
  reviewParameters,
}: {
  reviewParameters: ReviewParameters
}) {
  // Initialize theme state and ensure it updates from localStorage
  const [theme, setTheme] = useState<'light' | 'dark'>(
    () => (localStorage.getItem('theme') as 'light' | 'dark') || 'light'
  )

  useEffect(() => {
    // Listen for theme changes in the root document class
    const handleThemeChange = () => {
      const root = document.documentElement
      setTheme(root.classList.contains('dark') ? 'dark' : 'light')
    }

    // Add mutation observer to watch for class changes
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'class'
        ) {
          handleThemeChange()
        }
      })
    })

    observer.observe(document.documentElement, { attributes: true })

    // Initial theme check
    handleThemeChange()

    // Cleanup the observer
    return () => observer.disconnect()
  }, [])

  // Dynamically change color based on the theme
  const getTextColor = (theme: 'light' | 'dark') => {
    return theme === 'dark'
      ? 'hsl(0, 0%, 98%)' // Dark theme color
      : 'hsl(240, 10%, 3.9%)' // Light theme color
  }

  const data = calculatePercentages(reviewParameters)

  return (
    <Card className='w-full'>
      <CardHeader>
        <CardTitle className='mb-0'>Review Parameters</CardTitle>
      </CardHeader>
      <CardContent>
        <div className='mx-auto w-full h-[300px]'>
          <ResponsiveContainer width='100%' height='100%'>
            <PieChart>
              <Pie
                data={data}
                dataKey='value'
                nameKey='name'
                cx='50%'
                cy='50%'
                innerRadius={60}
                outerRadius={100}
                stroke='none'
                label={({
                  name,
                  value,
                  cx,
                  cy,
                  midAngle,
                  innerRadius,
                  outerRadius,
                }) => {
                  const RADIAN = Math.PI / 180
                  const radius = innerRadius + (outerRadius - innerRadius) * 1.5 // Increased padding for labels
                  const padding = 0 // Additional padding
                  const x = cx + radius * Math.cos(-midAngle * RADIAN) + padding
                  const y = cy + radius * Math.sin(-midAngle * RADIAN) + padding

                  const textColor = getTextColor(theme) // Get dynamic color

                  return (
                    <text
                      x={x}
                      y={y}
                      fill={textColor} // Apply dynamic text color
                      textAnchor={x > cx ? 'start' : 'end'}
                      dominantBaseline='central'
                      className='text-sm font-medium'
                    >
                      {name} - {value}%
                    </text>
                  )
                }}
                paddingAngle={0}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip
                formatter={(value) => [`${value}%`, 'Percentage']}
                contentStyle={{ backgroundColor: '#fff', borderRadius: '4px' }}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  )
}
