import React, { useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from '@apollo/client'
import { RootState } from '@/store/store'
import { GET_ALL_USERS_BY_ORGID } from '@/graphql/userQuery'
import { hideLoader, showLoader } from '@/store/idea/loaderSlice'
import { useDataTable } from '@/hooks/useDataTable'
import { DataTable } from '@/components/shared/data-table/DataTable'
import { DataTableFacetedFilter } from '@/components/shared/data-table/DataTableFacetedFilter'
import { Separator } from '@/components/ui/separator'
import { roles } from './data/data'
import { ColumnDef } from '@tanstack/react-table'
import { User, useUserColumns } from './components/columns'

// Define custom column types with proper filtering
const getUpdatedUserColumns = (columns: ColumnDef<User>[]) => {
  return columns.map((column) => {
    if (column.id === 'role') {
      return {
        ...column,
        filterFn: (row: any, columnId: string, filterValue: string[]) => {
          if (!filterValue.length) return true
          const role = row.getValue(columnId) as { id: number; role: string }
          return role?.role
            ? filterValue.includes(role.role.toLowerCase())
            : false
        },
      }
    }
    return column
  })
}

const Users: React.FC = () => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state: RootState) => state.auth)
  const userColumns = useUserColumns()
  // GraphQL query with proper error handling
  const { data, loading, error, refetch } = useQuery(GET_ALL_USERS_BY_ORGID, {
    variables: { org_id: currentUser?.org_id },
    skip: !currentUser?.org_id,
    fetchPolicy: 'network-only',
  })

  // Handle loading state
  useEffect(() => {
    if (loading) {
      dispatch(showLoader())
    } else {
      dispatch(hideLoader())
    }
  }, [loading, dispatch])

  // Memoize role options
  const roleOptions = useMemo(
    () =>
      roles.map((role) => ({
        label: role.label,
        value: role.value.toLowerCase(),
      })),
    []
  )

  // Memoize updated columns with filter functions
  const updatedColumns = useMemo(() => getUpdatedUserColumns(userColumns), [])

  // Memoize users data
  const users = useMemo(() => data?.suhrt_users || [], [data])

  // Initialize table with memoized data and updated columns
  const userTable = useDataTable({
    data: users as User[],
    columns: updatedColumns,
  })

  // Memoize retry handler
  const handleRetry = useCallback(() => {
    refetch()
  }, [refetch])

  // Error state
  if (error) {
    return (
      <div className='flex flex-col items-center space-y-4'>
        <p className='text-red-500'>Error fetching users. Please try again.</p>
        <button
          onClick={handleRetry}
          className='px-4 py-2 bg-primary text-white rounded-md'
        >
          Retry
        </button>
      </div>
    )
  }

  // Empty state
  if (!loading && users.length === 0) {
    return <p>No users found for this organization.</p>
  }

  const roleColumn = userTable.table.getColumn('role')

  return (
    <div className='space-y-6'>
      <header className='flex flex-col items-start'>
        <h3 className='text-lg font-medium'>Users</h3>
        <p className='text-muted-foreground'>
          Manage your users and references.
        </p>
      </header>
      <Separator />
      <div className='rounded-xl bg-card text-card-foreground col-span-3'>
        <DataTable
          table={userTable.table}
          columns={updatedColumns}
          filterColumn='username'
          filterTitle='Search username...'
          showAddUser={true}
          toolbarFilters={
            <div className='filters filters md:mt-0 mt-4'>
              <div>
                {roleColumn && (
                  <DataTableFacetedFilter
                    column={roleColumn}
                    title='Role'
                    options={roleOptions}
                  />
                )}
              </div>
            </div>
          }
        />
      </div>
    </div>
  )
}

export default Users
