import React from 'react'
const StatusCircle: React.FC<{ className: string; status: string }> =
  React.memo(({ className, status }) => {
    return (
      <div className='flex items-center space-x-2'>
        <div className={`w-4 h-4 ${className} rounded-full`} /> {/* Circle */}
        <span className=' '>{status}</span> {/* Text */}
      </div>
    )
  })

export default StatusCircle
