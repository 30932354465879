import {
  GET_IDEA_DETAILS,
  GET_IDEA_DETAILS_FOR_ADMIN,
  GET_IDEA_BY_ID,
  UpdateIdeaStatus,
  UpdateIdeaVisibility,
} from '@/graphql/ideaQuery'
import { Idea } from '@/types/idea'
import { useMutation, useQuery } from '@apollo/client'
import { Badge } from '@/components/ui/badge'
import IdeaTabs from '../IdeaTabs'
import { useDialog } from '@/components/global-dialog/DialogContext'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import {
  GET_LIKE_BY_USER_FOR_IDEA,
  LIKE,
  UNLIKE_IDEA,
} from '@/graphql/likeQuery'
import { ScoresProps } from '../views/SuhrtScoreParams'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/components/ui/select'
import { Eye, MessageCircle, Tag, ThumbsUp } from 'lucide-react'
import { Separator } from '@/components/ui/separator'
import { useState, useCallback, useMemo } from 'react'
import { ACTION, IDEA_STATUS } from '../../../suhrt/idea_status'

import ReviewIdeaForm from '@/components/ideas/review-idea/ReviewIdeaForm'

import { FaLightbulb } from 'react-icons/fa'
import { Attachments } from './Attachments'
import { hideLoader, showLoader } from '@/store/idea/loaderSlice'
import { useDispatch } from 'react-redux'
import { useToast } from '@/components/ui/use-toast'
import { IdeaCollaborators } from './IdeaCollaborators'
import { BusinessCanvasFile } from './BusinessCanvasFile'
import { clearEvent } from '@/store/idea/ideaSlice'
import IdeaVisibilityRadio from '../forms/IdeaForm/IdeaVisibilityRadio'

type Prop = {
  ideaDetails: Idea
  ideaScores?: ScoresProps
}

export default function IdeaSummary({ ideaDetails, ideaScores }: Prop) {
  const { openDialog, closeDialog } = useDialog()
  const { currentUser } = useSelector((state: RootState) => state.auth)
  const [action] = useState<ACTION>(ACTION.NONE)

  const { data, refetch } = useQuery(GET_LIKE_BY_USER_FOR_IDEA, {
    variables: { ideaId: ideaDetails.id, userId: currentUser?.id },
  })

  const [visibility, setVisibility] = useState(
    ideaDetails?.visibility.toString()
  )
  const [updateIdeaVisibility] = useMutation(UpdateIdeaVisibility)

  const [likeIdea] =
    currentUser?.role_id === 1
      ? useMutation(LIKE, {
          refetchQueries: [
            {
              query: GET_IDEA_BY_ID,
              variables: { id: ideaDetails.id },
            },
          ],
        })
      : currentUser?.role_id === 2
      ? useMutation(LIKE, {
          refetchQueries: [
            {
              query: GET_IDEA_DETAILS_FOR_ADMIN,
              variables: {
                idea_id: ideaDetails.id,
                org_id: currentUser?.org_id,
              },
            },
          ],
        })
      : useMutation(LIKE, {
          refetchQueries: [
            {
              query: GET_IDEA_DETAILS,
              variables: {
                idea_id: ideaDetails.id,
                org_id: currentUser?.org_id,
                user_id: currentUser?.id,
              },
            },
          ],
        })

  const [unlikeIdea] =
    currentUser?.role_id === 1
      ? useMutation(UNLIKE_IDEA, {
          refetchQueries: [
            {
              query: GET_IDEA_BY_ID,
              variables: { id: ideaDetails.id },
            },
          ],
        })
      : currentUser?.role_id === 2
      ? useMutation(UNLIKE_IDEA, {
          refetchQueries: [
            {
              query: GET_IDEA_DETAILS_FOR_ADMIN,
              variables: {
                idea_id: ideaDetails.id,
                org_id: currentUser?.org_id,
              },
            },
          ],
        })
      : useMutation(UNLIKE_IDEA, {
          refetchQueries: [
            {
              query: GET_IDEA_DETAILS,
              variables: {
                idea_id: ideaDetails.id,
                org_id: currentUser?.org_id,
                user_id: currentUser?.id,
              },
            },
          ],
        })

  const [updateIdeaStatus] =
    currentUser?.role_id === 1
      ? useMutation(UpdateIdeaStatus, {
          refetchQueries: [
            {
              query: GET_IDEA_BY_ID,
              variables: { id: ideaDetails.id },
            },
          ],
        })
      : currentUser?.role_id === 2
      ? useMutation(UpdateIdeaStatus, {
          refetchQueries: [
            {
              query: GET_IDEA_DETAILS_FOR_ADMIN,
              variables: {
                idea_id: ideaDetails.id,
                org_id: currentUser?.org_id,
              },
            },
          ],
        })
      : useMutation(UpdateIdeaStatus, {
          refetchQueries: [
            {
              query: GET_IDEA_DETAILS,
              variables: {
                idea_id: ideaDetails.id,
                org_id: currentUser?.org_id,
                user_id: currentUser?.id,
              },
            },
          ],
        })

  const likesCount =
    (ideaDetails.likes && ideaDetails.likes.aggregate?.count) ?? 0
  const userHasLiked = data?.suhrt_likes.length > 0
  const commentsCount = ideaDetails.comments?.aggregate?.count ?? 0
  const toggleLikeHandler = async () => {
    if (!currentUser) return
    try {
      if (userHasLiked) {
        await unlikeIdea({
          variables: {
            idea_id: ideaDetails.id,
            liked_by: currentUser.id,
          },
        })
      } else {
        await likeIdea({
          variables: {
            object: { idea_id: ideaDetails.id, liked_by: currentUser.id },
          },
        })
      }
      await refetch()
    } catch (error) {
      console.log(error)
    }
  }

  const editIdea = () => {
    dispatch(clearEvent())
    openDialog({
      title: 'Edit Idea',
      description: '',
      content: <IdeaTabs idea={ideaDetails} ideaScores={ideaScores} />,
      confirmAction: () => {
        closeDialog()
      },
      width: 'md:w-[95vw]',
      height: 'h-[90vh]',
      showFooter: true,
      showFooterButtons: true,
      requireConfirmation: true,
    })
  }

  const dispatch = useDispatch()
  const { toast } = useToast()

  const submitIdea = async () => {
    try {
      dispatch(showLoader())
      const response = await updateIdeaStatus({
        variables: {
          idea_id: ideaDetails.id,
          status_id: 2,
          note: 'Sent for Review',
          user_id: ideaDetails.idea_by,
          action: 'Sent for Review',
        },
      })
      console.log('Mutation response:', response.data)
      dispatch(hideLoader())
      closeDialog()
      toast({
        variant: 'success',
        description: 'Idea submitted successfully',
      })
    } catch (error) {
      console.error('Error updating idea status:', error)
      dispatch(hideLoader())
      closeDialog()
    }
  }

  const approveOrSendBack = (action: ACTION) => {
    console.log('====APPROVINTG===')
    openDialog({
      title: `Review Idea: ${ideaDetails?.idea_title}`,
      description: action,
      content: <ReviewIdeaForm ideaDetails={ideaDetails} action={action} />,
      confirmAction: () => {
        closeDialog()
      },
      width: 'md:w-[auto] md:min-w-[700px]',
      height: 'h-auto',
    })
  }

  const handleChangeVisibility = async (value: string) => {
    try {
      dispatch(showLoader())
      const result = await updateIdeaVisibility({
        variables: {
          idea_id: ideaDetails.id,
          visibility: value,
          note: 'Updated visibility to all users across the organisation',
          user_id: ideaDetails.idea_by,
          action: 'Idea Visibility Updated',
        },
      })
      console.log(result)
      dispatch(hideLoader())
      closeDialog()
      toast({
        variant: 'success',
        description: 'Idea Visibility Updated successfully',
      })
      setVisibility(value)
    } catch (error) {
      console.error('Error updating idea status:', error)
      dispatch(hideLoader())
      closeDialog()
    }
  }

  const handleVisibilityClick = () => {
    openDialog({
      title: `Who can see this idea`,
      description: '',
      content: (
        <IdeaVisibilityRadio
          value={visibility}
          onChange={handleChangeVisibility}
          insideDialog={true}
        />
      ),
      width: 'md:w-[auto]',
      height: 'h-auto',
      showFooter: false,
    })
  }
  const handleChange = (e: any) => {
    switch (e) {
      case ACTION.EDIT:
        editIdea()
        break
      case ACTION.SUBMIT:
        submitIdea()
        break
      case ACTION.APPROVE:
      case ACTION.SEND_BACK:
        approveOrSendBack(e)
        break
    }
  }

  const isOwner = useCallback(() => {
    return ideaDetails?.idea_by === currentUser?.id
  }, [ideaDetails?.idea_by, currentUser?.id])

  const isCollaborator = useCallback(() => {
    const collaborator = ideaDetails?.idea_collaborators?.find(
      (c) => c.user.id === currentUser?.id
    )
    if (collaborator) return true
    else return false
  }, [ideaDetails?.idea_collaborators, currentUser?.id])

  const isDisabled = useCallback(() => {
    switch (ideaDetails?.status) {
      case 1: //Draft
      case 5: //Rejected
        if (!isOwner() && !isCollaborator()) {
          return true
        }
        return false
      case 2: //In Review
        if (currentUser?.role_id === 2) {
          //2: org admin
          return false
        }
        return true
      case 3: //Approved
        return true
      default:
        return false
    }
  }, [ideaDetails?.status, currentUser?.role_id, ideaDetails?.idea_by])
  const bulbStyleMap: {
    1: { text: string; bg: string }
    2: { text: string; bg: string }
    3: { text: string; bg: string }
    5: { text: string; bg: string }
  } = {
    1: { text: 'text-primary', bg: 'bg-primary/20' },
    2: { text: 'text-accent', bg: 'bg-accent/20' },
    3: { text: 'text-lime-500', bg: 'bg-lime-100 dark:bg-lime-900/50' },
    5: { text: 'text-red-500', bg: 'bg-red-100 ' },
  }

  // Type-safe fallback for invalid status
  const bulbStyles = bulbStyleMap[
    ideaDetails?.status as keyof typeof bulbStyleMap
  ] || {
    text: 'text-primary',
    bg: 'bg-primary/20',
  }
  interface User {
    id: number
    username: string
    first_name: string
    last_name: string
    email: string
    org_id: number
    role_id: number
    picture: string
    isActive?: boolean
  }

  const collaborators = useCallback((): any => {
    const ideaCreator: any = {
      id: ideaDetails?.user?.id,
      username: ideaDetails?.user?.username,
      first_name: ideaDetails?.user?.first_name,
      last_name: ideaDetails?.user?.last_name,
      email: ideaDetails?.user?.email,
      org_id: ideaDetails?.user?.org_id,
      role_id: ideaDetails?.user?.role_id,
      picture: ideaDetails?.user?.picture || '',
      isActive: ideaDetails?.user?.isActive,
    }

    const collaboratorUsers: User[] =
      ideaDetails?.idea_collaborators?.map((c) => ({
        id: c.user.id,
        username: c.user.username,
        first_name: c.user.first_name,
        last_name: c.user.last_name,
        email: c.user.email,
        org_id: c.user.org_id,
        role_id: c.user.role_id,
        picture: c.user.picture || '',
        isActive: c.user.isActive,
      })) || []

    return [
      ideaCreator,
      ...collaboratorUsers.filter((user) => user.id !== ideaCreator.id),
    ]
  }, [ideaDetails?.user, ideaDetails?.idea_collaborators])

  const showEye = useMemo(() => {
    if (currentUser?.role_id === 1)
      //super user
      return true
    if (currentUser?.role_id === 2 && ideaDetails?.status !== 1)
      //admin and status not draft
      return true
    if (ideaDetails?.status === 1 && isOwner()) return true
    return false
  }, [ideaDetails?.status, currentUser?.role_id, isOwner])

  return (
    <Card className='w-full border-0 shadow-none'>
      <CardHeader
        className={`rounded-t-md space-y-0 py-4 md:px-6 px-4 items-start mb-4 ${bulbStyles.bg}`}
      >
        <CardTitle className='text-base mb-0 flex items-center'>
          <div className='mr-4 h-10 w-10 rounded-full bg-white dark:bg-foreground flex items-center justify-center'>
            <FaLightbulb
              className={`{
              'text-[2.5rem] relative z-10' h-6 w-6 ${bulbStyles.text}`}
            ></FaLightbulb>
          </div>
          <span>{ideaDetails.idea_title}</span>
        </CardTitle>
      </CardHeader>
      <CardContent className='space-y-4 px-4 md:px-6'>
        <div className='flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4'>
          <div className='flex items-center space-x-4'>
            <IdeaCollaborators
              collaborators={collaborators()}
              className='sm:order-1'
            />
          </div>
          <div className='flex flex-row items-center space-x-4 sm:space-y-0 text-right'>
            {/* Dropdown */}

            {/* Buttons */}
            <div className='flex items-center flex-row justify-start space-x-4 cursor-pointer'>
              {/* Like Button */}
              {/* <Button
                variant='outline'
                size='sm'
                className='flex items-center justify-center md:w-auto text-foreground'
                onClick={toggleLikeHandler}
              > */}
              <div className='flex items-center'>
                <ThumbsUp
                  onClick={toggleLikeHandler}
                  className={`h-[1.2rem] w-[1.2rem] hover:-rotate-12 transition-transform duration-200 text-muted-foreground hover:text-foreground ${
                    userHasLiked ? 'text-primary' : ''
                  }`}
                />
                {likesCount > 0 && (
                  <span className='pt-1 ml-1'>{likesCount}</span>
                )}
              </div>
              {/* </Button> */}
              {/* Comment Count */}
              <span className='flex items-center justify-center md:w-auto'>
                <MessageCircle className='h-[1.2rem] w-[1.2rem] text-muted-foreground' />
                {commentsCount > 0 && (
                  <span className='pt-1 ml-1'>{commentsCount}</span>
                )}
              </span>

              {showEye && (
                <Eye
                  className='h-6 w-6 transition-colors hover:scale-110 transition-transform duration-200 text-muted-foreground hover:text-foreground'
                  onClick={handleVisibilityClick}
                />
              )}
            </div>

            <div className='w-full sm:w-auto mt-0'>
              <Select
                disabled={isDisabled()}
                onValueChange={(e) => handleChange(e)}
                value={action}
              >
                <SelectTrigger className='w-full bg-transparent rounded-lg h-8'>
                  <span className='flex items-center gap-2 justify-between'>
                    <span className='text-muted-foreground mr-2'>Status:</span>

                    {IDEA_STATUS[ideaDetails.status].label}
                  </span>
                </SelectTrigger>
                <SelectContent>
                  {IDEA_STATUS[ideaDetails.status].action.map(
                    (action, index) => (
                      <SelectItem value={action} key={index}>
                        {action}
                      </SelectItem>
                    )
                  )}
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>

        <Separator />

        <div>
          <h3 className='font-semibold mb-2'>Description</h3>
          <p className='text-sm text-muted-foreground'>
            {ideaDetails.description}
          </p>
        </div>

        <div>
          <h3 className='font-semibold mb-2'>Business Value Proposition</h3>
          <p className='text-sm text-muted-foreground'>
            {ideaDetails.business_value_prop}
          </p>
        </div>

        <div>
          <h3 className='font-semibold mb-2 flex items-center'>
            <Tag className='mr-2 h-4 w-4' />
            Tags
          </h3>
          <div className='flex flex-wrap gap-2'>
            {ideaDetails?.tags?.map((tag: string, index: number) => (
              <Badge key={index} variant='secondary'>
                {tag}
              </Badge>
            ))}
          </div>
        </div>

        <Attachments attachments={ideaDetails.attachments} />
        <BusinessCanvasFile fileName={ideaDetails?.business_canvas_file} />
      </CardContent>
    </Card>
  )
}
