import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
} from '@/components/ui/card'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Separator } from '@/components/ui/separator'
import Users from '@/components/organisation/users/Users'
import Department from '@/components/organisation/departments/Department'
import Settings from '@/components/organisation/idea-settings/Settings'
import Notification from '@/components/organisation/notifications/Notification'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { OrgDetails } from '@/components/organisation/org-details/OrgDetails'
const Organisation = () => {
  const { currentUser } = useSelector((state: RootState) => state.auth)
  return (
    <div className=' py-5'>
      <div className='grid md:grid-cols-[50px_minmax(0,1fr)]  justify-center items-center gap-2 pb-5'>
        <Avatar className='h-12 w-12'>
          <AvatarImage src='https://github.com/shadcn.png' alt='@shadcn' />
          <AvatarFallback>CN</AvatarFallback>
        </Avatar>
        <h4 className='mb-1 rounded-md px-2 py-1 text-lg font-semibold'>
          {currentUser?.org_name}
        </h4>
      </div>
      <Separator className='mb-5' />
      <Tabs
        defaultValue='about'
        className='md:grid md:grid-cols-[200px_1fr] gap-4'
      >
        {/* Vertical TabsList */}
        <TabsList className='grid md:flex rounded-md md:flex-col h-full md:justify-start justify-center items-start mb-4 md:mb-0 md:w-full'>
          <TabsTrigger value='about' className='w-40 md:w-full mt-4'>
            About
          </TabsTrigger>
          <TabsTrigger value='users' className='w-40 md:w-full mt-4'>
            Users
          </TabsTrigger>
          <TabsTrigger value='idea_settings' className='w-40 md:w-full mt-4'>
            Idea Settings
          </TabsTrigger>
          <TabsTrigger value='notifications' className='w-40 md:w-full mt-4'>
            Notifications
          </TabsTrigger>
          <TabsTrigger
            value='departments'
            className='w-40 md:w-full mt-4'
            disabled
          >
            Departments
          </TabsTrigger>
        </TabsList>

        {/* Tab Contents */}
        <div className='w-full md:overflow-x-auto'>
          <TabsContent value='about' className='mt-0'>
            <Card>
              <CardHeader className='p-6 pb-0'>
                <CardDescription className='my-0 w-full'>
                  {/* Optional Content */}
                </CardDescription>
              </CardHeader>
              <CardContent className='space-y-2 text-center overflow-auto'>
                <OrgDetails />
              </CardContent>
            </Card>
          </TabsContent>
          <TabsContent value='users' className='mt-0'>
            <Card>
              <CardHeader className='p-6 pb-0'>
                <CardDescription className='my-0 w-full'>
                  {/* Optional Content */}
                </CardDescription>
              </CardHeader>
              <CardContent className='space-y-2 text-center overflow-auto'>
                <Users />
              </CardContent>
            </Card>
          </TabsContent>
          <TabsContent value='idea_settings' className='mt-0'>
            <Card>
              <CardHeader className='p-6 pb-0'></CardHeader>
              <CardContent className='space-y-2'>
                <Settings />
              </CardContent>
            </Card>
          </TabsContent>
          <TabsContent value='notifications' className='mt-0'>
            <Card>
              <CardHeader className='p-6 pb-0'></CardHeader>
              <CardContent className='space-y-2'>
                <Notification />
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value='departments' className='mt-0'>
            <Card>
              <CardHeader className='p-6 pb-0'></CardHeader>
              <CardContent className='space-y-2'>
                <Department />
              </CardContent>
            </Card>
          </TabsContent>
        </div>
      </Tabs>
    </div>
  )
}

export default Organisation
