'use client'

import * as React from 'react'
import { motion } from 'framer-motion'
import { Check, Sparkles } from 'lucide-react'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { SecurePayment } from '@/components/pricing/SecurePayment'
import { GET_TIER_DETAILS } from '@/graphql/tokenQuery'
import { useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { Badge } from '@/components/ui/badge'

interface Tier {
  id: number
  name: string
  token_allocation: number
  monthly_price: number
  features: string[]
}

interface GetTierDetailsData {
  suhrt_tiers: Tier[]
}

// const tiers = [
//   {
//     name: 'Free',
//     id: 'tier-free',
//     href: '#',
//     price: { monthly: 0, annually: 0 },
//     description: 'Perfect for trying out our platform.',
//     features: [
//       { name: 'Access to basic features only', included: true },
//       { name: 'Limited support', included: true },
//       { name: 'No access to AI Ideator', included: false },
//       { name: 'AI-powered suggestions', included: false },
//       { name: 'Idea collaboration', included: false },
//     ],
//     mostPopular: false,
//   },
//   {
//     name: 'Standard',
//     id: 'tier-standard',
//     href: '#',
//     price: { monthly: 999, annually: 11988 },
//     description: 'Great for individual innovators.',
//     features: [
//       { name: 'Access to core features', included: true },
//       { name: '50 tokens for AI Ideator (1 token = 1 idea)', included: true },
//       { name: 'basic analytics, Suhrt Score', included: true },
//       { name: 'Email support', included: true },
//     ],
//     mostPopular: false,
//   },
//   {
//     name: 'Premium',
//     id: 'tier-premium',
//     href: '#',
//     price: { monthly: 1999, annually: 23988 },
//     description: 'Perfect for small teams and startups.',
//     features: [
//       { name: 'All Standard features', included: true },
//       { name: '100 tokens for AI Ideator', included: true },
//       { name: 'Advanced analytics', included: true },
//       { name: 'Priority email support', included: true },
//       { name: 'Business Model Canvas', included: true },
//     ],
//     mostPopular: true,
//   },
//   {
//     name: 'Enterprise',
//     id: 'tier-enterprise',
//     href: '#',
//     price: { monthly: 2999, annually: 35988 },
//     description: 'For organizations with advanced needs.',
//     features: [
//       { name: 'All Premium features', included: true },
//       { name: 'Unlimited tokens for AI Ideator', included: true },
//       { name: 'Dedicated account manager', included: true },
//       { name: 'Custom integrations', included: true },
//       { name: 'Collaboration integrations', included: true },
//       { name: 'Enhanced security features', included: true },
//       { name: 'Onboarding and training', included: true },
//     ],
//     mostPopular: false,
//   },
// ]

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 },
}

const staggerChildren = {
  animate: {
    transition: {
      staggerChildren: 0.1,
    },
  },
}

export function Pricing() {
  const { loginWithRedirect } = useAuth0()
  const [] = React.useState(false)
  const { data } = useQuery<GetTierDetailsData>(GET_TIER_DETAILS)
  const tiers = data?.suhrt_tiers || []
  return (
    <motion.div
      className='mx-auto max-w-8xl'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div
        className='mx-auto max-w-8xl text-center'
        variants={fadeInUp}
        initial='initial'
        animate='animate'
      >
        <h2 className='mt-2 text-4xl font-bold tracking-tight'>
          Choose the right plan for&nbsp;you
        </h2>
      </motion.div>
      <motion.p
        className='mx-auto mt-2 max-w-2xl text-center text-lg leading-8 text-muted-foreground'
        variants={fadeInUp}
        initial='initial'
        animate='animate'
        transition={{ delay: 0.2 }}
      >
        Whether you're just starting out or scaling up, we have a plan that fits
        your needs.
      </motion.p>
      {/* <motion.div
        className='mt-16 flex justify-center'
        variants={fadeInUp}
        initial='initial'
        animate='animate'
        transition={{ delay: 0.4 }}
      >
        <div className='flex items-center gap-4'>
          <span className='text-base font-semibold leading-6'>
            Monthly billing
          </span>
          <Switch
            id='billing-switch'
            checked={annualBilling}
            onCheckedChange={setAnnualBilling}
          />
          <span className='text-base font-semibold leading-6'>
            Annual billing
          </span>
        </div> 
      </motion.div> */}
      <motion.div
        className='isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-6 lg:mx-0 lg:max-w-none lg:grid-cols-4'
        variants={staggerChildren}
        initial='initial'
        animate='animate'
      >
        {tiers.map((tier, index) => (
          <motion.div
            key={tier.id}
            variants={fadeInUp}
            transition={{ delay: 0.1 + index * 0.2 }}
          >
            <Card
              className={cn(
                'ring-1 ring-foreground/10',
                'rounded-3xl p-6 h-full flex flex-col'
              )}
            >
              <CardHeader>
                <CardTitle className='text-2xl font-bold tracking-tight'>
                  {tier.name}
                </CardTitle>
                <CardDescription className='mt-6 flex flex-col items-start gap-y-2'>
                  <div className='flex items-baseline gap-x-1'>
                    <span className='text-4xl font-bold tracking-tight'>
                      {tier.monthly_price != -1
                        ? `₹  ${tier.monthly_price}`
                        : '-'}
                    </span>
                    <span className='text-sm font-semibold leading-6 text-muted-foreground'>
                      {tier.monthly_price != -1 ? '/month' : ''}
                    </span>
                  </div>
                  {(tier.name === 'Premium' || tier.name === 'Standard') && (
                    <motion.div
                      initial={{ opacity: 0, y: -20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <Badge
                        variant='outline'
                        className='px-4 py-2 text-md font-bold text-orange-700 border-orange-700'
                      >
                        <Sparkles className='w-5 h-5 mr-2' />
                        Introductory offer!
                      </Badge>
                    </motion.div>
                  )}
                </CardDescription>
              </CardHeader>
              <CardContent className='p-0 flex-grow flex items-center justify-center'>
                <ul role='list' className='space-y-3 text-sm leading-6'>
                  {tier.features.map((feature) => (
                    <li key={feature} className='flex items-center gap-x-3'>
                      <Check
                        className='h-6 w-5 flex-none text-primary'
                        aria-hidden='true'
                      />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </CardContent>
              <CardFooter className='mt-auto pt-6 pb-0'>
                <Dialog>
                  {tier.name === 'Free' || tier.name === 'Enterprise' ? (
                    <a
                      href={tier.name === 'Free' ? '#' : '/contact'}
                      className='w-full'
                      onClick={(e) => {
                        if (tier.name === 'Free') {
                          e.preventDefault() // Prevent navigation if Free tier
                          loginWithRedirect() // Call the function for the Free tier
                        }
                        // No need for else since Enterprise link is handled by href
                      }}
                    >
                      <Button className='w-full'>
                        {tier.name === 'Free' ? 'Get started' : 'Get Quote'}
                      </Button>
                    </a>
                  ) : (
                    // <DialogTrigger asChild>
                    <Button className='w-full'>
                      {tier.name === 'Standard' ? (
                        <a
                          className=' hover:text-white transition-colors duration-200'
                          href='mailto:contact-us@suhrt.com?subject=Subscribe%20to%20Standard%20Plan&body=I%20would%20like%20to%20subscribe%20to%20Standard%20Plan'
                        >
                          Subscribe
                        </a>
                      ) : (
                        <a
                          className=' hover:text-white transition-colors duration-200'
                          href='mailto:contact-us@suhrt.com?subject=Subscribe%20to%20Premium%20Plan&body=I%20would%20like%20to%20subscribe%20to%20the%20Premium%20Plan'
                        >
                          Subscribe
                        </a>
                      )}
                    </Button>
                    // </DialogTrigger>
                  )}
                  {tier.name !== 'Free' && tier.name !== 'Enterprise' && (
                    <DialogContent className='sm:max-w-[425px]'>
                      <DialogHeader>
                        <DialogTitle>Subscribe to {tier.name} Plan</DialogTitle>
                        <DialogDescription>
                          Complete your subscription to the {tier.name} plan.
                        </DialogDescription>
                      </DialogHeader>
                      {true ? (
                        'Coming Soon..'
                      ) : (
                        <SecurePayment
                          amount={tier.monthly_price}
                          planName={tier.name}
                        />
                      )}
                    </DialogContent>
                  )}
                </Dialog>
              </CardFooter>
            </Card>
          </motion.div>
        ))}
      </motion.div>

      <FAQ />
    </motion.div>
  )
}

function FAQ() {
  const faqs = [
    {
      question: "What's included in the Free plan?",
      answer:
        "The Free plan includes up to 3 ideas per month, basic AI assistance, and community support. It's perfect for individuals just starting out with idea generation.",
    },
    {
      question: 'How does the billing work for annual plans?',
      answer:
        "Annual plans are billed once a year and offer a discount compared to monthly billing. You'll be charged the annual amount upfront, which covers 12 months of service.",
    },
    {
      question: 'Can I upgrade or downgrade my plan at any time?',
      answer:
        "Yes, you can upgrade or downgrade your plan at any time. If you upgrade, you'll be charged the prorated difference for the remainder of your billing cycle. If you downgrade, you'll receive a prorated credit towards your next billing cycle.",
    },
    {
      question: 'What kind of support is included in each plan?',
      answer:
        'All plans include community support. The Pro plan offers faster response times, while the Enterprise plan includes priority support with dedicated account management.',
    },
  ]

  return (
    <motion.section
      className='mx-auto my-12 max-w-6xl'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 1.2 }}
    >
      <motion.h2
        className='text-2xl font-bold leading-10 tracking-tight'
        variants={fadeInUp}
        initial='initial'
        animate='animate'
      >
        Frequently asked questions
      </motion.h2>
      <motion.dl
        className='mt-10 space-y-8'
        variants={staggerChildren}
        initial='initial'
        animate='animate'
      >
        {faqs.map((faq, index) => (
          <motion.div
            key={index}
            variants={fadeInUp}
            transition={{ delay: 1.4 + index * 0.2 }}
          >
            <dt className='text-base font-semibold leading-7'>
              {faq.question}
            </dt>
            <dd className='mt-2 text-base leading-7 text-muted-foreground'>
              {faq.answer}
            </dd>
          </motion.div>
        ))}
      </motion.dl>
    </motion.section>
  )
}
