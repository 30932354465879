// File: components/BusinessCanvasFile.tsx
import React from 'react'
import { Download, FileDown } from 'lucide-react' // Icons for delete and download
import { Button } from '@/components/ui/button'
import { downloadBMC } from '@/service/ideas'

interface BusinessCanvasFileProps {
  fileName?: string | null | undefined
}

export const BusinessCanvasFile: React.FC<BusinessCanvasFileProps> = ({
  fileName,
}) => {
  const handleDownload = () => {
    downloadBMC(fileName)
  }

  return (
    <div>
      <h3 className='font-semibold mb-2 flex items-center'>
        <FileDown className='w-4 h-4 mr-2' />
        Business Plan
      </h3>
      {fileName ? (
        <div className='flex items-center justify-between p-2 bg-muted/40 border rounded-md'>
          <div className='flex items-center space-x-2'>
            <span className='icon-file-document text-blue-500' />
            <span className='text-sm font-medium truncate md:w-auto w-[216px]'>
              business_plan.pptx
            </span>
          </div>
          <div className='flex items-center text-muted-foreground hover:text-primary transition-colors'>
            <Button
              variant='ghost'
              size='icon'
              onClick={handleDownload}
              className='h-4 w-4 cursor-pointer '
              title='Download business_canvas_file.pptx'
            >
              <Download className='w-4 h-4 cursor-pointer' />
            </Button>
          </div>
        </div>
      ) : (
        <p className='text-sm text-muted-foreground'>
          No business model canvas
        </p>
      )}
    </div>
  )
}
