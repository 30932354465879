import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
} from 'react'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
// import { X } from 'lucide-react'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearEvent,
  resetIdeaForm,
  saveIdea,
  submitIdea,
} from '@/store/idea/ideaSlice'
import { RootState } from '@/store/store'
import { selectIsLoading } from '@/store/idea/loaderSlice'

interface DialogData {
  title: string
  description: string
  content?: ReactNode
  confirmAction?: () => void
  cancelAction?: () => void
  width?: string
  height?: string
  requireConfirmation?: boolean
  showCancelButton?: boolean
  showConfirmButton?: boolean
  showFooter?: boolean
  showFooterButtons?: boolean
}

interface DialogContextType {
  openDialog: (data: DialogData) => void
  closeDialog: () => void
  isOpen: boolean
}

const DialogContext = createContext<DialogContextType | undefined>(undefined)

export const DialogProvider = ({ children }: { children: ReactNode }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [dialogData, setDialogData] = useState<DialogData | null>(null)
  const [isClosing, setIsClosing] = useState(false)
  const isLoading = useSelector(selectIsLoading)
  const dispatch = useDispatch()
  const step = useSelector((state: RootState) => state.idea.step)
  const isFormValid = useSelector((state: RootState) => state.idea.isFormValid)

  // Add effect to handle pointer-events
  useEffect(() => {
    const body = document.body

    // Function to remove pointer-events-none class
    const removePointerEvents = () => {
      body.style.removeProperty('pointer-events')
      const elements = document.querySelectorAll(
        '[style*="pointer-events: none"]'
      )
      elements.forEach((element) => {
        if (element instanceof HTMLElement) {
          element.style.removeProperty('pointer-events')
        }
      })
    }

    // Remove pointer-events when dialog is closed
    if (!isDialogOpen && !showConfirmation) {
      // Small delay to ensure dialog transition is complete
      setTimeout(removePointerEvents, 300)
    }

    // Cleanup function
    return () => {
      removePointerEvents()
    }
  }, [isDialogOpen, showConfirmation])

  useEffect(() => {
    if (isClosing) {
      const timer = setTimeout(() => {
        setDialogData(null)
        setIsClosing(false)
      }, 300)
      return () => clearTimeout(timer)
    }
  }, [isClosing])

  const openDialog = useCallback(
    (data: DialogData) => {
      if (isDialogOpen) return

      setDialogData(data)
      setTimeout(() => {
        setIsDialogOpen(true)
        setIsClosing(false)
        setShowConfirmation(false)
      }, 0)
    },
    [isDialogOpen]
  )

  const handleAttemptClose = useCallback(
    (open: boolean) => {
      if (!open) {
        if (dialogData?.requireConfirmation && !showConfirmation) {
          setShowConfirmation(true)
        } else {
          setIsDialogOpen(false)
          setShowConfirmation(false)
          setIsClosing(true)
          dispatch(clearEvent())
        }
      }
    },
    [dialogData, showConfirmation, dispatch]
  )

  const handleCancelConfirmation = useCallback(() => {
    setShowConfirmation(false)
  }, [])

  const closeDialog = useCallback(() => {
    setIsDialogOpen(false)
    setShowConfirmation(false)
    setIsClosing(true)
    dispatch(clearEvent())
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(clearEvent())
    }
  }, [dispatch])

  const handleConfirmClose = useCallback(() => {
    if (dialogData?.confirmAction) {
      dialogData.confirmAction()
    }
    closeDialog()
  }, [dialogData, closeDialog])

  return (
    <DialogContext.Provider
      value={{ openDialog, closeDialog, isOpen: isDialogOpen }}
    >
      {children}
      {dialogData && (
        <>
          <Dialog
            open={isDialogOpen}
            onOpenChange={handleAttemptClose}
            modal={true}
          >
            <DialogContent
              className={`w-[98vw] h-[90vh] p-4 flex gap-2 flex-col ${
                dialogData.width || 'md:w-[95vw]'
              } ${dialogData.height || ''}`}
              onPointerDownOutside={(e) => {
                e.preventDefault()
                if (!isLoading) {
                  handleAttemptClose(false)
                }
              }}
              onEscapeKeyDown={(e) => {
                e.preventDefault()
                if (!isLoading) {
                  handleAttemptClose(false)
                }
              }}
              aria-describedby='dialog-description'
            >
              <DialogHeader className='flex p-0 m-0 flex-row items-center justify-between'>
                <div className='flex-1 p-0 m-0'>
                  {dialogData.title && (
                    <DialogTitle className='text-lg'>
                      {dialogData.title}
                    </DialogTitle>
                  )}
                  {dialogData.description && (
                    <DialogDescription id='dialog-description'>
                      {dialogData.description}
                    </DialogDescription>
                  )}
                </div>
                {/* <Button
                  variant='ghost'
                  size='icon'
                  onClick={() => handleAttemptClose(false)}
                  aria-label='Close dialog'
                  className='ml-2 h-4 w-4 flex self-start hover:bg-muted transition-all space-y-0'
                >
                  <X className='w-4 h-4 text-md' />
                </Button> */}
              </DialogHeader>

              <div className='flex-1 overflow-auto md:overflow-hidden max-h-[calc(100vh-80px)]'>
                {dialogData.content}
              </div>

              {dialogData.showFooter && (step === 'Ideate' || step === '') && (
                <DialogFooter className='p-4 pb-0 border-t'>
                  {dialogData.showFooterButtons && (
                    <div className='flex justify-end space-x-2'>
                      <Button
                        variant='outline'
                        size='sm'
                        onClick={() => dispatch(resetIdeaForm())}
                      >
                        Reset
                      </Button>
                      <Button
                        size='sm'
                        onClick={() => dispatch(saveIdea())}
                        disabled={!isFormValid}
                      >
                        Save
                      </Button>
                      <Button
                        size='sm'
                        onClick={() => dispatch(submitIdea())}
                        disabled={!isFormValid}
                      >
                        Submit
                      </Button>
                    </div>
                  )}
                </DialogFooter>
              )}
            </DialogContent>
          </Dialog>

          <Dialog
            open={showConfirmation && !isLoading}
            onOpenChange={handleCancelConfirmation}
            modal={true}
          >
            <DialogContent
              className='sm:max-w-[425px]'
              onPointerDownOutside={(e) => e.preventDefault()}
              onEscapeKeyDown={(e) => e.preventDefault()}
              aria-describedby='confirmation-description'
            >
              <DialogHeader>
                <DialogTitle>Close Confirmation</DialogTitle>
                <DialogDescription>
                  Are you sure you want to close without saving changes?
                </DialogDescription>
              </DialogHeader>
              <DialogFooter className='justify-end'>
                <Button
                  type='button'
                  variant='secondary'
                  onClick={handleCancelConfirmation}
                >
                  No
                </Button>
                <Button
                  type='button'
                  className='mb-4 md:mb-0'
                  variant='destructive'
                  onClick={handleConfirmClose}
                >
                  Yes
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </>
      )}
    </DialogContext.Provider>
  )
}

export const useDialog = (): DialogContextType => {
  const context = useContext(DialogContext)
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider')
  }
  return context
}
