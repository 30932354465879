import React from 'react'
import { Check, Clock, X } from 'lucide-react'

interface TimelineItem {
  label: string
  date: string
  status: 'completed' | 'in-process' | 'rejected' | string
  tooltip?: string
}

interface TimelineProps {
  items: TimelineItem[]
}

const Timeline: React.FC<TimelineProps> = ({ items }) => {
  // Circle styles based on status
  const circleStyles = {
    completed: 'bg-lime-500',
    'in-process': 'bg-accent', // HSL color shadow with opacity
    rejected: 'bg-destructive',
    default: 'bg-gray-300 dark:bg-muted border border-muted-foreground',
  }
  const getIcon = (status: string) => {
    switch (status) {
      case 'completed':
        return <Check className='w-4 h-4 text-white' />
      case 'in-process':
        return <Clock className='w-4 h-4 text-white' />
      case 'rejected':
        return <X className='w-4 h-4 text-white' />
      default:
        return null
    }
  }

  return (
    <div className='flex items-center bg-background dark:border dark:border-border justify-between w-full py-4 rounded-md'>
      {items.map((item, index) => {
        const isLastItem = index === items.length - 1
        return (
          <div key={index} className='flex-1 relative'>
            <div className='flex flex-col items-center'>
              {/* Date (time) */}
              <div
                className='mb-3 md:text-[0.725rem] text-[0.525rem] text-muted-foreground italic'
                style={{
                  visibility: item.date === 'N/A' ? 'hidden' : 'visible',
                }}
              >
                {item.date}
              </div>

              {/* Line */}
              {!isLastItem && (
                <div
                  className={`absolute w-full h-px bg-muted-foreground top-[50%] transform -translate-y-1/2 z-0`}
                  style={{
                    left: '52%',
                    width: 'calc(100%)', // Adjust as needed for spacing
                    marginLeft: '0%', // Center the line
                  }}
                ></div>
              )}

              {/* Circle or Icon */}
              <div
                className={`rounded-full w-6 h-6 flex items-center justify-center z-10 relative ${
                  circleStyles[item.status as keyof typeof circleStyles] ||
                  circleStyles.default
                }`}
              >
                {getIcon(item.status)}
              </div>

              {/* Label (below circle) */}
              <div className='mt-2 text-center'>
                <div className='flex'>
                  <span className='text-foreground'>{item.label}</span>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Timeline
