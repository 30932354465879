import { useMemo, memo } from 'react'
import {
  Label,
  PolarGrid,
  PolarRadiusAxis,
  RadialBar,
  RadialBarChart,
  ResponsiveContainer,
} from 'recharts'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'

// Move constants outside component to prevent recreation
const MAX_SCORE = 10
const CHART_HEIGHT = 80
const INNER_RADIUS = 35
const OUTER_RADIUS = 45
const GRID_STROKE_WIDTH = 7
const BAR_STROKE_WIDTH = 4
const CORNER_RADIUS = 3
const POLAR_RADIUS = [25, 35]
const START_ANGLE = 90

// Memoize color thresholds
const SCORE_COLOR_THRESHOLDS = {
  LOW: 5,
  MEDIUM: 7,
} as const

// Memoize colors
const SCORE_COLORS = {
  LOW: 'hsl(var(--destructive))',
  MEDIUM: 'hsl(var(--accent))',
  HIGH: '#65a30d',
} as const

// Memoized function for getting score color
const getScoreColor = (score: number): string => {
  if (score < SCORE_COLOR_THRESHOLDS.LOW) return SCORE_COLORS.LOW
  if (score < SCORE_COLOR_THRESHOLDS.MEDIUM) return SCORE_COLORS.MEDIUM
  return SCORE_COLORS.HIGH
}

interface SuhrtScoreProps {
  weighted_avg_score: number
}

// Memoized label component to prevent unnecessary rerenders
const ScoreLabel = memo(
  ({
    score,
    viewBox,
  }: {
    score: number
    viewBox: { cx: number; cy: number }
  }) => (
    <text
      x={viewBox.cx}
      y={viewBox.cy}
      textAnchor='middle'
      dominantBaseline='middle'
    >
      <tspan
        x={viewBox.cx}
        y={viewBox.cy}
        className='fill-foreground text-base sm:text-xl font-bold'
      >
        {score.toFixed(1)}
      </tspan>
    </text>
  )
)

ScoreLabel.displayName = 'ScoreLabel'

export const SuhrtScore = memo(function SuhrtScore({
  weighted_avg_score,
}: SuhrtScoreProps) {
  // Memoize score-related calculations
  const scoreData = useMemo(() => {
    const score = Math.min(Math.max(weighted_avg_score, 0), MAX_SCORE)
    const scoreColor = getScoreColor(score)
    const endAngle = START_ANGLE + (score / MAX_SCORE) * 360

    return {
      score,
      scoreColor,
      endAngle,
      chartData: [{ scores: score, fill: scoreColor }],
    }
  }, [weighted_avg_score])
  return (
    <Card className='h-auto md:w-[230px] w-[90%] pt-4 justify-self-center grid grid-cols-2 items-center bg-transparent border-0 shadow-none'>
      <CardHeader className='items-center p-0'>
        <CardTitle className='m-0 text-sm sm:text-base'>
          Suhrt Idea Score
        </CardTitle>
      </CardHeader>
      <CardContent className='flex-1 pb-0 h-full w-full px-0'>
        <ResponsiveContainer width='100%' height={CHART_HEIGHT}>
          <RadialBarChart
            data={scoreData.chartData}
            startAngle={START_ANGLE}
            endAngle={scoreData.endAngle}
            innerRadius={String(INNER_RADIUS)}
            outerRadius={String(OUTER_RADIUS)}
            className='w-full h-full'
          >
            <PolarGrid
              gridType='circle'
              radialLines={false}
              stroke='#cbd5e1'
              strokeWidth={GRID_STROKE_WIDTH}
              className='first:fill-muted last:fill-background'
              polarRadius={POLAR_RADIUS}
            />
            <RadialBar
              dataKey='scores'
              background
              cornerRadius={CORNER_RADIUS}
              fill={scoreData.scoreColor}
              max={MAX_SCORE}
              stroke={scoreData.scoreColor}
              strokeWidth={BAR_STROKE_WIDTH}
            />
            <PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
              <Label
                content={({ viewBox }) => {
                  if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                    return (
                      <ScoreLabel
                        score={scoreData.score}
                        viewBox={viewBox as { cx: number; cy: number }}
                      />
                    )
                  }
                  return null
                }}
              />
            </PolarRadiusAxis>
          </RadialBarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  )
})

SuhrtScore.displayName = 'SuhrtScore'
