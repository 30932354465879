'use client'

import type React from 'react'
import { useState } from 'react'
import { Button } from '@/components/ui/button'
import { Download } from 'lucide-react'
import { SuhrtScore } from '../../SuhrtScore'
import MindMap from './MindMap'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Card, CardContent } from '@/components/ui/card'
import { SuhrtScoreParams } from '../../views/SuhrtScoreParams'
import { useQuery } from '@apollo/client'
import { GET_SCORE_WEIGHTS_BY_ORGID } from '@/graphql/orgQuery'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { downloadBMC } from '@/service/ideas'

interface ScoreSectionProps {
  aiIdeaData: any
  bcGenerated: string
}

export const ScoreSection: React.FC<ScoreSectionProps> = ({
  aiIdeaData,
  bcGenerated,
}) => {
  const [activeTab, setActiveTab] = useState('mindmap')
  const { currentUser } = useSelector((state: RootState) => state.auth)
  const { data } = useQuery(GET_SCORE_WEIGHTS_BY_ORGID, {
    variables: { org_id: currentUser?.org_id },
    skip: !currentUser?.org_id,
  })
  // Extract scores from aiIdeaData
  const suhrtScoreData: any = {
    weighted_avg_score: aiIdeaData?.scores?.weighted_avg_score || 0,
    scores: {
      feasibility_score: aiIdeaData?.scores?.feasibility_score || 20,
      impact_score: aiIdeaData?.scores?.impact_score || 20,
      originality_score: aiIdeaData?.scores?.originality_score || 20,
      relevance_score: aiIdeaData?.scores?.relevance_score || 20,
      reuse_score: aiIdeaData?.scores?.reuse_score || 20,
    },
    notes: {
      feasibility_note: aiIdeaData?.notes?.feasibility_note || '',
      impact_note: aiIdeaData?.notes?.impact_note || '',
      originality_note: aiIdeaData?.notes?.originality_note || '',
      relevance_note: aiIdeaData?.notes?.relevance_note || '',
      reuse_note: aiIdeaData?.notes?.reuse_note || '',
    },
    weights: {
      relevance_weight: data?.suhrt_org_score_weights[0].relevance_weight ?? 0,
      originality_weight:
        data?.suhrt_org_score_weights[0].originality_weight ?? 0,
      impact_weight: data?.suhrt_org_score_weights[0].impact_weight ?? 0,
      reuse_weight: data?.suhrt_org_score_weights[0]?.reuse_weight ?? 0,
      feasibility_weight:
        data?.suhrt_org_score_weights[0]?.feasibility_weight ?? 0,
    },
  }

  // Format mind map data for the MindMap component
  const mindMapData = {
    title: aiIdeaData?.title || '',
    central: {
      label: 'MINDMAP',
      color: '#00008b',
    },
    mind_map: aiIdeaData?.mind_map || {},
  }

  // Handle tab change
  const handleTabChange = (value: string) => {
    setActiveTab(value)
  }

  // Handle business canvas download

  // Create a temporary anchor element
  const handleDownloadBMC = async () => {
    downloadBMC(bcGenerated)
  }

  const isEmptyMindmap =
    aiIdeaData == null ||
    aiIdeaData?.mind_map === null ||
    (typeof aiIdeaData?.mind_map === 'object' &&
      Object.keys(aiIdeaData?.mind_map).length === 0)
  return (
    <div className='relative'>
      <Tabs
        value={activeTab}
        onValueChange={handleTabChange}
        className='w-full'
      >
        <div className='flex justify-between items-center mb-4'>
          <TabsList>
            <TabsTrigger value='mindmap'>MindMap</TabsTrigger>
            <TabsTrigger value='score'>Suhrt Score</TabsTrigger>
            <TabsTrigger value='business'>Business Plan</TabsTrigger>
          </TabsList>
        </div>

        <TabsContent value='mindmap' className='mt-0'>
          <Card className='border-0 shadow-none'>
            <CardContent className='p-0 h-[600px] overflow-hidden'>
              {isEmptyMindmap ? (
                <div className='flex items-center justify-center mt-8'>
                  <p>No mindmap data available.</p>
                </div>
              ) : (
                <MindMap data={mindMapData} />
              )}
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value='score' className='mt-0'>
          <Card className='border-0 shadow-none'>
            <CardContent className='p-4'>
              {aiIdeaData?.scores ? (
                <>
                  <SuhrtScore
                    weighted_avg_score={suhrtScoreData.weighted_avg_score}
                  />
                  <SuhrtScoreParams suhrtScores={suhrtScoreData} />
                </>
              ) : (
                <div className='text-center p-6 text-muted-foreground'>
                  No score data available for this idea.
                </div>
              )}
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value='business' className='mt-0'>
          <Card className='border-0 shadow-none'>
            <CardContent className='p-4'>
              {bcGenerated ? (
                <div className='text-center p-6 border border-dashed rounded-md'>
                  <p className='font-medium'>Business Canvas Available</p>
                  <p className='text-sm text-muted-foreground mt-2'>
                    Your business canvas is ready.{' '}
                    <Button
                      variant='link'
                      onClick={handleDownloadBMC}
                      className='p-0 h-auto font-normal'
                    >
                      Click here <Download className='h-4 w-4 ml-1 inline' /> to
                      download it.
                    </Button>
                  </p>
                </div>
              ) : (
                <div className='text-center p-6 text-muted-foreground'>
                  No business canvas available for this idea.
                </div>
              )}
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  )
}

export default ScoreSection
