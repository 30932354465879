'use client'

import { useState } from 'react'
import { motion } from 'framer-motion'
import { LockIcon, Sparkles, Zap, ArrowRight } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'

interface TokenLimitOverlayProps {
  onUpgradeClick: () => void
  maxTokens: number
}

export function TokenLimitOverlay({
  onUpgradeClick,
  maxTokens,
}: TokenLimitOverlayProps) {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{ duration: 0.3 }}
      className='absolute rounded-lg inset-0 z-50 flex items-center justify-center p-4 backdrop-blur-sm bg-black/70 '
    >
      <Card className='w-full max-w-4xl mx-auto md:overflow-hidden overflow-auto h-[315px] md:h-auto'>
        <motion.div
          className='flex flex-col md:flex-row'
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.1, duration: 0.4 }}
        >
          <div className='md:w-1/2 p-6 flex flex-col justify-between items-center'>
            <div className='flex flex-col items-center text-center'>
              <motion.div
                className='w-16 h-16 mb-4 rounded-full bg-gradient-to-br from-yellow-200 to-yellow-400 flex items-center justify-center'
                animate={{ scale: isHovered ? 1.1 : 1 }}
                transition={{ type: 'spring', stiffness: 400, damping: 10 }}
              >
                <LockIcon className='w-8 h-8 text-yellow-600' />
              </motion.div>
              <motion.h2
                className='text-2xl font-bold tracking-tight mb-2'
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.4 }}
              >
                Token Limit Reached
              </motion.h2>
              <motion.p
                className='text-muted-foreground'
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.3, duration: 0.4 }}
              >
                You've used all {maxTokens} available tokens in your current
                plan.
              </motion.p>
            </div>
            <div className='mt-6'>
              <div className='space-y-2 mb-4'>
                <p className='text-xs text-muted-foreground text-center'>
                  {maxTokens} / {maxTokens} tokens used
                </p>
              </div>
              <Button
                size='lg'
                className='w-full bg-primary hover:bg-primary/90 text-primary-foreground'
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={onUpgradeClick}
              >
                Upgrade Now
                <ArrowRight className='ml-2 h-4 w-4' />
              </Button>
            </div>
          </div>
          <motion.div
            className='md:w-1/2 bg-muted p-6 flex flex-col justify-center'
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.4 }}
          >
            <h3 className='text-lg font-semibold mb-4'>Upgrade Benefits</h3>
            <ul className='space-y-4'>
              <motion.li
                className='flex items-start'
                initial={{ x: 20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.5, duration: 0.4 }}
              >
                <Sparkles className='w-5 h-5 text-primary mr-2 mt-0.5' />
                <div>
                  <p className='font-medium'>Unlimited AI-powered ideation</p>
                  <p className='text-sm text-muted-foreground'>
                    Generate ideas without restrictions
                  </p>
                </div>
              </motion.li>
              <motion.li
                className='flex items-start'
                initial={{ x: 20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.6, duration: 0.4 }}
              >
                <Zap className='w-5 h-5 text-primary mr-2 mt-0.5' />
                <div>
                  <p className='font-medium'>Priority processing</p>
                  <p className='text-sm text-muted-foreground'>
                    Get faster responses for your queries
                  </p>
                </div>
              </motion.li>
              <motion.li
                className='flex items-start'
                initial={{ x: 20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.7, duration: 0.4 }}
              >
                <Sparkles className='w-5 h-5 text-primary mr-2 mt-0.5' />
                <div>
                  <p className='font-medium'>Advanced features</p>
                  <p className='text-sm text-muted-foreground'>
                    Access to premium tools and capabilities
                  </p>
                </div>
              </motion.li>
            </ul>
          </motion.div>
        </motion.div>
      </Card>
    </motion.div>
  )
}
