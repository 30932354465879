import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { Label } from '@/components/ui/label'
import { TagsInput, Tag } from '../TagsInput'
import { IdeaFormData } from '@/types/idea'

interface TagsProps {
  control: Control<IdeaFormData>
}

export const Tags: React.FC<TagsProps> = ({ control }) => {
  return (
    <div className='mt-2'>
      <Label htmlFor='tags'>Tags</Label>
      <Controller
        control={control}
        name='tags'
        render={({ field: { value, onChange } }) => {
          const handleTagChange = (tags: Tag[]) => {
            const tagStrings = tags.map((tag) => tag.text)
            onChange(tagStrings)
          }

          const tagObjects = (value || []).map(
            (tag: string, index: number) => ({
              id: index.toString(),
              text: tag,
            })
          )

          return (
            <TagsInput
              value={tagObjects}
              onChange={handleTagChange}
              placeholder='Enter a tag'
            />
          )
        }}
      />
    </div>
  )
}
