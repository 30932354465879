import { SUHRT } from '@/suhrt/config'
import CryptoJS from 'crypto-js'

export const UTILITY = {
  encryptedId: (id: number | string) => {
    return encodeURIComponent(
      CryptoJS.AES.encrypt(id.toString(), SUHRT.ideaIdSecretKey).toString()
    )
    //return CryptoJS.AES.encrypt(id.toString(), SUHRT.ideaIdSecretKey).toString();
  },
  decryptedId: (encryptedId: string) => {
    return CryptoJS.AES.decrypt(
      decodeURIComponent(encryptedId),
      SUHRT.ideaIdSecretKey
    ).toString(CryptoJS.enc.Utf8)
    //return CryptoJS.AES.decrypt(encryptedId, SUHRT.ideaIdSecretKey).toString(CryptoJS.enc.Utf8);
  },
  decryptToken: (encryptedToken: string) => {
    if (!encryptedToken || typeof encryptedToken !== 'string') {
      console.error('Invalid token format')
      return null
    }

    // Validate Base64 token
    const isValidBase64 = (str: string) => {
      try {
        return btoa(atob(str)) === str
      } catch (e) {
        return false
      }
    }

    if (!isValidBase64(encryptedToken)) {
      console.error('Invalid Base64 token')
      return null
    }

    try {
      // Step 1: Decode the Base64 encrypted token
      const decodedBytes = CryptoJS.enc.Base64.parse(encryptedToken)

      // Step 2: Create a key from the secret key
      const key = CryptoJS.enc.Utf8.parse(SUHRT.verifySecretKey)

      // Step 3: Create a CipherParams object from the decoded bytes
      const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: decodedBytes,
      })

      // Step 4: Decrypt the token using AES
      const decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
        mode: CryptoJS.mode.ECB, // Ensure this matches the encryption mode
        padding: CryptoJS.pad.Pkcs7, // Ensure this matches the encryption padding
      })

      // Step 5: Convert the decrypted data to a UTF-8 string
      const decryptedString = decrypted.toString(CryptoJS.enc.Utf8)

      if (!decryptedString) {
        console.error('Decryption failed: Invalid or empty decrypted data')
        return null
      }

      // Step 6: Return the decrypted string
      const [userId, email] = decryptedString.split('|')
      return [userId, email]
    } catch (error) {
      console.error('Decryption failed:', error)
      throw new Error('Failed to decrypt token')
    }
  },
}
