'use client'

import { useEffect, useState } from 'react'
import { Card, CardContent } from '@/components/ui/card'
import { Stepper, type StepItem } from '@/components/ui/stepper'
import { motion, AnimatePresence } from 'framer-motion'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import StepFour, { IdeaDetails } from './StepFour'
import { updateAIIdeatorStep } from '@/store/idea/ideaSlice'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { DEDUCT_TOKEN } from '@/graphql/tokenQuery'
import { useMutation } from '@apollo/client'

const steps = [
  {
    label: 'Generate Themes',
    description: 'Tell us what you want to innovate on',
  },
  {
    label: 'Select Themes',
    description: 'Choose themes to combine',
  },
  {
    label: 'Trisociate',
    description: 'Combine themes to generate ideas',
  },
  {
    label: 'Ideate',
    description: 'Review and refine your idea',
  },
] satisfies StepItem[]
interface IdeaStepperProps {
  refetchTokens: () => Promise<any>
  setShowOverlay: (show: boolean) => void
  onStepChange?: (hasChanges: boolean) => void
}
export default function IdeaStepper({
  refetchTokens,
  setShowOverlay,
  onStepChange,
}: IdeaStepperProps) {
  const [currentStep, setCurrentStep] = useState(0)
  const [themes, setThemes] = useState<string[]>([])
  const [selectedThemes, setSelectedThemes] = useState<string[]>([])
  const [ideaDetails, setIdeaDetails] = useState<IdeaDetails>({
    bc_attachment: '',
    ideas: {
      title: '',
      description: '',
      tags: [],
      valueprop: '',
      mind_map: {}, // Empty object as default
      notes: {
        feasibility_note: '',
        impact_note: '',
        originality_note: '',
        relevance_note: '',
        reuse_note: '',
      },
      scores: {
        feasibility_score: 0,
        impact_score: 0,
        originality_score: 0,
        relevance_score: 0,
        reuse_score: 0,
        weighted_avg_score: 0,
      },
    },
  })
  const dispatch = useDispatch()
  //const [tokenDeducted, setTokenDeducted] = useState(false)
  const { currentUser } = useSelector((state: RootState) => state.auth)
  const [prompt, setPrompt] = useState('')
  const [suggestions, setSuggestions] = useState<string[]>([])
  // Query for getting latest token details
  // const { refetch: refetchTokens } = useQuery<{
  //   suhrt_tokens: Array<{
  //     remaining_tokens: number
  //     total_tokens: number
  //     used_tokens: number
  //   }>
  // }>(GET_TOKEN_DETAILS_BY_ORG_ID, {
  //   variables: { org_id: currentUser?.org_id },
  //   fetchPolicy: 'network-only',
  // })

  // Mutation for deducting token

  const [deductToken] = useMutation(DEDUCT_TOKEN)
  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((prev) => prev + 1)
    }
  }

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1)
    }
    if (currentStep === 1) {
      setSelectedThemes([])
    }
  }
  useEffect(() => {
    if (currentStep === 3) {
      dispatch(updateAIIdeatorStep('Ideate'))
    } else {
      dispatch(updateAIIdeatorStep(steps[currentStep].label))
    }
  }, [currentStep, dispatch])
  // Effect to handle token deduction when idea details are populated
  useEffect(() => {
    const handleTokenDeduction = async () => {
      try {
        // Only proceed if we have idea details and haven't deducted yet
        if (
          !ideaDetails?.ideas?.title ||
          !ideaDetails?.ideas?.description ||
          !currentUser?.org_id
        ) {
          return
        }

        // Get fresh token details
        const { data: freshTokenData } = await refetchTokens()
        const remainingTokens =
          freshTokenData?.suhrt_tokens[0]?.remaining_tokens

        // Check if we have remaining tokens
        if (typeof remainingTokens === 'number' && remainingTokens > 0) {
          try {
            // Attempt to deduct token
            await deductToken({
              variables: { org_id: currentUser.org_id },
            })
            //setTokenDeducted(true)

            // Refresh token details after successful deduction
            await refetchTokens()
          } catch (error) {
            console.error('Error deducting token:', error)
          }
        } else {
          console.log('No remaining tokens available for deduction')
        }
      } catch (error) {
        console.error('Error handling token deduction:', error)
      }
    }

    handleTokenDeduction()
  }, [ideaDetails, currentUser?.org_id, deductToken, refetchTokens])
  const checkTokenAvailability = async () => {
    const { data: freshTokenData } = await refetchTokens()
    const remainingTokens =
      freshTokenData?.suhrt_tokens[0]?.remaining_tokens ?? 0
    return remainingTokens
  }
  useEffect(() => {
    // Consider changes present if we're past step 0 and have any data
    const hasChanges: any =
      currentStep > 0 ||
      themes.length > 0 ||
      selectedThemes.length > 0 ||
      ideaDetails.ideas?.title ||
      ideaDetails.ideas.description

    onStepChange?.(hasChanges)
  }, [currentStep, themes, selectedThemes, ideaDetails, onStepChange])
  return (
    <Card className='w-full border-0 shadow-none relative'>
      <CardContent className='p-0 '>
        <div className=''>
          <Stepper steps={steps} currentStep={currentStep} className='' />
        </div>

        <AnimatePresence mode='wait'>
          <motion.div
            key={currentStep}
            initial={{ opacity: 0, x: 10 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -10 }}
            transition={{ duration: 0.2 }}
            className='h-full'
          >
            {currentStep === 0 && (
              <StepOne
                onThemesGenerated={setThemes}
                onNext={nextStep}
                retainedPrompt={prompt}
                retainedSuggestions={suggestions}
                onPromptChange={setPrompt}
                onSuggestionsChange={setSuggestions}
                checkTokenAvailability={checkTokenAvailability}
                setShowOverlay={setShowOverlay}
              />
            )}
            {currentStep === 1 && (
              <StepTwo
                themes={themes}
                selectedThemes={selectedThemes}
                onThemesSelected={setSelectedThemes}
                onNext={nextStep}
                onBack={prevStep}
              />
            )}
            {currentStep === 2 && (
              <StepThree
                selectedThemes={selectedThemes}
                onIdeaGenerated={setIdeaDetails}
                onNext={nextStep}
                onBack={prevStep}
                checkTokenAvailability={checkTokenAvailability}
                setShowOverlay={setShowOverlay}
              />
            )}
            {currentStep === 3 && (
              <StepFour ideaDetails={ideaDetails} onBack={prevStep} />
            )}
          </motion.div>
        </AnimatePresence>
      </CardContent>
    </Card>
  )
}
