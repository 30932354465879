import React from 'react'
import { Idea } from '@/types/idea'
import IdeaToolTip from './grid-view-component/IdeaToolTip'

interface IdeaCardProps extends Idea {
  newlyAdded: boolean
  onClick: (id: string | number) => void
}

export const IdeaCard: React.FC<IdeaCardProps> = ({
  id,
  idea_title,
  newlyAdded,
  onClick,
  ...ideaProps
}) => (
  <div
    className={`flex items-center flex-col p-4 transition-all relative`}
    key={id}
  >
    <IdeaToolTip
      {...ideaProps}
      idea_title={idea_title}
      id={id}
      newlyAdded={newlyAdded}
    />
    <span
      onClick={() => onClick(id)}
      className={`text-center break-words mt-2 cursor-pointer hover:text-primary ${
        newlyAdded ? 'font-bold text-md' : 'text-sm'
      }`}
    >
      {idea_title && idea_title.length > 35
        ? idea_title.slice(0, 35) + '...'
        : idea_title || 'No Title'}
    </span>
  </div>
)
