// BlogPost.tsx
import { useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useQuery } from '@apollo/client'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Facebook, Linkedin, Printer } from 'lucide-react'
import { BlogHeader } from './BlogHeader'
import { ScrollProgress } from './ScrollProgress'
import { AnimatedImage } from './AnimatedImage'
import { htmlToStructuredContent } from './htmlToStructuredContent'
import { GET_BLOG_BY_ID } from '@/graphql/blogQuery'
import { formatDate } from '@/helpers/utils'

export default function BlogPost() {
  const { slug } = useParams<{ slug: string }>()
  const [activeSection, setActiveSection] = useState('')
  const [activeSectionTitle, setActiveSectionTitle] = useState('')

  const { loading, error, data } = useQuery(GET_BLOG_BY_ID, {
    variables: { perma_slug: slug },
  })

  const blogPost = data?.suhrt_blogs[0]

  const structuredContent = useMemo(() => {
    if (!blogPost?.content) return []
    return htmlToStructuredContent(blogPost.content)
  }, [blogPost])

  const tableOfContents = useMemo(() => {
    return structuredContent
      .filter((block) => block.type === 'heading' && block.level === 2)
      .map((block) => ({
        id: block.id!,
        title: block.content,
        level: block.level!,
      }))
  }, [structuredContent])

  useEffect(() => {
    if (!blogPost) return

    window.scrollTo(0, 0)

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id)
            setActiveSectionTitle(entry.target.textContent || '')
          }
        })
      },
      { threshold: 0.2 }
    )

    const articleElement = document.querySelector('article.prose')
    if (articleElement) {
      articleElement
        .querySelectorAll('h2')
        .forEach((heading) => observer.observe(heading))
    }

    return () => observer.disconnect()
  }, [blogPost])

  const shareOnLinkedIn = () => {
    const url = window.location.href
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        url
      )}`,
      '_blank'
    )
  }

  const shareOnFacebook = () => {
    const url = window.location.href
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      '_blank'
    )
  }

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error.message}</div>
  if (!blogPost) return <div>Blog post not found</div>

  return (
    <>
      <div className='min-h-screen bg-background'>
        <BlogHeader currentSection={activeSectionTitle} />
        <ScrollProgress />

        {/* Hero Section */}
        <div className='relative h-[400px] bg-indigo-950 mb-8'>
          <img
            src={blogPost.feature_image_url || '/placeholder.svg'}
            alt='Blog post hero'
            className='w-full h-full object-cover opacity-50'
          />
          <div className='absolute inset-0 flex items-center justify-center text-white bg-black/50'>
            <div className='max-w-screen-xl w-full mx-auto px-4 sm:px-6 lg:px-8 text-center'>
              <Badge variant='secondary' className='mb-6'>
                {blogPost.tags[0]}
              </Badge>
              <h1 className='text-4xl md:text-5xl font-bold mb-6'>
                {blogPost.title}
              </h1>
              <div className='flex items-center justify-center gap-4 text-sm'>
                <span>By {blogPost.author_name}</span>
                <span>|</span>
                <span>{formatDate(blogPost.created_dt)}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Content Section */}
        <div className='max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 py-12'>
          <div className='grid grid-cols-1 lg:grid-cols-4 gap-8'>
            {/* Sidebar */}
            <motion.div
              className='lg:col-span-1 hidden lg:block'
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              <div className='sticky top-24'>
                <h3 className='text-lg font-semibold mb-4'>
                  Table of Contents
                </h3>
                <nav className='space-y-2'>
                  {tableOfContents.map((item) => (
                    <a
                      key={item.id}
                      href={`#${item.id}`}
                      className={`block hover:text-primary transition-colors text-base my-2 leading-relaxed  ${
                        activeSection === item.id
                          ? 'text-primary font-medium'
                          : 'text-muted-foreground'
                      }`}
                    >
                      {item.title}
                    </a>
                  ))}
                </nav>

                <div className='mt-8 pt-8 border-t'>
                  <h3 className='text-lg font-semibold mb-4'>Share</h3>
                  <div className='flex gap-2'>
                    <Button
                      variant='outline'
                      size='icon'
                      onClick={shareOnLinkedIn}
                    >
                      <Linkedin className='h-4 w-4' />
                    </Button>
                    <Button
                      variant='outline'
                      size='icon'
                      onClick={shareOnFacebook}
                    >
                      <Facebook className='h-4 w-4' />
                    </Button>
                    <Button
                      variant='outline'
                      size='icon'
                      onClick={() => window.print()}
                    >
                      <Printer className='h-4 w-4' />
                    </Button>
                  </div>
                </div>
              </div>
            </motion.div>

            {/* Main Content */}
            <motion.div
              className='lg:col-span-3'
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <article className='prose prose-lg max-w-none text-md'>
                {structuredContent.map((block, index) => {
                  switch (block.type) {
                    case 'paragraph':
                      return (
                        <p
                          key={index}
                          dangerouslySetInnerHTML={{ __html: block.content }}
                          className='text-base my-2 leading-relaxed '
                        />
                      )

                    case 'heading':
                      const HeadingTag =
                        `h${block.level}` as keyof JSX.IntrinsicElements
                      return (
                        <HeadingTag
                          key={index}
                          id={block.id}
                          className='text-lg font-semibold mb-2 mt-4'
                        >
                          {block.content}
                        </HeadingTag>
                      )

                    case 'image':
                      return (
                        <AnimatedImage
                          key={index}
                          src={block.src || '/placeholder.svg'}
                          alt={block.alt || ''}
                          caption={block.caption}
                        />
                      )

                    case 'list':
                      const ListTag = block.listType === 'ordered' ? 'ol' : 'ul'
                      return (
                        <ListTag
                          key={index}
                          className={`my-6 pl-6 ${
                            block.listType === 'ordered'
                              ? 'list-decimal'
                              : 'list-disc'
                          }`}
                        >
                          {block.items?.map((item, i) => (
                            <li
                              key={i}
                              dangerouslySetInnerHTML={{ __html: item }}
                              className='text-base my-2 leading-relaxed '
                            />
                          ))}
                        </ListTag>
                      )

                    case 'blockquote':
                      return (
                        <blockquote
                          key={index}
                          className='py-4 px-6 my-8 bg-muted border-l-4 border-gray-200 italic text-xl leading-relaxed'
                          dangerouslySetInnerHTML={{ __html: block.content }}
                        />
                      )

                    default:
                      return null
                  }
                })}
              </article>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  )
}
