import { gql } from '@apollo/client'

/* 21. Get All Ideas by Org ID */
export const GET_IDEAS = gql`
  query GET_IDEAS($org_id: Int, $user_id: Int!) {
    suhrt_idea(
      where: {
        user: { org_id: { _eq: $org_id } }
        _or: [
          { visibility: { _eq: 2 } } # Visible to all users in the organization
          {
            _and: [
              { visibility: { _eq: 1 } } # Visible only to ideator & collaborators
              {
                _or: [
                  { idea_by: { _eq: $user_id } } # If user is the creator
                  { idea_collaborators: { user_id: { _eq: $user_id } } } # If user is a collaborator
                ]
              }
            ]
          }
        ]
      }
      order_by: { idea_dt: desc }
    ) {
      id
      idea_title
      description
      visibility
      business_value_prop
      tags
      idea_by
      idea_dt
      status

      user {
        id
        username
        first_name
        last_name
        email
        org_id
        role_id
        picture
        isActive
      }
      comments: comments_aggregate {
        aggregate {
          count
        }
      }
      likes: likes_aggregate {
        aggregate {
          count
        }
      }
      idea_categories {
        category {
          category_id
          category_name
          category_description
        }
      }
      idea_collaborators {
        user {
          username
          first_name
          last_name
          email
          id
        }
      }
      idea_notes {
        note
        action
      }
      idea_scores {
        weighted_avg_score
      }
    }
  }
`

export const GET_ALL_IDEAS = gql`
  query GET_IDEAS {
    suhrt_idea(order_by: { idea_dt: desc }) {
      id
      idea_title
      description
      visibility
      business_value_prop
      tags
      idea_by
      idea_dt
      status

      user {
        id
        username
        first_name
        last_name
        email
        org_id
        role_id
        picture
        isActive
      }
      comments: comments_aggregate {
        aggregate {
          count
        }
      }
      likes: likes_aggregate {
        aggregate {
          count
        }
      }
      idea_categories {
        category {
          category_id
          category_name
          category_description
        }
      }
      idea_collaborators {
        user {
          username
          first_name
          last_name
          email
          id
        }
      }
      idea_notes {
        note
        action
      }
      idea_scores {
        weighted_avg_score
      }
    }
  }
`

export const EDIT_IDEA = gql`
  mutation UpdateIdeaByID(
    $idea_id: Int!
    $idea_by: Int
    $business_value_prop: String
    $description: String
    $visibility: Int
    $idea_title: String
    $status: Int
    $collaborators: [suhrt_idea_collaborators_insert_input!]!
    $scores: [suhrt_idea_scores_insert_input!]!
    $categories: [suhrt_idea_category_insert_input!]!
    $new_notes: [suhrt_idea_notes_insert_input!]!
    $tags: [String!]
    $attachments: [bytea!]
    $business_canvas_file: String
    $mindmap: String
  ) {
    update_suhrt_idea(
      where: { id: { _eq: $idea_id } }
      _set: {
        idea_by: $idea_by
        business_value_prop: $business_value_prop
        description: $description
        visibility: $visibility
        idea_title: $idea_title
        status: $status
        tags: $tags
        attachments: $attachments
        business_canvas_file: $business_canvas_file
        mindmap: $mindmap
      }
    ) {
      returning {
        id
        idea_by
        business_value_prop
        description
        idea_title
        status
        idea_dt
        mindmap
      }
    }

    delete_suhrt_idea_category(where: { idea_id: { _eq: $idea_id } }) {
      affected_rows
    }

    insert_suhrt_idea_category(
      objects: $categories
      on_conflict: { constraint: idea_category_pkey, update_columns: [] }
    ) {
      affected_rows
    }

    delete_suhrt_idea_collaborators(where: { idea_id: { _eq: $idea_id } }) {
      affected_rows
    }

    insert_suhrt_idea_collaborators(
      objects: $collaborators
      on_conflict: { constraint: idea_collaborators_pkey, update_columns: [] }
    ) {
      affected_rows
    }

    delete_suhrt_idea_scores(where: { idea_id: { _eq: $idea_id } }) {
      affected_rows
    }

    insert_suhrt_idea_scores(
      objects: $scores
      on_conflict: { constraint: idea_scores_pkey, update_columns: [] }
    ) {
      affected_rows
    }

    insert_suhrt_idea_notes(
      objects: $new_notes
      on_conflict: { constraint: idea_notes_pkey, update_columns: [] }
    ) {
      affected_rows
    }
  }
`

/* 33. Get Idea Details */
export const GET_IDEA_DETAILS = gql`
  query getIdeaDetails($idea_id: Int!, $org_id: Int!, $user_id: Int!) {
    suhrt_idea(
      where: {
        id: { _eq: $idea_id }
        user: { org_id: { _eq: $org_id } }
        _or: [
          { visibility: { _eq: 2 } } # Public visibility
          {
            visibility: { _eq: 1 } # Private visibility
            _or: [
              { idea_by: { _eq: $user_id } } # Ideator
              { idea_collaborators: { user_id: { _eq: $user_id } } } # Collaborator
            ]
          }
        ]
      }
    ) {
      id
      idea_title
      description
      visibility
      business_value_prop
      tags
      attachments
      business_canvas_file
      idea_by
      idea_dt
      status
      mindmap
      user {
        id
        username
        first_name
        last_name
        email
        org_id
        role_id
        picture
        isActive
      }
      comments: comments_aggregate {
        aggregate {
          count
        }
      }
      likes: likes_aggregate {
        aggregate {
          count
        }
      }
      idea_categories {
        category {
          category_id
          category_name
          category_description
        }
      }
      idea_collaborators {
        user {
          id
          username
          first_name
          last_name
          email
          role_id
        }
      }
      idea_notes {
        note_id
        note
        action
        created_dt
      }
      idea_scores {
        idea_id
        weight_id
        feasibility_score
        relevance_score
        originality_score
        impact_score
        reuse_score
        feasibility_score
        weighted_avg_score
        relevance_note
        originality_note
        impact_note
        reuse_note
        feasibility_note
        org_score_weight {
          relevance_weight
          originality_weight
          impact_weight
          reuse_weight
          feasibility_weight
        }
      }
      idea_status {
        id
        status_name
      }
    }
  }
`

export const GET_IDEA_BY_ID = gql`
  query getIdeaDetails($id: Int!) {
    suhrt_idea(where: { id: { _eq: $id } }) {
      id
      idea_title
      description
      visibility
      business_value_prop
      tags
      attachments
      business_canvas_file
      idea_by
      idea_dt
      status
      mindmap
      user {
        id
        username
        first_name
        last_name
        email
        org_id
        role_id
        picture
        isActive
      }
      comments: comments_aggregate {
        aggregate {
          count
        }
      }
      likes: likes_aggregate {
        aggregate {
          count
        }
      }
      idea_categories {
        category {
          category_id
          category_name
          category_description
        }
      }
      idea_collaborators {
        user {
          id
          username
          first_name
          last_name
          email
          role_id
        }
      }
      idea_notes {
        note_id
        note
        action
        created_dt
      }
      idea_scores {
        idea_id
        weight_id
        feasibility_score
        relevance_score
        originality_score
        impact_score
        reuse_score
        feasibility_score
        weighted_avg_score
        relevance_note
        originality_note
        impact_note
        reuse_note
        feasibility_note
        org_score_weight {
          relevance_weight
          originality_weight
          impact_weight
          reuse_weight
          feasibility_weight
        }
      }
      idea_status {
        id
        status_name
      }
    }
  }
`
export const ADD_IDEA = gql`
  mutation ADD_IDEA($object: suhrt_idea_insert_input!) {
    insert_suhrt_idea_one(object: $object) {
      id
      idea_title
      description
      visibility
      business_value_prop
      tags
      attachments
      idea_by
      idea_dt
      status
    }
  }
`

export const UpdateIdeaStatus = gql`
  mutation UpdateIdeaStatus(
    $idea_id: Int!
    $status_id: Int!
    $note: String!
    $user_id: Int!
    $action: String
  ) {
    update_suhrt_idea(
      where: { id: { _eq: $idea_id } }
      _set: { status: $status_id }
    ) {
      affected_rows
      returning {
        id
        idea_title
        status
      }
    }

    insert_suhrt_idea_notes(
      objects: {
        idea_id: $idea_id
        note: $note
        user_id: $user_id
        action: $action
      }
    ) {
      affected_rows
      returning {
        note_id
        idea_id
        note
        user_id
        action
        created_dt
      }
    }
  }
`

/* 41. Update Idea Visibility */
export const UpdateIdeaVisibility = gql`
  mutation UpdateIdeaStatus(
    $idea_id: Int!
    $visibility: Int!
    $note: String!
    $user_id: Int!
    $action: String
  ) {
    update_suhrt_idea(
      where: { id: { _eq: $idea_id } }
      _set: { visibility: $visibility }
    ) {
      affected_rows
      returning {
        id
        idea_title
        visibility
      }
    }

    insert_suhrt_idea_notes(
      objects: {
        idea_id: $idea_id
        note: $note
        user_id: $user_id
        action: $action
      }
    ) {
      affected_rows
      returning {
        note_id
        idea_id
        note
        user_id
        action
        created_dt
      }
    }
  }
`

/* 42. Idea Details for Admin: This API to be used when the logged in user is organisation Admin who would be able to see the ideas in his org 
   irrespective of the visibility settings */

export const GET_IDEA_DETAILS_FOR_ADMIN = gql`
  query _getIdeaDetailsForAdmin($idea_id: Int!, $org_id: Int!) {
    suhrt_idea(
      where: { id: { _eq: $idea_id }, user: { org_id: { _eq: $org_id } } }
    ) {
      id
      idea_title
      description
      visibility
      business_value_prop
      tags
      attachments
      business_canvas_file
      idea_by
      idea_dt
      status
      mindmap
      user {
        id
        username
        first_name
        last_name
        email
        org_id
        role_id
        picture
        isActive
      }
      comments: comments_aggregate {
        aggregate {
          count
        }
      }
      likes: likes_aggregate {
        aggregate {
          count
        }
      }
      idea_categories {
        category {
          category_id
          category_name
          category_description
        }
      }
      idea_collaborators {
        user {
          id
          username
          first_name
          last_name
          email
          role_id
        }
      }
      idea_notes {
        note_id
        note
        action
        created_dt
      }
      idea_scores {
        idea_id
        weight_id
        feasibility_score
        relevance_score
        originality_score
        impact_score
        reuse_score
        feasibility_score
        weighted_avg_score
        relevance_note
        originality_note
        impact_note
        reuse_note
        feasibility_note
        org_score_weight {
          relevance_weight
          originality_weight
          impact_weight
          reuse_weight
          feasibility_weight
        }
      }
      idea_status {
        id
        status_name
      }
    }
  }
`

/* 43. */
export const GET_IDEAS_BY_ORGID_FOR_ADMIN = gql`
  query GET_IDEAS_BY_ORGID_FOR_ADMIN($org_id: Int!) {
    suhrt_idea(where: { user: { org_id: { _eq: $org_id } } }) {
      id
      idea_title
      description
      business_value_prop
      tags
      idea_by
      idea_dt
      status
      visibility

      # Idea status name
      idea_status {
        status_name
      }

      user {
        id
        username
        first_name
        last_name
        email
        org_id
        role_id
        picture
        isActive
      }

      # Aggregate counts from the comments table
      comments: comments_aggregate {
        aggregate {
          count
        }
      }

      # Aggregate counts from the likes table
      likes: likes_aggregate {
        aggregate {
          count
        }
      }

      # Retrieve the idea category details
      idea_categories {
        category {
          category_id
          category_name
          category_description
        }
      }

      # Retrieve the collaborators details
      idea_collaborators {
        user {
          username
          first_name
          last_name
          email
          id
        }
      }

      # Retrieve the notes
      idea_notes {
        note
        action
        created_dt
      }

      # Weighted average score from the idea_scores table
      idea_scores {
        weighted_avg_score
      }
    }
  }
`
