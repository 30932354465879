'use client'

import type React from 'react'

import { useDialog } from '@/components/global-dialog/DialogContext'
import { Button } from '@/components/ui/button'
import { ArrowLeft } from 'lucide-react'
import IdeaSubmitForm from '../../forms/IdeaForm/IdeaSubmitForm'
import { clearEvent } from '@/store/idea/ideaSlice'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

export interface IdeaDetails {
  bc_attachment?: string
  ideas: {
    title: string
    description: string
    tags: string[]
    valueprop: string
    mind_map: Record<string, Record<string, string[]>>
    notes: {
      feasibility_note: string
      impact_note: string
      originality_note: string
      relevance_note: string
      reuse_note: string
    }
    scores: {
      feasibility_score: number
      impact_score: number
      originality_score: number
      relevance_score: number
      reuse_score: number
      weighted_avg_score: number
    }
  }
}

export interface IdeaDetailsProps {
  ideaDetails: IdeaDetails
  onBack: () => void
}

const StepFour: React.FC<IdeaDetailsProps> = ({ ideaDetails, onBack }) => {
  const dispatch = useDispatch()
  const { closeDialog } = useDialog()

  // Move dispatch to useEffect
  useEffect(() => {
    dispatch(clearEvent())
  }, [dispatch]) // Add dispatch as dependency

  return (
    <div className='mt-2'>
      <IdeaSubmitForm
        onClose={closeDialog}
        aiIdeaData={ideaDetails.ideas}
        bcGenerated={ideaDetails.bc_attachment || ''}
      />
      <div className='flex justify-between mt-2'>
        <Button variant='ghost' onClick={onBack} size='xs'>
          <ArrowLeft className='w-4 h-4 mr-2' />
          Back
        </Button>
      </div>
    </div>
  )
}

export default StepFour
