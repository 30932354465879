import React from 'react'

interface DotButtonProps {
  selected: boolean
  onClick: () => void
}

export const CarouselDotButton: React.FC<DotButtonProps> = ({
  selected,
  onClick,
}) => (
  <button
    className={`w-2 h-2 rounded-full cursor-pointer transition-colors ${
      selected ? 'bg-primary' : 'bg-foreground hover:bg-primary/50'
    }`}
    type='button'
    onClick={onClick}
  />
)
