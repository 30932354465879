'use client'

import { Button } from '@/components/ui/button'
import { Badge } from '@/components/ui/badge'
import { AnimatePresence, motion } from 'framer-motion'
import { ChevronLeft, ChevronRight } from 'lucide-react'

interface StepTwoProps {
  themes: string[]
  selectedThemes: string[]
  onThemesSelected: (themes: string[]) => void
  onNext: () => void
  onBack: () => void
}

export default function StepTwo({
  themes,
  selectedThemes,
  onThemesSelected,
  onNext,
  onBack,
}: StepTwoProps) {
  const toggleTheme = (theme: string) => {
    if (selectedThemes.includes(theme)) {
      onThemesSelected(selectedThemes.filter((t) => t !== theme))
    } else {
      onThemesSelected([...selectedThemes, theme])
    }
  }

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  }

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  }
  const badgeContainer = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05,
        delayChildren: 0.1,
      },
    },
  }

  const badgeItem = {
    hidden: { opacity: 0, scale: 0.8, x: -20 },
    show: {
      opacity: 1,
      scale: 1,
      x: 0,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 20,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      x: -20,
      transition: {
        duration: 0.2,
      },
    },
  }
  return (
    <div className='space-y-6 h-[calc(100vh-292px)] grid overflow-auto'>
      <div className='space-y-4'>
        <p className='text-sm text-muted-foreground'>
          Select at least two themes to combine. Choose themes that spark your
          interest or seem promising for innovation.
        </p>

        <motion.div
          className='grid grid-cols-1 md:grid-cols-5 gap-3'
          variants={container}
          initial='hidden'
          animate='show'
        >
          {themes.map((theme) => (
            <motion.div key={theme} variants={item}>
              <Button
                variant={selectedThemes.includes(theme) ? 'default' : 'outline'}
                className='w-full h-auto py-1 px-6 text-left justify-start'
                onClick={() => toggleTheme(theme)}
              >
                {theme}
              </Button>
            </motion.div>
          ))}
        </motion.div>

        {/* {selectedThemes.length > 0 && (
          <div className='pt-4 space-y-2'>
            <p className='text-sm font-medium'>Selected Themes:</p>
            <div className='flex flex-wrap gap-2'>
              {selectedThemes.map((theme) => (
                <Badge key={theme} variant='secondary' className='text-sm'>
                  {theme}
                </Badge>
              ))}
            </div>
          </div>
        )} */}
        <AnimatePresence>
          {selectedThemes.length > 0 && (
            <motion.div
              className='pt-4 space-y-2'
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
            >
              <p className='text-sm font-medium'>Selected Themes:</p>
              <motion.div
                className='flex flex-wrap gap-2'
                variants={badgeContainer}
                initial='hidden'
                animate='show'
              >
                <AnimatePresence mode='popLayout'>
                  {selectedThemes.map((theme) => (
                    <motion.div
                      key={theme}
                      variants={badgeItem}
                      initial='hidden'
                      animate='show'
                      exit='exit'
                      layout
                    >
                      <Badge variant='secondary' className='text-sm'>
                        {theme}
                      </Badge>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <div className='flex justify-between pt-4 items-end'>
        <Button variant='ghost' onClick={onBack} size='xs'>
          <ChevronLeft className='mr-2 h-4 w-4' />
          Back
        </Button>
        <Button onClick={onNext} disabled={selectedThemes.length < 2} size='xs'>
          Continue
          <ChevronRight className='ml-2 h-4 w-4' />
        </Button>
      </div>
    </div>
  )
}
