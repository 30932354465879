'use client'

import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Separator } from '@/components/ui/separator'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/use-toast'
import { RootState } from '@/store/store'
import { hideLoader, showLoader } from '@/store/idea/loaderSlice'
import { UPDATE_USER_INFO } from '@/graphql/userQuery'
import { setCurrentUser } from '@/store/user/userSlice'
import { User } from '@/types/user'
const Profile = () => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state: RootState) => state.auth)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (currentUser) {
      setFirstName(currentUser.first_name || '')
      setLastName(currentUser.last_name || '')
    }
  }, [currentUser])

  const [updateUserInfo] = useMutation(UPDATE_USER_INFO, {
    onCompleted: (data) => {
      const updatedUser = data.update_suhrt_users.returning[0]

      // Ensure `id` is defined and is a valid number
      if (updatedUser.id !== undefined && typeof updatedUser.id === 'number') {
        const userToUpdate: User = {
          id: updatedUser.id, // Ensure we are passing a valid id
          username: updatedUser.username, // Default if empty
          first_name: updatedUser.first_name,
          last_name: updatedUser.last_name,
          email: updatedUser.email,
          org_id: updatedUser.organisation.id, // Default if empty
          org_name: updatedUser.organisation.name,
          org_details: updatedUser.organisation.org_details,
          role_id: updatedUser.role.id, // Default if empty
          picture: updatedUser.picture,
          isActive: updatedUser.isActive,
        }

        // Dispatch to Redux store
        dispatch(setCurrentUser(userToUpdate))

        setLoading(false)
        dispatch(hideLoader())
        toast({
          variant: 'success',
          description: 'Profile updated successfully.',
        })
      } else {
        console.error('Invalid user id received')
        setLoading(false)
        dispatch(hideLoader())
        toast({
          variant: 'destructive',
          description: 'Failed to update profile: Invalid user id.',
        })
      }
    },
    onError: (error) => {
      // Handle mutation error
      setLoading(false)
      dispatch(hideLoader())
      toast({
        variant: 'destructive',
        description: `Failed to update profile: ${error.message}`,
      })
    },
  })

  const handleUpdate = async () => {
    if (!currentUser) return

    try {
      setLoading(true)
      dispatch(showLoader())
      await updateUserInfo({
        variables: {
          id: currentUser.id,
          input: { first_name: firstName, last_name: lastName },
        },
      })
    } catch (error) {
      console.error('Error updating profile:', error)
      setLoading(false)
      dispatch(hideLoader())
    }
  }

  return (
    <div className='py-5'>
      <div className='grid grid-cols-[50px_minmax(0,1fr)] justify-center items-center gap-2 pb-5'>
        <Avatar className='h-12 w-12'>
          <AvatarImage
            src={currentUser?.picture || ''}
            alt={currentUser?.username}
          />
          <AvatarFallback>
            {currentUser?.username?.[0]?.toUpperCase() || 'U'}
          </AvatarFallback>
        </Avatar>
        <h2 className='mb-1 rounded-md px-2 py-1 text-lg font-semibold'>
          Your Profile
        </h2>
      </div>
      <Separator className='mb-6' />

      <form
        className='grid gap-4 md:grid-cols-2 md:gap-6 p-6 border border-border  rounded-lg shadow-md max-w-screen-md m-auto'
        onSubmit={(e) => {
          e.preventDefault()
          handleUpdate()
        }}
      >
        <div className='grid gap-1'>
          <Label htmlFor='firstName'>First Name</Label>
          <Input
            id='firstName'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder='Enter your first name'
          />
        </div>

        <div className='grid gap-1'>
          <Label htmlFor='lastName'>Last Name</Label>
          <Input
            id='lastName'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder='Enter your last name'
          />
        </div>

        <div className='grid gap-1'>
          <Label htmlFor='email'>Email</Label>
          <Input
            id='email'
            value={currentUser?.email || ''}
            disabled
            className='cursor-not-allowed flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-base ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 md:text-sm'
          />
        </div>

        <div className='grid gap-1'>
          <Label htmlFor='role'>Role</Label>
          <Input
            id='role'
            value={
              currentUser?.role_id === 1
                ? 'Super Admin'
                : currentUser?.role_id === 2
                ? 'Admin'
                : currentUser?.role_id === 3
                ? 'User'
                : 'Unknown Role'
            }
            disabled
            className='flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-base ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 md:text-sm cursor-not-allowed '
          />
        </div>

        <div className='grid gap-1'>
          <Label htmlFor='orgName'>My Space’s Name</Label>
          <Input
            id='orgName'
            value={currentUser?.org_name || ''}
            disabled
            className='flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-base ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 md:text-sm cursor-not-allowed'
          />
        </div>

        <div className='md:col-span-2 text-right'>
          <Button type='submit' className='w-full md:w-auto' disabled={loading}>
            {loading ? 'Updating...' : 'Update Profile'}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default Profile
