import { useMemo } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'

interface Scores {
  relevance_score: number
  originality_score: number
  impact_score: number
  reuse_score: number
  feasibility_score: number
}

interface Notes {
  relevance_note: string
  originality_note: string
  impact_note: string
  reuse_note: string
  feasibility_note: string
}

interface OrgScoreWeight {
  relevance_weight: number
  originality_weight: number
  impact_weight: number
  reuse_weight: number
  feasibility_weight: number
}

export interface ScoresProps {
  ideaID: number
  weight_id: number
  weighted_avg_score: number
  scores: Scores
  notes: Notes
  weights?: OrgScoreWeight
}

//type ScoreKey = keyof Scores
type NoteKey = keyof Notes
type WeightKey = keyof OrgScoreWeight

interface ScoreRowData {
  parameter: string
  weight: string
  note: string
  score: number
}

export function SuhrtScoreParams({
  suhrtScores,
}: {
  suhrtScores: ScoresProps
}) {
  const scoreData = useMemo(() => {
    return Object.entries(suhrtScores.scores).map(
      ([key, score]): ScoreRowData => {
        const baseKey = key.split('_')[0]
        const weightKey = `${baseKey}_weight` as WeightKey
        const noteKey = `${baseKey}_note` as NoteKey

        const weight = suhrtScores.weights
          ? `${suhrtScores.weights[weightKey] * 100}`
          : '-'

        return {
          parameter:
            baseKey.charAt(0).toUpperCase() + baseKey.slice(1).toLowerCase(),
          weight,
          note: suhrtScores.notes[noteKey],
          score,
        }
      }
    )
  }, [suhrtScores])

  const formattedAvgScore = useMemo(() => {
    return suhrtScores?.weighted_avg_score?.toFixed(1)
  }, [suhrtScores.weighted_avg_score])

  return (
    <Table className='md:w-full border border-1 caption-top'>
      <TableHeader>
        <TableRow>
          <TableHead className='w-[100px] p-2'>Parameter</TableHead>
          <TableHead className='p-2'>Weightage</TableHead>
          <TableHead className='p-2'>Details</TableHead>
          <TableHead className='p-2'>Score</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {scoreData.map(({ parameter, weight, note, score }) => (
          <TableRow key={parameter}>
            <TableCell className='font-medium p-2'>{parameter}</TableCell>
            <TableCell className='p-2'>
              {weight}
              <span className='font-bold mx-1'>%</span>
            </TableCell>
            <TableCell className='p-2'>{note}</TableCell>
            <TableCell className='p-2'>
              {score}/<span className='font-bold'>10</span>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell colSpan={3} className='text-lg font-semibold'>
            Weighted average score
          </TableCell>
          <TableCell className='text-lg font-semibold'>
            {formattedAvgScore}
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  )
}
