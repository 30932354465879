import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  hideLoader,
  selectIsLoading,
  showLoader,
} from './store/idea/loaderSlice'

import Layout from './components/layout/Layout'
import Ideas from './pages/Ideas'
import Dashboard from './pages/Dashboard'
import { Pricing } from './pages/Pricing'
import IdeaDetails from './pages/IdeaDetails'
import Home from './pages/Home'
import Loader from './components/Loader'
import Organisation from './pages/Organisation'
import Profile from './pages/UserProfile'
import CreateOranisation from './pages/CreateOranisation'
import { useAuth0 } from '@auth0/auth0-react'
import { useCallback, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_USER,
  GET_USER_BY_EMAIL,
  UPDATE_LASTLOGGED_IN,
  UPDATE_USER_ACTIVE_STATUS,
} from './graphql/userQuery'
import { resetGetStarted, setCurrentUser } from './store/user/userSlice'
import { RootState } from './store/store'
import { GET_ORGANIZATION_BY_ID } from './graphql/orgQuery'
import Contact from './pages/Contact'
import { SettingsForm } from './pages/Settings'
import AboutPage from './pages/AboutPage'
import BlogPage from './pages/BlogPage'
import BlogPost from './blog/BlogPost'
import { UTILITY } from './util/utility'
import VerifyEmail from './components/login/VerifyEmail'
import {
  AuthenticationGuard,
  AuthProtectedRoute,
  CreateOrgProtectedRoute,
  RequireOrgRoute,
  VerifyEmailGuard,
} from './components/protetectedRoutes/ProtectedRoutes'
import { GetStartedRibbon } from './components/ribbon/GetStartedRibbon'
import { useToast } from './components/ui/use-toast'

function App() {
  const isLoading = useSelector(selectIsLoading)
  const { user } = useAuth0()
  const { currentUser } = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { toast } = useToast()
  const [createUser] = useMutation(CREATE_USER)
  const [updateLastLoggedIn] = useMutation(UPDATE_LASTLOGGED_IN)
  const [updateUserActiveStatus] = useMutation(UPDATE_USER_ACTIVE_STATUS)

  // Memoize the user creation function
  const handleCreateUser = useCallback(async () => {
    if (!user) return null

    const [firstName, lastName] = user.name?.split(' ') ?? ['New User', '']

    const response = await createUser({
      variables: {
        object: {
          email: user.email,
          username: user.email,
          first_name: firstName,
          last_name: lastName,
          picture: user.picture ?? '',
          isActive: false,
        },
      },
    })

    return response.data?.insert_suhrt_users_one
  }, [user, createUser])

  // Handle user data fetching and creation
  useQuery(GET_USER_BY_EMAIL, {
    variables: { email: user?.email },
    skip: !user?.email,
    onCompleted: async (data) => {
      try {
        const existingUser = data.suhrt_users?.[0]

        if (existingUser) {
          dispatch(setCurrentUser(existingUser))
          await updateLastLoggedIn({
            variables: { user_id: existingUser.id },
          })

          if (!existingUser.isActive && location.pathname !== '/verifyEmail') {
            navigate('/verifyEmail', { replace: true })
          }
        } else {
          const newUser = await handleCreateUser()
          if (newUser) {
            dispatch(setCurrentUser(newUser))
            navigate('/verifyEmail', { replace: true })
          }
        }
      } catch (error) {
        console.error('Error in user query or creation:', error)
      }
    },
  })

  // Fetch organization details
  const { data: orgData } = useQuery(GET_ORGANIZATION_BY_ID, {
    variables: { org_id: currentUser?.org_id },
    skip: !currentUser?.org_id,
  })

  const handleEmailVerification = useCallback(
    async (token: string) => {
      const result = UTILITY.decryptToken(token)
      if (!result) {
        toast({
          variant: 'destructive',
          title: 'Verification Failed',
          description: 'Invalid or expired verification link.',
        })
        navigate('/', { replace: true })
        return
      }

      try {
        dispatch(showLoader())

        const { data } = await updateUserActiveStatus({
          variables: { user_id: result[0], isActive: true },
        })

        if (data.update_suhrt_users_by_pk) {
          const updatedUser = {
            ...data.update_suhrt_users_by_pk,
            isActive: true,
          }

          // Update user state and reset get started state before navigation
          await Promise.all([
            dispatch(setCurrentUser(updatedUser)),
            dispatch(resetGetStarted()),
          ])

          toast({
            description: 'Your account has been verified. Welcome!',
            duration: 3000,
          })

          //const destination = updatedUser.org_id ? '/' : '/createorganisation'
          navigate('/', { replace: true })
        }
      } catch (error) {
        console.error('Error updating user status:', error)
        toast({
          variant: 'destructive',
          title: 'Verification Failed',
          description: 'Please try again or contact support.',
        })
        navigate('/', { replace: true })
      } finally {
        dispatch(hideLoader())
      }
    },
    [updateUserActiveStatus, dispatch, navigate]
  )
  // Handle email verification token
  useEffect(() => {
    if (location.pathname === '/verifyEmail') {
      const token = new URLSearchParams(location.search).get('token')
      if (token) {
        handleEmailVerification(token)
      }
    }
  }, [location, handleEmailVerification])

  // Update user with organization details
  useEffect(() => {
    if (orgData && currentUser) {
      const orgDetails = orgData.suhrt_organisation_by_pk
      if (orgDetails && currentUser.org_name !== orgDetails.name) {
        dispatch(
          setCurrentUser({
            ...currentUser,
            org_name: orgDetails.name,
            org_details: orgDetails.org_details,
          })
        )
      }
    }
  }, [orgData, currentUser, dispatch, location.pathname])

  return (
    <>
      {isLoading && <Loader />}
      <GetStartedRibbon />
      <Routes>
        <Route
          path='/'
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path='/pricing'
          element={
            <Layout>
              <Pricing />
            </Layout>
          }
        />
        <Route
          path='/contact'
          element={
            <Layout>
              <Contact />
            </Layout>
          }
        />
        <Route
          path='/about'
          element={
            <Layout>
              <AboutPage />
            </Layout>
          }
        />
        <Route
          path='/blog'
          element={
            <Layout>
              <BlogPage />
            </Layout>
          }
        />
        <Route
          path='/blog/:slug'
          element={
            <Layout>
              <BlogPost />
            </Layout>
          }
        />
        {/* Email verification route */}
        <Route element={<VerifyEmailGuard />}>
          <Route path='/verifyEmail' element={<VerifyEmail />} />
        </Route>

        {/* Base authentication guard */}
        <Route element={<AuthenticationGuard />}>
          {/* Public routes requiring verification */}
          {/* <Route element={<VerifiedUserGuard />}>
            
          </Route> */}

          {/* Protected routes requiring both auth and verification */}
          <Route element={<AuthProtectedRoute />}>
            {/* Routes requiring organization */}
            <Route element={<RequireOrgRoute />}>
              <Route
                path='/ideas'
                element={
                  <Layout>
                    <Ideas />
                  </Layout>
                }
              />
              <Route
                path='/ideas/:id'
                element={
                  <Layout>
                    <IdeaDetails />
                  </Layout>
                }
              />
              <Route
                path='/dashboard'
                element={
                  <Layout>
                    <Dashboard />
                  </Layout>
                }
              />
              <Route
                path='/myinnovationspace'
                element={
                  <Layout>
                    <Organisation />
                  </Layout>
                }
              />
              <Route
                path='/profile'
                element={
                  <Layout>
                    <Profile />
                  </Layout>
                }
              />
              <Route
                path='/settings'
                element={
                  <Layout>
                    <SettingsForm />
                  </Layout>
                }
              />
            </Route>

            {/* Create organization route */}
            <Route element={<CreateOrgProtectedRoute />}>
              <Route
                path='/createmyinnovationspace'
                element={
                  <Layout>
                    <CreateOranisation />
                  </Layout>
                }
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  )
}

export default App
