import { useSelector } from 'react-redux'
import { Button } from '../ui/button'
import { DashboardCarousel } from './DashboardCarousel'
import { useNavigate } from 'react-router-dom'
import { RootState } from '@/store/store'

export default function ContentWrapper() {
  const { showGetStarted } = useSelector((state: RootState) => state.auth)
  const navigate = useNavigate()
  return (
    <main className='container mx-auto px-0'>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 py-8 md:py-12 items-center'>
        {/* Text Content - First on mobile, second on desktop */}
        <section className='order-2 md:order-1 text-center md:text-left'>
          <h1 className='md:pt-0 pt-8 font-semibold text-4xl sm:text-4xl md:text-5xl pb-6 dark:text-white leading-tight'>
            Suhrt
            <span className='block text-primary pt-1'>
              AI innovation Co-pilot
            </span>
          </h1>
          <p className='mb-6 text-base md:text-lg text-muted-foreground max-w-prose mx-auto md:mx-0'>
            <strong className='font-semibold text-foreground'>
              Empowering Ideas, Fuelling Innovations with AI:
            </strong>{' '}
            Discover the power of Suhrt Innovation, an AI-driven platform that
            leverages design thinking to generate ideas, auto-evaluate them, and
            create business plans seamlessly, transforming concepts into
            groundbreaking innovations.
          </p>
          <div className='flex justify-center md:justify-start'>
            {showGetStarted && (
              <Button
                variant='default'
                size='lg'
                onClick={() => {
                  navigate('/createmyinnovationspace')
                }}
                className='text-[#09090b] text-base px-12'
              >
                Start for Free
              </Button>
            )}
          </div>
        </section>

        {/* Carousel Content - Second on mobile, first on desktop */}
        <section className='order-1 md:order-2 flex justify-center items-center'>
          <div className='w-full'>
            <DashboardCarousel />
          </div>
        </section>
      </div>
    </main>
  )
}
