import Select, { StylesConfig } from 'react-select'
import { Control, Controller } from 'react-hook-form'
import { useEffect, useState } from 'react'

interface MultiSelectProps {
  control: Control<any>
  name: string
  options: { value: string; label: string }[]
  placeholder: string
}

export const MultiSelect: React.FC<MultiSelectProps> = ({
  control,
  name,
  options,
  placeholder,
}) => {
  const [theme, setTheme] = useState<'light' | 'dark'>(() => {
    // Check initial theme
    const savedTheme = localStorage.getItem('theme')
    if (savedTheme) return savedTheme as 'light' | 'dark'

    return window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light'
  })

  useEffect(() => {
    // Listen for theme changes
    const handleThemeChange = () => {
      const root = document.documentElement
      setTheme(root.classList.contains('dark') ? 'dark' : 'light')
    }

    // Add mutation observer to watch for class changes
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'class'
        ) {
          handleThemeChange()
        }
      })
    })

    observer.observe(document.documentElement, { attributes: true })

    // Initial check
    handleThemeChange()

    // Cleanup
    return () => observer.disconnect()
  }, [])

  // Custom styles for react-select
  const customStyles: StylesConfig = {
    control: (provided, _state) => ({
      ...provided,
      backgroundColor:
        theme === 'dark' ? 'hsl(var(--background))' : 'hsl(var(--background))',
      borderColor:
        theme === 'dark' ? 'hsl(var(--border))' : 'hsl(var(--border))',
      boxShadow: 'none',
      '&:hover': {
        borderColor:
          theme === 'dark' ? 'hsl(var(--primary))' : 'hsl(var(--primary))',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor:
        theme === 'dark' ? 'hsl(var(--popover))' : 'hsl(var(--background))',
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '0.875rem',
      backgroundColor: state.isSelected
        ? theme === 'dark'
          ? 'hsl(var(--primary))'
          : 'hsl(var(--primary))'
        : state.isFocused
        ? theme === 'dark'
          ? 'hsl(var(--accent))'
          : 'hsl(var(--accent-50))'
        : 'transparent',
      color: state.isSelected
        ? theme === 'dark'
          ? 'hsl(var(--foreground))'
          : 'hsl(var(--foreground))'
        : theme === 'dark'
        ? 'hsl(var(--foreground))'
        : 'hsl(var(--foreground))',
      ':active': {
        backgroundColor:
          theme === 'dark' ? 'hsl(var(--accent))' : 'hsl(var(--accent-50))',
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      fontSize: '0.875rem', // Selected tag font size
      backgroundColor:
        theme === 'dark'
          ? 'hsl(var(--primary-500))'
          : 'hsl(var(--primary-100))',
      color:
        theme === 'dark' ? 'hsl(var(--foreground))' : 'hsl(var(--foreground))',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontSize: '0.875rem', // Selected label font size
      color:
        theme === 'dark'
          ? 'hsl(var(--foreground) / 0.6)'
          : 'hsl(var(--foreground) / 0.6)',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color:
        theme === 'dark' ? 'hsl(var(--foreground))' : 'hsl(var(--foreground)) ',
      ':hover': {
        backgroundColor:
          theme === 'dark'
            ? 'hsl(var(--destructive))'
            : 'hsl(var(--destructive))',
        color: 'hsl(var(--destructive-foreground))',
      },
    }),
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Select
          isMulti
          value={value}
          onChange={onChange}
          options={options}
          placeholder={placeholder}
          className='text-sm'
          styles={customStyles}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: 'hsl(var(--primary))',
              primary75: 'hsl(var(--primary-50))',
              primary50: 'hsl(var(--primary-50))',
              primary25: 'hsl(var(--primary-50))',
            },
          })}
        />
      )}
    />
  )
}
