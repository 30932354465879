import React from 'react'
import { motion, useInView } from 'framer-motion'

interface AnimatedImageProps {
  src: string
  alt: string
  caption?: string
}

const imageVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: 'easeOut',
    },
  },
}

export function AnimatedImage({ src, alt, caption }: AnimatedImageProps) {
  const ref = React.useRef(null)
  const isInView = useInView(ref, {
    once: true,
    margin: '0px 0px -100px 0px',
  })

  return (
    <motion.figure
      ref={ref}
      className='my-8'
      initial='hidden'
      animate={isInView ? 'visible' : 'hidden'}
      variants={imageVariants}
    >
      <img
        src={src || '/placeholder.svg'}
        alt={alt}
        className='rounded-lg w-full'
        loading='lazy'
      />
      {caption && (
        <figcaption className='text-center text-sm text-gray-500 mt-2'>
          {caption}
        </figcaption>
      )}
    </motion.figure>
  )
}
