import React from "react";
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";

interface Department {
  name: string;
  colors: string[];
}

const departments: Department[] = [
  { name: "SAO", colors: ["green", "blue"] },
  { name: "BSC", colors: [] },
];

const DepartmentForm: React.FC = () => {
  return (
    <div className="max-w-2xl pt-6">
      <div className="grid grid-cols-2 gap-6">
        {departments.map((dept) => (
          <Card key={dept.name} className="p-4 shadow-md rounded-lg">
            <CardHeader>
              <h2 className="text-xl font-semibold">{dept.name}</h2>
            </CardHeader>
            <CardContent>
              {dept.colors.length > 0 ? (
                <div className="flex space-x-2">
                  {dept.colors.map((color) => (
                    <Badge key={color} className={`bg-${color}-500 text-white`}>
                      {color.charAt(0).toUpperCase() + color.slice(1)}
                    </Badge>
                  ))}
                </div>
              ) : (
                <p>No colors assigned</p>
              )}
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default DepartmentForm;
