import { gql } from "@apollo/client"

export const LIKE = gql`
  mutation LIKE($object: suhrt_likes_insert_input!) {
    insert_suhrt_likes_one(object: $object) {
      id
    }
  }
`

export const UNLIKE_IDEA = gql`
  mutation UNLIKE_BY_IDEA_ID($idea_id: Int!, $liked_by: Int!) {
    delete_suhrt_likes(
      where: { idea_id: { _eq: $idea_id }, liked_by: { _eq: $liked_by } }
    ) {
      affected_rows
    }
  }
`

export const UNLIKE_COMMENT = gql`
  mutation UNLIKE_BY_COMMENT_ID($comment_id: Int!, $liked_by: Int!) {
    delete_suhrt_likes(
      where: { comment_id: { _eq: $comment_id }, liked_by: { _eq: $liked_by } }
    ) {
      affected_rows
    }
  }
`

export const GET_LIKE_BY_USER_FOR_IDEA = gql`
  query GET_LIKE_BY_USER_FOR_IDEA($ideaId: Int!, $userId: Int!) {
    suhrt_likes(
      where: { idea_id: { _eq: $ideaId }, liked_by: { _eq: $userId } }
    ) {
      id
    }
  }
`
