import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { FaLightbulb } from 'react-icons/fa'
import { PopoverAnchor, PopoverArrow } from '@radix-ui/react-popover'
import { useState, useEffect } from 'react'
import { TooltipContent } from './TooltipContent'
import { useNavigate } from 'react-router-dom'
import { UTILITY } from '@/util/utility'

function IdeaToolTip(idea: any & { newlyAdded?: boolean }) {
  const [open, setOpen] = useState(false)
  const [isNewlyAdded, setIsNewlyAdded] = useState(idea.newlyAdded || false)

  // Effect to manage newly added state
  useEffect(() => {
    const newlyAddedIds = JSON.parse(
      sessionStorage.getItem('newlyAddedIdeas') || '[]'
    )
    setIsNewlyAdded(newlyAddedIds.includes(idea.id))

    if (newlyAddedIds.includes(idea.id)) {
      const timer = setTimeout(() => {
        const updatedIds = newlyAddedIds.filter((id: string) => id !== idea.id)
        sessionStorage.setItem('newlyAddedIdeas', JSON.stringify(updatedIds))
        setIsNewlyAdded(false)
      }, 10000)

      return () => clearTimeout(timer)
    }
  }, [idea.id])

  const handleMouseEnter = () => {
    setOpen(true)
  }

  let navigate = useNavigate()
  // Determine ping animation color based on idea ID
  const isPrimary = Number(idea.id) % 2 === 0
  const pingColor = isPrimary ? 'bg-primary' : 'bg-accent'
  // Determine bulb color based on idea.status
  const bulbColorMap: {
    1: string
    2: string
    3: string
  } = {
    1: 'text-primary',
    2: 'text-accent',
    3: 'text-lime-500',
  }

  // Type-safe fallback for invalid status
  const bulbColor =
    bulbColorMap[idea.status as keyof typeof bulbColorMap] || 'text-primary'
  const [fillColor, setFillColor] = useState('')

  // Detect theme and update fill color
  useEffect(() => {
    const updateFillColor = () => {
      const isDarkMode = document.documentElement.classList.contains('dark')
      setFillColor(isDarkMode ? 'black' : 'white') // Adjust colors as needed
    }

    updateFillColor() // Set initially

    // Add an event listener to handle theme changes dynamically
    const themeChangeObserver = new MutationObserver(updateFillColor)
    themeChangeObserver.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class'],
    })

    return () => themeChangeObserver.disconnect()
  }, [])
  return (
    <div className='relative inline-block'>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverAnchor
          asChild
          onMouseEnter={handleMouseEnter}
          onClick={(e) => {
            e.preventDefault()
          }}
        >
          <PopoverTrigger className='relative'>
            {idea.newlyAdded && (
              <span className='absolute inset-0 z-0 flex h-[2.5rem] w-[2.5rem]'>
                <span
                  className={`animate-ping absolute inline-flex h-full w-full rounded-full ${pingColor} opacity-70`}
                ></span>
                <span className='relative inline-flex rounded-full h-full w-full bg-transparent'></span>
              </span>
            )}

            <FaLightbulb
              onClick={() => navigate(`/ideas/${UTILITY.encryptedId(idea.id)}`)}
              className={[
                'text-[2.5rem] relative z-10',
                bulbColor,
                isNewlyAdded && '',
              ]
                .filter(Boolean)
                .join(' ')}
            ></FaLightbulb>
          </PopoverTrigger>
        </PopoverAnchor>
        <PopoverContent
          className='PopoverContent rounded-lg shadow-outline border-0 shadow-gray-300 dark:shadow-muted shadow-[0_2px_22px_0px_rgba(0,0,0,0.3)]'
          sideOffset={3}
          side={'top'}
          align='start'
        >
          <TooltipContent {...idea} />
          <PopoverArrow className='h-2 w-4' style={{ fill: fillColor }} />
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default IdeaToolTip
