// ProtectedRoutes.tsx
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { hideLoader, showLoader } from '@/store/idea/loaderSlice'

// Base authentication guard for all routes
export const AuthenticationGuard = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { currentUser } = useSelector((state: RootState) => state.auth)
  useEffect(() => {
    const shouldShowLoader = isLoading || (isAuthenticated && !currentUser)
    dispatch(shouldShowLoader ? showLoader() : hideLoader())

    return () => {
      dispatch(hideLoader())
    }
  }, [isLoading, isAuthenticated, currentUser, dispatch])

  useEffect(() => {
    const handleAuthentication = async () => {
      if (!isLoading) {
        if (!isAuthenticated) {
          await loginWithRedirect({
            appState: { returnTo: location.pathname },
          })
          return
        }

        if (!currentUser) {
          return
        }

        if (!currentUser.isActive && location.pathname !== '/verifyEmail') {
          navigate('/verifyEmail', { replace: true })
        }
      }
    }

    handleAuthentication()
  }, [
    isLoading,
    isAuthenticated,
    currentUser,
    location.pathname,
    loginWithRedirect,
    navigate,
  ])

  if (isLoading || !isAuthenticated || !currentUser) {
    return null
  }

  return <Outlet />
}

// Guard for routes requiring authentication and verification
export const AuthProtectedRoute = () => {
  const { currentUser } = useSelector((state: RootState) => state.auth)
  const { isAuthenticated } = useAuth0()

  if (!isAuthenticated) {
    return null
  }

  if (!currentUser?.isActive) {
    return <Navigate to='/verifyEmail' replace />
  }

  return <Outlet />
}

// Guard for verified users to access public routes
export const VerifiedUserGuard = () => {
  const { currentUser } = useSelector((state: RootState) => state.auth)
  const { isAuthenticated } = useAuth0()

  if (!isAuthenticated) {
    return null
  }

  if (!currentUser?.isActive) {
    return <Navigate to='/verifyEmail' replace />
  }

  return <Outlet />
}

// Guard for organization creation route
export const CreateOrgProtectedRoute = () => {
  const { currentUser } = useSelector((state: RootState) => state.auth)
  const { isAuthenticated } = useAuth0()
  const location = useLocation()

  if (!isAuthenticated) {
    return null
  }

  if (currentUser?.org_id && location.pathname === '/createmyinnovationspace') {
    return <Navigate to='/' replace />
  }

  return <Outlet />
}

// Guard for routes requiring organization
export const RequireOrgRoute = () => {
  const { currentUser } = useSelector((state: RootState) => state.auth)
  const { isAuthenticated } = useAuth0()

  if (!isAuthenticated) {
    return null
  }

  if (!currentUser?.org_id) {
    return <Navigate to='/createmyinnovationspace' replace />
  }

  return <Outlet />
}
export const VerifyEmailGuard = () => {
  const { currentUser } = useSelector((state: RootState) => state.auth)
  const { isAuthenticated, isLoading } = useAuth0()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isLoading && isAuthenticated && currentUser?.isActive) {
      dispatch(showLoader())
      navigate('/', { replace: true })
    }
  }, [isLoading, isAuthenticated, currentUser, navigate, dispatch])

  // Prevent any rendering if we're about to redirect
  if (isLoading || (isAuthenticated && currentUser?.isActive)) {
    return null
  }

  return <Outlet />
}
