'use client'

import { useState } from 'react'
import { motion } from 'framer-motion'
import { LockIcon, ArrowRight } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'

interface ScoreSectionOverlayProps {
  onUpgradeClick: () => void
  maxScores: number
}

export function ScoreSectionOverlay({
  onUpgradeClick,
  maxScores,
}: ScoreSectionOverlayProps) {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{ duration: 0.3 }}
      className='absolute inset-0 z-50 rounded-md flex items-center justify-center p-4 backdrop-blur-sm bg-black/70'
    >
      <Card className='w-full max-w-md mx-auto overflow-hidden'>
        <motion.div
          className='flex flex-col items-center p-6'
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.1, duration: 0.4 }}
        >
          <motion.div
            className='w-16 h-16 mb-4 rounded-full bg-gradient-to-br from-yellow-200 to-yellow-400 flex items-center justify-center'
            animate={{ scale: isHovered ? 1.1 : 1 }}
            transition={{ type: 'spring', stiffness: 400, damping: 10 }}
          >
            <LockIcon className='w-8 h-8 text-yellow-600' />
          </motion.div>
          <motion.h2
            className='text-2xl font-bold tracking-tight mb-2 text-center'
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.4 }}
          >
            Token Limit Reached
          </motion.h2>
          <motion.p
            className='text-muted-foreground text-center mb-6'
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.4 }}
          >
            You've used all {maxScores} available tokens in your current plan.
          </motion.p>
          <div className='w-full space-y-4 flex flex-col items-center'>
            <p className='text-xs text-muted-foreground text-center'>
              {maxScores} / {maxScores} tokens used
            </p>
            <Button
              size='lg'
              className='w-full md:w-auto bg-primary hover:bg-primary/90 text-primary-foreground'
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={onUpgradeClick}
            >
              Upgrade Now
              <ArrowRight className='ml-2 h-4 w-4' />
            </Button>
          </div>
        </motion.div>
      </Card>
    </motion.div>
  )
}
