'use client'

import { useState } from 'react'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Card, CardContent } from '@/components/ui/card'
import { Loader2, ArrowLeft, Shuffle, Plus } from 'lucide-react'
import { motion } from 'framer-motion'
import { useToast } from '@/components/ui/use-toast'
import { cn } from '@/lib/utils'
import config from '@/config'
import { clearEvent } from '@/store/idea/ideaSlice'
import { useDispatch, useSelector } from 'react-redux'
import { GET_SCORE_WEIGHTS_BY_ORGID } from '@/graphql/orgQuery'
import { useQuery } from '@apollo/client'
import { RootState } from '@/store/store'
import { useNavigate } from 'react-router-dom'
import { useDialog } from '@/components/global-dialog/DialogContext'

interface StepThreeProps {
  selectedThemes: string[]
  onIdeaGenerated: (idea: any) => void
  onNext: () => void
  onBack: () => void
  checkTokenAvailability: () => Promise<number>
  setShowOverlay: (show: boolean) => void
}
interface MindMapStage {
  [key: string]: any[]
}
export default function StepThree({
  selectedThemes,
  onIdeaGenerated,
  onNext,
  onBack,
  checkTokenAvailability,
  setShowOverlay,
}: StepThreeProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [autoSelectedThemes, setAutoSelectedThemes] = useState<string[]>([])
  const [isGeneratingIdea, setIsGeneratingIdea] = useState(false)
  const [thirdTheme, setThirdTheme] = useState('')
  const { toast } = useToast()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { closeDialog } = useDialog()
  const autoSelectThemes = () => {
    if (selectedThemes.length < 2) {
      toast({
        title: 'Not enough themes',
        description: 'Please select at least two themes to auto-select',
        variant: 'destructive',
      })
      return
    }
    const shuffled = [...selectedThemes].sort(() => 0.5 - Math.random())
    setAutoSelectedThemes(shuffled.slice(0, 2))
  }

  const generateRandomTheme = async () => {
    try {
      setIsLoading(true)
      const hasTokens = await checkTokenAvailability()

      if (hasTokens === 0) {
        setShowOverlay(true)
        return
      }
      const response = await fetch(
        `${config.apiUrl}/suhrtai/generateRandomTheme`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        }
      )
      if (!response.ok) throw new Error('Failed to generate theme')
      const data = await response.json()
      setThirdTheme(data.random_theme)
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to generate random theme',
        variant: 'destructive',
      })
    } finally {
      setIsLoading(false)
    }
  }
  const { currentUser } = useSelector((state: RootState) => state.auth)
  const { data } = useQuery(GET_SCORE_WEIGHTS_BY_ORGID, {
    variables: { org_id: currentUser?.org_id },
    skip: !currentUser?.org_id,
  })
  const reorderMindMap = (data: any) => {
    // Define the desired order of mind map stages
    const desiredOrder: string[] = [
      'Empathize',
      'Define',
      'Ideate',
      'Prototype',
      'Test',
    ]

    // Create a new mind map with reordered stages
    const reorderedMindMap = desiredOrder.reduce((acc, stage) => {
      // If the stage exists in the original mind map, preserve its data
      acc[stage] = data.ideas.mind_map[stage] || {}
      return acc
    }, {} as { [key: string]: MindMapStage })

    // Return a new object with the reordered mind map
    return {
      ...data,
      ideas: {
        ...data.ideas,
        mind_map: reorderedMindMap,
      },
    }
  }
  const handleUpgradeClick = () => {
    closeDialog()
    navigate('/pricing') // Navigate to the pricing page route
  }
  const MAX_RETRIES = 3
  const INITIAL_DELAY = 1000 // 1 second

  const generateIdea = async () => {
    const hasTokens = await checkTokenAvailability()
    if (hasTokens === 0) {
      setShowOverlay(true)
      return
    }
    if (hasTokens === 1) {
      toast({
        title: 'Insufficient Tokens',
        description: (
          <>
            You have insufficient tokens.{' '}
            <span
              onClick={handleUpgradeClick}
              style={{
                fontWeight: 'Bold',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
            >
              Upgrade now
            </span>{' '}
            to generate an idea.
          </>
        ),
        variant: 'destructive',
        duration: 2000,
      })
      return
    }
    const formattedScores = {
      Feasibility: data.suhrt_org_score_weights[0].feasibility_weight,
      Impact: data.suhrt_org_score_weights[0].impact_weight,
      Originality: data.suhrt_org_score_weights[0].originality_weight,
      Reuse: data.suhrt_org_score_weights[0].reuse_weight,
      relevance_score: data.suhrt_org_score_weights[0].relevance_weight,
    }

    const payload = {
      themes: [...autoSelectedThemes, thirdTheme],
      scores: formattedScores,
    }

    if (autoSelectedThemes.length !== 2 || !thirdTheme) {
      toast({
        title: 'Incomplete combination',
        description: 'Please auto-select two themes and add a third theme',
        variant: 'destructive',
      })
      return
    }

    let retries = 0
    let delay = INITIAL_DELAY

    const attemptGenerate = async () => {
      try {
        const response = await fetch(
          `${config.apiUrl}/suhrtai/generateIdea_v2`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              accept: 'application/json',
            },
            body: JSON.stringify(payload),
          }
        )

        if (!response.ok) {
          throw new Error('Failed to generate idea')
        }

        const data = await response.json()

        if (!data || typeof data !== 'object') {
          throw new Error('Invalid idea data received')
        }

        return data
      } catch (error) {
        if (retries < MAX_RETRIES) {
          retries++
          setIsGeneratingIdea(true)
          toast({
            title: 'Retrying',
            description: `Attempt ${retries} of ${MAX_RETRIES}...`,
            duration: 2000,
          })
          await new Promise((resolve) => setTimeout(resolve, delay))
          delay *= 2 // Exponential backoff
          return attemptGenerate()
        }
        throw error
      }
    }

    try {
      setIsGeneratingIdea(true)
      dispatch(clearEvent())
      const data = await attemptGenerate()
      const reorderedData = reorderMindMap(data)

      onIdeaGenerated(reorderedData)
      onNext()
    } catch (error) {
      console.error('Error generating idea:', error)
      toast({
        title: 'Error',
        description:
          'Failed to generate idea after multiple attempts. Please try again.',
        variant: 'destructive',
      })
    } finally {
      setIsGeneratingIdea(false)
    }
  }
  return (
    <div className='space-y-6 h-[calc(100vh-292px)] grid overflow-auto'>
      <Card>
        <CardContent className='p-6'>
          <div className='space-y-6'>
            <h3 className='text-lg font-medium'>Selected Themes</h3>

            {/* All Selected Themes Grid */}
            <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3'>
              {selectedThemes.map((theme) => (
                <motion.div
                  key={theme}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className={cn(
                    'bg-primary/10 px-4 py-2 rounded-full text-sm font-medium text-center dark:bg-muted',
                    autoSelectedThemes.includes(theme) && 'ring-1 ring-primary'
                  )}
                >
                  {theme}
                </motion.div>
              ))}
            </div>

            {/* Auto Select and Display */}
            <div className='flex flex-col sm:flex-row items-center justify-between gap-4'>
              <Button
                variant='outline'
                size='sm'
                onClick={autoSelectThemes}
                className='w-full sm:w-auto'
                disabled={isLoading || selectedThemes.length < 2}
              >
                <Shuffle className='w-4 h-4 mr-2' />
                Auto Select Two Themes
              </Button>
              {autoSelectedThemes.length === 2 && (
                <div className='flex items-center gap-2 text-sm '>
                  <span className='font-semibold'>Selected:</span>
                  <span className='bg-primary/10 px-4 py-1 rounded-full dark:bg-muted'>
                    {autoSelectedThemes[0]}
                  </span>
                  <Plus className='w-4 h-4' />
                  <span className='bg-primary/10 px-4 py-1 rounded-full dark:bg-muted'>
                    {autoSelectedThemes[1]}
                  </span>
                </div>
              )}
            </div>

            {/* Third Theme Input */}
            {autoSelectedThemes.length === 2 && (
              <div className='flex items-center gap-3'>
                <Input
                  placeholder='Enter or generate a third theme'
                  value={thirdTheme}
                  onChange={(e) => setThirdTheme(e.target.value)}
                  className='flex-1'
                />
                <Button
                  onClick={generateRandomTheme}
                  disabled={isLoading}
                  size='sm'
                  variant='outline'
                >
                  {isLoading ? (
                    <Loader2 className='w-4 h-4 animate-spin' />
                  ) : (
                    'Generate'
                  )}
                </Button>
              </div>
            )}
          </div>
        </CardContent>
      </Card>

      <div className='flex justify-between items-end'>
        <Button
          variant='ghost'
          onClick={onBack}
          disabled={isLoading}
          className='text-gray-600'
        >
          <ArrowLeft className='w-4 h-4 mr-2' />
          Back
        </Button>
        <Button
          onClick={generateIdea}
          disabled={isLoading || autoSelectedThemes.length !== 2 || !thirdTheme}
          className={cn('disabled:bg-gray-200 disabled:text-gray-400')}
          size='sm'
        >
          {isGeneratingIdea ? (
            <>
              <Loader2 className='w-4 h-4 mr-2 animate-spin' />
              Generating...
            </>
          ) : (
            'Ideate'
          )}
        </Button>
      </div>
    </div>
  )
}
