import { useEffect, useState } from 'react'
import { User } from '@auth0/auth0-react'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { BiLike } from 'react-icons/bi'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { useMutation, useQuery } from '@apollo/client'
import {
  ADD_IDEA_COMMENT,
  DELETE_IDEA_COMMENT,
  GET_IDEA_COMMENTS,
} from '@/graphql/ideaCommentQuery'
import { GET_IDEAS, GET_IDEAS_BY_ORGID_FOR_ADMIN } from '@/graphql/ideaQuery'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { LIKE, UNLIKE_COMMENT } from '@/graphql/likeQuery'
import { formatDate } from '@/helpers/utils'
import { Textarea } from '@/components/ui/textarea'

type Comment = {
  id: string
  user: {
    email: string
    first_name: string
    last_name: string
    picture: string
  }
  comment_text: string
  comment_dt: string
  likes: {
    id: number
    liked_by: number
  }[]
  likes_aggregate: {
    aggregate: {
      count: number
    }
  }
  likedByCurrentUser: boolean
  root_comment_id: string
  flat_comments?: Comment[]
}

const CommentItem = ({
  comment,
  onLikeToggle,
  onReply,
  onDelete,
  user,
  isDeleted,
  isNew,
}: {
  comment: Comment
  onLikeToggle: (id: string, userHasLiked: boolean) => void
  onReply: (text: string, parentId: string, root_comment_id: string) => void
  onDelete: (id: string) => void
  user: User
  isDeleted: boolean
  isNew: any
}) => {
  const [replyText, setReplyText] = useState<string>('')
  const [showReplyInput, setShowReplyInput] = useState<boolean>(false)
  const { currentUser } = useSelector((state: RootState) => state.auth)

  const hasUserLiked = comment.likes.some((like) => {
    const userId = currentUser?.id
    return userId !== undefined && like.liked_by === userId
  })

  const handleReply = () => {
    if (!replyText.trim()) return
    onReply(replyText, comment.id, comment.root_comment_id ?? comment.id)
    setReplyText('')
    setShowReplyInput(false)
  }

  const handleEnterKeyOnReply = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return
    handleReply()
  }

  return (
    <div
      className={`flex flex-col gap-3 mt-4 border-b last:border-0 animation-all
    ${isDeleted ? 'animate-slide-out-left' : 'animate-slide-in-top'}
    ${isNew ? 'animate-slide-in-right' : 'animate-slide-in-bottom'}`}
    >
      <div className='flex gap-3'>
        {/* Avatar */}
        <div className='flex-shrink-0'>
          <Avatar className='shadow-none h-8 w-8'>
            {/* <AvatarImage src={comment?.user?.picture} /> */}
            <AvatarImage
              src={
                comment?.user?.picture != ''
                  ? comment?.user?.picture
                  : `/users/${comment?.user?.first_name?.toLocaleLowerCase()}.png`
              }
            />
            <AvatarFallback>
              {comment?.user?.first_name?.charAt(0)}
            </AvatarFallback>
          </Avatar>
        </div>
        {/* Comment Content */}
        <div className='flex-1'>
          <div className=' font-medium '>
            {comment.user.first_name} - {formatDate(comment.comment_dt)}
          </div>
          <div className=' mt-1 text-muted-foreground'>
            {comment.comment_text}
          </div>
          {/* Reply & Like */}
          <div className='mt-2 flex items-center  gap-2 text-slate-400'>
            <button
              className='hover:underline'
              onClick={() => setShowReplyInput((prev) => !prev)}
            >
              Reply
              {comment?.flat_comments?.length ? (
                <span> ({comment.flat_comments.length})</span>
              ) : (
                ''
              )}
              {/* {showReplyInput ? "Cancel" : "Reply"} */}
            </button>
            <span>•</span>
            <button
              className={`flex items-center gap-1 ${
                hasUserLiked ? 'text-primary' : 'text-slate-400'
              }`}
              onClick={() => onLikeToggle(comment.id, hasUserLiked)}
            >
              <span>{comment.likes_aggregate.aggregate.count}</span>
              <BiLike className='hover:text-primary'></BiLike>
            </button>
            {comment.user.email === user.email && ( // Show delete button if user is authenticated
              <button
                className='hover:text-red-600 '
                onClick={() => onDelete(comment.id)}
              >
                <RiDeleteBin6Line></RiDeleteBin6Line>
              </button>
            )}
          </div>
          {/* Reply Input */}

          {/* Reply Input */}
          <div
            className={`transition-all duration-500 ease-in-out overflow-hidden ${
              showReplyInput
                ? 'max-h-72 opacity-100 visible transform translate-y-0'
                : 'max-h-0 opacity-0 invisible transform translate-y-10'
            }`}
          >
            <Input
              type='text'
              placeholder='Write a reply...'
              value={replyText}
              onChange={(e) => setReplyText(e.target.value)}
              onKeyDown={handleEnterKeyOnReply}
              className='w-full border rounded-full px-3 py-2 focus:outline-none'
            />
            <Button
              onClick={handleReply}
              className='mt-2 px-4 py-2 rounded-full text-xs'
              variant={'outline'}
              size='xs'
            >
              Submit
            </Button>
            <button
              className='hover:underline text-destructive ml-4'
              onClick={() => setShowReplyInput((prev) => !prev)}
            >
              Cancel
            </button>
          </div>

          {/* Replies */}
          <div className='mt-3 ml-4'>
            {comment.flat_comments?.map((reply) => (
              <CommentItem
                key={reply.id}
                comment={reply}
                onLikeToggle={onLikeToggle}
                onReply={onReply}
                onDelete={onDelete}
                user={user}
                isDeleted={isDeleted}
                isNew={isNew}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const Comments = ({ ideaId }: { ideaId: string }) => {
  const { currentUser } = useSelector((state: RootState) => state.auth)
  const [newComment, setNewComment] = useState<string>('')
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const [commentToDelete, setCommentToDelete] = useState<string | null>(null)
  //const [isDeleted, setIsDeleted] = useState(false) // Track animation state
  const [deletedComments, setDeletedComments] = useState<string[]>([]) // Track deleted comments for animation
  const [newlyAddedCommentId, setNewlyAddedCommentId] = useState<string | null>(
    null
  )

  const { data } = useQuery(GET_IDEA_COMMENTS, {
    variables: { id: ideaId },
  })
  const comments: Comment[] = data?.suhrt_comments ?? []

  const [addIdeaComment] = useMutation(ADD_IDEA_COMMENT, {
    refetchQueries: [
      {
        query: GET_IDEA_COMMENTS,
        variables: { id: ideaId },
      },
      currentUser?.role_id === 2
        ? {
            query: GET_IDEAS_BY_ORGID_FOR_ADMIN,
            variables: { org_id: currentUser?.org_id },
          }
        : {
            query: GET_IDEAS,
            variables: {
              org_id: currentUser?.org_id,
              user_id: currentUser?.id,
            },
          },
    ],
  })

  const [likeComment] = useMutation(LIKE, {
    refetchQueries: [
      {
        query: GET_IDEA_COMMENTS,
        variables: { id: ideaId },
      },
    ],
  })

  const [unlikeComment] = useMutation(UNLIKE_COMMENT, {
    refetchQueries: [
      {
        query: GET_IDEA_COMMENTS,
        variables: { id: ideaId },
      },
    ],
  })

  // const handleAddIdeaComment = async (
  //   content: string,
  //   parentId: string | null = null
  //   // rootCommentId: string | null = null
  // ) => {
  //   await addIdeaComment({
  //     variables: {
  //       idea_id: ideaId,
  //       comment_by: currentUser?.id,
  //       comment_text: content,
  //       parent_comment_id: parentId,
  //       // root_comment_id: rootCommentId,
  //     },
  //   })
  // }
  const handleAddIdeaComment = async (
    content: string,
    parentId: string | null = null,
    rootCommentId: string | null = null
  ) => {
    try {
      const response = await addIdeaComment({
        variables: {
          idea_id: ideaId,
          comment_by: currentUser?.id,
          comment_text: content,
          parent_comment_id: parentId,
          root_comment_id: rootCommentId,
        },
      })

      // Assuming the response contains the newly added comment's ID

      const newCommentId =
        response?.data?.insert_suhrt_comments?.returning[0]?.id
      return newCommentId
    } catch (error) {
      console.error('Error adding comment:', error)
      throw error // Re-throw the error to be handled in handleAddComment
    }
  }

  const [deleteIdeaComment] = useMutation(DELETE_IDEA_COMMENT, {
    refetchQueries: [
      {
        query: GET_IDEA_COMMENTS,
        variables: { id: ideaId },
      },
      currentUser?.role_id === 2
        ? {
            query: GET_IDEAS_BY_ORGID_FOR_ADMIN,
            variables: { org_id: currentUser?.org_id },
          }
        : {
            query: GET_IDEAS,
            variables: {
              org_id: currentUser?.org_id,
              user_id: currentUser?.id,
            },
          },
    ],
  })

  const handleAddComment = async () => {
    if (!newComment.trim()) return

    try {
      const newCommentData = await handleAddIdeaComment(newComment)
      setNewlyAddedCommentId(newCommentData) // Set the newly added comment's ID for animation
      setNewComment('') // Clear the new comment input
    } catch (error) {
      console.error('Error adding comment:', error)
    }
  }

  const handleEnterKey = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Check if Enter is pressed without Shift
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault() // Prevent adding a new line
      handleAddComment()
    }
  }

  const handleLikeToggle = async (id: string, hasUserLiked: boolean) => {
    try {
      if (hasUserLiked) {
        await unlikeComment({
          variables: {
            comment_id: id,
            liked_by: currentUser?.id,
          },
        })
      } else {
        await likeComment({
          variables: {
            object: { comment_id: id, liked_by: currentUser?.id },
          },
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleReply = async (
    text: string,
    parentId: string,
    rootCommentId: string
  ) => {
    try {
      await handleAddIdeaComment(text, parentId, rootCommentId)
    } catch (error) {
      console.log(error)
    }
  }

  const handleDelete = async (id: string) => {
    setShowConfirmation(true)
    setCommentToDelete(id)
  }
  const confirmDelete = () => {
    setShowConfirmation(false)
    setDeletedComments((prev) => [...prev, commentToDelete!]) // Add to deleted comments array for animation
    //setTimeout(() => {
    handleCommentDelete() // Proceed with deletion after animation
    //}, 500) // Wait for the animation to finish (duration of `slide-in-left` is 0.5s)
  }
  const handleCommentDelete = async () => {
    if (!commentToDelete) return
    try {
      await deleteIdeaComment({
        variables: { id: commentToDelete },
      })
      setCommentToDelete(null)
      setShowConfirmation(false)
    } catch (e) {
      setCommentToDelete(null)
      setShowConfirmation(false)
    }
  }
  useEffect(() => {
    if (newlyAddedCommentId) {
      // Reset newly added comment after animation duration
      const timer = setTimeout(() => {
        setNewlyAddedCommentId(null)
      }, 500) // Match the duration of your animation (e.g., 500ms)
      return () => clearTimeout(timer)
    }
  }, [newlyAddedCommentId])
  return (
    <div className='w-full'>
      <div className='flex items-center flex-col sm:flex-row gap-3 md:gap-6 py-4 px-0 border-b m-0'>
        {/* <div className=''>
          <Avatar className='shadow-none'>
            <AvatarImage src={user?.picture} />
            <AvatarFallback>{user?.name?.charAt(0)}</AvatarFallback>
          </Avatar>
        </div> */}
        <Textarea
          placeholder='Enter comments'
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          onKeyDown={handleEnterKey}
          className='w-full border rounded-md px-3 py-1 focus:outline-none'
        />
        <Button
          onClick={handleAddComment}
          className='px-4 py-2 rounded-full'
          variant={'outline'}
        >
          Submit
        </Button>
      </div>
      <div className='max-h-[540px] overflow-y-auto'>
        {comments.map((comment) => (
          <CommentItem
            key={comment.id}
            comment={comment}
            onLikeToggle={handleLikeToggle}
            onReply={handleReply}
            onDelete={handleDelete}
            user={comment.user!}
            isDeleted={deletedComments.includes(comment.id)} // Check if comment is marked for deletion
            isNew={newlyAddedCommentId === comment.id} // Add condition to apply animation
          />
        ))}
      </div>
      {showConfirmation && (
        <Dialog open={showConfirmation} onOpenChange={setShowConfirmation}>
          <DialogContent className='md:w-[450px] w-[80%]'>
            <DialogHeader>
              <DialogTitle>Delete Confirmation</DialogTitle>
              <DialogDescription>
                Are you sure, you want to delete this comment ?
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button
                variant='outline'
                onClick={() => {
                  setShowConfirmation(false)
                  setCommentToDelete(null)
                }}
              >
                Cancel
              </Button>
              <Button
                variant='destructive'
                onClick={confirmDelete}
                className='md:mb-0 mb-2'
              >
                Delete
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}

export default Comments
