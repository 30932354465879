import { Card, CardContent } from '@/components/ui/card'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { useDialog } from '../global-dialog/DialogContext'
import { Idea } from '@/types/idea'
import { ScoresProps } from './views/SuhrtScoreParams'
import { updateAIIdeatorStep } from '@/store/idea/ideaSlice'
import { useDispatch, useSelector } from 'react-redux'
import IdeaStepper from './ideator/stepper/Stepper'
import { useEffect, useState } from 'react'
import IdeaSubmitForm from './forms/IdeaForm/IdeaSubmitForm'
import { TokenUI } from './ideator/TokenUI'
import { RootState } from '@/store/store'
import { GET_TOKEN_DETAILS_BY_ORG_ID } from '@/graphql/tokenQuery'
import { useQuery } from '@apollo/client'
import { TokenLimitOverlay } from './ideator/TokenLimitOverlay'
import { useNavigate } from 'react-router-dom'

interface TokenDetails {
  suhrt_tokens: Array<{
    total_tokens: number
    used_tokens: number
    remaining_tokens: number
  }>
}

const IdeaTabs = ({
  idea,
  ideaScores,
}: {
  idea?: Idea
  ideaScores?: ScoresProps
}) => {
  const { closeDialog } = useDialog()
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state: RootState) => state.auth)
  const [currentTab, setCurrentTab] = useState('new_idea')
  const [showOverlay, setShowOverlay] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [pendingTab, setPendingTab] = useState<string | null>(null)
  const [hasChanges, setHasChanges] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(updateAIIdeatorStep(''))
  }, [])

  const { data: tokenData, refetch: refetchTokens } = useQuery<TokenDetails>(
    GET_TOKEN_DETAILS_BY_ORG_ID,
    {
      variables: { org_id: currentUser?.org_id },
      fetchPolicy: 'network-only',
    }
  )

  const handleFormChange = (changed: boolean) => {
    setHasChanges(changed)
  }

  const tabChangeHandler = async (value: string) => {
    if (hasChanges) {
      setPendingTab(value)
      setShowConfirmDialog(true)
      return
    }

    await executeTabChange(value)
  }

  const executeTabChange = async (value: string) => {
    setCurrentTab(value)
    if (value !== 'ai_ideator') {
      dispatch(updateAIIdeatorStep(''))
      setShowOverlay(false)
    } else {
      const { data: freshTokenData } = await refetchTokens()
      const currentTokens =
        freshTokenData?.suhrt_tokens[0]?.remaining_tokens ?? 0
      setShowOverlay(currentTokens === 0)
    }
  }

  const handleConfirmTabChange = async () => {
    setShowConfirmDialog(false)
    if (pendingTab) {
      await executeTabChange(pendingTab)
      setHasChanges(false)
    }

    setPendingTab(null)
  }

  const handleCancelTabChange = () => {
    setShowConfirmDialog(false)
    setPendingTab(null)
  }

  const handleUpgradeClick = () => {
    navigate('/pricing')
    closeDialog()
  }

  useEffect(() => {
    const checkTokens = async () => {
      if (currentTab === 'ai_ideator') {
        const { data: freshTokenData } = await refetchTokens()
        const currentTokens =
          freshTokenData?.suhrt_tokens[0]?.remaining_tokens ?? 0
        setShowOverlay(currentTokens === 0)
      }
    }

    checkTokens()
  }, [currentTab, refetchTokens])

  const usedTokens = tokenData?.suhrt_tokens[0]?.used_tokens ?? 0
  const maxTokens = tokenData?.suhrt_tokens[0]?.total_tokens ?? 0

  return (
    <>
      <Tabs
        defaultValue='new_idea'
        className='relative'
        value={currentTab}
        onValueChange={tabChangeHandler}
      >
        <div className='flex flex-col sm:flex-row sm:items-center justify-between gap-4 w-full'>
          {!idea && (
            <>
              <TabsList className='grid w-[270px] grid-cols-2 text-center'>
                <TabsTrigger value='new_idea'>Add New Idea</TabsTrigger>
                <TabsTrigger value='ai_ideator'>AI Ideator</TabsTrigger>
              </TabsList>

              <TokenUI usedTokens={usedTokens} maxTokens={maxTokens} />
            </>
          )}
        </div>

        <TabsContent value='new_idea' className='relative index-0'>
          <Card className='border-0 shadow-none'>
            <CardContent className='p-0 relative'>
              <IdeaSubmitForm
                idea={idea}
                onClose={closeDialog}
                ideaScores={ideaScores}
                onFormChange={handleFormChange}
              />
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value='ai_ideator'>
          <Card>
            <CardContent className='p-2 md:p-4 md:pb-2 relative'>
              <IdeaStepper
                refetchTokens={refetchTokens}
                setShowOverlay={setShowOverlay}
                onStepChange={handleFormChange}
              />
              {showOverlay && (
                <TokenLimitOverlay
                  onUpgradeClick={handleUpgradeClick}
                  maxTokens={maxTokens}
                />
              )}
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>

      <AlertDialog open={showConfirmDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Unsaved Changes</AlertDialogTitle>
            <AlertDialogDescription>
              You have unsaved changes. If you switch tabs, your changes will be
              lost. Do you want to continue?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={handleCancelTabChange}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirmTabChange}>
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

export default IdeaTabs
