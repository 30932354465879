import { memo } from 'react'
import MenuBar from './MenuBar'

// function capitalizeFirstLetter(link: string) {
//   return link.charAt(0).toUpperCase() + link.slice(1)
// }

function Header() {
  // const location = useLocation()

  // const segments = location.pathname.split("/").filter(Boolean)

  // const breadcrumbItems = segments.map((segment, index) => ({
  //   label: capitalizeFirstLetter(segment),
  //   link: `/${segments.slice(0, index + 1).join("/")}`,
  // }))

  // breadcrumbItems.unshift({
  //   label: "Home",
  //   link: "/",
  // })

  return (
    <div className=' flex justify-center fixed top-0 left-0 w-full z-10 bg-background h-16 py-2 border-b border-border'>
      <div className='w-full max-w-screen-2xl flex items-center'>
        <MenuBar />
      </div>
    </div>
  )
}
export default memo(Header)
