import { gql } from '@apollo/client'

export const GET_IDEA_COMMENTS = gql`
  query GET_COMMENTS($id: Int!) {
    suhrt_comments(
      where: { idea_id: { _eq: $id }, parent_comment_id: { _is_null: true } }
      order_by: { comment_dt: desc }
    ) {
      id
      comment_text
      comment_dt
      user {
        email
        first_name
        last_name
        picture
      }
      likes {
        id
        liked_by
      }
      likes_aggregate {
        aggregate {
          count
        }
      }
      root_comment_id
      flat_comments {
        id
        comment_text
        comment_dt
        user {
          email
          first_name
          last_name
          picture
        }
        likes {
          id
          liked_by
        }
        likes_aggregate {
          aggregate {
            count
          }
        }
        root_comment_id
      }
    }
  }
`

export const ADD_IDEA_COMMENT = gql`
  mutation ADD_IDEA_COMMENT(
    $idea_id: Int!
    $comment_by: Int!
    $comment_text: String!
    $parent_comment_id: Int
    $root_comment_id: Int
  ) {
    insert_suhrt_comments(
      objects: {
        idea_id: $idea_id
        comment_by: $comment_by
        comment_text: $comment_text
        parent_comment_id: $parent_comment_id
        root_comment_id: $root_comment_id
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const DELETE_IDEA_COMMENT = gql`
  mutation DeleteIdeaComment($id: Int!) {
    delete_suhrt_comments(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`
