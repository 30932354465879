import React from 'react'
import { Table, ColumnDef } from '@tanstack/react-table'
import {
  Table as UITable,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { DataTableToolbar } from './DataTableToolbar'
import { DataTablePagination } from './DataTablePagination'

interface DataTableProps<TData> {
  table: Table<TData>
  columns: ColumnDef<TData>[]
  filterColumn?: string
  filterTitle?: string
  toolbarFilters?: React.ReactNode
  onRowClick?: (row: TData) => void
  renderCustomCell?: (cell: any) => React.ReactNode
  rowClick?: (rowData: TData) => void // Optional callback for row clicks
  showAddUser?: boolean
}

function flexRender<TProps extends object>(
  Comp: React.ReactNode | ((props: TProps) => React.ReactNode),
  props: TProps
): React.ReactNode {
  return typeof Comp === 'function' ? Comp(props) : Comp
}

export function DataTable<TData>({
  table,
  columns,
  filterColumn,
  filterTitle = 'Search',
  toolbarFilters,
  onRowClick,
  rowClick,
  showAddUser,
}: DataTableProps<TData>) {
  return (
    <div className='space-y-4'>
      <DataTableToolbar
        table={table}
        filterColumn={filterColumn}
        filterTitle={filterTitle}
        showAddUser={showAddUser}
      >
        {toolbarFilters}
      </DataTableToolbar>

      <div className='rounded-md border'>
        <UITable>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  className={onRowClick ? 'cursor-pointer' : ''}
                  onClick={() => {
                    if (rowClick) rowClick(row.original) // Trigger the rowClick callback
                    row.toggleSelected() // Keep row selection functionality
                  }}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {cell.column.id === 'actions' // Check if the column is "Action"
                        ? flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )
                        : !cell.getValue() // Apply the original logic for other columns
                        ? '-' // Display a hyphen if null or undefined
                        : flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className='h-24 text-center'
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </UITable>
      </div>
      <DataTablePagination table={table} />
    </div>
  )
}
