import { useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store/store'
import { GET_ORGANIZATION_BY_ID, UPDATE_ORG_BY_ID } from '@/graphql/orgQuery'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Textarea } from '@/components/ui/textarea'
import { useToast } from '@/components/ui/use-toast'
import { Separator } from '@/components/ui/separator'
import { hideLoader, showLoader } from '@/store/idea/loaderSlice'
import { setCurrentUser } from '@/store/user/userSlice'

export const OrgDetails = () => {
  const { toast } = useToast()
  const dispatch = useDispatch()
  const orgId = useSelector(
    (state: RootState) => state.auth.currentUser?.org_id
  )
  const userId = useSelector((state: RootState) => state.auth.currentUser?.id)

  const { currentUser } = useSelector((state: RootState) => state.auth)
  const {
    data,
    loading: loadingOrg,
    error: fetchError,
  } = useQuery(GET_ORGANIZATION_BY_ID, {
    variables: { org_id: orgId },
    skip: !orgId,
    onError: (error) => {
      toast({
        variant: 'destructive',
        description: `Error fetching organization details: ${error.message}`,
      })
    },
  })

  const [updateOrgById, { loading: updatingOrg }] = useMutation(
    UPDATE_ORG_BY_ID,
    {
      onCompleted: () => {
        // Update the current user in Redux with new org details
        const updatedUser: any = {
          ...currentUser,
          org_name: data.suhrt_organisation_by_pk.name ?? '',
          org_details: data.suhrt_organisation_by_pk.org_details ?? '',
        }
        dispatch(setCurrentUser(updatedUser))
        toast({
          variant: 'success',
          description: 'Organization details updated successfully.',
        })
        setHasChanges(false)
        dispatch(hideLoader())
      },
      onError: (error) => {
        toast({
          variant: 'destructive',
          description: `Error updating organization details: ${error.message}`,
        })
        dispatch(hideLoader())
      },
    }
  )

  const [orgDetails, setOrgDetails] = useState('')
  const [hasChanges, setHasChanges] = useState(false)
  const orgName = data?.suhrt_organisation_by_pk?.name
  const orgDetailsFromServer = data?.suhrt_organisation_by_pk?.org_details

  // Set initial value
  useEffect(() => {
    if (orgDetailsFromServer && !orgDetails) {
      setOrgDetails(orgDetailsFromServer)
    }
  }, [orgDetailsFromServer])

  const handleTextAreaChange = (e: { target: { value: any } }) => {
    const newValue = e.target.value
    setOrgDetails(newValue)
    setHasChanges(newValue !== orgDetailsFromServer)
  }

  const handleUpdateOrgDetails = async () => {
    if (!orgDetails.trim()) {
      return toast({
        variant: 'destructive',
        description: 'Organization details cannot be empty.',
      })
    }

    try {
      dispatch(showLoader())
      await updateOrgById({
        variables: {
          id: orgId,
          name: orgName,
          org_details: orgDetails,
          updated_by: userId,
        },
      })
    } catch (error) {
      dispatch(hideLoader())
    }
  }

  if (loadingOrg) {
    return <div>Loading organization details...</div>
  }

  if (fetchError) {
    return <div>Error loading organization details.</div>
  }

  return (
    <div className='space-y-6'>
      <header className='flex flex-col items-start'>
        <h3 className='text-lg font-medium'>My Innovation Space</h3>
        <p className='text-muted-foreground'>Manage your Innovation Space. </p>
      </header>
      <Separator />
      <div className='rounded-xl bg-card text-card-foreground col-span-3 border border-border p-6'>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className='grid w-full gap-6'>
            <div>
              <Label
                htmlFor='orgName'
                className='block text-sm font-medium text-left'
              >
                My Space’s Name
              </Label>
              <Input
                id='orgName'
                value={orgName || ''}
                disabled
                className='mt-1'
              />
            </div>
            <div>
              <Label
                htmlFor='orgDetails'
                className='block text-sm font-medium text-left'
              >
                My Interests
              </Label>
              <Textarea
                id='orgDetails'
                placeholder='Enter organization details'
                value={orgDetails}
                onChange={handleTextAreaChange}
                className='mt-1 min-h-32'
                rows={4}
              />
            </div>
          </div>
        </form>
        <div className='mt-6 flex justify-end'>
          <Button
            onClick={handleUpdateOrgDetails}
            disabled={updatingOrg || !orgDetails.trim() || !hasChanges}
          >
            {updatingOrg ? 'Updating...' : 'Update'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default OrgDetails
