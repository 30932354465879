import { gql } from '@apollo/client'

export const CREATE_ORGANIZATION = gql`
  mutation AddOrganizationWithScoreWeightsAndTokens(
    $org_name: String!
    $org_details: String!
    $created_by: Int!
    $relevance_weight: numeric!
    $originality_weight: numeric!
    $impact_weight: numeric!
    $reuse_weight: numeric!
    $feasibility_weight: numeric!
  ) {
    insert_suhrt_organisation_one(
      object: {
        name: $org_name
        created_by: $created_by
        org_details: $org_details
        org_score_weights: {
          data: {
            relevance_weight: $relevance_weight
            originality_weight: $originality_weight
            impact_weight: $impact_weight
            reuse_weight: $reuse_weight
            feasibility_weight: $feasibility_weight
          }
        }
        token: {
          # Adding tokens directly
          data: {
            total_tokens: 3 # Default value for total tokens
            used_tokens: 0 # Default value for used tokens
          }
        }
      }
    ) {
      id
      name
      org_details
      created_by
      org_score_weights {
        org_id
        relevance_weight
        originality_weight
        impact_weight
        reuse_weight
        feasibility_weight
      }
      token {
        # Fetching token details after insertion
        id
        total_tokens
        used_tokens
      }
    }
  }
`
export const UPDATE_ORG_BY_ID = gql`
  mutation UpdateOrgById(
    $id: Int!
    $name: String
    $org_details: String
    $updated_by: Int!
  ) {
    update_suhrt_organisation(
      where: { id: { _eq: $id } }
      _set: { name: $name, org_details: $org_details, updated_by: $updated_by }
    ) {
      affected_rows
      returning {
        id
        name
        org_details
        updated_by
        updated_dt
      }
    }
  }
`
export const GET_ORGANIZATION_BY_ID = gql`
  query GET_ORGANIZATION_BY_ID($org_id: Int!) {
    suhrt_organisation_by_pk(id: $org_id) {
      id
      name
      org_details
    }
  }
`

export const CREATE_CATEGORY = gql`
  mutation CREATE_CATEGORY(
    $category_name: String!
    $org_id: Int!
    $category_description: String!
  ) {
    insert_suhrt_category_one(
      object: {
        category_name: $category_name
        org_id: $org_id
        category_description: $category_description
      }
    ) {
      category_id
      category_name
      category_description
    }
  }
`

export const GET_CATEGORIES_BY_ORG_ID = gql`
  query GetCategoriesByOrgID($org_id: Int!) {
    suhrt_category(where: { org_id: { _eq: $org_id } }) {
      category_id
      category_name
      category_description
      org_id
    }
  }
`
export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory(
    $id: Int!
    $category_name: String!
    $category_description: String!
  ) {
    updateCategory(
      id: $id
      category_name: $category_name
      category_description: $category_description
    ) {
      category_id
      category_name
      category_description
    }
  }
`
export const UPDATE_CATEGORY_BY_ID = gql`
  mutation UpdateCategoryByID(
    $category_id: Int!
    $category_name: String!
    $category_description: String!
  ) {
    update_suhrt_category(
      where: { category_id: { _eq: $category_id } }
      _set: {
        category_name: $category_name
        category_description: $category_description
        updated_dt: "now()"
      }
    ) {
      returning {
        category_id
        category_name
        category_description
        updated_dt
      }
    }
  }
`
export const DELETE_CATEGORY_BY_ID = gql`
  mutation DeleteCategoryByID($category_id: Int!) {
    delete_suhrt_category_by_pk(category_id: $category_id) {
      category_id
      category_name
      category_description
    }
  }
`
export const GET_SCORE_WEIGHTS_BY_ORGID = gql`
  query GetScoreWeightsByOrgID($org_id: Int!) {
    suhrt_org_score_weights(where: { org_id: { _eq: $org_id } }) {
      weight_id
      org_id
      relevance_weight
      originality_weight
      impact_weight
      reuse_weight
      feasibility_weight
    }
  }
`
export const UPDATE_SCORE_WEIGHTS_BY_ORGID = gql`
  mutation UpdateScoreWeightsByOrgID(
    $org_id: Int!
    $relevance_weight: numeric!
    $originality_weight: numeric!
    $impact_weight: numeric!
    $reuse_weight: numeric!
    $feasibility_weight: numeric!
  ) {
    update_suhrt_org_score_weights(
      where: { org_id: { _eq: $org_id } }
      _set: {
        relevance_weight: $relevance_weight
        originality_weight: $originality_weight
        impact_weight: $impact_weight
        reuse_weight: $reuse_weight
        feasibility_weight: $feasibility_weight
      }
    ) {
      returning {
        weight_id
        org_id
        relevance_weight
        originality_weight
        impact_weight
        reuse_weight
        feasibility_weight
      }
    }
  }
`
export const CREATE_SAMPLE_CATEGORIES_AND_USERS = gql`
  mutation CreateSampleCategoriesAndUsers(
    $org_id: Int!
    $username1: String!
    $email1: String!
    $username2: String!
    $email2: String!
    $username3: String!
    $email3: String!
    $username4: String!
    $email4: String!
  ) {
    # Add sample categories
    insert_suhrt_category(
      objects: [
        {
          category_name: "Sustainability"
          org_id: $org_id
          category_description: "Focus on eco-friendly practices"
        }
        {
          category_name: "Process Enhancement"
          org_id: $org_id
          category_description: "Improve operational workflows"
        }
        {
          category_name: "Automation"
          org_id: $org_id
          category_description: "Streamline tasks with automation"
        }
        {
          category_name: "Continuous Improvement"
          org_id: $org_id
          category_description: "Drive consistent development"
        }
      ]
    ) {
      returning {
        category_id
        category_name
        category_description
      }
    }

    # Add sample users
    insert_suhrt_users(
      objects: [
        {
          org_id: $org_id
          username: $username1
          email: $email1
          first_name: "Vishal"
          last_name: "Mishra"
          role_id: 2
          invitation_status: "active"
        }
        {
          org_id: $org_id
          username: $username2
          email: $email2
          first_name: "Eric"
          last_name: "Tan"
          role_id: 3
          invitation_status: "active"
        }
        {
          org_id: $org_id
          username: $username3
          email: $email3
          first_name: "Chris"
          last_name: "Dower"
          role_id: 3
          invitation_status: "active"
        }
        {
          org_id: $org_id
          username: $username4
          email: $email4
          first_name: "Suzanne"
          last_name: "Gomes"
          role_id: 3
          invitation_status: "active"
        }
      ]
    ) {
      returning {
        id
        username
      }
    }
  }
`
export const CREATE_SAMPLE_IDEAS_AND_NOTES = gql`
  mutation CreateSampleIdeasAndNotes(
    $user_id_1: Int!
    $user_id_2: Int!
    $user_id_3: Int!
    $user_id_4: Int!
    $category_id_1: Int!
    $category_id_2: Int!
  ) {
    # Insert Ideas with detailed values and tags
    insert_suhrt_idea(
      objects: [
        {
          idea_by: $user_id_1 # Use the first user_id
          business_value_prop: "Leveraging renewable energy sources to reduce carbon footprint and ensure sustainability."
          description: "This idea focuses on utilizing renewable energy sources, such as solar and wind, to power operations, thereby reducing carbon emissions and contributing to a sustainable future."
          idea_title: "Renewable Energy for Sustainable Growth"
          tags: [
            "renewable"
            "energy efficiency"
            "sustainability"
            "green technology"
          ] # Added tags
          idea_categories: { data: [{ category_id: $category_id_1 }] } # Use the first category_id
          idea_collaborators: {
            data: [{ user_id: $user_id_2 }, { user_id: $user_id_3 }]
          } # Collaborators
          idea_notes: {
            data: [
              {
                note: "Initial draft submitted for review."
                user_id: $user_id_1
                action: "Drafted"
              }
              {
                note: "Reviewed and ready for approval."
                user_id: $user_id_2
                action: "Reviewed"
              }
            ]
          }
        }
        {
          idea_by: $user_id_2 # Use the second user_id
          status: 2
          business_value_prop: "Automating workflows to eliminate inefficiencies and reduce costs in daily processes."
          description: "This idea proposes a system to automate routine manual workflows, which will significantly reduce time spent on repetitive tasks and increase overall business efficiency."
          idea_title: "Automation of Business Workflows"
          tags: [
            "automation"
            "workflow efficiency"
            "cost reduction"
            "digital transformation"
          ] # Added tags
          idea_categories: { data: [{ category_id: $category_id_2 }] } # Use the second category_id
          idea_collaborators: {
            data: [{ user_id: $user_id_1 }, { user_id: $user_id_4 }]
          } # Collaborators
          idea_notes: {
            data: [
              {
                note: "Conceptualized and shared with stakeholders for input."
                user_id: $user_id_2
                action: "Drafted"
              }
              {
                note: "Approved for further development."
                user_id: $user_id_4
                action: "Approved"
              }
            ]
          }
        }
      ]
    ) {
      returning {
        id
        idea_title
      }
    }
  }
`
export const CREATE_SAMPLE_COMMENTS = gql`
  mutation CreateSampleComments(
    $user_id_1: Int!
    $user_id_2: Int!
    $user_id_3: Int!
    $idea_id_1: Int!
    $idea_id_2: Int!
  ) {
    # Insert Comments
    insert_suhrt_comments(
      objects: [
        {
          comment_text: "This is an excellent proposal! Renewable energy solutions like this are the future."
          comment_by: $user_id_2 # User ID 2 commenting on Idea 1
          idea_id: $idea_id_1 # Idea ID 1
          comment_dt: "now()"
          parent_comment_id: null
        }
        {
          comment_text: "I agree, but more details on implementation will be helpful."
          comment_by: $user_id_3 # User ID 3 commenting on Idea 1
          idea_id: $idea_id_1 # Idea ID 1
          comment_dt: "now()"
          parent_comment_id: null
        }
        {
          comment_text: "The idea is great. How do you plan to integrate this into existing workflows?"
          comment_by: $user_id_1 # User ID 1 commenting on Idea 2
          idea_id: $idea_id_2 # Idea ID 2
          comment_dt: "now()"
          parent_comment_id: null
        }
        {
          comment_text: "Looking forward to seeing the automation in action!"
          comment_by: $user_id_2 # User ID 2 commenting on Idea 2
          idea_id: $idea_id_2 # Idea ID 2
          comment_dt: "now()"
          parent_comment_id: null
        }
      ]
    ) {
      affected_rows
    }
  }
`



