import { useEffect, useState } from 'react'
import logo from '../../assets/logo2.png'
import logoWhite from '../../assets/logo_white.png'
import { NavLink, useNavigate } from 'react-router-dom'
import Profile from '../login/Profile/Profile'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '../ui/button'
import { hideLoader, showLoader } from '@/store/idea/loaderSlice'
import { setUsers } from '@/store/user/userSlice'
import { useQuery } from '@apollo/client'
import { GET_USERS } from '@/graphql/userQuery'
import { useDispatch, useSelector } from 'react-redux'
import { MobileNav } from './MobileNav'
import { LucideMoon, LucideSun } from 'lucide-react'
import { NotificationPopover } from '../notification/NotificationPopover'
import { RootState } from '@/store/store'

type Link = {
  to: string
  text: string
}

export default function MenuBar() {
  const dispatch = useDispatch()
  const { user, loginWithRedirect, isAuthenticated } = useAuth0()
  const { loading, data } = useQuery(GET_USERS)
  const { currentUser } = useSelector((state: RootState) => state.auth) // Get currentUser from Redux
  const users = data ? data.suhrt_users : []
  const navigate = useNavigate()
  const links: Link[] = [
    { to: '/', text: 'Home' },
    // Only show these if authenticated
    ...(isAuthenticated && currentUser?.org_id
      ? [
          { to: '/ideas', text: 'Ideas' },
          { to: '/dashboard', text: 'Dashboard' },
        ]
      : []),
    { to: '/pricing', text: 'Pricing' },
    { to: '/contact', text: 'Contact' },
    { to: '/about', text: 'About' },
    { to: '/blog', text: 'Blogs' },
  ]

  useEffect(() => {
    if (loading) {
      dispatch(showLoader())
    } else {
      dispatch(hideLoader())
      if (users) dispatch(setUsers(users))
    }
  }, [loading, dispatch])
  // Theme state management
  const [theme, setTheme] = useState<'light' | 'dark'>(() => {
    // Check initial theme
    const savedTheme = localStorage.getItem('theme')
    if (savedTheme) return savedTheme as 'light' | 'dark'

    return window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light'
  })

  // Effect to update theme
  useEffect(() => {
    const root = document.documentElement

    // Remove existing theme classes
    root.classList.remove('light', 'dark')

    // Add current theme class
    root.classList.add(theme)

    // Save theme preference to local storage
    localStorage.setItem('theme', theme)
  }, [theme])

  // Theme toggle handler
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'))
  }
  
  return (
    <div className='w-full'>
      <nav className='md:px-8 px-4'>
        <div className=''>
          <div className='grid grid-cols-[auto_1fr_auto] items-center justify-center'>
            <div className='flex md:hidden'>
              <MobileNav links={links} />
            </div>
            <div
              className='flex items-center justify-center cursor-pointer'
              onClick={() => navigate('/')}
            >
              <div className='flex-shrink-0 md:h-8 h-6 w-full'>
                {theme === 'light' ? (
                  <img className='h-6 md:h-8' src={logo} alt='Logo' />
                ) : (
                  <img className='h-6 md:h-8' src={logoWhite} alt='Logo' />
                )}
              </div>
            </div>
            <div className='hidden md:block'>
              <div className='flex items-baseline space-x-4 items-center justify-center text-base'>
                {links.map((link) => (
                  <NavLink
                    key={link.to}
                    className={({ isActive }) =>
                      isActive
                        ? 'text-foreground focus:bg-primary-50 text-primary px-3 py-2 rounded-md font-semibold'
                        : 'text-foreground focus:bg-primary-50 hover:text-primary px-3 py-2 rounded-md font-medium'
                    }
                    to={link.to}
                  >
                    {link.text}
                  </NavLink>
                ))}
              </div>
            </div>
            <div className='grid gap-4 items-center grid-cols-[auto_auto_auto] items-center justify-center'>
              {/* Theme Toggle */}
              <button
                onClick={toggleTheme}
                className='inline-flex border border-border hover:border-primary hover:text-white items-center justify-center gap-2 whitespace-nowrap rounded-full  font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 hover:bg-primary py-2 group/toggle h-8 w-8 px-0'
                aria-label={`Switch to ${
                  theme === 'light' ? 'dark' : 'light'
                } mode`}
              >
                {theme === 'light' ? (
                  <LucideMoon className='h-6 w-6 transition-all' />
                ) : (
                  <LucideSun className='h-6 w-6 transition-all' />
                )}
              </button>
              <div className=''>
                <NotificationPopover></NotificationPopover>
              </div>

              {isAuthenticated || currentUser?.id ? (
                <Profile user={user}></Profile>
              ) : (
                <Button
                  onClick={() => loginWithRedirect()}
                  className=''
                  size='sm'
                >
                  Log In
                </Button>
              )}
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}
