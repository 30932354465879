import { Label } from '@/components/ui/label'
import IconWrapper from '@/components/utility/IconWrapper'
import { Idea } from '@/types/idea'

import { BiLike } from 'react-icons/bi'
import CommentsPopup from './CommentsPopup'
import { useMutation, useQuery } from '@apollo/client'
import { GET_IDEAS, GET_IDEAS_BY_ORGID_FOR_ADMIN } from '@/graphql/ideaQuery'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import {
  GET_LIKE_BY_USER_FOR_IDEA,
  LIKE,
  UNLIKE_IDEA,
} from '@/graphql/likeQuery'
import { formatDate } from '@/helpers/utils'

export function TooltipContent(idea: Idea) {
  const { currentUser } = useSelector((state: RootState) => state.auth)
  const userId = currentUser?.id

  const { data, refetch } = useQuery(GET_LIKE_BY_USER_FOR_IDEA, {
    variables: { ideaId: idea.id, userId },
  })

  const userHasLiked = data?.suhrt_likes.length > 0

  const [likeIdea] = useMutation(LIKE, {
    refetchQueries: [
      currentUser?.role_id === 2
      ? {
        query: GET_IDEAS_BY_ORGID_FOR_ADMIN,
        variables: { org_id: currentUser?.org_id },
      }
      : {
        query: GET_IDEAS,
        variables: { org_id: currentUser?.org_id, user_id: currentUser?.id },
      },
    ],
  })

  const [unlikeIdea] = useMutation(UNLIKE_IDEA, {
    refetchQueries: [
      currentUser?.role_id === 2
      ? {
        query: GET_IDEAS_BY_ORGID_FOR_ADMIN,
        variables: { org_id: currentUser?.org_id },
      }
      : {
        query: GET_IDEAS,
        variables: { org_id: currentUser?.org_id, user_id: currentUser?.id },
      },
    ],
  })

  const handleIdeaLikeToggle = async () => {
    if (!currentUser) return
    try {
      if (userHasLiked) {
        await unlikeIdea({
          variables: {
            idea_id: idea.id,
            liked_by: currentUser.id,
          },
        })
      } else {
        await likeIdea({
          variables: {
            object: { idea_id: idea.id, liked_by: currentUser.id },
          },
        })
      }
      await refetch()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='grid gap-4'>
      <div className='grid gap-2'>
        <div className='grid grid-cols-[65px_minmax(0,_1fr)]  items-start gap-4'>
          <Label className='text-xs font-medium '>Title</Label>
          <Label className='text-xs font-normal text-muted-foreground'>
            {idea.idea_title}
          </Label>
        </div>
        <div className='grid grid-cols-[65px_minmax(0,_1fr)] items-start gap-4'>
          <Label className='text-xs font-medium '>Created At</Label>
          <Label className='text-xs font-normal text-muted-foreground'>
            {idea.user?.first_name} - {formatDate(idea.idea_dt)}
          </Label>
        </div>
        <div className='grid grid-cols-[65px_minmax(0,_1fr)]  items-center gap-4 '>
          <Label className='text-xs font-bold flex items-center justify-start '>
            {idea.likes?.aggregate?.count}
            <IconWrapper
              className={` ml-2 text-base h-6 w-6 cursor-pointer ${
                userHasLiked
                  ? 'text-primary'
                  : 'hover:text-primary dark:text-muted-foreground'
              }`}
            >
              <BiLike
                className=' text-[1rem] transition-all '
                onClick={handleIdeaLikeToggle}
              />
            </IconWrapper>
          </Label>
          <Label className='text-xs font-bold flex items-center justify-start '>
            {idea.comments?.aggregate?.count}
            {/* <IconWrapper className="shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px]  ml-2 hover:text-primary text-base h-6 w-6 cursor-pointer" onClick={addComments()}>
              <FaRegComments className=" text-[1rem] transition-all" />
            </IconWrapper> */}
            <CommentsPopup ideaId={idea.id} />
          </Label>
        </div>
      </div>
    </div>
  )
}
