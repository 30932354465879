import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import {
  NOTIFICATION_COUNT_QUERY,
  NOTIFICATION_QUERY,
} from '@/graphql/notificationQuery'
import { RootState } from '@/store/store'
import { UTILITY } from '@/util/utility'
import { useLazyQuery, useQuery } from '@apollo/client'
import { LucideBell } from 'lucide-react'
import { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Skeleton } from '../ui/skeleton'

interface Notification {
  idea_id: number
  idea_title: string
  notification_text: string
  creator: string
  date: string
  time: string
}

export function NotificationPopover() {
  const [count, setCount] = useState(0)
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const { currentUser } = useSelector((state: RootState) => state.auth)
  const [getNotifications, { loading }] = useLazyQuery(NOTIFICATION_QUERY)

  // Fetch notification count
  useQuery(NOTIFICATION_COUNT_QUERY, {
    variables: { user_id: currentUser?.id },
    onCompleted: (data) => {
      setCount(data.suhrt_notifications_by_user_aggregate.aggregate.count)
    },
  })

  // Fetch notifications on click
  const handleClick = useCallback(async () => {
    if (loading) return // Prevent clicking while loading
    setIsOpen(true)
    const { data } = await getNotifications({
      variables: { user_id: currentUser?.id },
    })
    setNotifications(data.suhrt_notifications_by_user)
  }, [getNotifications, currentUser?.id, loading])

  // Bold the username in the notification text
  const boldUserName = (text: string) => {
    const regex = /submitted by (.*?) is ready/
    const match = text.match(regex)

    if (match && match[1]) {
      const userName = match[1]
      const [before, after] = text.split(userName)
      return (
        <>
          {before}
          <strong className='font-semibold text-foreground'>{userName}</strong>
          {after}
        </>
      )
    }
    return text
  }

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <div
          className='relative h-8 w-8 flex items-center justify-center hover:text-white border hover:border-primary border-border cursor-pointer rounded-full hover:bg-primary transition-all'
          onClick={handleClick}
        >
          <LucideBell className='h-5 w-5 hover:text-white' />
          {/* Notification count */}
          {count > 0 && (
            <span
              className={`absolute -top-1 -right-1 flex items-center justify-center rounded-full bg-red-500 text-white font-bold ${
                count > 9 ? 'h-6 w-6 text-xs' : 'h-5 w-5 text-[10px]'
              }`}
            >
              {count > 9 ? '10+' : count}
            </span>
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent
        className='md:w-[450px] p-0 dark:border border-0 rounded-md bg-background shadow-[0_4px_25px_12px_rgba(0,0,0,0.1)]'
        align='end'
      >
        {/* Header */}
        <div className='border-b border-border p-4'>
          <h4 className='font-semibold text-lg'>Notifications</h4>
        </div>

        {/* Notifications List */}
        <div className='max-h-96 min-h-96 overflow-y-auto'>
          {loading ? (
            // Loading skeleton
            Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                className='p-4 border-b border-border animate-pulse'
              >
                <Skeleton className='h-4 w-3/4 mb-2' />
                <Skeleton className='h-3 w-1/2' />
              </div>
            ))
          ) : notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <Link
                to={`/ideas/${UTILITY.encryptedId(notification.idea_id)}`}
                key={notification.idea_id}
                onClick={() => setIsOpen(false)}
              >
                <div
                  className={`flex flex-col gap-1 p-4 cursor-pointer hover:bg-muted/50 transition-transform opacity-0 animate-slide-in-right ${
                    index !== notifications.length - 1
                      ? 'border-b border-border' // Add border for all except last item
                      : ''
                  }`}
                  style={{
                    animationDelay: `${index * 0.3}s`,
                  }}
                >
                  <p className='font-medium text-sm'>
                    {notification.idea_title}
                  </p>
                  <div className='flex justify-between text-xs text-muted-foreground'>
                    <span>
                      {notification.notification_text
                        ? boldUserName(notification.notification_text)
                        : 'No notification text'}
                    </span>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <div className='p-4 text-sm text-center text-muted-foreground'>
              No notifications available.
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  )
}
