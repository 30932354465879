'use client'

import { motion } from 'framer-motion'
import { Coins } from 'lucide-react'
import { Progress } from '@/components/ui/progress'
import { Separator } from '@/components/ui/separator'

interface TokenUIProps {
  usedTokens: number
  maxTokens: number
}

export function TokenUI({ usedTokens, maxTokens }: TokenUIProps) {
  const percentage = (usedTokens / maxTokens) * 100
  const remainingTokens = maxTokens - usedTokens

  return (
    <motion.div
      className='flex items-center gap-6 px-4 h-[40px] rounded-full bg-background border shadow-sm'
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className='flex items-center gap-2'>
        <Coins className='w-6 h-6 text-yellow-600' />
        <span className='text-sm font-medium'>Tokens Used</span>
      </div>

      <Separator orientation='vertical' className='h-6' />

      <div className='flex items-center gap-6'>
        <div className='flex items-center gap-1.5'>
          <span className='text-lg font-semibold tabular-nums'>
            {usedTokens}
          </span>
          <span className='text-muted-foreground'>/</span>
          <span className='text-muted-foreground'>{maxTokens}</span>
        </div>

        <div className='flex flex-col gap-1 min-w-[120px]'>
          <Progress
            value={percentage}
            className='h-2 bg-muted'
            indicatorClassName='bg-yellow-600'
          />
          <span className='text-xs text-muted-foreground text-right'>
            {remainingTokens} tokens remaining
          </span>
        </div>
      </div>
    </motion.div>
  )
}
