'use client'

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { motion, AnimatePresence } from 'framer-motion'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  Cell,
  PieChart,
  Pie,
  Cell as PieCell,
} from 'recharts'
import { Lightbulb } from 'lucide-react'
import WordCloudChart from './WordCloud'
import { selectIsLoading } from '@/store/idea/loaderSlice'

// Colors for the charts
const COLORS = ['#38bdff', '#ffbf00', '#65a30d', '#b91c1c', '#8b5cf6']

interface DashboardProps {
  data: {
    total_ideas: { aggregate: { count: number } }
    draft_ideas: { aggregate: { count: number } }
    inreview_ideas: { aggregate: { count: number } }
    approved_ideas: { aggregate: { count: number } }
    deleted_ideas: { aggregate: { count: number } }
    tag_cloud: Array<{ tag: string; count: number }>
    idea_across_categories: Array<{
      category_name: string
      ideas_count: number
    }>
    suhrt_idea_status_time_series: Array<{
      month: string
      year: number
      draft: number
      in_review: number
      approved: number
      deleted: number
    }>
  }
}

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 },
}

const staggerChildren = {
  animate: {
    transition: {
      staggerChildren: 0.1,
    },
  },
}

const DashboardCharts = ({ data }: DashboardProps) => {
  const isLoading = useSelector(selectIsLoading)

  // const tagCloud = useMemo(() => {
  //   if (!data?.tag_cloud) return []

  //   const topTags = [...data.tag_cloud]
  //     .sort((a, b) => b.count - a.count)
  //     .slice(0, 25)

  //   return topTags.map(({ tag, count }) => ({
  //     name: tag,
  //     value: count,
  //   }))
  // }, [data?.tag_cloud])
  const tagCloud = useMemo(() => {
    if (!data?.tag_cloud) return []

    // Create a map to combine counts of case-insensitive duplicates
    const tagMap = data.tag_cloud.reduce((acc, { tag, count }) => {
      // Convert tag to lowercase for case-insensitive comparison
      const normalizedTag = tag.toLowerCase()

      if (acc.has(normalizedTag)) {
        // If tag already exists, update the count and keep the version with better capitalization
        const existing = acc.get(normalizedTag)
        acc.set(normalizedTag, {
          // Prefer the version that starts with a capital letter if available
          tag: /^[A-Z]/.test(tag) ? tag : existing.tag,
          count: existing.count + count,
        })
      } else {
        // If tag doesn't exist, add it to the map
        acc.set(normalizedTag, { tag, count })
      }

      return acc
    }, new Map())

    // Convert map back to array and sort by count
    const topTags = [...tagMap.values()]
      .sort((a, b) => b.count - a.count)
      .slice(0, 25)

    return topTags.map(({ tag, count }) => ({
      name: tag,
      value: count,
    }))
  }, [data?.tag_cloud])
  const statusCards = useMemo(
    () => [
      {
        title: 'Total Ideas',
        value: data.total_ideas.aggregate.count,
        bg: 'bg-blue-100',
        color: 'text-blue-500',
      },
      {
        title: 'In Draft',
        value: data.draft_ideas.aggregate.count,
        bg: 'bg-primary/20 dark:bg-sky-300',
        color: 'text-primary',
      },
      {
        title: 'In Review',
        value: data.inreview_ideas.aggregate.count,
        bg: 'bg-yellow-100',
        color: 'text-yellow-500',
      },
      {
        title: 'Approved',
        value: data.approved_ideas.aggregate.count,
        bg: 'bg-lime-100',
        color: 'text-lime-500',
      },
      {
        title: 'Deleted',
        value: data.deleted_ideas.aggregate.count,
        bg: 'bg-red-100',
        color: 'text-red-500',
      },
    ],
    [data]
  )

  const barChartData = useMemo(
    () =>
      data.idea_across_categories.map((category) => ({
        name: category.category_name,
        count: category.ideas_count,
      })),
    [data.idea_across_categories]
  )

  const pieChartData = useMemo(
    () => barChartData.map(({ name, count }) => ({ name, value: count })),
    [barChartData]
  )

  const statusDistributionData = useMemo(
    () => [
      { name: 'Draft', value: data.draft_ideas.aggregate.count },
      { name: 'In Review', value: data.inreview_ideas.aggregate.count },
      { name: 'Approved', value: data.approved_ideas.aggregate.count },
      { name: 'Deleted', value: data.deleted_ideas.aggregate.count },
    ],
    [data]
  )

  const lineChartData = useMemo(
    () =>
      data.suhrt_idea_status_time_series.map((item) => ({
        month: `${item.month} ${item.year}`,
        draft: item.draft,
        inReview: item.in_review,
        approved: item.approved,
        deleted: item.deleted,
      })),
    [data.suhrt_idea_status_time_series]
  )

  return (
    <AnimatePresence mode='wait'>
      {!isLoading && (
        <motion.div
          key='dashboard'
          className=' mx-auto py-4'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <motion.div
            className='space-y-4'
            variants={staggerChildren}
            initial='initial'
            animate='animate'
          >
            {/* Status Cards */}
            <motion.div variants={fadeInUp}>
              <motion.div
                className='grid gap-4 md:grid-cols-2 lg:grid-cols-5'
                variants={staggerChildren}
              >
                {statusCards.map(({ title, value, bg, color }, index) => (
                  <motion.div
                    key={title}
                    variants={fadeInUp}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <Card className={`border-0 ${bg}`}>
                      <CardHeader className='flex flex-row items-center justify-between space-y-0 pb-2'>
                        <CardTitle className='text-[#09090b] font-medium'>
                          {title}
                        </CardTitle>
                      </CardHeader>
                      <CardContent className='flex items-center justify-between'>
                        <div className='text-2xl font-bold text-[#09090b]'>
                          {value}
                        </div>
                        <Lightbulb className={`h-10 w-10 ${color}`} />
                      </CardContent>
                    </Card>
                  </motion.div>
                ))}
              </motion.div>
            </motion.div>

            {/* Charts Section */}
            <motion.div
              className='grid gap-4 md:grid-cols-2 lg:grid-cols-3'
              variants={staggerChildren}
            >
              {/* Tag Cloud */}
              <motion.div
                className='lg:col-span-2 md:col-span-2 dark:border dark:border-border'
                variants={fadeInUp}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.6 }}
              >
                <ChartCard title='Idea Spread'>
                  <div className='h-[450px]'>
                    <WordCloudChart data={tagCloud} />
                  </div>
                </ChartCard>
              </motion.div>

              {/* Pie Chart */}
              <motion.div
                className='lg:col-span-1 md:col-span-1 dark:border dark:border-border'
                variants={fadeInUp}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.8 }}
              >
                <ChartCard title='Ideas by Category'>
                  <ResponsiveContainer width='100%' height={450}>
                    <PieChart>
                      <Pie
                        data={pieChartData}
                        dataKey='value'
                        nameKey='name'
                        cx='50%'
                        cy='50%'
                        outerRadius={100}
                        label
                      >
                        {pieChartData.map((_, index) => (
                          <PieCell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </ChartCard>
              </motion.div>

              {/* Line Chart - Ideas Trend */}
              <motion.div
                className='lg:col-span-2 md:col-span-2 dark:border dark:border-border'
                variants={fadeInUp}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 1 }}
              >
                <ChartCard title='Ideas Trend'>
                  <ResponsiveContainer width='100%' height={300}>
                    <LineChart data={lineChartData}>
                      <CartesianGrid strokeDasharray='3 3' />
                      <XAxis dataKey='month' tick={{ fontSize: 12 }} />
                      <YAxis tick={{ fontSize: 12 }} />
                      <Tooltip />
                      <Legend />
                      <Line
                        type='monotone'
                        dataKey='draft'
                        stroke={COLORS[0]}
                        strokeWidth={2}
                        name='Draft'
                      />
                      <Line
                        type='monotone'
                        dataKey='inReview'
                        stroke={COLORS[1]}
                        strokeWidth={2}
                        name='In Review'
                      />
                      <Line
                        type='monotone'
                        dataKey='approved'
                        stroke={COLORS[2]}
                        strokeWidth={2}
                        name='Approved'
                      />
                      <Line
                        type='monotone'
                        dataKey='deleted'
                        stroke={COLORS[3]}
                        strokeWidth={2}
                        name='Deleted'
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </ChartCard>
              </motion.div>

              {/* Bar Chart - Ideas Status Distribution */}
              <motion.div
                className='lg:col-span-1 md:col-span-1 dark:border dark:border-border'
                variants={fadeInUp}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 1.2 }}
              >
                <ChartCard title='Ideas by Status'>
                  <ResponsiveContainer width='100%' height={300}>
                    <BarChart data={statusDistributionData}>
                      <CartesianGrid strokeDasharray='3 3' />
                      <XAxis dataKey='name' />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey='value' fill='#8884d8'>
                        {statusDistributionData.map((_, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </ChartCard>
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const ChartCard: React.FC<{ title: string; children: React.ReactNode }> =
  React.memo(({ title, children }) => (
    <Card className='border-0'>
      <CardHeader>
        <CardTitle className='mb-0'>{title}</CardTitle>
      </CardHeader>
      <CardContent>{children}</CardContent>
    </Card>
  ))

export default DashboardCharts
