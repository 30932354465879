export enum ACTION {
    EDIT = 'Edit',
    SUBMIT = 'Submit',
    APPROVE = 'Approve',
    SEND_BACK = 'Send Back',
    NONE = ''
}

export enum LABEL {
    NONE = '',
    DRAFT = 'Draft',
    IN_REVIEW = 'In Review',
    APPROVED = 'Approved',
    DELETED = 'Deleted',
    REJECTED = 'Rejected'
}

export interface STATUS {
    value: number,
    label: LABEL,
    action: ACTION[]
}
export const IDEA_STATUS: STATUS[] = [
    {
        value: 0,
        label: LABEL.NONE,
        action: []
    },
    {
        value: 1,
        label: LABEL.DRAFT,
        action: [
          ACTION.EDIT,
          ACTION.SUBMIT
        ]
    },
    {
        value: 2,
        label: LABEL.IN_REVIEW,
        action: [
            ACTION.APPROVE,
            ACTION.SEND_BACK
        ]
    },
    {
        value: 3,
        label: LABEL.APPROVED,
        action: []
    },
    {
        value: 4,
        label: LABEL.DELETED,
        action:  []
    },
    {
        value: 5,
        label: LABEL.REJECTED,
        action: [
            ACTION.EDIT,
            ACTION.SUBMIT
        ]
    }   
]