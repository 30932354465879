import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { SelectRole } from './SelectRole'
import { useForm, Controller } from 'react-hook-form'
import { useEffect, useCallback } from 'react'
import { RootState } from '@/store/store'
import { useDispatch, useSelector } from 'react-redux'
import { hideLoader, showLoader } from '@/store/idea/loaderSlice'
import {
  CREATE_USER,
  GET_ALL_USERS_BY_ORGID,
  UPDATE_USER_INFO,
} from '@/graphql/userQuery'
import { useMutation } from '@apollo/client'
import { toast } from '@/components/ui/use-toast'
import { useDialog } from '@/components/global-dialog/DialogContext'
import { setCurrentUser } from '@/store/user/userSlice'

export type FormValues = {
  firstname: string
  lastname: string
  email: string
  role: string
}

interface AddUserProps {
  initialData?: FormValues & { id?: number }
  editableFields?: {
    canEditName: boolean
    canEditEmail: boolean
    canEditRole: boolean
  }
  isOwnProfile?: boolean
}

export function AddUser({
  initialData,
  editableFields = {
    canEditName: true,
    canEditEmail: true,
    canEditRole: true,
  },
  isOwnProfile = false,
}: AddUserProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch, // Added watch here
  } = useForm<FormValues>({
    defaultValues: {
      firstname: initialData?.firstname || '',
      lastname: initialData?.lastname || '',
      email: initialData?.email || '',
      role: initialData?.role || '',
    },
  })

  const { currentUser } = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch()
  const { closeDialog } = useDialog()
  const [createUser] = useMutation(CREATE_USER)
  //const [updateInvitation] = useMutation(UPDATE_USER_INVITATION)
  const [updateUserInfo] = useMutation(UPDATE_USER_INFO)

  const initializeForm = useCallback(() => {
    if (initialData) {
      reset(initialData)
    }
  }, [initialData, reset])

  useEffect(() => {
    initializeForm()
  }, [initializeForm])

  const handleFormSubmit = async (data: FormValues) => {
    dispatch(showLoader())
    try {
      if (initialData?.id) {
        // For role 3 users editing their own profile, only update name fields
        if (currentUser?.role_id === 3 && isOwnProfile) {
          await handleUpdate(
            {
              ...initialData,
              firstname: data.firstname,
              lastname: data.lastname,
            },
            initialData.id
          )
        } else {
          await handleUpdate(data, initialData.id)
        }
      } else {
        await handleCreate(data)
      }
      closeDialog()
      toast({
        variant: 'success',
        description: initialData
          ? 'User updated successfully.'
          : 'Invitation sent successfully.',
        duration: 3000,
      })
    } catch (error) {
      console.error('Operation failed:', error)
    } finally {
      dispatch(hideLoader())
    }
  }

  const handleCreate = async (data: FormValues) => {
    try {
      const roleMapping = { superadmin: 1, admin: 2, user: 3 }
      const role_id = roleMapping[data.role as keyof typeof roleMapping] || 3

      await createUser({
        variables: {
          object: {
            email: data.email,
            username: data.email,
            first_name: data.firstname,
            last_name: data.lastname,
            org_id: currentUser?.org_id,
            role_id,
            picture: '',
            isActive: false,
            invitation_status: 'sent',
            invitation_sent_time: 'now()',
            invitation_expiry: new Date(
              Date.now() + 7 * 24 * 60 * 60 * 1000
            ).toISOString(),
          },
        },
        refetchQueries: [
          {
            query: GET_ALL_USERS_BY_ORGID,
            variables: { org_id: currentUser?.org_id },
          },
        ],
      })

      //const newUserId = response.data?.insert_suhrt_users_one?.id
      // if (newUserId) {
      //   await updateInvitation({
      //     variables: {
      //       user_id: newUserId,
      //       expiry_time: new Date(
      //         Date.now() + 7 * 24 * 60 * 60 * 1000
      //       ).toISOString(),
      //     },
      //     refetchQueries: [
      //       {
      //         query: GET_ALL_USERS_BY_ORGID,
      //         variables: { org_id: currentUser?.org_id },
      //       },
      //     ],
      //   })
      // }
    } catch (error: any) {
      // Check for specific uniqueness violation error
      if (
        error.message.includes('users_username_key') ||
        error.message.includes('users_email_key')
      ) {
        toast({
          variant: 'destructive',
          description:
            'A user with this email already exists. Please try a different email.',
          duration: 3000,
        })
        // Rethrow to prevent closing the dialog and stop loader
        throw error
      } else {
        // For other unexpected errors
        toast({
          variant: 'destructive',
          description: 'Failed to create user. Please try again.',
          duration: 3000,
        })
        throw error
      }
    }
  }

  const handleUpdate = async (data: FormValues, userId: number) => {
    const roleMapping = { superadmin: 1, admin: 2, user: 3 }
    const role_id = roleMapping[data.role as keyof typeof roleMapping] || 3

    const updateData: any = {
      first_name: data.firstname,
      last_name: data.lastname,
    }

    // Only include role_id if user has permission to edit roles
    if (editableFields.canEditRole) {
      updateData.role_id = role_id
    }

    const response = await updateUserInfo({
      variables: {
        id: userId,
        input: updateData,
      },
      refetchQueries: [
        {
          query: GET_ALL_USERS_BY_ORGID,
          variables: { org_id: currentUser?.org_id },
        },
      ],
    })
    const updatedUser = response?.data?.update_suhrt_users?.returning[0]

    if (updatedUser && updatedUser.id === currentUser?.id) {
      const userToUpdate = {
        id: updatedUser.id,
        username: updatedUser.username,
        first_name: updatedUser.first_name,
        last_name: updatedUser.last_name,
        email: updatedUser.email,
        org_id: updatedUser.organisation.id,
        org_name: updatedUser.organisation.name,
        org_details: updatedUser.organisation.org_details,
        role_id: updatedUser.role.id,
        picture: updatedUser.picture,
        isActive: updatedUser.isActive,
      }

      dispatch(setCurrentUser(userToUpdate))
    }
  }

  // Watch the form values for firstname and lastname
  const currentFirstname = watch('firstname')
  const currentLastname = watch('lastname')

  // Check if either field has changed
  const hasChanges =
    currentFirstname !== initialData?.firstname ||
    currentLastname !== initialData?.lastname

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className='space-y-4'
      noValidate
    >
      {['firstname', 'lastname', 'email'].map((field) => (
        <div key={field} className='grid gap-2 md:grid-cols-4 md:items-center'>
          <Label htmlFor={field} className='md:text-right capitalize'>
            {field.replace('name', ' Name')}
          </Label>
          <Controller
            name={field as keyof FormValues}
            control={control}
            rules={{
              required: `${field.replace('name', ' name')} is required`,
              ...(field === 'email' && {
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: 'Enter a valid email address',
                },
              }),
            }}
            render={({ field: { ref, ...fieldProps } }) => (
              <Input
                {...fieldProps}
                id={field}
                placeholder={`Enter ${field.replace('name', ' name')}`}
                className='md:col-span-3'
                disabled={
                  (field === 'email' && !editableFields.canEditEmail) ||
                  (field.includes('name') && !editableFields.canEditName) ||
                  (field === 'email' && Boolean(initialData))
                }
              />
            )}
          />
          {errors[field as keyof FormValues] && (
            <p className='md:col-span-4 text-red-500 text-sm'>
              {errors[field as keyof FormValues]?.message}
            </p>
          )}
        </div>
      ))}

      {/* Only show role selection if user has permission to edit roles */}
      {editableFields.canEditRole && (
        <div className='grid gap-2 md:grid-cols-4 md:items-center'>
          <Label htmlFor='role' className='md:text-right'>
            Assign Role
          </Label>
          <div className='md:col-span-3'>
            <SelectRole
              control={control}
              name='role'
              disabled={!editableFields.canEditRole}
              initialData={initialData}
            />
            {errors.role && (
              <p className='text-red-500 text-sm'>{errors.role.message}</p>
            )}
          </div>
        </div>
      )}

      <div className='flex justify-end gap-4 pt-4'>
        <Button
          type='submit'
          disabled={
            isOwnProfile && currentUser?.role_id === 3 && !hasChanges // Use the hasChanges variable to determine if the button should be disabled
          }
        >
          {initialData ? 'Update User' : 'Send Invite'}
        </Button>
        <Button variant='outline' onClick={closeDialog} type='button'>
          Cancel
        </Button>
      </div>
    </form>
  )
}
