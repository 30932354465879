import { Separator } from '@/components/ui/separator'
import CategoryForm from './CategoryForm'
import GamificationForm from './GamificationForm'
import ReviewForm from './ReviewForm'

export default function Settings() {
  return (
    <div className='space-y-6'>
      <div>
        <h3 className='text-lg font-medium'>Idea Settings</h3>
        <p className=' text-muted-foreground'>
          Configure how you receive notifications.
        </p>
      </div>
      <Separator />
      <CategoryForm />
      <Separator />
      <GamificationForm />
      <Separator />
      <ReviewForm />
    </div>
  )
}
