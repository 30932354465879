import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import { showLoader, hideLoader } from '@/store/idea/loaderSlice'
import {
  GET_IDEA_SUMMERY_BY_ORG_ID,
  GET_IDEA_AGGREGATES,
} from '@/graphql/dashboard'
import { RootState } from '@/store/store'
import DashboardCharts from '@/components/dashboard/DashboardCharts'

const Dashboard = () => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state: RootState) => state.auth)
  const [orgId, setOrgId] = useState<number | null>(null)

  // Determine query based on role
  const query =
    currentUser?.role_id === 1
      ? GET_IDEA_AGGREGATES
      : GET_IDEA_SUMMERY_BY_ORG_ID

  // Lazy query for data fetching
  const [fetchData, { data, loading, error, refetch }] = useLazyQuery(query, {
    onCompleted: (data) => {
      console.log('Query completed successfully:', data)
      dispatch(hideLoader())
    },
    onError: (err) => {
      console.error('Query error:', err)
      dispatch(hideLoader())
    },
    fetchPolicy: 'cache-first',
  })

  // Update orgId when currentUser changes
  useEffect(() => {
    setOrgId(currentUser?.org_id ?? null)
  }, [currentUser])

  // Handle initial data fetching based on role and orgId
  useEffect(() => {
    if (!currentUser) return

    dispatch(showLoader())
    if (currentUser.role_id === 1) {
      fetchData()
        .then((result) => console.log('Initial Fetch (Admin) Success:', result))
        .catch((err) => console.error('Initial Fetch (Admin) Error:', err))
    } else if (
      (currentUser.role_id === 2 || currentUser.role_id === 3) &&
      orgId
    ) {
      fetchData({ variables: { org_id: orgId } })
        .then((result) =>
          console.log('Initial Fetch (User/Manager) Success:', result)
        )
        .catch((err) =>
          console.error('Initial Fetch (User/Manager) Error:', err)
        )
    }
  }, [currentUser, orgId, fetchData, dispatch])

  // Refetch data when orgId changes
  useEffect(() => {
    if (
      (currentUser?.role_id === 2 || currentUser?.role_id === 3) &&
      orgId !== null
    ) {
      refetch({ org_id: orgId }) // Fix: Pass variables directly
        .then((result) => console.log('Refetch Success:', result))
        .catch((err) => console.error('Refetch Error:', err))
    }
  }, [orgId, refetch, currentUser])

  // Manage loader visibility based on loading state
  useEffect(() => {
    if (loading) {
      dispatch(showLoader())
    } else {
      dispatch(hideLoader())
    }
  }, [loading, dispatch])

  // Error display
  if (error) {
    return (
      <div>
        <p>Error fetching data:</p>
        <pre>{error.message}</pre>
      </div>
    )
  }

  return <div>{data && <DashboardCharts data={data} />}</div>
}

export default Dashboard
