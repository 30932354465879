import { useEffect, useState, useMemo } from 'react'
import { TbLayoutList } from 'react-icons/tb'
import { HiViewGrid } from 'react-icons/hi'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { FaPlus } from 'react-icons/fa'
import { useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'

import {
  GET_IDEAS,
  GET_ALL_IDEAS,
  GET_IDEAS_BY_ORGID_FOR_ADMIN,
} from '@/graphql/ideaQuery'
import { GET_CATEGORIES_BY_ORG_ID } from '@/graphql/orgQuery'
import { hideLoader, showLoader } from '@/store/idea/loaderSlice'
import {
  loadCategories,
  loadFilteredIdeas,
  loadIdeas,
} from '@/store/idea/ideaSlice'
import { RootState } from '@/store/store'
import { Idea } from '@/types/idea'
import { useDialog } from '@/components/global-dialog/DialogContext'

import FilterForm from '../components/ideas/forms/filter/FilterForm'
import IconWrapper from '@/components/utility/IconWrapper'
import ListView from '@/components/ideas/views/ListView'
import GridView from '@/components/ideas/views/GridView'
import IdeaTabs from '@/components/ideas/IdeaTabs'
import { Button } from '@/components/ui/button'

const VIEW_TYPES = {
  LIST: 'LIST',
  GRID: 'GRID',
} as const

type ViewType = (typeof VIEW_TYPES)[keyof typeof VIEW_TYPES]

// Separate the filtering logic into a custom hook
const useFilteredIdeas = (ideas: Idea[], filterQuery: any) => {
  return useMemo(() => {
    if (!ideas?.length) return []

    const filterTagsSet = new Set(
      filterQuery.tags?.map((tag: string) => tag.toLowerCase()) || []
    )

    const filterCategory = filterQuery.category?.toLowerCase() || null
    const isCategoryFilterActive = !!filterCategory
    const isTagFilterActive = filterTagsSet.size > 0

    if (!isCategoryFilterActive && !isTagFilterActive) return ideas

    return ideas.filter((idea: Idea) => {
      const hasCategoryMatch =
        !isCategoryFilterActive ||
        idea.idea_categories?.some(
          (cat) => cat.category.category_name.toLowerCase() === filterCategory
        )

      const hasTagMatch =
        !isTagFilterActive ||
        idea.tags?.some((tag: any) =>
          filterTagsSet.has(
            typeof tag === 'string'
              ? tag.toLowerCase()
              : tag.text?.toLowerCase() || ''
          )
        )

      return hasCategoryMatch && hasTagMatch
    })
  }, [ideas, filterQuery])
}

// Separate dialog configuration into its own component
const useIdeaDialog = () => {
  const { openDialog, closeDialog } = useDialog()

  return () => {
    openDialog({
      title: 'Add New Idea',
      description: '',
      requireConfirmation: true,
      content: <IdeaTabs />,
      confirmAction: () => {
        closeDialog()
      },
      width: 'md:w-[95vw]',
      height: 'h-[90vh]',
      showCancelButton: false,
      showConfirmButton: false,
      showFooter: true,
      showFooterButtons: true,
    })
  }
}

export default function Ideas() {
  const [view, setView] = useState<ViewType>(VIEW_TYPES.LIST)
  const [isFilterHidden, setIsFilterHidden] = useState(false)

  const dispatch = useDispatch()
  const { filterQuery, ideas } = useSelector((state: RootState) => state.idea)
  const { currentUser } = useSelector((state: RootState) => state.auth)
  const openIdeaDialog = useIdeaDialog()

  // Query categories
  const { data: categoryData } = useQuery(GET_CATEGORIES_BY_ORG_ID, {
    variables: { org_id: currentUser?.org_id },
    skip: !currentUser?.org_id,
  })

  // Query ideas with refetch configuration
  const {
    loading,
    data: ideasData,
    error,
    refetch,
  } = currentUser?.role_id === 1
    ? useQuery(GET_ALL_IDEAS, {
        fetchPolicy: 'network-only', // Don't use cache for this query
      })
    : currentUser?.role_id === 2
    ? useQuery(GET_IDEAS_BY_ORGID_FOR_ADMIN, {
        variables: { org_id: currentUser?.org_id },
        skip: !currentUser?.org_id,
        fetchPolicy: 'network-only', // Don't use cache for this query
      })
    : useQuery(GET_IDEAS, {
        variables: { org_id: currentUser?.org_id, user_id: currentUser?.id },
        skip: !currentUser?.org_id,
        fetchPolicy: 'network-only', // Don't use cache for this query
      })
  // Add effect to refetch on component mount/navigation
  useEffect(() => {
    const refetchIdeas = async () => {
      try {
        dispatch(showLoader())
        await refetch()
      } catch (error) {
        console.error('Error refetching ideas:', error)
      } finally {
        dispatch(hideLoader())
      }
    }

    refetchIdeas()
  }, [refetch, dispatch])
  // Filter ideas
  const filteredIdeas = useFilteredIdeas(ideas, filterQuery)

  // Handle categories data
  useEffect(() => {
    if (categoryData) {
      dispatch(loadCategories(categoryData.suhrt_category))
    }
  }, [categoryData, dispatch])

  // Handle ideas data and loading state
  useEffect(() => {
    if (loading) {
      dispatch(showLoader())
    } else {
      dispatch(hideLoader())
      if (error) {
        console.error('Error fetching ideas:', error)
      } else if (ideasData?.suhrt_idea) {
        dispatch(loadIdeas(ideasData.suhrt_idea))
      }
    }
  }, [loading, ideasData, error, dispatch])

  // Update filtered ideas in store
  useEffect(() => {
    dispatch(loadFilteredIdeas(filteredIdeas))
  }, [filteredIdeas, dispatch])

  const toggleFilter = () => setIsFilterHidden((prev) => !prev)
  const toggleView = () =>
    setView((prev) =>
      prev === VIEW_TYPES.LIST ? VIEW_TYPES.GRID : VIEW_TYPES.LIST
    )

  if (loading) return <div>Loading...</div>

  return (
    <div
      className={`ideas py-4 transition-all ${
        isFilterHidden
          ? 'flex w-full box-border'
          : 'grid gap-6 md:grid-cols-[230px_minmax(0,_1fr)] grid-cols-1'
      }`}
    >
      {/* Filter Section */}
      <aside
        className={`grow-0 flex flex-col ${
          isFilterHidden ? 'sm:invisible hidden sm:block' : 'block'
        }`}
      >
        <div className='flex items-center pb-4'>
          <IconWrapper
            className='w-8 h-8 text-base flex cursor-pointer hover:text-white hover:bg-primary'
            onClick={toggleFilter}
          >
            <MdChevronLeft
              className={
                isFilterHidden ? 'animate-spinOnce' : 'text-lg h-6 w-6'
              }
              title='Hide Filter'
            />
          </IconWrapper>
          <h3 className='h3 tracking-tight text-base ml-2'>Filter</h3>
        </div>
        <div className='py-4 px-6 bg-background dark:border dark:border-border rounded-sm'>
          <FilterForm />
        </div>
      </aside>

      {/* Main Content */}
      <main
        className={`transition-all duration-500 ease-in-out ${
          isFilterHidden ? 'w-full sm:-ml-[230px]' : 'ml-0'
        }`}
      >
        <div className='flex justify-between pb-4 flex-col sm:flex-row items-center gap-6'>
          <div className='h3 tracking-tight text-base flex items-center gap-2'>
            {isFilterHidden && (
              <IconWrapper
                className='w-8 h-8 z-10 text-base flex cursor-pointer hover:text-white hover:bg-primary'
                onClick={toggleFilter}
              >
                <MdChevronRight
                  className='animate-spinOnce text-lg h-6 w-6'
                  title='Show Filter'
                />
              </IconWrapper>
            )}

            <Button
              onClick={openIdeaDialog}
              className='flex items-center gap-2'
            >
              <FaPlus /> Create New Idea
            </Button>
          </div>

          <div className='flex items-center justify-center gap-4'>
            <div className='flex items-center text-base'>
              <span className='mr-8 italic'>
                Total Ideas (
                <span className='text-primary text-base font-normal not-italic'>
                  {filteredIdeas.length}
                </span>
                )
              </span>
              <span className='mr-2 h3 tracking-tight text-base'>View</span>
              {Object.values(VIEW_TYPES).map((viewType) => (
                <IconWrapper
                  key={viewType}
                  className={`cursor-pointer w-8 h-8 hover:bg-primary ${
                    view === viewType
                      ? 'text-primary hover:text-white'
                      : 'hover:text-white'
                  }`}
                  onClick={toggleView}
                >
                  {viewType === VIEW_TYPES.LIST ? (
                    <HiViewGrid
                      className={`text-[1.2rem] ${
                        view === viewType ? 'animate-spinOnce' : ''
                      }`}
                    />
                  ) : (
                    <TbLayoutList
                      className={`text-[1.2rem] ${
                        view === viewType ? 'animate-spinOnce' : ''
                      }`}
                    />
                  )}
                </IconWrapper>
              ))}
            </div>
            {/* <Button
              onClick={openIdeaDialog}
              className='flex items-center gap-2'
            >
              <FaPlus /> Create New Idea
            </Button> */}
          </div>
        </div>

        <div className='bg-white dark:border dark:bg-background dark:shadow w-full rounded-sm'>
          {view === VIEW_TYPES.LIST ? <GridView /> : <ListView />}{' '}
          {/* Pass ideas prop */}
        </div>
      </main>
    </div>
  )
}
