import { gql } from '@apollo/client'
export const GET_IDEA_SUMMERY_BY_ORG_ID = gql`
  query GetIdeaSummaryByOrgID($org_id: Int!) {
    total_ideas: suhrt_idea_aggregate(
      where: { user: { org_id: { _eq: $org_id } } }
    ) {
      aggregate {
        count
      }
    }
    draft_ideas: suhrt_idea_aggregate(
      where: { user: { org_id: { _eq: $org_id } }, status: { _eq: 1 } }
    ) {
      aggregate {
        count
      }
    }
    inreview_ideas: suhrt_idea_aggregate(
      where: { user: { org_id: { _eq: $org_id } }, status: { _eq: 2 } }
    ) {
      aggregate {
        count
      }
    }
    approved_ideas: suhrt_idea_aggregate(
      where: { user: { org_id: { _eq: $org_id } }, status: { _eq: 3 } }
    ) {
      aggregate {
        count
      }
    }
    deleted_ideas: suhrt_idea_aggregate(
      where: { user: { org_id: { _eq: $org_id } }, status: { _eq: 4 } }
    ) {
      aggregate {
        count
      }
    }
    tag_cloud: suhrt_tag_cloud_by_org(where: { org_id: { _eq: $org_id } }) {
      tag
      count
    }
    idea_across_categories: suhrt_category_idea_count(
      where: { org_id: { _eq: $org_id } }
    ) {
      category_name
      ideas_count
    }
    suhrt_idea_status_time_series(where: { org_id: { _eq: $org_id } }) {
      month
      year
      draft
      in_review
      approved
      deleted
    }
  }
`
export const GET_IDEA_AGGREGATES = gql`
  query GetIdeaAggregates {
    total_ideas: suhrt_idea_aggregate {
      aggregate {
        count
      }
    }
    draft_ideas: suhrt_idea_aggregate(where: { status: { _eq: 1 } }) {
      aggregate {
        count
      }
    }
    inreview_ideas: suhrt_idea_aggregate(where: { status: { _eq: 2 } }) {
      aggregate {
        count
      }
    }
    approved_ideas: suhrt_idea_aggregate(where: { status: { _eq: 3 } }) {
      aggregate {
        count
      }
    }
    deleted_ideas: suhrt_idea_aggregate(where: { status: { _eq: 4 } }) {
      aggregate {
        count
      }
    }
    tag_cloud: suhrt_tag_cloud_all_orgs {
      tag
      count
    }
    idea_across_categories: suhrt_category_idea_count_all_orgs {
      category_name
      ideas_count
    }
    suhrt_idea_status_time_series: suhrt_idea_status_time_series_all_orgs {
      month
      year
      draft
      in_review
      approved
      deleted
    }
  }
`
