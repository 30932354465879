import { gql } from "@apollo/client"

export const NOTIFICATION_COUNT_SUBSCRIPTION = gql`
  subscription GetNotificationCount($user_id: Int!) {
    suhrt_notifications_by_user_aggregate(where: {user_id: {_eq: $user_id}}) {
      aggregate {
        count
      }
    }
  }
`

// export const NOTIFICATION_SUBSCRIPTION = gql`
//   subscription GetNotificationCount($user_id: Int!) {
//     suhrt_notifications_by_user_aggregate(where: {user_id: {_eq: $user_id}}) {
//       aggregate {
//         count
//       }
//     }
//   }
// `

export const NOTIFICATION_COUNT_QUERY = gql`
  query GetNotificationCount($user_id: Int!) {
    suhrt_notifications_by_user_aggregate(where: {user_id: {_eq: $user_id}}) {
      aggregate {
        count
      }
    }
  }
`

export const NOTIFICATION_QUERY = gql`
  query GetNotifications($user_id: Int!) {
  suhrt_notifications_by_user(where: {user_id: {_eq: $user_id}}) {
    idea_id
    idea_title
    notification_text
  }
}
`