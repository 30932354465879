import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { Button } from '../ui/button'
import { ScrollArea } from '../ui/react-scroll-area'
import { motion } from 'framer-motion'
import { X } from 'lucide-react' // Import the close icon from Lucide

import logo from '../../assets/logo2.png' // Light theme logo
import logoWhite from '../../assets/logo_white.png' // Dark theme logo
import { Separator } from '../ui/separator'

type Link = {
  to: string
  text: string
}

interface MobileNavProps {
  links: Link[]
}

export function MobileNav({ links }: MobileNavProps) {
  const [open, setOpen] = React.useState(false)

  return (
    <div>
      {/* Hamburger Button */}
      <Button
        variant='ghost'
        onClick={() => setOpen(true)}
        className='mr-2 px-0 text-base hover:bg-transparent focus-visible:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 md:hidden'
      >
        <svg
          strokeWidth='1.5'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className='h-5 w-5'
        >
          <path
            d='M3 5H11'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
          <path
            d='M3 12H16'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
          <path
            d='M3 19H21'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
        </svg>
        <span className='sr-only'>Toggle Menu</span>
      </Button>

      {/* Sidebar */}
      {open && (
        <motion.div
          initial={{ x: '-100%' }}
          animate={{ x: 0 }}
          exit={{ x: '-100%' }}
          transition={{ duration: 0.3 }}
          className={`fixed inset-y-0 left-0 z-50 w-64 bg-white shadow-lg dark:bg-gray-900`}
        >
          <div className='relative h-full'>
            {/* Close Button */}
            <button
              onClick={() => setOpen(false)}
              className='absolute top-[0.3rem] right-[0.5rem] p-2 text-gray-900 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-full'
            >
              <X className='h-6 w-6' />
            </button>

            {/* Logo */}
            <NavLink to='/' className='flex items-center p-4'>
              <img
                className='mr-2 h-4 dark:hidden' // Show in light mode
                src={logo}
                alt='Logo'
              />
              <img
                className='mr-2 h-4 hidden dark:block' // Show in dark mode
                src={logoWhite}
                alt='Logo'
              />
            </NavLink>
            <Separator className='border-gray-300 dark:border-gray-700' />

            {/* Links */}
            <ScrollArea className='my-4 h-[calc(100vh-8rem)] pb-10'>
              <div className='flex flex-col space-y-3 p-4'>
                {links.map((link) => (
                  <NavLink
                    key={link.to}
                    className={({ isActive }) =>
                      `transition-colors duration-200 ease-in-out ${
                        isActive
                          ? 'text-primary rounded-md font-semibold dark:text-white'
                          : 'hover:text-primary rounded-md font-medium dark:text-gray-400'
                      }`
                    }
                    to={link.to}
                    onClick={() => setOpen(false)}
                  >
                    {link.text}
                  </NavLink>
                ))}
              </div>
            </ScrollArea>
          </div>
        </motion.div>
      )}

      {/* Overlay */}
      {open && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className='fixed inset-0 bg-black'
          onClick={() => setOpen(false)}
        />
      )}
    </div>
  )
}
