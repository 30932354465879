export const roles = [
  {
    label: 'admin',
    value: 'admin',
    description: 'Learn about the history, usage and variations of Lorem Ipsum',
  },
  {
    label: 'user',
    value: 'user',
    description: 'Learn about the history, usage and variations of Lorem Ipsum',
  },
]
