import type React from 'react'
import { Card, CardContent } from '@/components/ui/card'
import { RootState } from '@/store/store'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { hideLoader } from '@/store/idea/loaderSlice'

const VerifyEmail: React.FC = () => {
  const { currentUser } = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    // Ensure loader is hidden when verification page mounts
    dispatch(hideLoader())
  }, [dispatch])
  return (
    <div className='relative flex items-center justify-center min-h-screen p-4 overflow-hidden bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50'>
      <Card className='relative w-full max-w-2xl bg-white/95 backdrop-blur-sm shadow-xl'>
        <CardContent className='pt-6 px-6'>
          <h1 className='text-3xl font-semibold text-center mb-4'>
            Check Your Email
          </h1>
          <p className='text-muted-foreground text-center mb-6'>
            We've sent you an email to{' '}
            <strong className='underline'>{currentUser?.email}</strong> with a
            link to verify your account. Please check your inbox and click on
            the verification link.
          </p>
          <p className='text-muted-foreground text-center mb-4'>
            If you don't see the email, please check your spam folder. If you
            still can't find it, you can request a new verification email.
          </p>
          <p className='text-muted-foreground text-center mb-4'>
            For additional clarifications please reach out to us at
            <a
              className='ml-2 hover:text-primary transition-colors duration-200 font-bold'
              href='mailto:contact-us@suhrt.com?subject=%5BSuhrt%20Sales%5D%20Information%20Required&body=%5BAdd%20Brief%20Description%20of%20Your%20Query%5D'
            >
              contact-us@suhrt.com
            </a>
          </p>
        </CardContent>
      </Card>
    </div>
  )
}

export default VerifyEmail
