export const formatDate = (dateString: string): string => {
  const date = new Date(dateString)

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short', // Abbreviated month, e.g., "Nov"
    day: '2-digit', // Day of the month with leading zero if necessary
    hour: 'numeric',
    minute: '2-digit',
    hour12: true, // Use 12-hour format (AM/PM)
  }

  return date.toLocaleString('en-US', options)
}
