import { gql } from '@apollo/client'

export const GET_PUBLISHED_BLOGS = gql`
  query GetPublishedBlogs {
    suhrt_blogs(
      where: { status: { _eq: "published" } }
      order_by: { created_dt: desc }
    ) {
      title
      perma_slug
      content
      feature_image_url
      author_name
      created_dt
      tags
    }
  }
`
export const GET_BLOG_BY_ID = gql`
  query GetBlogByPK($perma_slug: String!) {
    suhrt_blogs(where: { perma_slug: { _eq: $perma_slug } }) {
      title
      perma_slug
      content
      feature_image_url
      author_name
      created_dt
      tags
    }
  }
`
