'use client'

import { useEffect, useState } from 'react'
import { Button } from '@/components/ui/button'
import { Textarea } from '@/components/ui/textarea'
import { useToast } from '@/components/ui/use-toast'
import { Loader2, Sparkles, Zap } from 'lucide-react'
import { AnimatePresence, motion } from 'framer-motion'
import config from '@/config'
import { Card, CardContent } from '@/components/ui/card'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
const MAX_RETRIES = 3
const INITIAL_DELAY = 1000 // 1 second
interface StepOneProps {
  onThemesGenerated: (Themes: string[]) => void
  onNext: () => void
  retainedPrompt: string
  retainedSuggestions: string[]
  onPromptChange: (prompt: string) => void
  onSuggestionsChange: (suggestions: string[]) => void
  checkTokenAvailability: () => Promise<boolean>
  setShowOverlay: (show: boolean) => void
}

export default function StepOne({
  onThemesGenerated,
  onNext,
  retainedPrompt,
  retainedSuggestions,
  onPromptChange,
  onSuggestionsChange,
  checkTokenAvailability,
  setShowOverlay,
}: StepOneProps) {
  const [prompt, setPrompt] = useState(retainedPrompt)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false)
  const [suggestions, setSuggestions] = useState<string[]>(retainedSuggestions)
  const { toast } = useToast()
  const { currentUser } = useSelector((state: RootState) => state.auth)
  useEffect(() => {
    setPrompt(retainedPrompt)
    setSuggestions(retainedSuggestions)
  }, [retainedPrompt, retainedSuggestions])
  const generateThemes = async () => {
    if (!prompt.trim()) {
      toast({
        title: 'Input required',
        description: "Please enter what you'd like to innovate on",
        variant: 'destructive',
      })
      return
    }
    const hasTokens = await checkTokenAvailability()
    if (!hasTokens) {
      setShowOverlay(true)
      return
    }
    let retries = 0
    let delay = INITIAL_DELAY

    const attemptGenerate = async (): Promise<string[]> => {
      try {
        const response = await fetch(
          `${config.apiUrl}/suhrtai/generate-themes`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ prompt }),
          }
        )

        if (!response.ok) {
          throw new Error('Failed to generate Themes')
        }

        const data = await response.json()

        if (
          !data.Themes ||
          !Array.isArray(data.Themes) ||
          data.Themes.length === 0
        ) {
          throw new Error('Invalid Themes data received')
        }

        return data.Themes
      } catch (error) {
        if (retries < MAX_RETRIES) {
          retries++
          setIsLoading(true)
          toast({
            title: 'Retrying',
            description: `Attempt ${retries} of ${MAX_RETRIES}...`,
            duration: 2000,
          })
          await new Promise((resolve) => setTimeout(resolve, delay))
          delay *= 2 // Exponential backoff
          return attemptGenerate()
        }
        throw error
      }
    }

    try {
      setIsLoading(true)
      const themes = await attemptGenerate()
      onThemesGenerated(themes)
      onNext()
    } catch (error) {
      console.error('Error generating Themes:', error)
      toast({
        title: 'Error',
        description:
          'Failed to generate Themes after multiple attempts. Please try again.',
        variant: 'destructive',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const getSuggestions = async () => {
    try {
      setIsSuggestionsLoading(true)
      const hasTokens = await checkTokenAvailability()
      if (!hasTokens) {
        setShowOverlay(true)
        return
      }
      const response = await fetch(
        `${config.apiUrl}/suhrtai/generate_trending_ideas`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ prompt: currentUser?.org_details }),
        }
      )

      if (!response.ok) {
        throw new Error('Failed to get suggestions')
      }

      const data = await response.json()

      if (
        Array.isArray(data) &&
        data.every((item) => typeof item === 'string')
      ) {
        setSuggestions(data)
        onSuggestionsChange(data)
      } else {
        throw new Error('Invalid suggestions format')
      }
    } catch (error) {
      console.error('Error getting suggestions:', error)
      toast({
        title: 'Error',
        description: 'Failed to get suggestions. Please try again.',
        variant: 'destructive',
      })
      setSuggestions([]) // Clear any existing suggestions
      onSuggestionsChange([])
    } finally {
      setIsSuggestionsLoading(false)
    }
  }
  const handlePromptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newPrompt = e.target.value
    setPrompt(newPrompt)
    onPromptChange(newPrompt)
  }
  return (
    <motion.div
      className='space-y-6 h-[calc(100vh-292px)] overflow-auto pr-4'
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className='space-y-4'>
        <p className='text-sm text-muted-foreground mb-4 md:mb-0 flex items-center'>
          Try to be specific about the problem or area you want to explore.
        </p>
        <Textarea
          placeholder='Describe what you want to innovate on or click on one of the curated suggestions below.'
          value={prompt}
          onChange={handlePromptChange}
          className='min-h-[100px] resize-none text-base leading-relaxed rounded-xl'
          disabled={isLoading}
        />

        <div className='flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4'>
          <Button
            variant='outline'
            size='sm'
            onClick={getSuggestions}
            disabled={isLoading || isSuggestionsLoading}
            className='w-full sm:w-auto'
          >
            {isSuggestionsLoading ? ( // Update 3
              <>
                <Loader2 className='mr-2 h-5 w-5 animate-spin' />
                Getting Suggestions...
              </>
            ) : (
              <>
                <Zap className='mr-2 h-5 w-5' />
                Get Curated Suggestions
              </>
            )}
          </Button>

          <Button
            size='sm'
            onClick={generateThemes}
            disabled={isLoading || !prompt.trim()}
            className='w-full sm:w-auto bg-primary hover:bg-primary/90'
          >
            {isLoading ? (
              <>
                <Loader2 className='mr-2 h-5 w-5 animate-spin' />
                Generating...
              </>
            ) : (
              <>
                <Sparkles className='mr-2 h-5 w-5' />
                Generate Themes
              </>
            )}
          </Button>
        </div>
      </div>
      <AnimatePresence>
        {suggestions.length > 0 && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className='mt-4 space-y-2'
          >
            <h3 className='text-lg font-semibold px-1'>Curated Suggestions:</h3>
            <div className='grid gap-2 mt-2'>
              {suggestions.map((suggestion, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <Card
                    className='cursor-pointer group hover:shadow-lg transition-all duration-300'
                    onClick={() => {
                      setPrompt(suggestion)
                      onPromptChange(suggestion)
                    }}
                  >
                    <CardContent className='p-2'>
                      <div className='flex items-center gap-4'>
                        <div className='p-2 rounded-lg bg-primary/10 group-hover:bg-primary/20 transition-colors'>
                          <Zap className='h-4 w-4 text-primary' />
                        </div>
                        <p className='text-sm group-hover:text-primary transition-colors'>
                          {suggestion}
                        </p>
                      </div>
                    </CardContent>
                  </Card>
                </motion.div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}
