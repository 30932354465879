import React from 'react'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { Attachment } from '../Attachment'
import { MinusCircleIcon, PlusCircleIcon } from 'lucide-react'

interface AdditionalOptionsProps {
  register: any
  showAdditionalOptions: boolean
  setShowAdditionalOptions: (show: boolean) => void
  existingAttachments: string[]
  watch: any
  setValue: any
  handleRemoveExistingFile: (index: number) => void
  disabled: boolean
}

export const AdditionalOptions: React.FC<AdditionalOptionsProps> = ({
  register,
  showAdditionalOptions,
  setShowAdditionalOptions,
  existingAttachments,
  watch,
  setValue,
  handleRemoveExistingFile,
  disabled,
}) => {
  return (
    <div className='mt-2'>
      <div
        className='flex items-center cursor-pointer rounded-md hover:text-primary'
        onClick={() => setShowAdditionalOptions(!showAdditionalOptions)}
      >
        {showAdditionalOptions ? (
          <MinusCircleIcon className='mr-2' size={20} />
        ) : (
          <PlusCircleIcon className='mr-2' size={20} />
        )}
        <Label className='cursor-pointer'>
          {showAdditionalOptions
            ? 'Hide Additional Options'
            : 'Additional Options'}
        </Label>
      </div>

      {showAdditionalOptions && (
        <div className='mt-2 space-y-2'>
          <div>
            <Label htmlFor='attachments'>Attachments (Optional)</Label>
            <Attachment
              register={register}
              disabled={disabled}
              attachments={existingAttachments}
              watch={watch}
              setValue={setValue}
              onRemoveExistingFile={handleRemoveExistingFile}
            />
          </div>
          <div>
            <Label htmlFor='note'>Add Note (Optional)</Label>
            <Input
              id='note'
              {...register('note')}
              placeholder='Add a note'
              className='mt-1'
            />
          </div>
        </div>
      )}
    </div>
  )
}
