'use client'

import { motion } from 'framer-motion'
import { Card, CardContent } from '@/components/ui/card'

export default function AboutPage() {
  return (
    <div className='container mx-auto px-4 py-16'>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className='space-y-12'
      >
        <div className='text-center'>
          <h2 className='text-4xl font-bold text-foreground mb-4'>
            About Suhrt
          </h2>
        </div>

        <Card className='bg-background border border-primary/10 overflow-hidden transition-all duration-300 hover:shadow-md hover:shadow-primary/5'>
          <CardContent className='p-12'>
            <motion.p
              className='text-lg text-foreground leading-relaxed mb-6'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.5 }}
            >
              <span className='font-semibold'>Suhrt (सुहृत्)</span>, derived
              from Sanskrit, means "well-wisher." The term is profoundly rooted
              in the Bhagavad Gita, where Bhagavan Sri Krishna is revered as{' '}
              <span className='italic'>suhṛtaṁ sarva-bhūtānam</span>—the Supreme
              well-wisher of all beings. Inspired by this timeless wisdom, Suhrt
              embodies the spirit of being a trusted companion in your
              innovation journey.
            </motion.p>
            <motion.p
              className='text-lg text-foreground leading-relaxed mb-6'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4, duration: 0.5 }}
            >
              At Suhrt, we aim to democratize innovation by serving as your
              dedicated co-pilot in the ideation process. Leveraging AI-driven
              capabilities, Suhrt fosters structured creativity and empowers
              organizations to boost their{' '}
              <span className='italic'>
                innovation calorie-count—unlocking{' '}
              </span>
              the potential of every individual and team to generate impactful
              ideas.
            </motion.p>
            <motion.p
              className='text-lg text-foreground leading-relaxed'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.6, duration: 0.5 }}
            >
              Together, let's turn every spark of creativity into a blazing
              innovation, making extraordinary ideas an everyday reality!
            </motion.p>
          </CardContent>
        </Card>
      </motion.div>
    </div>
  )
}
