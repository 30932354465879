function Loader() {
  return (
    <div
      className='fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-[9999]'
      style={{ pointerEvents: 'auto' }} // Explicitly enable pointer events on the container
    >
      {/* Blocking overlay - notice removal of pointer-events-none */}
      <div className='absolute inset-0' />

      {/* Loader */}
      <div className='relative'>
        {' '}
        {/* Wrapper to ensure loader stays above overlay */}
        <div className='w-20 h-20 border-4 border-t-4 border-t-primary border-gray-200 rounded-full animate-spin' />
      </div>
    </div>
  )
}

export default Loader
