import { createSlice } from '@reduxjs/toolkit'

interface LoaderState {
  isLoading: boolean
}

const initialState: LoaderState = {
  isLoading: false,
}

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    showLoader(state) {
      state.isLoading = true
    },

    hideLoader(state) {
      state.isLoading = false
    },
  },
})

export const { showLoader, hideLoader } = loaderSlice.actions

export const loaderReducer = loaderSlice.reducer

export const selectIsLoading = (state: { loader: LoaderState }) =>
  state.loader.isLoading
