import { FaFacebookSquare, FaLinkedin, FaTwitterSquare } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { Separator } from '../ui/separator'

export default function Footer() {
  const navigate = useNavigate()
  return (
    <div className='py-2 bg-[#EBF8FF] dark:bg-muted w-full'>
      <div className=' container max-w-screen-2xl px-8 flex flex-col md:flex-row items-center justify-center md:justify-between w-full'>
        <div className='address flex-1'>
          <div className='text-center md:text-left'>
            <h2
              className='font-semibold text-base leading-tight hover:text-primary cursor-pointer'
              onClick={() => navigate('/about')}
            >
              About Suhrt
            </h2>
          </div>
        </div>
        <div className='tech flex items-center justify-center text-[0.7rem] my-8 flex-1'>
          <p>©2025 Suhrt.</p>
        </div>
        <div className='social flex-1'>
          <div className='text-center md:text-right flex flex-col'>
            <div className='flex items-center justify-end'>
              <h5 className='font-semibold text-base leading-tight'>
                <a
                  className=' hover:text-primary transition-colors duration-200'
                  href='https://forms.zohopublic.in/ashwathkannansu1/form/SuhrtDemoFeedbackForm/formperma/Jqk-Rn5bmD-Opndr52tLmTBIeF4Eg-_Mlt13EeVuE8s'
                  target='_blank'
                >
                  Share Feedback
                </a>
              </h5>
              <Separator
                orientation='vertical'
                className='h-3 mx-2 bg-foreground'
              />
              <h5 className='font-semibold text-base leading-tight'>
                <a
                  className=' hover:text-primary transition-colors duration-200'
                  href='mailto:contact-us@suhrt.com?subject=%5BSuhrt%20Sales%5D%20Information%20Required&body=%5BAdd%20Brief%20Description%20of%20Your%20Query%5D'
                >
                  Contact Us
                </a>
              </h5>
              <Separator
                orientation='vertical'
                className='h-3 mx-2 bg-foreground'
              />
              <h5 className='font-semibold text-base leading-tight'>
                <a
                  className=' hover:text-primary transition-colors duration-200'
                  href='mailto:support@suhrt.com?subject=%5BSuhrt%20Support%5D%20Assistance%20Required&body=%5BAdd%20Brief%20Description%20of%20Your%20Issue%2FQuery%5D'
                >
                  Need Support?
                </a>
              </h5>
            </div>

            <div className='flex md:justify-between justify-center mt-4 items-center md:flex-row-reverse'>
              <p className=' text-slate-500 flex '>
                <a
                  href='https://x.com/SuhrtInnovation'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FaTwitterSquare className='text-foreground' />
                </a>
                <a
                  href='https://www.linkedin.com/company/suhrt-innovation'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FaLinkedin className='mx-4 text-foreground' />
                </a>
                <a
                  href='https://www.facebook.com/people/Suhrt-Innovation/pfbid02gwR11UpaSGbR8NycZst3hsaF3z3kQ6CbK7HeNgjyb8oyjRrb4SJ5PLMJhQR7xCysl/?viewas&show_switched_toast=false&show_switched_tooltip=false&is_tour_dismissed=false&is_tour_completed=false&show_podcast_settings=false&show_community_review_changes=false&should_open_composer=false&badge_type=NEW_MEMBER&show_community_rollback_toast=false&show_community_rollback=false&show_follower_visibility_disclosure=false&bypass_exit_warning=true'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FaFacebookSquare className='text-foreground' />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
