'use client'

import { motion } from 'framer-motion'
import { ContactForm } from '@/components/contact/ContactForm'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { Mail, MessageSquare, Clock } from 'lucide-react'

export default function ContactPage() {
  return (
    <motion.div
      className='container mx-auto py-12 px-4'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className='grid gap-12 lg:grid-cols-2'>
        <motion.div
          className='space-y-6'
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <div>
            <h2 className='text-4xl font-bold tracking-tight'>Get in Touch</h2>
            <p className='mt-2 text-lg text-muted-foreground'>
              Have questions about idea innovation? We're here to help.
            </p>
          </div>

          <motion.div
            className='grid gap-6'
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <motion.div
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.2 }}
            >
              <Card>
                <CardContent className='flex items-center gap-4 p-6'>
                  <Mail className='h-6 w-6 text-primary' />
                  <div>
                    <h3 className='font-semibold'>Email Us</h3>
                    <p className='text-sm text-muted-foreground'>
                      <a
                        href='mailto:support@suhrt.com?subject=%5BSuhrt%20Support%5D%20Assistance%20Required&body=%5BAdd%20Brief%20Description%20of%20Your%20Issue%2FQuery%5D'
                        className='hover:underline hover:text-primary'
                      >
                        support@suhrt.com
                      </a>
                    </p>
                  </div>
                </CardContent>
              </Card>
            </motion.div>

            <motion.div
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.2 }}
            >
              <Card>
                <CardContent className='flex items-center gap-4 p-6'>
                  <MessageSquare className='h-6 w-6 text-primary' />
                  <div className='w-full'>
                    <h3 className='font-semibold'>Live Chat</h3>
                    <div className='flex justify-between gap-4 items-center'>
                      <p className='text-sm text-muted-foreground'>
                        Available during business hours
                      </p>
                      <span>Coming Soon..</span>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </motion.div>

            <motion.div
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.2 }}
            >
              <Card>
                <CardContent className='flex items-center gap-4 p-6'>
                  <Clock className='h-6 w-6 text-primary' />
                  <div>
                    <h3 className='font-semibold'>Response Time</h3>
                    <p className='text-sm text-muted-foreground'>
                      24 - 48 hours
                    </p>
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          </motion.div>

          {/* <motion.div
            className='rounded-lg bg-muted p-6'
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <h3 className='font-semibold'>FAQ</h3>
            <div className='mt-4 space-y-4'>
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.8 }}
              >
                <h4 className='font-medium'>What is idea innovation?</h4>
                <p className='text-sm text-muted-foreground'>
                  Idea innovation is our platform that helps you generate and
                  develop creative ideas using AI-powered tools and
                  methodologies.
                </p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 1 }}
              >
                <h4 className='font-medium'>How can I get started?</h4>
                <p className='text-sm text-muted-foreground'>
                  You can start by signing up for a free account and exploring
                  our idea generation tools.
                </p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 1.2 }}
              >
                <h4 className='font-medium'>Do you offer custom solutions?</h4>
                <p className='text-sm text-muted-foreground'>
                  Yes, we offer enterprise solutions tailored to your
                  organization's needs.
                </p>
              </motion.div>
            </div>
          </motion.div> */}
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <Card>
            <CardHeader>
              <CardTitle>Send us a message</CardTitle>
              <CardDescription>
                Fill out the form below and we'll get back to you as soon as
                possible.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <ContactForm />
            </CardContent>
          </Card>
        </motion.div>
      </div>
    </motion.div>
  )
}
