'use client'

import { useEffect, useMemo, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { motion, AnimatePresence } from 'framer-motion'

import Comments from '@/components/ideas/idea-summery/Comments'
import IdeaSummary from '@/components/ideas/idea-summery/IdeaSummary'
import Timeline from '@/components/ideas/idea-summery/Timeline'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { SuhrtScoreParams } from '@/components/ideas/views/SuhrtScoreParams'
import { SuhrtScore } from '@/components/ideas/SuhrtScore'
import NoteCard from '@/components/notes/NoteCard'
import {
  GET_IDEA_BY_ID,
  GET_IDEA_DETAILS,
  GET_IDEA_DETAILS_FOR_ADMIN,
} from '@/graphql/ideaQuery'
import { mapApiResponseToScores } from '@/service/ideas'
import { hideLoader, showLoader } from '@/store/idea/loaderSlice'
import { formatDate } from '@/helpers/utils'
import { RootState } from '@/store/store'
import { UTILITY } from '@/util/utility'
import NotFoundPage from './NotFoundPage'
import MindMap from '@/components/ideas/forms/IdeaForm/MindMap'

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 },
}

const staggerChildren = {
  animate: {
    transition: {
      staggerChildren: 0.1,
    },
  },
}

function IdeaDetails() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state: RootState) => state.auth)

  const decryptedId = useMemo(() => {
    try {
      return id ? UTILITY.decryptedId(id) : undefined
    } catch (error) {
      return undefined
    }
  }, [id])

  const {
    loading,
    data: ideaData,
    error,
  } = currentUser?.role_id === 1
    ? useQuery(GET_IDEA_BY_ID, {
        variables: { id: decryptedId },
        skip: !decryptedId,
        fetchPolicy: 'network-only', // Forces a network request each time
        nextFetchPolicy: 'cache-first', // Subsequent requests will use cache
      })
    : currentUser?.role_id === 2
    ? useQuery(GET_IDEA_DETAILS_FOR_ADMIN, {
        variables: {
          idea_id: decryptedId,
          org_id: currentUser?.org_id,
        },
        skip: !decryptedId || !currentUser?.org_id,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
      })
    : useQuery(GET_IDEA_DETAILS, {
        variables: {
          idea_id: decryptedId,
          org_id: currentUser?.org_id,
          user_id: currentUser?.id,
        },
        skip: !decryptedId || !currentUser?.org_id || !currentUser?.id,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
      })
  useEffect(() => {
    if (loading) {
      dispatch(showLoader())
    } else {
      dispatch(hideLoader())
    }
  }, [loading, dispatch])

  const ideaDetails = useMemo(() => {
    return ideaData?.suhrt_idea[0] || undefined
  }, [ideaData])

  const mappedScores = useMemo(() => {
    if (!ideaDetails?.idea_scores[0]) return undefined
    return mapApiResponseToScores(
      ideaDetails.idea_scores[0],
      ideaDetails.idea_scores[0].weight_id
    )
  }, [ideaDetails])

  const timelineItems = useMemo(() => {
    if (!ideaDetails) return []

    const getDateForAction = (action: string) => {
      const note = ideaDetails.idea_notes?.find(
        (note: { action: string }) => note.action === action
      )
      return note ? formatDate(note.created_dt) : 'N/A'
    }

    return [
      {
        label: 'Draft',
        date: getDateForAction('create'),
        status:
          ideaDetails.status === 1
            ? 'in-process'
            : ideaDetails.status >= 2
            ? 'completed'
            : 'pending',
        tooltip: 'The idea was drafted.',
      },
      {
        label: 'In Review',
        date: getDateForAction('Sent for Review'),
        status:
          ideaDetails.status === 2
            ? 'in-process'
            : ideaDetails.status === 3
            ? 'completed'
            : 'pending',
        tooltip: 'The idea is under review.',
      },
      {
        label: 'Approved',
        date: ideaDetails.idea_notes
          ?.filter((note: { action: string }) => note.action === 'Approved')
          .slice(-1)[0]?.created_dt
          ? formatDate(
              ideaDetails.idea_notes
                .filter(
                  (note: { action: string }) => note.action === 'Approved'
                )
                .slice(-1)[0].created_dt
            )
          : 'N/A',
        status: ideaDetails.status === 3 ? 'completed' : 'pending',
        tooltip: 'Approval is in process.',
      },
    ]
  }, [ideaDetails])

  const renderContent = useCallback(() => {
    if (!ideaDetails) return null
    // Format mind map data for the MindMap component
    const mindMapData = {
      title: ideaDetails?.idea_title || '',
      central: {
        label: 'MINDMAP',
        color: '#00008b',
      },
      mind_map: ideaDetails?.mindmap ? JSON.parse(ideaDetails.mindmap) : {},
    }
    const isEmptyMindmap =
      ideaDetails?.mindmap === null ||
      (typeof ideaDetails?.mindmap === 'object' &&
        Object.keys(ideaDetails?.mindmap).length === 0)
    return (
      <motion.div
        className='relative'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          className='flex items-center justify-between'
          variants={fadeInUp}
          initial='initial'
          animate='animate'
        >
          <h3 className='text-lg py-4 tracking-tight'>Idea Summary</h3>
        </motion.div>
        <motion.div
          className='grid sm:grid-cols-2 sm:gap-4 gap-4 grid-cols-1 mb-4'
          variants={staggerChildren}
          initial='initial'
          animate='animate'
        >
          <motion.div
            className='left-side flex flex-col'
            variants={fadeInUp}
            transition={{ delay: 0.2 }}
          >
            <Timeline items={timelineItems} />
            <motion.div
              className='bg-background dark:border dark:border-border rounded-md mt-4 flex-grow'
              variants={fadeInUp}
              transition={{ delay: 0.4 }}
            >
              <IdeaSummary
                ideaDetails={ideaDetails}
                ideaScores={mappedScores}
              />
            </motion.div>
          </motion.div>

          <motion.div
            className='right-side bg-background dark:border dark:border-border rounded-md h-full p-6'
            variants={fadeInUp}
            transition={{ delay: 0.6 }}
          >
            <Tabs defaultValue='mindMap' className='h-full'>
              <TabsList className='grid grid-cols-4 md:w-[400px] justify-self-center items-center'>
                <TabsTrigger value='mindMap'> Mind Map</TabsTrigger>
                <TabsTrigger value='score'>Score</TabsTrigger>
                <TabsTrigger value='comments'>Comments</TabsTrigger>
                <TabsTrigger value='notes'>Notes</TabsTrigger>
              </TabsList>
              <TabsContent value='mindMap' className='m-0 w-full flex-grow'>
                <motion.div
                  variants={fadeInUp}
                  initial='initial'
                  animate='animate'
                  transition={{ delay: 0.2 }}
                >
                  {isEmptyMindmap ? (
                    <div className='flex items-center justify-center mt-8'>
                      <p>No mindmap data available.</p>
                    </div>
                  ) : (
                    <MindMap data={mindMapData} />
                  )}
                </motion.div>
              </TabsContent>

              <TabsContent
                value='score'
                className='m-0 md:w-full overflow-x-auto flex-grow'
              >
                <motion.div
                  variants={fadeInUp}
                  initial='initial'
                  animate='animate'
                  transition={{ delay: 0.2 }}
                >
                  <SuhrtScore
                    weighted_avg_score={
                      ideaDetails?.idea_scores[0]?.weighted_avg_score || 0
                    }
                  />
                </motion.div>
                {mappedScores && (
                  <motion.div
                    variants={fadeInUp}
                    initial='initial'
                    animate='animate'
                    transition={{ delay: 0.2 }}
                  >
                    <SuhrtScoreParams suhrtScores={mappedScores} />
                  </motion.div>
                )}
              </TabsContent>
              <TabsContent value='comments' className='m-0 w-full flex-grow'>
                <motion.div
                  variants={fadeInUp}
                  initial='initial'
                  animate='animate'
                  transition={{ delay: 0.2 }}
                >
                  <Comments ideaId={decryptedId!} />
                </motion.div>
              </TabsContent>
              <TabsContent value='notes' className='mt-4 py-4 w-full flex-grow'>
                <motion.div
                  variants={fadeInUp}
                  initial='initial'
                  animate='animate'
                  transition={{ delay: 0.2 }}
                >
                  <NoteCard notes={ideaDetails?.idea_notes ?? []} />
                </motion.div>
              </TabsContent>
            </Tabs>
          </motion.div>
        </motion.div>
      </motion.div>
    )
  }, [ideaDetails, timelineItems, mappedScores, decryptedId])

  // Show NotFoundPage in any of these cases:
  // 1. No ID provided
  // 2. ID decryption failed
  // 3. GraphQL error
  // 4. Query completed but no idea found

  if (!id || !decryptedId || error || (!loading && !ideaDetails)) {
    return <NotFoundPage />
  }

  return <AnimatePresence mode='wait'>{renderContent()}</AnimatePresence>
}

export default IdeaDetails
