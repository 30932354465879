import React, { useCallback, useMemo } from 'react'
import { DotsHorizontalIcon } from '@radix-ui/react-icons'
import { Row } from '@tanstack/react-table'
import { useSelector } from 'react-redux'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useDialog } from '@/components/global-dialog/DialogContext'
import { RootState } from '@/store/store'

import { useLazyQuery } from '@apollo/client'
import {
  GET_IDEA_BY_ID,
  GET_IDEA_DETAILS,
  GET_IDEA_DETAILS_FOR_ADMIN,
} from '@/graphql/ideaQuery'
import { Idea } from '@/types/idea'
import { mapApiResponseToScores } from '@/service/ideas'
import { AddUser } from '@/components/organisation/users/components/AddUser'
import IdeaTabs from '@/components/ideas/IdeaTabs'
import { List } from '@/components/ideas/views/components/listColumns'

// Define the User type
interface User {
  id: number
  first_name: string | null
  last_name: string | null
  email: string
  role: {
    role: string
  }
}

// Define the IdeaData type
interface IdeaData {
  id: string
  status: number | null
}

interface DataTableRowActionsProps<TData> {
  row: Row<TData>
  tableType: 'user' | 'listview'
}

function DataTableRowActionsComponent<TData extends User | List | IdeaData>({
  row,
  tableType,
}: DataTableRowActionsProps<TData>) {
  const { currentUser } = useSelector((state: RootState) => state.auth)
  const { openDialog, closeDialog } = useDialog()
  const [getIdeaDetails] =
    currentUser?.role_id === 1
      ? useLazyQuery(GET_IDEA_BY_ID)
      : currentUser?.role_id === 2
      ? useLazyQuery(GET_IDEA_DETAILS_FOR_ADMIN)
      : useLazyQuery(GET_IDEA_DETAILS)

  const getEditableFields = useCallback(() => {
    if (tableType === 'user') {
      const task = row.original as User
      if (currentUser?.role_id === 1 || currentUser?.role_id === 2) {
        return {
          canEditName: true,
          canEditEmail: false,
          canEditRole: true,
        }
      }

      if (currentUser?.role_id === 3 && currentUser.id === task.id) {
        return {
          canEditName: true,
          canEditEmail: false,
          canEditRole: false,
        }
      }

      return {
        canEditName: false,
        canEditEmail: false,
        canEditRole: false,
      }
    }
    return undefined
  }, [currentUser?.id, currentUser?.role_id, row.original, tableType])

  const handleUserEditClick = useCallback(() => {
    if (tableType === 'user') {
      const task = row.original as User
      const editableFields = getEditableFields()

      openDialog({
        title: 'Edit User',
        description: '',
        content: (
          <AddUser
            initialData={{
              id: task.id,
              firstname: task.first_name || '',
              lastname: task.last_name || '',
              email: task.email,
              role: task.role.role,
            }}
            editableFields={
              editableFields || {
                canEditName: false,
                canEditEmail: false,
                canEditRole: false,
              }
            }
            isOwnProfile={currentUser?.id === task.id}
          />
        ),
        confirmAction: () => {
          closeDialog()
        },
        width: 'md:w-96',
        height: 'h-auto',
      })
    }
  }, [
    tableType,
    row.original,
    getEditableFields,
    openDialog,
    closeDialog,
    currentUser?.id,
  ])

  const handleIdeaEditClick = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      if (tableType === 'listview') {
        e.preventDefault()
        e.stopPropagation()

        const task = row.original as IdeaData

        const payload =
          currentUser?.role_id === 1
            ? {
                variables: { id: task.id },
              }
            : currentUser?.role_id === 2
            ? {
                variables: { idea_id: task.id, org_id: currentUser?.org_id },
              }
            : {
                variables: {
                  idea_id: task.id,
                  org_id: currentUser?.org_id,
                  user_id: currentUser?.id,
                },
              }

        const data = await getIdeaDetails(payload)

        const ideaDetails = data.data.suhrt_idea[0] as Idea
        closeDialog()
        openDialog({
          title: 'Edit Idea',
          description: '',
          content: (
            <IdeaTabs
              idea={ideaDetails}
              ideaScores={mapApiResponseToScores(
                ideaDetails?.idea_scores[0],
                ideaDetails?.idea_scores[0]?.weight_id
              )}
            />
          ),
          confirmAction: () => {
            closeDialog()
          },
          requireConfirmation: true,
          width: 'md:w-[95vw]',
          height: 'h-[90vh]',
          showFooter: true,
          showFooterButtons: true,
        })
      }
    },
    [
      tableType,
      row.original,
      currentUser?.org_id,
      getIdeaDetails,
      closeDialog,
      openDialog,
    ]
  )

  const canEdit = useMemo(() => {
    if (tableType === 'user') {
      const task = row.original as User
      return (
        currentUser?.role_id === 1 ||
        currentUser?.role_id === 2 ||
        (currentUser?.role_id === 3 && currentUser.id === task.id)
      )
    }
    return false
  }, [currentUser?.id, currentUser?.role_id, row.original, tableType])

  const isEditable = useMemo(() => {
    if (tableType === 'listview') {
      const task = row.original as IdeaData
      return task.status === 1 || task.status === 5
    }
    return false
  }, [row.original, tableType])

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant='ghost'
          className='flex h-8 w-8 p-0 data-[state=open]:bg-muted'
        >
          <DotsHorizontalIcon className='h-4 w-4' />
          <span className='sr-only'>Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align='end' className='w-[160px]'>
        {tableType === 'user' ? (
          <DropdownMenuItem
            onClick={handleUserEditClick}
            disabled={!canEdit}
            className={`${!canEdit ? 'cursor-not-allowed opacity-50' : ''}`}
          >
            Edit
          </DropdownMenuItem>
        ) : (
          <DropdownMenuItem
            onClick={handleIdeaEditClick}
            disabled={!isEditable}
          >
            Edit
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export const DataTableRowActions = React.memo(DataTableRowActionsComponent)
