import React, { useState } from 'react'
import { Input } from '@/components/ui/input'

interface GamificationFormValues {
  ideas: number
  comments: number
  replies: number
  likes: number
}

const GamificationForm: React.FC = () => {
  const [formValues, setFormValues] = useState<GamificationFormValues>({
    ideas: 0,
    comments: 0,
    replies: 0,
    likes: 0,
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: parseFloat(value) })
  }

  // const calculatePoints = () => {
  //   const points =
  //     formValues.ideas * 5 +
  //     formValues.comments * 2 +
  //     formValues.replies * 1 +
  //     formValues.likes * 0.5
  //   return points.toFixed(2)
  // }

  return (
    <div className='pt-0'>
      <h3 className='text-base font-medium mb-4'>Gamification</h3>
      <form className='grid md:grid-cols-2 gap-6 md:w-1/2'>
        {/* First Column */}
        <div className=''>
          <label className='block mb-2 '>Add Idea (5 points)</label>
          <Input
            type='number'
            name='ideas'
            value={formValues.ideas}
            onChange={handleChange}
            className='border border-border p-2 rounded w-full cursor-not-allowed'
            placeholder='Enter number of ideas'
            disabled
          />
        </div>

        {/* Second Column */}
        <div className=''>
          <label className='block mb-2 '>Comment (2 points)</label>
          <Input
            type='number'
            name='comments'
            value={formValues.comments}
            onChange={handleChange}
            className='border border-border p-2 rounded w-full cursor-not-allowed'
            placeholder='Enter number of comments'
            disabled
          />
        </div>

        {/* First Column */}
        <div className=''>
          <label className='block mb-2 '>Reply (1 point)</label>
          <Input
            type='number'
            name='replies'
            value={formValues.replies}
            onChange={handleChange}
            className='border border-border p-2 rounded w-full cursor-not-allowed'
            placeholder='Enter number of replies'
            disabled
          />
        </div>

        {/* Second Column */}
        <div className=''>
          <label className='block mb-2 '>Like (0.5 points)</label>
          <Input
            type='number'
            name='likes'
            value={formValues.likes}
            onChange={handleChange}
            className='border border-border p-2 rounded w-full cursor-not-allowed'
            placeholder='Enter number of likes'
            disabled
          />
        </div>
      </form>

      {/* Display total points */}
      {/* <div className='mt-6'>
        <h2 className='text-lg font-semibold'>
          Total Points: {calculatePoints()}
        </h2>
      </div> */}
    </div>
  )
}

export default GamificationForm
