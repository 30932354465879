'use client'

import * as React from 'react'
import { UserCircle } from 'lucide-react'
import { Controller, Control } from 'react-hook-form'
import { useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { GET_ROLES } from '@/graphql/userQuery'
import { hideLoader, showLoader } from '@/store/idea/loaderSlice'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

interface SelectRoleProps {
  control: Control<any>
  name: string
  disabled?: boolean
  initialData?: {
    email: string
    role?: string
  }
}

export function SelectRole({
  control,
  name,
  disabled = false,
  initialData,
}: SelectRoleProps) {
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state: RootState) => state.auth)

  const { data, loading, error } = useQuery(GET_ROLES, {
    onCompleted: () => dispatch(hideLoader()),
    onError: () => dispatch(hideLoader()),
  })

  React.useEffect(() => {
    if (loading) {
      dispatch(showLoader())
    }
  }, [loading, dispatch])

  if (error) {
    return <p className='text-red-500'>Error fetching roles: {error.message}</p>
  }

  const roles = data?.suhrt_roles || []

  const isOwnProfile = currentUser?.email === initialData?.email
  const hasEditPermission =
    currentUser?.role_id === 1 || currentUser?.role_id === 2
  const isDisabled = disabled || !hasEditPermission || isOwnProfile

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={initialData?.role || ''}
      render={({ field }) => {
        React.useEffect(() => {
          if (initialData?.role && initialData.role !== field.value) {
            field.onChange(initialData.role)
          }
        }, [initialData?.role])

        return (
          <Select
            disabled={isDisabled}
            onValueChange={field.onChange}
            value={field.value}
          >
            <SelectTrigger className='w-full'>
              <SelectValue placeholder='Select a role' />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {roles.map((role: { id: number; role: string }) => (
                  <SelectItem key={role.id} value={role.role}>
                    <div className='flex items-center'>
                      <UserCircle className='mr-2 h-4 w-4' />
                      <span>{role.role.replace('-', ' ').toUpperCase()}</span>
                    </div>
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        )
      }}
    />
  )
}
