import React, { useMemo, useCallback } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import mindmap from '../..//assets/mindmap.png'
import bcm from '../..//assets/bcm.png'
import ideas from '../..//assets/ideas.png'
import dashboard from '../..//assets/dashbord.png'
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  Cell,
  ComposedChart,
  Area,
} from 'recharts'
import Autoplay from 'embla-carousel-autoplay'
import { EmblaCarouselType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import { FaRegCirclePause, FaRegCirclePlay } from 'react-icons/fa6'
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md'
// Dot Button Component
const DotButton = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithRef<'button'>
>((props, ref) => {
  const { children, ...restProps } = props

  return (
    <button ref={ref} type='button' {...restProps}>
      {children}
    </button>
  )
})

// Custom hook for dot button functionality
const useDotButton = (
  emblaApi: EmblaCarouselType | undefined,
  onButtonClick?: (emblaApi: EmblaCarouselType) => void
) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [scrollSnaps, setScrollSnaps] = React.useState<number[]>([])

  const onDotButtonClick = React.useCallback(
    (index: number) => {
      if (!emblaApi) return
      emblaApi.scrollTo(index)
      if (onButtonClick) onButtonClick(emblaApi)
    },
    [emblaApi, onButtonClick]
  )

  const onInit = React.useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList())
  }, [])

  const onSelect = React.useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap())
  }, [])

  React.useEffect(() => {
    if (!emblaApi) return

    onInit(emblaApi)
    onSelect(emblaApi)
    emblaApi.on('reInit', onInit).on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onInit, onSelect])

  return {
    selectedIndex,
    scrollSnaps,
    onDotButtonClick,
  }
}

// Colors for the charts
const COLORS = ['#38bdff', '#ffbf00', '#65a30d', '#b91c1c', '#8b5cf6']

// Sample Data Generation Functions
const generateLineChartData = () => {
  return Array.from({ length: 7 }, (_, i) => ({
    month: `Month ${i + 1}`,
    draft: Math.floor(Math.random() * 50),
    inReview: Math.floor(Math.random() * 30),
    approved: Math.floor(Math.random() * 40),
    deleted: Math.floor(Math.random() * 20),
  }))
}

const generateBarChartData = () => {
  return [
    { name: 'Product', value: Math.floor(Math.random() * 100) },
    { name: 'Marketing', value: Math.floor(Math.random() * 100) },
    { name: 'Design', value: Math.floor(Math.random() * 100) },
    { name: 'Engineering', value: Math.floor(Math.random() * 100) },
    { name: 'Sales', value: Math.floor(Math.random() * 100) },
  ]
}

// const generatePieChartData = () => {
//   return [
//     { name: 'Completed', value: Math.floor(Math.random() * 40) },
//     { name: 'In Progress', value: Math.floor(Math.random() * 30) },
//     { name: 'Pending', value: Math.floor(Math.random() * 20) },
//     { name: 'On Hold', value: Math.floor(Math.random() * 10) },
//   ]
// }

const generateMixedChartData = () => {
  return Array.from({ length: 7 }, (_, i) => ({
    month: `Month ${i + 1}`,
    revenue: Math.floor(Math.random() * 1000),
    sales: Math.floor(Math.random() * 500),
    profit: Math.floor(Math.random() * 250),
  }))
}

export const DashboardCarousel: React.FC = () => {
  // Memoize chart data to prevent unnecessary re-renders
  const lineChartData = useMemo(generateLineChartData, [])
  const barChartData = useMemo(generateBarChartData, [])
  //const pieChartData = useMemo(generatePieChartData, [])
  const mixedChartData = useMemo(generateMixedChartData, [])

  // Define a consistent height for all charts and images
  const contentHeight = 350 // Set a consistent height

  // Render functions for each chart type
  const renderLineChart = () => (
    <div className='w-full h-full flex items-center justify-center'>
      <ResponsiveContainer width='100%' height={contentHeight}>
        <LineChart data={lineChartData}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='month' />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type='monotone' dataKey='draft' stroke={COLORS[0]} />
          <Line type='monotone' dataKey='inReview' stroke={COLORS[1]} />
          <Line type='monotone' dataKey='approved' stroke={COLORS[2]} />
          <Line type='monotone' dataKey='deleted' stroke={COLORS[3]} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )

  const renderBarChart = () => (
    <div className='w-full h-full flex items-center justify-center'>
      <ResponsiveContainer width='100%' height={contentHeight}>
        <BarChart data={barChartData}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' />
          <YAxis />
          <Tooltip />
          <Bar dataKey='value'>
            {barChartData.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )

  // const renderPieChart = () => (
  //   <div className='w-full h-full flex items-center justify-center'>
  //     <ResponsiveContainer width='100%' height={contentHeight}>
  //       <PieChart>
  //         <Pie
  //           data={pieChartData}
  //           dataKey='value'
  //           nameKey='name'
  //           cx='50%'
  //           cy='50%'
  //           outerRadius={contentHeight / 4}
  //         >
  //           {pieChartData.map((_, index) => (
  //             <Cell
  //               key={`cell-${index}`}
  //               fill={COLORS[index % COLORS.length]}
  //             />
  //           ))}
  //         </Pie>
  //         <Tooltip />
  //         <Legend />
  //       </PieChart>
  //     </ResponsiveContainer>
  //   </div>
  // )

  const renderMixedChart = () => (
    <div className='w-full h-full flex items-center justify-center'>
      <ResponsiveContainer width='100%' height={contentHeight}>
        <ComposedChart data={mixedChartData}>
          <XAxis dataKey='month' />
          <YAxis />
          <Tooltip />
          <Legend />
          <Area
            type='monotone'
            dataKey='revenue'
            fill={COLORS[0]}
            stroke={COLORS[0]}
            opacity={0.7}
          />
          <Bar dataKey='sales' barSize={20} fill={COLORS[1]} />
          <Line type='monotone' dataKey='profit' stroke={COLORS[2]} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )

  // Image rendering components
  const renderImage1 = () => (
    <div className='w-full h-full flex justify-center items-center'>
      <div className='relative w-full h-full flex justify-center items-center'>
        <img
          src={mindmap}
          alt='Mind Map'
          className='object-contain max-h-full max-w-full rounded-md'
          style={{ height: contentHeight }}
        />
      </div>
    </div>
  )

  const renderImage2 = () => (
    <div className='w-full h-full flex justify-center items-center'>
      <div className='relative w-full h-full flex justify-center items-center'>
        <img
          src={bcm}
          alt='Business Canvas Model'
          className='object-contain max-h-full max-w-full rounded-md'
          style={{ height: contentHeight }}
        />
      </div>
    </div>
  )
  const renderImage3 = () => (
    <div className='w-full h-full flex justify-center items-center'>
      <div className='relative w-full h-full flex justify-center items-center'>
        <img
          src={ideas}
          alt='Business Canvas Model'
          className='object-contain max-h-full max-w-full rounded-md'
          style={{ height: contentHeight }}
        />
      </div>
    </div>
  )
  const renderImage4 = () => (
    <div className='w-full h-full flex justify-center items-center'>
      <div className='relative w-full h-full flex justify-center items-center'>
        <img
          src={dashboard}
          alt='Business Canvas Model'
          className='object-contain max-h-full max-w-full rounded-md'
          style={{ height: contentHeight }}
        />
      </div>
    </div>
  )
  // Slides configuration - adding the images to the beginning and end
  const slides = [
    {
      title: 'Design Thinking based Mind Map',
      chart: renderImage1(),
      description: 'Design Thinking based Mind Map',
    },
    {
      title: 'Business Plan',
      chart: renderImage2(),
      description: 'Business Plan',
    },
    {
      title: 'Ideas',
      chart: renderImage3(),
      description: 'Submitted Ideas by Status',
    },
    {
      title: 'Dashborad',
      chart: renderImage4(),
      description: 'Ideas Dashborad',
    },
    {
      title: 'Ideas Trend',
      chart: renderLineChart(),
      description: 'Monthly idea lifecycle progression',
    },
    {
      title: 'Ideas by Department',
      chart: renderBarChart(),
      description: 'Idea distribution across departments',
    },
    // {
    //   title: 'Ideas by Status',
    //   chart: renderPieChart(),
    //   description: 'Current project status breakdown',
    // },
    {
      title: 'Performance Metrics',
      chart: renderMixedChart(),
      description: 'Combined revenue, sales, and profit trends',
    },
  ]

  const [isPlaying, setIsPlaying] = React.useState(true)
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { align: 'start', loop: true },
    [Autoplay({ delay: 3000, stopOnInteraction: false })]
  )
  // Toggle Play/Pause
  const togglePlayPause = useCallback(() => {
    if (!emblaApi) return
    const autoplay = emblaApi?.plugins()?.autoplay
    if (!autoplay) return

    if (isPlaying) {
      autoplay.stop()
    } else {
      autoplay.play()
    }
    setIsPlaying((prev) => !prev)
  }, [emblaApi, isPlaying])
  // Left and Right Navigation
  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])
  // Dot Button Hook
  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi)

  return (
    <div className='w-full overflow-hidden'>
      <div className='embla' ref={emblaRef}>
        <div className='embla__container flex'>
          {slides.map((slide, index) => (
            <div key={index} className='embla__slide flex-[0_0_100%] px-2'>
              <Card className='w-full p-4 border-0 dark:border dark:border-border'>
                <CardHeader className='p-2 pt-0'>
                  <CardTitle className='mb-0 p-0'>{slide.title}</CardTitle>
                </CardHeader>
                <CardContent
                  className='flex flex-col items-center p-0'
                  style={{ height: `${contentHeight}px` }}
                >
                  {slide.chart}
                  {/* <p className='text-sm text-muted-foreground mt-2'>
                    {slide.description}
                  </p> */}
                </CardContent>
              </Card>
            </div>
          ))}
        </div>
      </div>
      {/* Play/Pause Button */}
      <div className='flex justify-center mt-4 items-center gap-4'>
        <button
          onClick={togglePlayPause}
          className='w-6 h-6 text-foreground hover:text-primary flex items-center justify-center'
        >
          {isPlaying ? (
            <FaRegCirclePause className='text-lg' />
          ) : (
            <FaRegCirclePlay className='text-lg' />
          )}
        </button>
        {/* Left Arrow */}
        <button
          onClick={scrollPrev}
          className='p-1 transition-transform duration-300 hover:-translate-x-1'
        >
          <MdArrowBackIosNew className='text-base text-foreground hover:text-primary/80' />
        </button>
        {/* Dot Navigation */}
        <div className='embla__controls'>
          <div className='embla__dots flex gap-2'>
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                onClick={() => onDotButtonClick(index)}
                className={`
                w-2 h-2 rounded-full cursor-pointer 
                ${
                  index === selectedIndex
                    ? 'bg-primary'
                    : 'bg-foreground hover:bg-primary/50'
                }
              `}
              />
            ))}
          </div>
        </div>
        {/* Right Arrow */}
        <button
          onClick={scrollNext}
          className='p-1 transition-transform duration-300 hover:translate-x-1'
        >
          <MdArrowForwardIos className='text-base text-foreground hover:text-primary/80' />
        </button>
      </div>
    </div>
  )
}

export default DashboardCarousel
