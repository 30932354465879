import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { useToast } from '@/components/ui/use-toast'
import { BOOK_DEMO } from '@/graphql/userQuery'
import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { hideLoader, showLoader } from '@/store/idea/loaderSlice'
import { useDialog } from '../global-dialog/DialogContext'

const demoFormSchema = z.object({
  name: z.string().min(2, 'Name must be at least 2 characters'),
  email: z.string().email('Please enter a valid email address'),
  company: z.string().min(2, 'Company must be at least 5 characters'),
  phone: z.string().min(10, 'Phone number must be atleast 10 characters'),
  preferred_date: z.string().refine(
    (value) => {
      const today = new Date().toISOString().split('T')[0]
      return value >= today // Ensure the date is today or in the future
    },
    { message: 'Preferred date must not be in the past' }
  ),
  preferred_time: z.string().refine(
    (value) => {
      const timeRegex = /^\d{2}:\d{2}$/
      return timeRegex.test(value)
    },
    { message: 'Preferred time must be in HH:MM format' }
  ),
  message: z.string().min(10, 'Message must be at least 10 characters'),
})

type DemoFormValues = z.infer<typeof demoFormSchema>

export function DemoForm() {
  const { closeDialog } = useDialog()
  const { toast } = useToast()
  const [bookDemo] = useMutation(BOOK_DEMO)
  const dispatch = useDispatch()
  const form = useForm<DemoFormValues>({
    resolver: zodResolver(demoFormSchema),
    defaultValues: {
      name: '',
      email: '',
      company: '',
      phone: '',
      preferred_date: '',
      preferred_time: '',
      message: '',
    },
  })

  async function onSubmit(data: DemoFormValues) {
    dispatch(showLoader())
    const timezone = new Intl.DateTimeFormat('en', {
      timeZoneName: 'short',
    })
      .formatToParts(new Date())
      .find(part => part.type === 'timeZoneName')?.value;

  // Append timezone to preferred_time
  const updatedData = {
    ...data,
    preferred_time: `${data.preferred_time} ${timezone}`, // Append the timezone
  }
    try {
      const bookDemoResponse = await bookDemo({
        variables: {
          input: updatedData,
        },
      })
      dispatch(hideLoader())
      const bookDemoID =
        bookDemoResponse.data?.insert_suhrt_demo_request?.returning[0]?.id
      if (bookDemoID) {
        toast({
          variant: 'success',
          description: 'Demo booked successfully',
        })
      }
      closeDialog()
    } catch (error) {
      dispatch(hideLoader())
      console.error('Failed to book demo:', error)
      toast({
        variant: 'destructive',
        description: 'Falied to book demo.',
      })
      closeDialog()
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-6'>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
          {/* Name */}
          <FormField
            control={form.control}
            name='name'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input placeholder='Your name' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Email */}
          <FormField
            control={form.control}
            name='email'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input placeholder='your@email.com' type='email' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Company */}
          <FormField
            control={form.control}
            name='company'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Company Name</FormLabel>
                <FormControl>
                  <Input placeholder='Your company name' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Phone */}
          <FormField
            control={form.control}
            name='phone'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Phone Number</FormLabel>
                <FormControl>
                  <Input placeholder='Your phone no.' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Preferred Date */}
          <FormField
            control={form.control}
            name='preferred_date'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Preferred Date</FormLabel>
                <FormControl>
                  <Input
                    type='date'
                    placeholder='Select your preferred date'
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Preferred Time */}
          <FormField
            control={form.control}
            name='preferred_time'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Preferred Time</FormLabel>
                <FormControl>
                  <Input placeholder='Enter your preferred time' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {/* Message */}
        <FormField
          control={form.control}
          name='message'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Message</FormLabel>
              <FormControl>
                <Textarea
                  placeholder='Tell us more about your inquiry...'
                  className='resize-none'
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* Submit Button */}
        <Button type='submit' className='text-white px-4 py-2' size='sm'>
          Submit
        </Button>
      </form>
    </Form>
  )
}
