'use client'

import type React from 'react'
import { useState, useEffect } from 'react'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { Label } from '@/components/ui/label'
import { Button } from '@/components/ui/button'

interface IdeaVisibilityRadioProps {
  value?: string
  onChange?: (value: string) => void
  className?: string
  defaultValue?: string
  insideDialog?: boolean
}

const IdeaVisibilityRadio: React.FC<IdeaVisibilityRadioProps> = ({
  value,
  onChange,
  className = '',
  defaultValue = '1',
  insideDialog = false,
}) => {
  const [internalValue, setInternalValue] = useState(value || defaultValue) // Committed value
  const [selectedValue, setSelectedValue] = useState(value || defaultValue) // Temporary selection
  const [isSelectionChanged, setIsSelectionChanged] = useState(false) // Track changes

  useEffect(() => {
    if (value !== undefined) {
      setInternalValue(value)
      setSelectedValue(value) // Sync external changes
    }
  }, [value])

  const handleSelectionChange = (newValue: string) => {
    setSelectedValue(newValue) // Update only temporary state
    setIsSelectionChanged(newValue !== internalValue) // Track if selection is different from committed value
  }

  const handleConfirmClick = () => {
    setInternalValue(selectedValue) // Commit the selection
    setIsSelectionChanged(false) // Reset change tracking
    if (onChange) {
      onChange(selectedValue)
    }
  }

  return (
    <div className={`flex flex-col items-start gap-4 mt-2 ${className}`}>
      {!insideDialog && (
        <Label className='text-base font-semibold whitespace-nowrap'>
          Idea Visibility:
        </Label>
      )}

      <RadioGroup
        value={selectedValue} // Show selection
        onValueChange={handleSelectionChange} // Update only temporary state
        className='flex items-center gap-6'
      >
        <div className='flex items-center gap-2'>
          <RadioGroupItem value='1' id='collaborators' />
          <Label htmlFor='collaborators' className='font-normal'>
            Me and Collaborators
          </Label>
        </div>
        <div className='flex items-center gap-2'>
          <RadioGroupItem value='2' id='org' />
          <Label htmlFor='org' className='font-normal'>
            Across Organization
          </Label>
        </div>
      </RadioGroup>

      {isSelectionChanged && (
        <div className='flex w-full justify-end'>
          <Button onClick={handleConfirmClick} className='mt-2'>
            Confirm Selection
          </Button>
        </div>
      )}
    </div>
  )
}

export default IdeaVisibilityRadio
