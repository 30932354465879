import { User } from '@/types/user'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AuthState {
  isLoggedIn: boolean
  users: User[]
  currentUser: User | null
  showGetStarted: boolean
}

const initialState: AuthState = {
  users: [],
  isLoggedIn: false,
  currentUser: null,
  showGetStarted: true,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state) => {
      state.isLoggedIn = true
    },
    logout: (state) => {
      state.isLoggedIn = false
    },
    setUsers(state, action: PayloadAction<User[]>) {
      state.users = action.payload
    },
    setCurrentUser(state, action: PayloadAction<User | null>) {
      state.currentUser = action.payload
    },
    hideGetStarted(state) {
      state.showGetStarted = false
    },
    // Optional: Reset get started state when user logs out
    resetGetStarted(state) {
      state.showGetStarted = true
    },
  },
})

export const {
  login,
  logout,
  setUsers,
  setCurrentUser,
  hideGetStarted,
  resetGetStarted,
} = authSlice.actions
export const authReducer = authSlice.reducer
