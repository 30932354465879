import { useState } from 'react'
import IconWrapper from '@/components/utility/IconWrapper'
import { FaRegComments } from 'react-icons/fa'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import Comments from '../../idea-summery/Comments'

const CommentsPopup = ({ ideaId }: { ideaId: string }) => {
  const [isOpen, setIsOpen] = useState(false)

  const openPopup = () => setIsOpen(true)

  return (
    <>
      <IconWrapper
        className='  ml-2 hover:text-primary text-base h-6 w-6 cursor-pointer'
        onClick={openPopup}
      >
        <FaRegComments className='text-[1rem] transition-all dark:text-muted-foreground' />
      </IconWrapper>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogHeader></DialogHeader>
        <DialogContent className='md:w-[40rem] overflow-auto w-[90%] md:p-4 px-2'>
          <DialogHeader>
            <DialogTitle>Add comments</DialogTitle>
          </DialogHeader>
          <Comments ideaId={ideaId} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CommentsPopup
