import { Button } from '@/components/ui/button'
import { motion, useScroll, useTransform } from 'framer-motion'
import { Facebook, Linkedin, Printer } from 'lucide-react'

interface BlogHeaderProps {
  currentSection: string
}

export function BlogHeader({ currentSection }: BlogHeaderProps) {
  const { scrollYProgress }: any = useScroll()
  const opacity = useTransform(scrollYProgress, [0, 0.1], [0, 1])
  const y = useTransform(scrollYProgress, [0, 0.1], [-100, 0])
  const shareOnLinkedIn = () => {
    const url = window.location.href
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        url
      )}`,
      '_blank'
    )
  }

  const shareOnFacebook = () => {
    const url = window.location.href
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      '_blank'
    )
  }
  return (
    <motion.div
      style={{ opacity, y }}
      className='fixed top-[64px] left-0 right-0 z-50 bg-muted backdrop-blur-md shadow-sm'
    >
      <div className='container mx-auto max-w-screen-2xl  md:px-8 px-4'>
        <div className='h-16 flex items-center justify-between'>
          <h2 className='font-semibold truncate text-lg'>{currentSection}</h2>
          <div className='flex items-center justify-center lg:hidden block'>
            <span className='text-lg font-semibold mr-2'>Share</span>
            <div className='flex gap-2'>
              <Button variant='outline' size='icon' onClick={shareOnLinkedIn}>
                <Linkedin className='h-4 w-4' />
              </Button>
              <Button variant='outline' size='icon' onClick={shareOnFacebook}>
                <Facebook className='h-4 w-4' />
              </Button>
              <Button
                variant='outline'
                size='icon'
                onClick={() => window.print()}
              >
                <Printer className='h-4 w-4' />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
