import { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { DataTableColumnHeader } from '@/components/shared/data-table/DataTableColumnHeader'
import { DataTableRowActions } from '@/components/shared/data-table/DataTableRowActions'
import { formatDate } from '@/helpers/utils'

export type User = {
  id: number
  username: string
  first_name: string | null
  last_name: string | null
  email: string
  role: { id: number; role: string }
  last_logged_in: string | null
  invitation_status: string
  invitation_sent_time: string | null
  invitation_expiry: string | null
}

export const useUserColumns = () => {
  const userColumns: ColumnDef<User>[] = useMemo(
    () => [
      {
        accessorKey: 'username',
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title='User Name' />
        ),
        cell: ({ row }) => <div className=''>{row.getValue('username')}</div>,
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: 'first_name',
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title='First Name' />
        ),
        cell: ({ row }) => (
          <div className='flex space-x-2'>
            <span className=' truncate font-medium'>
              {row.getValue('first_name')}
            </span>
          </div>
        ),
      },
      {
        accessorKey: 'last_name',
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title='Last Name' />
        ),
        cell: ({ row }) => (
          <div className='flex space-x-2'>
            <span className=' truncate font-medium'>
              {row.getValue('last_name')}
            </span>
          </div>
        ),
      },
      {
        accessorKey: 'email',
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title='Email' />
        ),
        cell: ({ row }) => (
          <div className='flex space-x-2'>
            <span className='truncate font-medium'>
              {row.getValue('email')}
            </span>
          </div>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        },
      },
      {
        accessorKey: 'role',
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title='Role' />
        ),
        cell: ({ row }) => {
          const role = row.getValue('role') as { id: number; role: string }
          return (
            <div className='flex space-x-2'>
              <span className='truncate font-medium'>
                {role ? role.role : 'No role assigned'}
              </span>
            </div>
          )
        },
        filterFn: (row, id, value) => {
          const role = row.getValue(id) as { id: number; role: string }
          return role?.role ? value.includes(role.role) : false
        },
      },
      {
        accessorKey: 'last_logged_in',
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title='Last Logged In' />
        ),
        cell: ({ row }) => {
          const lastLoggedIn = row.getValue('last_logged_in')
          return (
            <div className='flex space-x-2'>
              <span className='truncate font-medium'>
                {lastLoggedIn !== null
                  ? formatDate(row.getValue('last_logged_in'))
                  : 'null'}
              </span>
            </div>
          )
        },
      },
      {
        accessorKey: 'invitation_status',
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title='Invitation Status' />
        ),
        cell: ({ row }) => (
          <div className='flex space-x-2'>
            <span className='truncate font-medium'>
              {row.getValue('invitation_status')}
            </span>
          </div>
        ),
      },
      {
        accessorKey: 'invitation_sent_time',
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title='Invitation Sent Time' />
        ),
        cell: ({ row }) => {
          const invitationSentTime = row.getValue('invitation_sent_time')
          return (
            <div className='flex space-x-2'>
              <span className=' truncate font-medium'>
                {invitationSentTime !== null
                  ? formatDate(row.getValue('invitation_sent_time'))
                  : 'null'}
              </span>
            </div>
          )
        },
      },
      {
        accessorKey: 'invitation_expiry',
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title='Invitation Expiry' />
        ),
        cell: ({ row }) => {
          const invitationExpiry = row.getValue('invitation_expiry')

          return (
            <div className='flex space-x-2'>
              <span className=' truncate font-medium'>
                {invitationExpiry !== null
                  ? formatDate(row.getValue('invitation_expiry'))
                  : null}
              </span>
            </div>
          )
        },
      },
      {
        id: 'actions',
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title='Actions' />
        ),
        cell: ({ row }) => <DataTableRowActions row={row} tableType='user' />,
      },
    ],
    []
  )

  return userColumns
}
