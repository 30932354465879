'use client'

import React, { useRef, useEffect, useState, useCallback } from 'react'
import * as d3 from 'd3'
import cloud from 'd3-cloud'

interface WordCloudChartProps {
  data: Array<{ name: string; value: number }>
}

const WordCloudChart: React.FC<WordCloudChartProps> = ({ data }) => {
  const svgRef = useRef<SVGSVGElement>(null)
  const [width, setWidth] = useState<number>(600) // Default width
  const height = 450 // Fixed height
  const colors = ['#38bdff', '#ffbf00', '#64748b']

  const handleResize = useCallback(() => {
    if (svgRef.current) {
      setWidth(svgRef.current.parentElement?.clientWidth || 600) // Get parent width
    }
  }, [])
  // Dynamically load Nunito font
  useEffect(() => {
    const fontLink = document.createElement('link')
    fontLink.href =
      'https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap'
    fontLink.rel = 'stylesheet'
    document.head.appendChild(fontLink)

    return () => {
      document.head.removeChild(fontLink)
    }
  }, [])
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  useEffect(() => {
    if (!data || data.length === 0) {
      console.warn('No data provided for word cloud')
      return
    }

    if (!svgRef.current) {
      console.error('SVG ref is not available')
      return
    }

    try {
      const svg = d3.select(svgRef.current)
      svg.selectAll('*').remove()

      // Define font size scale based on screen size
      const maxFontSize = width < 400 ? 40 : 45 // Smaller fonts for smaller screens
      const minFontSize = width < 400 ? 10 : 15

      const maxValue = d3.max(data, (d) => d.value) || 1
      const sizeScale = d3
        .scaleLinear()
        .domain([1, maxValue])
        .range([minFontSize, maxFontSize])

      const words = data.map(({ name, value }) => ({
        text: name,
        size: sizeScale(value),
        rotate: Math.random() > 0.5 ? 0 : 90,
      }))

      const layout = cloud()
        .size([width, height])
        .words(words)
        .padding(5)
        .rotate(() => (Math.random() > 0.5 ? 0 : 90))
        .font('Poppins')
        .fontSize((d: any) => d.size)
        .on('end', draw)

      layout.start()

      function draw(words: any[]) {
        svg
          .attr('width', width)
          .attr('height', height)
          .append('g')
          .attr(
            'transform',
            `translate(${width / 2}, ${height / 2})` // Center alignment
          )
          .selectAll('text')
          .data(words)
          .enter()
          .append('text')
          .style('font-size', (d) => `${d.size}px`)
          .style('font-family', 'Nunito')
          .style('fill', (_, i) => colors[i % colors.length])
          .attr('text-anchor', 'middle')
          .attr(
            'transform',
            (d) => `translate(${d.x},${d.y})rotate(${d.rotate})`
          )
          .text((d) => d.text)
      }
    } catch (error) {
      console.error('Error creating word cloud:', error)
    }
  }, [data, width, height])

  return (
    <svg
      ref={svgRef}
      style={{
        width: '100%',
        height: `${height}px`,
      }}
    ></svg>
  )
}

export default WordCloudChart
