'use client'

import React, { useState, useRef, useEffect } from 'react'
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import { Maximize2, Minimize2 } from 'lucide-react'

interface MindMapData {
  title: string
  subtitle?: string
  central?: {
    label: string
    color: string
    icon?: string
  }
  mind_map: Record<string, Record<string, string[]>>
  icons?: Record<string, string>
}

interface MindMapProps {
  data: MindMapData
}

interface MindMapNode {
  id: string
  label: string
  color?: string
  textColor?: string
  bgColor?: string
  size?: number
  icon?: string
  children?: MindMapNode[]
}

const convertDataToNodeStructure = (data: MindMapData) => {
  if (!data || !data.mind_map) {
    return {
      central: { label: 'MINDMAP', color: '#00008b' },
      nodes: [],
    }
  }

  const central = data.central || { label: 'MINDMAP', color: '#00008b' }
  // Updated color palette to match screenshot
  const colorPalette = ['#5EBEC4', '#7A9D54', '#A52A2A', '#FFA500', '#1E3A8A']

  const nodes: MindMapNode[] = []

  Object.entries(data.mind_map).forEach(
    ([category, subcategories], categoryIndex) => {
      const mainColor = colorPalette[categoryIndex % colorPalette.length]
      const mainNode: MindMapNode = {
        id: category.toLowerCase().replace(/\s+/g, '-'),
        label: `${categoryIndex + 1}. ${category}`,
        color: mainColor,
        size: 60,
        children: [],
      }

      Object.entries(subcategories).forEach(
        ([subcategory, items], _subcategoryIndex) => {
          const subNode: MindMapNode = {
            id: subcategory.toLowerCase().replace(/\s+/g, '-'),
            label: subcategory,
            color: mainColor, // Use main color for children too
            children: [],
          }

          items.forEach((item) => {
            subNode.children?.push({
              id: item.toLowerCase().replace(/\s+/g, '-'),
              label: item,
              color: mainColor, // Use main color for grandchildren too
              size: 40,
            })
          })

          mainNode.children?.push(subNode)
        }
      )

      nodes.push(mainNode)
    }
  )

  return { central, nodes }
}

const MindMap: React.FC<MindMapProps> = ({ data }) => {
  const [pan, setPan] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [isDragging, setIsDragging] = useState(false)
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 })
  const [isExpanded, setIsExpanded] = useState(false)
  const [dimensions, setDimensions] = useState({ width: 1200, height: 800 })
  const svgRef = useRef<SVGSVGElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  // Add a default empty data object if data is undefined
  const safeData: MindMapData = data || {
    title: 'Mind Map',
    mind_map: {},
  }

  const { central, nodes } = convertDataToNodeStructure(safeData)

  // Update dimensions based on parent container size or full screen mode
  useEffect(() => {
    const updateDimensions = () => {
      if (isExpanded) {
        // For expanded mode, use window dimensions
        setDimensions({
          width: window.innerWidth * 0.95,
          height: window.innerHeight * 0.9,
        })
      } else if (containerRef.current) {
        // For normal mode, use container dimensions but ensure minimum size
        const { width, height } = containerRef.current.getBoundingClientRect()
        setDimensions({
          width: Math.max(width, 800),
          height: Math.max(height, 600),
        })
      }
    }

    updateDimensions()

    // Add resize event listener
    window.addEventListener('resize', updateDimensions)

    // Create ResizeObserver for container
    const resizeObserver = new ResizeObserver(updateDimensions)

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current)
    }

    return () => {
      window.removeEventListener('resize', updateDimensions)
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current)
      }
    }
  }, [isExpanded])

  // Auto-adjust zoom based on node count and screen size
  useEffect(() => {
    const autoAdjustZoom = () => {
      const nodeCount = nodes.length

      // Count total nodes including children and grandchildren
      let totalNodeCount = nodeCount
      nodes.forEach((node) => {
        if (node.children) {
          totalNodeCount += node.children.length
          node.children.forEach((child) => {
            if (child.children) {
              totalNodeCount += child.children.length
            }
          })
        }
      })

      // Base zoom calculation
      let calculatedZoom = 1

      // Adjust based on total node count
      if (totalNodeCount > 15) {
        calculatedZoom = 0.9
      } else if (totalNodeCount > 25) {
        calculatedZoom = 0.8
      } else if (totalNodeCount > 35) {
        calculatedZoom = 0.7
      }

      // Adjust based on screen dimensions
      const minDimension = Math.min(dimensions.width, dimensions.height)
      if (minDimension < 600) {
        calculatedZoom *= 0.8
      } else if (minDimension < 800) {
        calculatedZoom *= 0.9
      }

      // Ensure zoom is within reasonable bounds
      calculatedZoom = Math.max(0.4, Math.min(calculatedZoom, 1.5))

      setZoom(calculatedZoom)
    }

    autoAdjustZoom()
  }, [nodes.length, dimensions])

  // Reset view when toggling expanded mode
  useEffect(() => {
    resetView()
  }, [isExpanded])

  const getMultilineText = (
    text: string,
    x: number,
    y: number,
    fontSize: number,
    textAnchor = 'middle',
    color = 'black',
    fontWeight = 'normal'
  ) => {
    const words = text.split(' ')
    const lines: string[] = []
    let currentLine = ''

    words.forEach((word) => {
      if (currentLine === '') {
        currentLine = word
      } else if ((currentLine + ' ' + word).length <= 12) {
        currentLine += ' ' + word
      } else {
        lines.push(currentLine)
        currentLine = word
      }
    })

    if (currentLine !== '') {
      lines.push(currentLine)
    }

    return (
      <>
        {lines.map((line, i) => (
          <text
            key={i}
            x={x}
            y={y + i * (fontSize + 2)}
            fontSize={fontSize}
            textAnchor={textAnchor}
            fill={color}
            fontWeight={fontWeight}
          >
            {line}
          </text>
        ))}
      </>
    )
  }

  const calculateMainNodePositions = () => {
    const nodeCount = nodes.length
    const positions: Record<string, { x: number; y: number }> = {}
    const centerX = dimensions.width / 2
    const centerY = dimensions.height / 2

    // Create a square layout instead of circular
    const squareSize = Math.min(dimensions.width, dimensions.height) * 0.7
    const halfSize = squareSize / 2

    // Position nodes in a square pattern
    if (nodeCount <= 4) {
      // For 4 or fewer nodes, place them at the corners of a square
      const positions = [
        { x: centerX - halfSize, y: centerY - halfSize }, // top-left
        { x: centerX + halfSize, y: centerY - halfSize }, // top-right
        { x: centerX + halfSize, y: centerY + halfSize }, // bottom-right
        { x: centerX - halfSize, y: centerY + halfSize }, // bottom-left
      ]

      nodes.forEach((node: any, index) => {
        positions[node.id] = positions[index]
      })
    } else {
      // For 5 or more nodes, distribute them evenly
      const topCount = Math.ceil(nodeCount / 4)
      const rightCount = Math.ceil((nodeCount - topCount) / 3)
      const bottomCount = Math.ceil((nodeCount - topCount - rightCount) / 2)
      const leftCount = nodeCount - topCount - rightCount - bottomCount

      let currentIndex = 0

      // Top edge
      for (let i = 0; i < topCount; i++) {
        const node = nodes[currentIndex++]
        if (!node) break
        const x = centerX - halfSize + (squareSize * (i + 0.5)) / topCount
        const y = centerY - halfSize
        positions[node.id] = { x, y }
      }

      // Right edge
      for (let i = 0; i < rightCount; i++) {
        const node = nodes[currentIndex++]
        if (!node) break
        const x = centerX + halfSize
        const y = centerY - halfSize + (squareSize * (i + 0.5)) / rightCount
        positions[node.id] = { x, y }
      }

      // Bottom edge
      for (let i = 0; i < bottomCount; i++) {
        const node = nodes[currentIndex++]
        if (!node) break
        const x = centerX + halfSize - (squareSize * (i + 0.5)) / bottomCount
        const y = centerY + halfSize
        positions[node.id] = { x, y }
      }

      // Left edge
      for (let i = 0; i < leftCount; i++) {
        const node = nodes[currentIndex++]
        if (!node) break
        const x = centerX - halfSize
        const y = centerY + halfSize - (squareSize * (i + 0.5)) / leftCount
        positions[node.id] = { x, y }
      }
    }

    return positions
  }

  const calculateChildPositions = (
    parentPosition: { x: number; y: number },
    childCount: number,
    nodeSize = 15,
    centralPos: { x: number; y: number }
  ) => {
    if (childCount === 0) return []

    const positions = []
    const dirX = parentPosition.x - centralPos.x
    const dirY = parentPosition.y - centralPos.y
    const dirLength = Math.sqrt(dirX * dirX + dirY * dirY)
    const normDirX = dirX / dirLength
    const normDirY = dirY / dirLength
    const baseDistance = nodeSize * 4.5 // Increased from 3.5 to 4.5
    const arcAngle = Math.min(Math.PI * 0.9, childCount * 0.4) // Increased arc angle

    for (let i = 0; i < childCount; i++) {
      const angle = -arcAngle / 2 + (i / (childCount - 1 || 1)) * arcAngle
      const rotatedDirX =
        normDirX * Math.cos(angle) - normDirY * Math.sin(angle)
      const rotatedDirY =
        normDirX * Math.sin(angle) + normDirY * Math.cos(angle)
      const posX = parentPosition.x + rotatedDirX * baseDistance
      const posY = parentPosition.y + rotatedDirY * baseDistance
      positions.push({ x: posX, y: posY })
    }

    return positions
  }

  const calculateGrandchildPositions = (
    childPos: { x: number; y: number },
    grandchildCount: number,
    parentVector: { x: number; y: number },
    _childSize = 15
  ) => {
    if (grandchildCount === 0) return []

    const positions = []
    const baseDistance = 100 // Increased from 75 to 100
    const vectorLength = Math.sqrt(
      parentVector.x * parentVector.x + parentVector.y * parentVector.y
    )
    const normVecX = parentVector.x / vectorLength
    const normVecY = parentVector.y / vectorLength
    const arcAngle = Math.min(Math.PI * 0.9, grandchildCount * 0.5) // Increased arc angle

    for (let i = 0; i < grandchildCount; i++) {
      const angle = -arcAngle / 2 + (i / (grandchildCount - 1 || 1)) * arcAngle
      const rotX = normVecX * Math.cos(angle) - normVecY * Math.sin(angle)
      const rotY = normVecX * Math.sin(angle) + normVecY * Math.cos(angle)
      const distanceFactor = 1 + grandchildCount / 8
      positions.push({
        x: childPos.x + rotX * baseDistance * distanceFactor,
        y: childPos.y + rotY * baseDistance * distanceFactor,
      })
    }

    return positions
  }

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const handleMouseDown = (e: React.MouseEvent) => {
    if (e.button === 0) {
      setIsDragging(true)
      setDragStart({ x: e.clientX - pan.x, y: e.clientY - pan.y })
    }
  }

  const handleMouseMove = (e: React.MouseEvent) => {
    if (isDragging) {
      setPan({
        x: e.clientX - dragStart.x,
        y: e.clientY - dragStart.y,
      })
    }
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const handleWheel = (e: React.WheelEvent) => {
    // Explicitly set passive to false and prevent default only for zoom interaction
    if (e.ctrlKey) {
      e.preventDefault()

      const delta = e.deltaY < 0 ? 0.1 : -0.1
      const newZoom = Math.max(0.5, Math.min(2, zoom + delta))
      setZoom(newZoom)
    }
  }

  const resetView = () => {
    setPan({ x: 0, y: 0 })
    setZoom(1)
  }

  const fitToView = () => {
    // Calculate the bounds of all nodes
    const nodePositions = calculateMainNodePositions()
    const centralPos = { x: dimensions.width / 2, y: dimensions.height / 2 }

    // Start with central position
    let minX = centralPos.x
    let maxX = centralPos.x
    let minY = centralPos.y
    let maxY = centralPos.y

    // Check main nodes
    nodes.forEach((node) => {
      const position = nodePositions[node.id]
      const nodeRadius = node.size || 60

      minX = Math.min(minX, position.x - nodeRadius)
      maxX = Math.max(maxX, position.x + nodeRadius)
      minY = Math.min(minY, position.y - nodeRadius)
      maxY = Math.max(maxY, position.y + nodeRadius)

      // Check children
      if (node.children?.length) {
        const childPositions = calculateChildPositions(
          position,
          node.children.length,
          node.size || 60,
          centralPos
        )

        node.children.forEach((child, childIndex) => {
          const childPos = childPositions[childIndex]

          // Add padding for child nodes (rectangular)
          minX = Math.min(minX, childPos.x - 65)
          maxX = Math.max(maxX, childPos.x + 65)
          minY = Math.min(minY, childPos.y - 15)
          maxY = Math.max(maxY, childPos.y + 15)

          // Check grandchildren
          if (child.children?.length) {
            const childVector = {
              x: childPos.x - position.x,
              y: childPos.y - position.y,
            }
            const grandchildPositions = calculateGrandchildPositions(
              childPos,
              child.children.length,
              childVector
            )

            child.children.forEach((_, gIndex) => {
              const gChildPos = grandchildPositions[gIndex]
              const gChildRadius = 38

              minX = Math.min(minX, gChildPos.x - gChildRadius)
              maxX = Math.max(maxX, gChildPos.x + gChildRadius)
              minY = Math.min(minY, gChildPos.y - gChildRadius)
              maxY = Math.max(maxY, gChildPos.y + gChildRadius)
            })
          }
        })
      }
    })

    // Add some padding
    const padding = 50
    minX -= padding
    maxX += padding
    minY -= padding
    maxY += padding

    // Calculate required zoom
    const contentWidth = maxX - minX
    const contentHeight = maxY - minY

    let containerWidth, containerHeight

    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect()
      containerWidth = rect.width
      containerHeight = rect.height
    } else {
      containerWidth = dimensions.width
      containerHeight = dimensions.height
    }

    const horizontalZoom = containerWidth / contentWidth
    const verticalZoom = containerHeight / contentHeight
    const newZoom = Math.min(horizontalZoom, verticalZoom, 1.5) // Cap at 1.5x zoom

    // Center the content
    const centerX = (minX + maxX) / 2
    const centerY = (minY + maxY) / 2

    setPan({
      x: containerWidth / 2 - centerX * newZoom,
      y: containerHeight / 2 - centerY * newZoom,
    })

    setZoom(Math.max(0.4, newZoom))
  }

  // Add this after the other useEffects
  useEffect(() => {
    // Fit content to view on initial render and when dimensions change
    const timer = setTimeout(() => {
      fitToView()
    }, 200) // Slight delay to ensure DOM is ready

    return () => clearTimeout(timer)
  }, [dimensions, nodes.length]) // Re-run when dimensions or node count changes

  const renderMindMap = () => {
    const nodePositions = calculateMainNodePositions()
    const centralPos = { x: dimensions.width / 2, y: dimensions.height / 2 }

    return (
      <g
        transform={`translate(${pan.x}, ${pan.y}) scale(${zoom})`}
        width='100%'
        height='100%'
      >
        {/* Background silhouette - brain shape */}
        <path
          d='M200,620 C120,580 80,500 80,400 C80,280 150,180 250,130 C350,80 470,80 570,130 C670,180 740,280 740,400 C740,500 700,580 620,620 L570,720 L490,850 L430,850 L350,720 L200,620 Z'
          fill='#fff'
          opacity='0.3'
          transform={`translate(${dimensions.width / 2 - 410}, ${
            dimensions.height / 2 - 490
          })`}
        />

        {/* Draw connections first so they appear behind nodes */}
        {nodes.map((node) => {
          const position = nodePositions[node.id]

          // Draw line to central node
          return (
            <path
              key={`connection-${node.id}`}
              d={`M ${position.x} ${position.y} L ${centralPos.x} ${centralPos.y}`}
              stroke='#888'
              strokeWidth='1'
              opacity='0.6'
            />
          )
        })}

        {/* Draw child connections */}
        {nodes.map((node) => {
          const position = nodePositions[node.id]
          const childPositions = node.children?.length
            ? calculateChildPositions(
                position,
                node.children.length,
                node.size || 60,
                centralPos
              )
            : []

          return (
            <React.Fragment key={`child-connections-${node.id}`}>
              {node.children?.map((child, childIndex) => {
                const childPos = childPositions[childIndex]
                const childVector = {
                  x: childPos.x - position.x,
                  y: childPos.y - position.y,
                }
                const grandchildPositions = child.children?.length
                  ? calculateGrandchildPositions(
                      childPos,
                      child.children.length,
                      childVector
                    )
                  : []

                return (
                  <React.Fragment key={`connections-${child.id}`}>
                    {/* Child connection */}
                    <path
                      d={`M ${childPos.x} ${childPos.y} L ${position.x} ${position.y}`}
                      stroke='#888'
                      strokeDasharray='3,3'
                      strokeWidth='1.5'
                      opacity='0.6'
                    />

                    {/* Grandchild connections */}
                    {child.children?.map((grandchild, gIndex) => {
                      const gChildPos = grandchildPositions[gIndex]
                      return (
                        <path
                          key={`connection-${grandchild.id}`}
                          d={`M ${gChildPos.x} ${gChildPos.y} L ${childPos.x} ${childPos.y}`}
                          stroke='#888'
                          strokeDasharray='2,2'
                          strokeWidth='1'
                          opacity='0.5'
                        />
                      )
                    })}
                  </React.Fragment>
                )
              })}
            </React.Fragment>
          )
        })}

        {/* Central node */}
        <g transform={`translate(${centralPos.x}, ${centralPos.y})`}>
          {/* Inner circle only, no outer ring/border */}
          <circle r='50' fill={central.color} />
          <text
            textAnchor='middle'
            fill='white'
            fontSize='18'
            fontWeight='bold'
            y='6'
          >
            {central.label}
          </text>
        </g>

        {/* Title and subtitle */}
        <text
          x={centralPos.x}
          y={centralPos.y + 130}
          textAnchor='middle'
          fontSize='18'
          fontWeight='bold'
        >
          {data.title}
        </text>
        {data.subtitle && (
          <text
            x={centralPos.x}
            y={centralPos.y + 155}
            textAnchor='middle'
            fontSize='14'
          >
            {data.subtitle}
          </text>
        )}

        {/* Draw nodes after connections so they appear on top */}
        {nodes.map((node) => {
          const position = nodePositions[node.id]
          return renderNodeWithChildren(node, position, centralPos)
        })}
      </g>
    )
  }

  const renderNodeWithChildren = (
    node: MindMapNode,
    position: { x: number; y: number },
    centralPos: { x: number; y: number }
  ) => {
    const childPositions = node.children?.length
      ? calculateChildPositions(
          position,
          node.children.length,
          node.size || 60,
          centralPos
        )
      : []

    return (
      <React.Fragment key={`node-group-${node.id}`}>
        {/* Main node without outer ring/border */}
        <g transform={`translate(${position.x}, ${position.y})`}>
          {/* Main circle */}
          <circle
            r={node.size || 60}
            fill={node.color || '#808080'}
            className='transition-all duration-300 hover:opacity-80'
          />
          {getMultilineText(node.label, 0, 0, 14, 'middle', 'white', 'bold')}
        </g>

        {/* Children nodes */}
        {node.children?.map((child, childIndex) => {
          const childPos = childPositions[childIndex]
          const childVector = {
            x: childPos.x - position.x,
            y: childPos.y - position.y,
          }

          const grandchildPositions = child.children?.length
            ? calculateGrandchildPositions(
                childPos,
                child.children.length,
                childVector
              )
            : []

          return (
            <React.Fragment key={`child-group-${child.id}`}>
              {/* Child node */}
              <g transform={`translate(${childPos.x}, ${childPos.y})`}>
                {/* Label background */}
                <rect
                  x='-65'
                  y='-15'
                  width='130'
                  height='30'
                  rx='15'
                  ry='15'
                  fill={child.color || '#d0d0d0'}
                  className='transition-all duration-300 hover:opacity-80'
                />
                <text
                  textAnchor='middle'
                  fontSize='12'
                  fontWeight='medium'
                  fill='white'
                >
                  {child.label}
                </text>
              </g>

              {/* Grandchildren nodes */}
              {child.children?.map((grandchild, gIndex) => {
                const gChildPos = grandchildPositions[gIndex]

                return (
                  <g
                    key={`grandchild-${grandchild.id}`}
                    transform={`translate(${gChildPos.x}, ${gChildPos.y})`}
                  >
                    <circle
                      r={grandchild.size || 38}
                      fill={grandchild.color || child.color || '#808080'}
                      className='transition-all duration-300 hover:opacity-80'
                    />
                    {getMultilineText(
                      grandchild.label,
                      0,
                      0,
                      10,
                      'middle',
                      'white',
                      'bold'
                    )}
                  </g>
                )
              })}
            </React.Fragment>
          )
        })}
      </React.Fragment>
    )
  }

  return (
    <div
      className={`transition-all duration-300 ${
        isExpanded
          ? 'fixed inset-0 z-50 bg-background p-6 shadow-lg rounded-lg'
          : 'relative'
      }`}
    >
      {isExpanded ? (
        <>
          <div className='absolute top-4 left-4 z-10'>
            <h2 className='text-xl font-semibold'>{data.title}</h2>
            {data.subtitle && (
              <p className='text-sm text-gray-600'>{data.subtitle}</p>
            )}
          </div>
          <div className='absolute top-4 right-4 z-10 flex gap-2'>
            <Button
              onClick={fitToView}
              className='text-sm bg-blue-100 hover:bg-blue-200 text-blue-700 px-3 py-1 rounded-md'
            >
              Fit to View
            </Button>
            <Button
              onClick={resetView}
              className='text-sm bg-blue-100 hover:bg-blue-200 text-blue-700 px-3 py-1 rounded-md'
            >
              Reset View
            </Button>
            <Button
              variant='ghost'
              size='icon'
              onClick={toggleExpand}
              title='Minimize MindMap'
            >
              <Minimize2 className='h-5 w-5' />
            </Button>
          </div>
          <div className='h-full w-full pt-12'>
            <svg
              ref={svgRef}
              width='100%'
              height='100%'
              viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
              preserveAspectRatio='xMidYMid meet'
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
              onWheel={handleWheel}
              style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
            >
              <rect width='100%' height='100%' fill='#f8f9fa' rx='10' ry='10' />
              {renderMindMap()}
            </svg>
          </div>
        </>
      ) : (
        <Card className='shadow-md'>
          <CardHeader className='pt-4 pb-2 flex flex-row items-center justify-between'>
            <CardTitle className='text-xl'>
              Design Thinking based Mind Map
            </CardTitle>
            <div className='flex items-center gap-2'>
              <Button
                onClick={fitToView}
                className='text-sm bg-blue-100 hover:bg-blue-200 text-blue-700 px-3 py-1 rounded-md'
              >
                Fit to View
              </Button>
              <Button
                onClick={resetView}
                className='text-sm bg-blue-100 hover:bg-blue-200 text-blue-700 px-3 py-1 rounded-md'
              >
                Reset View
              </Button>
              <Button
                variant='ghost'
                size='icon'
                onClick={toggleExpand}
                title='Expand MindMap'
              >
                <Maximize2 className='h-5 w-5 hover:text-primary' />
              </Button>
            </div>
          </CardHeader>
          <CardContent className='pt-4'>
            <div
              ref={containerRef}
              className='w-full overflow-hidden bg-slate-50 rounded-lg'
              style={{ height: '75vh' }}
            >
              <svg
                ref={svgRef}
                width='100%'
                height='100%'
                viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
                preserveAspectRatio='xMidYMid meet'
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onWheel={handleWheel}
                style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
              >
                <rect
                  width={dimensions.width}
                  height={dimensions.height}
                  fill='#f8f9fa'
                  rx='10'
                  ry='10'
                />
                {renderMindMap()}
              </svg>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  )
}

export default MindMap
