import React from 'react'
import { Label } from '@/components/ui/label'
import { Textarea } from '@/components/ui/textarea'

interface DescriptionProps {
  register: any
}

export const Description: React.FC<DescriptionProps> = ({ register }) => {
  return (
    <>
      <div className='mt-2'>
        <Label htmlFor='description'>
          Description <span className='text-red-500'>*</span>
        </Label>
        <Textarea
          id='description'
          {...register('description', { required: true })}
          placeholder='Description'
          className='mt-1 h-52'
        />
      </div>

      <div className='mt-2'>
        <Label htmlFor='businessJustification'>
          Business Value Props <span className='text-red-500'>*</span>
        </Label>
        <Textarea
          id='businessJustification'
          {...register('businessJustification', { required: true })}
          placeholder='Business Justification'
          className='mt-1'
        />
      </div>
    </>
  )
}
