import {
  ReactNode,
  useEffect,
  useMemo,
  useState,
  useCallback,
  memo,
} from 'react'
import Footer from '../footer/Footer'
import Header from '../header/Header'
import { useLocation } from 'react-router-dom'
import Breadcrumbs from '../breadcrumbs/Breadcrumbs'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { PUBLIC_ROUTES } from '../utility/constants'

interface Props {
  children?: ReactNode
}

// Utility function to debounce a function
const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timeoutId: ReturnType<typeof setTimeout>
  return (...args: any[]) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => func(...args), delay)
  }
}

// Utility function to capitalize the first letter of a string
const capitalizeFirstLetter = (link: string) => {
  return link.charAt(0).toUpperCase() + link.slice(1)
}

const Layout = ({ children }: Props) => {
  const location = useLocation()
  const [isScrolled, setIsScrolled] = useState(false)
  const { showGetStarted, currentUser } = useSelector(
    (state: RootState) => state.auth
  )

  const isPublicRoute = PUBLIC_ROUTES.some((route) => {
    if (route === '/blog') {
      return (
        location.pathname === '/blog' || location.pathname.startsWith('/blog/')
      )
    }
    return location.pathname === route
  })

  const showRibbon =
    showGetStarted &&
    currentUser?.isActive &&
    !currentUser?.org_id &&
    isPublicRoute &&
    location.pathname !== '/verifyEmail'
  const segments = useMemo(
    () => location.pathname.split('/').filter(Boolean),
    [location.pathname]
  )

  const breadcrumbItems = useMemo(
    () => [
      { label: 'Home', link: '/' },
      ...segments.map((segment, index) => ({
        label:
          segment === 'createmyinnovationspace'
            ? 'Create My Innovation Space'
            : capitalizeFirstLetter(segment),
        link: `/${segments.slice(0, index + 1).join('/')}`,
      })),
    ],
    [segments]
  )

  const handleScroll = useCallback(
    debounce(() => {
      setIsScrolled(window.scrollY > 0)
    }, 100),
    []
  )

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <div className='relative flex flex-col min-h-screen bg-[#f8f9fa] dark:bg-background'>
      <header
        className={`sticky top-0 z-50 bg-background transition-shadow duration-300 ease-in-out h-16 ${
          isScrolled ? 'drop-shadow-md' : ''
        }`}
      >
        <Header />
      </header>
      <div className='container max-w-screen-2xl flex-1 mx-auto md:px-8 px-4'>
        {segments.length > 0 && (
          <Breadcrumbs items={breadcrumbItems} separator='/' />
        )}
        <main className={`min-h-screen ${showRibbon ? 'pb-20' : 'pb-4'}`}>
          {children}
        </main>
      </div>
      <Footer />
    </div>
  )
}

export default memo(Layout)
