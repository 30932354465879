// types.ts
export interface ContentBlock {
  type: 'paragraph' | 'heading' | 'image' | 'list' | 'blockquote'
  content: string
  level?: number
  id?: string
  src?: string
  alt?: string
  caption?: string
  listType?: 'ordered' | 'unordered'
  items?: string[]
}
export function htmlToStructuredContent(html: string): ContentBlock[] {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const body = doc.body
  const blocks: ContentBlock[] = []

  function processNode(node: Element) {
    switch (node.tagName.toLowerCase()) {
      case 'p':
        if (node.querySelector('img')) {
          const img = node.querySelector('img')!
          const caption = node.querySelector('em')?.textContent
          blocks.push({
            type: 'image',
            src: img.getAttribute('src') || '',
            alt: img.getAttribute('alt') || '',
            caption: caption || '',
            content: '',
          })
        } else {
          blocks.push({
            type: 'paragraph',
            content: node.innerHTML.trim(),
          })
        }
        break

      case 'h1':
      case 'h2':
      case 'h3':
      case 'h4':
      case 'h5':
      case 'h6':
        const level = parseInt(node.tagName.charAt(1))
        const id = `section-${blocks.length}`
        blocks.push({
          type: 'heading',
          level,
          content: node.textContent?.trim() || '',
          id,
        })
        break

      case 'blockquote':
        blocks.push({
          type: 'blockquote',
          content: node.innerHTML.trim(),
        })
        break

      case 'ul':
      case 'ol':
        blocks.push({
          type: 'list',
          listType:
            node.tagName.toLowerCase() === 'ul' ? 'unordered' : 'ordered',
          items: Array.from(node.querySelectorAll('li')).map((li) =>
            li.innerHTML.trim()
          ),
          content: '',
        })
        break

      case 'img':
        blocks.push({
          type: 'image',
          src: node.getAttribute('src') || '',
          alt: node.getAttribute('alt') || '',
          caption: node.getAttribute('title') || '',
          content: '',
        })
        break

      default:
        if (node.children.length > 0) {
          Array.from(node.children).forEach((child) =>
            processNode(child as Element)
          )
        }
    }
  }

  Array.from(body.children).forEach((node) => processNode(node as Element))
  return blocks
}
