import React from 'react'
import { Control } from 'react-hook-form'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { MultiSelect } from '../MultiSelect'
import { IdeaFormData } from '@/types/idea'

interface BasicInfoProps {
  register: any
  control: Control<IdeaFormData>
  categories: any[]
  collaborators: any[]
}

export const BasicInfo: React.FC<BasicInfoProps> = ({
  register,
  control,
  categories,
  collaborators,
}) => {
  return (
    <>
      <div>
        <Label htmlFor='title'>
          Title <span className='text-red-500'>*</span>
        </Label>
        <Input
          id='title'
          {...register('title', { required: true })}
          placeholder='Enter title'
          className='mt-1'
        />
      </div>

      <div className='mt-2'>
        <Label htmlFor='categories'>
          Category <span className='text-red-500'>*</span>
        </Label>
        <MultiSelect
          control={control}
          name='categories'
          options={categories}
          placeholder='Select Categories'
        />
      </div>

      <div className='mt-2'>
        <Label htmlFor='collaborators'>Collaborators</Label>
        <MultiSelect
          control={control}
          name='collaborators'
          options={collaborators}
          placeholder='Select Collaborators'
        />
      </div>
    </>
  )
}
