'use client'

import React, { useMemo, useCallback } from 'react'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { Crown, Users2, Mail, Shield } from 'lucide-react'
import { cn } from '@/lib/utils'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { ScrollArea } from '@/components/ui/react-scroll-area'
import { Badge } from '@/components/ui/badge'

interface User {
  id: number
  username: string
  first_name: string
  last_name: string
  email: string
  org_id: number
  role_id: number
  picture: string
  isActive?: boolean
}

interface IdeaCollaboratorsProps {
  collaborators: User[]
  maxVisible?: number
  className?: string
}

const ROLE_LABELS = {
  1: 'Super Admin',
  2: 'Org Admin',
  3: 'User',
}

const ROLE_ICONS = {
  1: Shield,
  2: Shield,
  3: Users2,
}

const IdeaCollaborators = React.memo(
  ({ collaborators, maxVisible = 4, className }: IdeaCollaboratorsProps) => {
    const visibleCollaborators = useMemo(
      () => collaborators.slice(0, maxVisible),
      [collaborators, maxVisible]
    )
    const remainingCollaborators = useMemo(
      () => collaborators.slice(maxVisible),
      [collaborators, maxVisible]
    )
    const hasMore = useMemo(
      () => remainingCollaborators.length > 0,
      [remainingCollaborators]
    )

    const getInitials = useCallback((user: User) => {
      const firstNameInitial = user?.first_name?.[0] || '' // Fallback to empty string if first_name is null/undefined
      const lastNameInitial = user?.last_name?.[0] || '' // Fallback to empty string if last_name is null/undefined
      return `${firstNameInitial}${lastNameInitial}`.toUpperCase()
    }, [])

    const getUserDisplayName = useCallback(
      (user: User) =>
        `${user.first_name}${user.last_name ? ` ${user.last_name}` : ''}`,
      []
    )

    const getRoleInfo = useCallback((roleId: number) => {
      const label = ROLE_LABELS[roleId as keyof typeof ROLE_LABELS] || 'User'
      const Icon = ROLE_ICONS[roleId as keyof typeof ROLE_ICONS] || Users2
      return { label, Icon }
    }, [])
    return (
      <div className={cn('flex items-center gap-4', className)}>
        <div className='flex items-center'>
          <div className='flex space-x-4'>
            {visibleCollaborators.map((user, index) => {
              const { label } = getRoleInfo(user.role_id)
              return (
                <TooltipProvider key={user.id} delayDuration={200}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div
                        className={cn(
                          'relative inline-block transition-all duration-200 hover:scale-110 hover:z-10',
                          index === 0 && 'z-[1]'
                        )}
                      >
                        <Avatar
                          className={cn(
                            'h-8 w-8 border-0 transition-colors duration-200 ',
                            index === 0 ? 'bg-muted' : 'bg-transparent'
                          )}
                        >
                          <AvatarImage
                            src={user.picture || undefined}
                            alt={getUserDisplayName(user)}
                          />
                          <AvatarFallback className='text-xs font-medium border border-border bg-transparent hover:bg-muted'>
                            {getInitials(user)}
                          </AvatarFallback>
                        </Avatar>
                        {index === 0 && (
                          <span className='bg-black absolute -top-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full'>
                            <Crown className='h-2.5 w-2.5 text-white ' />
                            <span className='sr-only'>Idea Creator</span>
                          </span>
                        )}
                      </div>
                    </TooltipTrigger>
                    <TooltipContent
                      side='bottom'
                      className='flex items-start gap-3 p-3 w-auto border-foreground/10'
                      sideOffset={4}
                    >
                      <div className='flex-1'>
                        <div className='flex items-center justify-between'>
                          <p className='font-medium mr-2'>
                            {getUserDisplayName(user)}
                          </p>
                          <Badge
                            variant='outline'
                            className='text-xs border-foreground/10 bg-primary/5 text-primary'
                          >
                            {label}
                          </Badge>
                        </div>
                        <p className='text-sm text-muted-foreground mt-1'>
                          {index === 0 ? 'Idea Creator' : 'Collaborator'}
                        </p>
                        <div className='mt-2 flex items-center gap-1.5 text-sm text-muted-foreground'>
                          <Mail className='h-3.5 w-3.5' />
                          {user.email}
                        </div>
                      </div>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )
            })}

            {hasMore && (
              <Popover>
                <PopoverTrigger asChild>
                  <button
                    className={cn(
                      'relative inline-flex h-8 w-8 items-center justify-center rounded-full',
                      'bg-muted border-2 border-transparent',
                      'transition-all duration-200 hover:border-foreground/20'
                    )}
                  >
                    <Users2 className='h-4 w-4 text-muted-foreground' />
                    <span className='absolute -top-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full bg-black text-[10px] font-medium text-white'>
                      +{remainingCollaborators.length}
                    </span>
                  </button>
                </PopoverTrigger>
                <PopoverContent
                  className='w-80 p-0 border-foreground/10'
                  align='start'
                >
                  <ScrollArea className='h-[200px]'>
                    <div className='p-4'>
                      <h4 className='font-medium mb-3'>Other Collaborators</h4>
                      <div className='space-y-3'>
                        {remainingCollaborators.map((user) => {
                          const { label, Icon } = getRoleInfo(user.role_id)
                          return (
                            <div
                              key={user.id}
                              className='flex items-center gap-3 group'
                            >
                              <Avatar className='h-8 w-8 transition-transform duration-200 group-hover:scale-105'>
                                <AvatarImage
                                  src={user.picture || undefined}
                                  alt={getUserDisplayName(user)}
                                />
                                <AvatarFallback
                                  className={cn(
                                    'text-xs',
                                    user.isActive
                                      ? 'bg-primary/10 text-primary'
                                      : 'bg-muted text-muted-foreground'
                                  )}
                                >
                                  {getInitials(user)}
                                </AvatarFallback>
                              </Avatar>
                              <div className='flex-1'>
                                <div className='flex items-center justify-between'>
                                  <p className='text-sm font-medium leading-none group-hover:text-primary transition-colors'>
                                    {getUserDisplayName(user)}
                                  </p>
                                  <Badge
                                    variant='outline'
                                    className='text-[10px] border-foreground/10 bg-primary/5 text-primary'
                                  >
                                    <Icon className='h-3 w-3 mr-1' />
                                    {label}
                                  </Badge>
                                </div>
                                <p className='text-sm text-muted-foreground mt-1'>
                                  {user.email}
                                </p>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </ScrollArea>
                </PopoverContent>
              </Popover>
            )}
          </div>
        </div>
      </div>
    )
  }
)

export { IdeaCollaborators }
