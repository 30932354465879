import { ColumnDef } from '@tanstack/react-table'
import { memo, useMemo } from 'react'

import StatusCircle from './StatusCircle.tsx'
import { DataTableRowActions } from '@/components/shared/data-table/DataTableRowActions.tsx'
import { DataTableColumnHeader } from '@/components/shared/data-table/DataTableColumnHeader.tsx'

export type List = {
  id: string
  title: string
  category: string | null
  status: string | null
  suhrt_score: string | null
  team: string | null
  created_at: string | null
  no_of_comments: string | null
  no_of_likes: string | null
}

const MemoizedStatusCircle = memo(StatusCircle)
//const MemoizedDataTableRowActions = memo(DataTableRowActions)

const getStatusBadge = (statusId: number) => {
  switch (statusId) {
    case 1:
    case 5:
      return (
        <MemoizedStatusCircle
          className='bg-primary text-white'
          status='Draft'
        />
      )
    case 2:
      return (
        <MemoizedStatusCircle
          className='bg-accent text-white'
          status='In-Review'
        />
      )
    case 3:
      return (
        <MemoizedStatusCircle
          className='bg-lime-500 text-white'
          status='Approved'
        />
      )
    case 4:
      return (
        <MemoizedStatusCircle
          className='text-destructive text-white'
          status='Deleted'
        />
      )
    default:
      return (
        <MemoizedStatusCircle
          className='bg-gray-200 text-black'
          status='Unknown'
        />
      )
  }
}

// Memoized cell components
const TitleCell = memo(({ value }: { value: string }) => (
  <span className='max-w-[50px] truncate'>{value}</span>
))

const CategoryCell = memo(
  ({
    categories,
  }: {
    categories: Array<{ category: { category_name: string } }>
  }) => {
    const categoryNames = useMemo(
      () => categories.map((c) => c.category.category_name).join(', '),
      [categories]
    )

    return (
      <div className='flex space-x-2'>
        <span className=''>{categoryNames}</span>
      </div>
    )
  }
)

const StatusCell = memo(({ status }: { status: number }) => (
  <div className='flex space-x-2'>
    <span className=''>{getStatusBadge(status)}</span>
  </div>
))

const ScoreCell = memo(
  ({ scores }: { scores: Array<{ weighted_avg_score: number }> | null }) => {
    const score =
      scores && scores.length ? `${scores[0].weighted_avg_score}` : '_'

    return (
      <div className='flex space-x-2'>
        <span className=''>{score}</span>
      </div>
    )
  }
)

const DateCell = memo(({ date }: { date: string }) => (
  <div className='flex space-x-2'>
    <span className=''>{new Date(date).toLocaleString()}</span>
  </div>
))

export const listColumns: ColumnDef<List>[] = [
  {
    accessorKey: 'idea_title',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Title' />
    ),
    cell: ({ row }) => <TitleCell value={row.getValue('idea_title')} />,
  },
  {
    accessorKey: 'idea_categories',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Category' />
    ),
    cell: ({ row }) => (
      <CategoryCell categories={row.getValue('idea_categories')} />
    ),
    filterFn: (row, id, value) => {
      const categories = row.getValue(id) as Array<{
        category: { category_name: string }
      }>
      const categoryNames = categories.map((c) => c.category.category_name)
      return value.every((val: string) => categoryNames.includes(val))
    },
  },
  {
    accessorKey: 'status',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Status' />
    ),
    cell: ({ row }) => <StatusCell status={row.getValue('status')} />,
    filterFn: (row, id, value) => {
      const statusMap: Record<number, string> = {
        1: 'draft',
        2: 'in review',
        3: 'approved',
      }
      const rowStatus = statusMap[row.getValue(id) as number]
      return value.includes(rowStatus)
    },
  },
  {
    accessorKey: 'idea_scores',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Suhrt Score' />
    ),
    cell: ({ row }) => <ScoreCell scores={row.getValue('idea_scores')} />,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    accessorKey: 'idea_dt',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Created' />
    ),
    cell: ({ row }) => <DateCell date={row.getValue('idea_dt')} />,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    id: 'actions',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Actions' />
    ),
    cell: ({ row }) => <DataTableRowActions row={row} tableType='listview' />,
  },
]
