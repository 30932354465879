import React from 'react'
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md'
import { CarouselDotButton } from './CarouselDotButton'

interface CarouselNavigationProps {
  selectedIndex: number
  scrollSnaps: number[]
  onPrevClick: () => void
  onNextClick: () => void
  onDotClick: (index: number) => void
}

export const CarouselNavigation: React.FC<CarouselNavigationProps> = ({
  selectedIndex,
  scrollSnaps,
  onPrevClick,
  onNextClick,
  onDotClick,
}) => (
  <div className='flex justify-center mt-4 items-center gap-4'>
    <button
      onClick={onPrevClick}
      className='p-1 transition-transform duration-300 hover:-translate-x-1'
    >
      <MdArrowBackIosNew className='text-base text-foreground hover:text-primary/80' />
    </button>

    <div className='flex gap-2'>
      {scrollSnaps.map((_, index) => (
        <CarouselDotButton
          key={index}
          selected={index === selectedIndex}
          onClick={() => onDotClick(index)}
        />
      ))}
    </div>

    <button
      onClick={onNextClick}
      className='p-1 transition-transform duration-300 hover:translate-x-1'
    >
      <MdArrowForwardIos className='text-base text-foreground hover:text-primary/80' />
    </button>
  </div>
)
