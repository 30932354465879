import { Separator } from "@/components/ui/separator"
import DepartmentForm from "./DepartmentForm"

const Department = () => {
  return (
    <div>
      <div className="mb-6">
        <h3 className="text-lg font-medium">Department</h3>
        <p className=" text-muted-foreground">
          Configure how you receive notifications.
        </p>
      </div>
      <Separator />
      <DepartmentForm />
    </div>
  )
}

export default Department
