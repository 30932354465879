import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  CREATE_ORGANIZATION,
  CREATE_SAMPLE_CATEGORIES_AND_USERS,
  CREATE_SAMPLE_IDEAS_AND_NOTES,
  CREATE_SAMPLE_COMMENTS,
} from '@/graphql/orgQuery'
import { UPDATE_USER_ORG } from '@/graphql/userQuery'
import { RootState } from '@/store/store'
import { hideGetStarted, setCurrentUser } from '@/store/user/userSlice'
import { hideLoader, showLoader } from '@/store/idea/loaderSlice'
import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { useToast } from '@/components/ui/use-toast'
import { Textarea } from '@/components/ui/textarea'

const CreateOrganisation = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { toast } = useToast()
  const { currentUser } = useSelector((state: RootState) => state.auth)

  const [orgName, setOrgName] = useState('')
  const [orgDetails, setOrgDetails] = useState('')

  const [createOrganization, { loading: creatingOrganization }] = useMutation(
    CREATE_ORGANIZATION,
    {
      onCompleted: (data) => handleOrgCreationSuccess(data),
      onError: (error) =>
        handleError(`Error creating organization: ${error.message}`),
    }
  )

  const [updateUserCompany] = useMutation(UPDATE_USER_ORG, {
    onError: (error) => handleError(`Error updating user: ${error.message}`),
  })

  const [createSampleCategoriesAndUsers] = useMutation(
    CREATE_SAMPLE_CATEGORIES_AND_USERS,
    {
      onError: (error) =>
        handleError(
          `Error creating sample categories and users: ${error.message}`
        ),
    }
  )

  const [createSampleIdeasAndNotes] = useMutation(
    CREATE_SAMPLE_IDEAS_AND_NOTES,
    {
      onError: (error) =>
        handleError(`Error creating sample ideas and notes: ${error.message}`),
    }
  )

  const [createSampleComments] = useMutation(CREATE_SAMPLE_COMMENTS, {
    onError: (error) =>
      handleError(`Error creating sample comments: ${error.message}`),
  })

  const handleOrgCreationSuccess = async (data: any) => {
    try {
      const orgId = data.insert_suhrt_organisation_one?.id
      const orgName = data.insert_suhrt_organisation_one?.name

      if (!orgId || !orgName || !currentUser) {
        throw new Error('Invalid organization or user data.')
      }

      // Update user with org information
      const updatedUser = {
        ...currentUser,
        org_id: orgId,
        org_name: orgName,
        org_details: orgDetails,
      }
      dispatch(setCurrentUser(updatedUser))

      await updateUserCompany({
        variables: { email: currentUser.email, org_id: orgId },
      })

      toast({
        variant: 'success',
        description: `Organisation "${orgName}" created successfully.`,
        duration: 3000,
      })
      dispatch(hideGetStarted())
      // Step 1: Create sample categories and users
      const { data: categoryAndUserData } =
        await createSampleCategoriesAndUsers({
          variables: {
            org_id: orgId,
            username1: `vishal.mishra${orgId}@example.com`,
            email1: `vishal.mishra${orgId}@example.com`,
            username2: `eric.tan${orgId}@example.com`,
            email2: `eric.tan${orgId}@example.com`,
            username3: `chris.dower${orgId}@example.com`,
            email3: `chris.dower${orgId}@example.com`,
            username4: `suzanne.gomes${orgId}@example.com`,
            email4: `suzanne.gomes${orgId}@example.com`,
          },
        })

      const categories = categoryAndUserData?.insert_suhrt_category?.returning
      const users = categoryAndUserData?.insert_suhrt_users?.returning

      if (!categories || !users) {
        throw new Error('Failed to create sample categories or users.')
      }

      // Extract necessary IDs for next step
      const [category1, category2] = categories
      const [user1, user2, user3, user4] = users

      // Step 2: Create sample ideas and notes
      const { data: ideaData } = await createSampleIdeasAndNotes({
        variables: {
          user_id_1: user1.id,
          user_id_2: currentUser.id,
          user_id_3: user3.id,
          user_id_4: user4.id,
          category_id_1: category1.category_id,
          category_id_2: category2.category_id,
        },
      })

      const ideas = ideaData?.insert_suhrt_idea?.returning
      if (!ideas) {
        throw new Error('Failed to create sample ideas and notes.')
      }

      // Extract necessary IDs for next step
      const [idea1, idea2] = ideas

      // Step 3: Create sample comments
      await createSampleComments({
        variables: {
          user_id_1: user1.id,
          user_id_2: user2.id,
          user_id_3: user3.id,
          idea_id_1: idea1.id,
          idea_id_2: idea2.id,
        },
      })
      // Navigate after success
      //setTimeout(() => {
      navigate('/ideas')
      dispatch(hideLoader())
      //}, 1500)
    } catch (error) {
      console.error('Error during organization creation:', error)
      handleError('Failed to process organization creation.')
    }
  }

  const handleError = (message: string) => {
    toast({ variant: 'destructive', description: message, duration: 3000 })
    dispatch(hideLoader())
  }

  const handleCreateOrg = () => {
    if (!orgName.trim()) {
      return handleError('Organization name cannot be empty.')
    }

    dispatch(showLoader())
    createOrganization({
      variables: {
        org_name: orgName,
        org_details: orgDetails, // Pass organization details
        created_by: currentUser?.id,
        relevance_weight: 0.3,
        originality_weight: 0.2,
        impact_weight: 0.25,
        reuse_weight: 0.15,
        feasibility_weight: 0.1,
      },

      onError: (error) => {
        if (error.message.includes('organisation_name_key')) {
          toast({
            variant: 'destructive',
            description:
              'An organization with this name already exists. Please choose a different name.',
            duration: 3000,
          })
          dispatch(hideLoader())
        } else {
          handleError(`Error creating organization: ${error.message}`)
        }
      },
    })
  }

  return (
    <div className='container flex items-center justify-center mt-5'>
      <Card className='w-[550px]'>
        <CardHeader>
          <CardTitle className='mb-0'>Create My Innovation Space</CardTitle>
          {/* <CardDescription>
            <p>What’s on your mind? </p>
            Tell us about what your innovation interests are, vision, mission,
            strategy etc
          </CardDescription> */}
        </CardHeader>
        <CardContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className='grid w-full items-center gap-4 mb-2'>
              <div className='flex flex-col space-y-1.5'>
                <Label htmlFor='name'>Your innovation space</Label>
                <Input
                  id='name'
                  placeholder='Name of your Innovation Space'
                  value={orgName}
                  onChange={(e) => setOrgName(e.target.value)}
                />
              </div>
            </div>
            <div className='grid w-full items-center gap-4'>
              <div className='flex flex-col space-y-1.5'>
                <Label htmlFor='details'>What’s on your mind?</Label>
                <Textarea
                  id='details'
                  placeholder='Tell us about what your innovation interests are, vision, mission, strategy etc'
                  value={orgDetails}
                  onChange={(e) => setOrgDetails(e.target.value)}
                />
              </div>
            </div>
          </form>
        </CardContent>
        <CardFooter className='flex justify-end'>
          <Button onClick={handleCreateOrg} disabled={creatingOrganization}>
            {creatingOrganization ? 'Creating...' : 'Submit'}
          </Button>
        </CardFooter>
      </Card>
    </div>
  )
}

export default CreateOrganisation
