import { CTASection } from '@/components/Home/CTASection'
import { FeatureCard } from '@/components/Home/FeatureCard'
// import { HeroSection } from '@/components/Home/HeroSection'
import { InnovationPackagesTable } from '@/components/Home/InnovationPackagesTable'
import { PartnershipSection } from '@/components/Home/PartnershipSection'
// import VideoSection from '@/components/Home/VideoSection'
// import { StatsSection } from '@/components/Home/StatsSection'

import ContentWrapper from '@/components/content/ContentWrapper'
import { useDialog } from '@/components/global-dialog/DialogContext'
// import CustomerList from '@/components/customers/CustomerList'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { motion } from 'framer-motion'
import {
  FaArrowRight,
  FaBrain,
  FaChartBar,
  FaPeopleGroup,
} from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'
const features = [
  {
    icon: <FaBrain className='w-8 h-8' />,
    title: 'AI-Powered Innovation',
    description:
      'Increase your per capita innovation using Suhrt AI Ideator, which provides trending suggestions tailored to your areas of interest.',
    gradient: 'from-blue-500/20 via-purple-500/20 to-pink-500/20',
  },
  {
    icon: <FaPeopleGroup className='w-8 h-8' />,
    title: 'Accelerate you innovation journey',
    description:
      'Suhrt empowers innovation by integrating AI-driven design thinking to generate ideas, create mind maps, auto-evaluate solutions, and seamlessly develop business plans, transforming concepts into actionable strategies.',
    gradient: 'from-emerald-500/20 via-teal-500/20 to-cyan-500/20',
  },
  {
    icon: <FaChartBar className='w-8 h-8' />,
    title: 'Innovation Insights with Advanced Analytics',
    description:
      'Evaluate your innovation calorie count using our advanced analytics.',
    gradient: 'from-orange-500/20 via-amber-500/20 to-yellow-500/20',
  },
]
export default function Home() {
  const { openDialog, closeDialog } = useDialog()
  const handleOpenDialog = () => {
    openDialog({
      title: 'Curated Innovation Packages',
      description: '',
      requireConfirmation: false,
      content: <InnovationPackagesTable />,
      confirmAction: () => {
        closeDialog()
      },
      width: 'md:w-[1000px]',
      height: 'h-[500px]',
    })
  }
  const navigate = useNavigate()
  return (
    <>
      <div className=' header-wrapper'>
        <ContentWrapper />
      </div>
      {/* <Separator />

      <div className='mx-auto pb-20 pt-20'>
        <HeroSection />
      </div>
      <div className='mx-auto pb-20'>
        <VideoSection />
      </div> */}

      <Separator />
      <div className=' pb-20 pt-20 mx-auto grid gap-8'>
        <section className='container mx-auto px-4'>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className='text-center mb-16'
          >
            <h2 className='text-3xl md:text-4xl font-bold text-foreground mb-4'>
              Our Salient Features
            </h2>
            <p className='text-muted-foreground max-w-2xl mx-auto text-base'>
              Transform your innovation process with our comprehensive suite of
              tools and features
            </p>
          </motion.div>

          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'>
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              >
                <FeatureCard {...feature} />
              </motion.div>
            ))}
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className='text-center mt-16'
          >
            <Button
              size='lg'
              className='bg-primary text-base text-primary-foreground hover:bg-primary/90 group px-12'
              onClick={() => navigate('/pricing')}
            >
              Feature List
              <FaArrowRight className='ml-2 group-hover:translate-x-1 transition-transform' />
            </Button>
          </motion.div>
        </section>
      </div>
      <Separator />
      <div className='pb-20 pt-20 mx-auto w-full max-w-3xl '>
        <PartnershipSection />
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className='text-center'
        >
          <Button
            size='lg'
            className='bg-primary text-base text-primary-foreground hover:bg-primary/90 group'
            onClick={handleOpenDialog}
          >
            Curated Innovation Packages
            <FaArrowRight className='ml-2 group-hover:translate-x-1 transition-transform' />
          </Button>
        </motion.div>
      </div>
      <Separator />
      <div className='pb-20 pt-20 md:mx-auto flex flex-wrap md:flex-nowrap items-center justify-between max-w-2xl sm:max-w-4xl'>
        <CTASection />
      </div>
    </>
  )
}
