const HASURA = {
    httpUri: "https://suhrt.com/v1/graphql",
    wsUri: "wss://suhrt.com/v1/graphql",  
};

export const HasuraGraphQL = {
    httpConfig: {
        uri: HASURA.httpUri
    },
    wsConfig: {
        url: HASURA.wsUri,
        options: {
            // lazy: true,
            reconnect: true
        }
    }
}

export const S3URL = {
    s3Url:'https://suhrt.com/s3/'
}