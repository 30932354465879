import { motion, useScroll } from 'framer-motion'

export function ScrollProgress() {
  const { scrollYProgress } = useScroll()

  return (
    <motion.div
      className='fixed top-[127px] left-0 right-0 h-[0.15rem] bg-primary z-50 origin-left'
      style={{ scaleX: scrollYProgress }}
    />
  )
}
