import { configureStore } from "@reduxjs/toolkit";
import { ideaReducer } from "./idea/ideaSlice";
import { authReducer } from "./user/userSlice";
import { commentReducer } from "./idea/commentSlice";
import { listenerMiddleware } from "./listenerMiddleware";
import { ideaApi } from "@/service/ideas";
import { loaderReducer } from "./idea/loaderSlice";

const rootReducer = {
  idea: ideaReducer,
  comment: commentReducer,
  auth: authReducer,
  loader: loaderReducer,
  [ideaApi.reducerPath]: ideaApi.reducer,
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefautMiddleware) =>
    getDefautMiddleware({ serializableCheck: false })
      .prepend(listenerMiddleware.middleware)
      .prepend(ideaApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
