import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Idea } from '@/types/idea'
import { endpoints } from './endpoints'
import { ScoresProps } from '@/components/ideas/views/SuhrtScoreParams'
import CONFIG from '@/config'

const { baseUrl } = endpoints

type StringAndNumber = string | number

export const getScoreForIdea = async (inputs: {
  Feasibility: StringAndNumber
  Impact: StringAndNumber
  Originality: StringAndNumber
  Reuse: StringAndNumber
  description: string
  idea: string
  relevance_score: StringAndNumber
  value_prop: string
}) => {
  try {
    const response = await fetch(`${CONFIG.apiUrl}/suhrtai/getScoreForIdea`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ inputs }),
    })

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`)
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching score for idea:', error)
    throw error
  }
}
export const getBcDocument = async (inputs: {
  description: string
  title: string
  valueprop: string
}) => {
  try {
    const response = await fetch(
      `${CONFIG.apiUrl}/suhrtai/generate_business_document`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({ ...inputs }),
      }
    )

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`)
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching score for idea:', error)
    throw error
  }
}
export const downloadBMC = async (fileName?: string | null) => {
  try {
    const response = await fetch(
      `${CONFIG.apiUrl}/suhrtai/download/${fileName}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/octet-stream', // For file downloads
        },
      }
    )

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`)
    }

    // Parse the response as a Blob
    const blob = await response.blob()

    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob)

    // Create a temporary anchor element to trigger the download
    const a = document.createElement('a')
    a.href = url
    a.download = 'business_canvas_file.pptx' // Set a fixed file name with .pptx extension
    document.body.appendChild(a)
    a.click() // Simulate a click
    a.remove() // Remove the anchor element

    // Release the URL after the download
    window.URL.revokeObjectURL(url)
  } catch (error) {
    console.error('Error in downloading file', error)
    throw error
  }
}

export const mapApiResponseToScores = (
  apiResp: any,
  weight_id: number
): ScoresProps => {
  const isNested = !!apiResp?.scores?.relevance_note

  return {
    ideaID: apiResp?.idea || apiResp?.idea_id,
    weight_id,
    weighted_avg_score: apiResp?.weighted_avg_score,
    scores: {
      relevance_score: isNested
        ? apiResp?.scores?.relevance_note?.score
        : apiResp?.relevance_score,
      originality_score: isNested
        ? apiResp?.scores?.originality_note?.score
        : apiResp?.originality_score,
      impact_score: isNested
        ? apiResp?.scores?.impact_note?.score
        : apiResp?.impact_score,
      reuse_score: isNested
        ? apiResp?.scores?.reuse_note?.score
        : apiResp?.reuse_score,
      feasibility_score: isNested
        ? apiResp?.scores?.feasibility_note?.score
        : apiResp?.feasibility_score,
    },
    notes: {
      relevance_note: isNested
        ? apiResp?.scores?.relevance_note?.note
        : apiResp?.relevance_note,
      originality_note: isNested
        ? apiResp?.scores?.originality_note?.note
        : apiResp?.originality_note,
      impact_note: isNested
        ? apiResp?.scores?.impact_note?.note
        : apiResp?.impact_note,
      reuse_note: isNested
        ? apiResp?.scores?.reuse_note?.note
        : apiResp?.reuse_note,
      feasibility_note: isNested
        ? apiResp?.scores?.feasibility_note?.note
        : apiResp?.feasibility_note,
    },
    weights: {
      relevance_weight: apiResp?.org_score_weight.relevance_weight,
      originality_weight: apiResp?.org_score_weight.originality_weight,
      impact_weight: apiResp?.org_score_weight.impact_weight,
      reuse_weight: apiResp?.org_score_weight.reuse_weight,
      feasibility_weight: apiResp?.org_score_weight.feasibility_weight,
    },
  }
}

export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const result = reader.result as string
      const base64 = result.split(',')[1]
      resolve(base64)
    }
    reader.onerror = (error) => reject(error)
  })
}

export const encodeFileWithMetadata = async (file: File): Promise<string> => {
  const base64Data = await convertFileToBase64(file)
  const metadata = {
    filename: file.name,
    mimeType: file.type,
    data: base64Data,
  }
  const jsonString = JSON.stringify(metadata)
  return btoa(jsonString)
}

/*const getIdeas = async ({ payload }: any) => {
  try {   
    const resp = await fetch(`${baseUrl}ideas`, {
      method: "POST",
      body: JSON.stringify(payload),
    });
    const result = await resp.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const getIdeaDetailsById = async ({ id }: { id: string }) => {
  try {
    const resp = await fetch(`${baseUrl}idea/${id}`)
    const result = await resp.json()
    return result
  } catch (error) {
    console.log(error)
  }
} */

const addIdea = async ({ idea }: { idea: Idea }) => {
  try {
    const resp = await fetch(`${baseUrl}idea`, {
      method: 'POST',
      body: JSON.stringify(idea),
    })
    const result = await resp.json()
    return result
  } catch (error) {
    console.log(error)
  }
}

export const ideaApi = createApi({
  reducerPath: 'ideaApi',
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    getIdeas: builder.mutation({
      query: (body) => ({ url: `ideas`, method: 'POST', body }),
    }),
    getIdeaDetailsById: builder.query({
      query: (id) => `/idea${id}`,
    }),
  }),
})

export const { useGetIdeasMutation, useGetIdeaDetailsByIdQuery } = ideaApi

// export { getIdeas, getIdeaDetailsById, addIdea };
export { addIdea }
