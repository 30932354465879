import { NavLink } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { UTILITY } from '@/util/utility'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { useQuery } from '@apollo/client'
import { GET_IDEA_BY_ID, GET_IDEA_DETAILS, GET_IDEA_DETAILS_FOR_ADMIN } from '@/graphql/ideaQuery'
import { useEffect, useState } from 'react'
import { Idea } from '@/types/idea'

type BreadcrumItem = {
  label: string
  link: string
  icon?: string
}

type Prop = {
  items: BreadcrumItem[]
  separator: string
}

const Breadcrumbs = ({ items, separator = '/' }: Prop) => {
  const { currentUser } = useSelector((state: RootState) => state.auth)
  const params = useParams()
  const encodedId = params.id ? encodeURIComponent(params.id) : ''
  const [ideaDetails, setIdeaDetails] = useState<Idea>()
  const { data: ideaData } = currentUser?.role_id ? useQuery(GET_IDEA_BY_ID, {
    variables: {
      id: UTILITY.decryptedId(params.id || '')
    },
  }) : currentUser?.role_id === 2 ? useQuery(GET_IDEA_DETAILS_FOR_ADMIN, {
    variables: {
      idea_id: UTILITY.decryptedId(params.id || ''),
      org_id: currentUser?.org_id
    },
  })

  : useQuery(GET_IDEA_DETAILS, {
    variables: {
      idea_id: UTILITY.decryptedId(params.id || ''),
      org_id: currentUser?.org_id,
      user_id: currentUser?.id
    },
  })

  useEffect(() => {
    if (ideaData && ideaData.suhrt_idea[0]) {
      setIdeaDetails(ideaData.suhrt_idea[0]) // Set the fetched idea data
    }
  }, [ideaData])

  const updatedItems = items.map((item) => {
    if (params.id && item.label.includes(encodedId)) {
      return { ...item, label: ideaDetails?.idea_title }
    }

    return item
  })

  return (
    <nav className='flex py-1 w-full' aria-label='Breadcrumb'>
      <ol className='flex items-center'>
        {updatedItems.map((item, index) => (
          <li
            key={index}
            className={`${index === updatedItems.length - 1 ? '' : ' '}`}
          >
            <NavLink
              end
              to={item.link}
              className={({ isActive }) =>
                isActive
                  ? '  focus:bg-primary-50 text-primary px-2 py-2 first:pl-0 rounded-md text-xs font-medium  md:flex inline-flex w-[260px] md:w-full'
                  : '  focus:bg-primary-50 hover:text-primary px-2 py-2 first:pl-0 rounded-md text-xs font-medium text-muted-foreground '
              }
            >
              {item.label}
            </NavLink>
            {index !== updatedItems.length - 1 && (
              <span className='mr-2 text-muted-foreground text-xs'>
                {separator}
              </span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default Breadcrumbs
