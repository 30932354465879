// GetStartedRibbon.tsx
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { Button } from '@/components/ui/button'
import { ArrowRight, Lightbulb } from 'lucide-react'
import { useEffect, useState } from 'react'
import { cn } from '@/lib/utils'

export const GetStartedRibbon = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { showGetStarted, currentUser } = useSelector(
    (state: RootState) => state.auth
  )
  const [shouldShow, setShouldShow] = useState(false)

  useEffect(() => {
    // Show ribbon if user is verified and doesn't have an org,
    // regardless of current route during verification

    const shouldDisplayRibbon: any =
      showGetStarted &&
      currentUser?.isActive &&
      !currentUser?.org_id &&
      location.pathname !== '/verifyEmail'

    setShouldShow(shouldDisplayRibbon)
  }, [showGetStarted, currentUser, location.pathname])

  if (!shouldShow) {
    return null
  }

  return (
    <div
      className={cn(
        'fixed bottom-0 left-0 right-0 py-6 px-8 flex justify-between items-center z-50 shadow-lg animate-slide-up',
        'bg-lime-500 text-white'
      )}
    >
      <div className='flex items-center space-x-4'>
        <Lightbulb size={40} className='text-black animate-pulse' />
        <div className='flex flex-col'>
          <span className=' text-black text-[2rem]'>
            Set Up Your Innovation Space and Supercharge Your Innovation
            Journey.
          </span>
        </div>
      </div>
      <Button
        onClick={() => navigate('/createmyinnovationspace')}
        variant='secondary'
        className='h-12 px-6 text-lg bg-white text-black hover:bg-white hover:text-lime-600 transition-colors duration-200 flex items-center gap-2 rounded-full shadow-md'
      >
        Get Started
        <ArrowRight size={20} />
      </Button>
    </div>
  )
}
