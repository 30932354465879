import { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useQuery } from '@apollo/client'
// import { Button } from '@/components/ui/button'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Badge } from '@/components/ui/badge'
import { Input } from '@/components/ui/input'
import { ArrowRight, X } from 'lucide-react'
import { GET_PUBLISHED_BLOGS } from '@/graphql/blogQuery'
import { formatDate } from '@/helpers/utils'

export default function BlogList() {
  const [selectedTopics, setSelectedTopics] = useState<string[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  // const [currentPage, setCurrentPage] = useState(1)

  const { loading, error, data } = useQuery(GET_PUBLISHED_BLOGS)

  const blogs = data?.suhrt_blogs || []

  const topics: any = useMemo(() => {
    const allTags = blogs.flatMap((blog: any) => blog.tags)
    return Array.from(new Set(allTags))
  }, [blogs])

  const filteredPosts = useMemo(() => {
    return blogs.filter((post: any) => {
      const matchesSearch =
        searchQuery === '' ||
        post.title.toLowerCase().includes(searchQuery.toLowerCase())

      const matchesTopics =
        selectedTopics.length === 0 ||
        selectedTopics.some((topic) => post.tags.includes(topic))

      return matchesSearch && matchesTopics
    })
  }, [blogs, searchQuery, selectedTopics])

  const handleTopicSelect = (topic: string) => {
    if (!selectedTopics.includes(topic)) {
      setSelectedTopics((prev) => [...prev, topic])
    }
  }

  const removeTopic = (topic: string) => {
    setSelectedTopics((prev) => prev.filter((t) => t !== topic))
  }

  const highlightText = (text: string) => {
    if (!searchQuery) return text

    const parts = text.split(new RegExp(`(${searchQuery})`, 'gi'))
    return parts.map((part, i) =>
      part.toLowerCase() === searchQuery.toLowerCase() ? (
        <span key={i} className='bg-yellow-200'>
          {part}
        </span>
      ) : (
        part
      )
    )
  }

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error.message}</div>

  return (
    <div className='min-h-screen'>
      {/* Hero Section */}
      <div className='relative py-10 text-center text-white bg-gradient-to-b from-indigo-950 to-blue-900'>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className='container mx-auto px-4'
        >
          <h1 className='text-sm uppercase tracking-wider mb-4'>
            SUHRT INNOVATION BLOG
          </h1>
          <h2 className='text-5xl font-bold mb-6'>
            Let&apos;s talk innovation
          </h2>
          <p className='text-xl mb-8 max-w-3xl mx-auto'>
            Inspiring insights and best practices on innovation management,
            foresight, and strategy.
          </p>
        </motion.div>
      </div>

      {/* Search and Filters */}
      <div className='bg-white py-8'>
        <div className='container mx-auto px-4'>
          <div className='flex flex-wrap gap-4 mb-6'>
            <Select onValueChange={handleTopicSelect}>
              <SelectTrigger className='w-[200px]'>
                <SelectValue placeholder='Select a topic' />
              </SelectTrigger>
              <SelectContent>
                {topics.map((topic: any) => (
                  <SelectItem key={topic} value={topic}>
                    {topic}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <div className='relative flex-1'>
              <Input
                type='text'
                placeholder='Search articles...'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className='w-full pr-8'
              />
              {searchQuery && (
                <button
                  onClick={() => setSearchQuery('')}
                  className='absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700'
                >
                  <X className='h-4 w-4' />
                </button>
              )}
            </div>
          </div>

          <div className='flex flex-wrap gap-2 mb-6'>
            {selectedTopics.map((topic) => (
              <Badge
                key={topic}
                variant='secondary'
                className='flex items-center gap-1'
              >
                {topic}
                <X
                  className='h-3 w-3 cursor-pointer'
                  onClick={() => removeTopic(topic)}
                />
              </Badge>
            ))}
          </div>

          {/* Blog Grid */}
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
            {filteredPosts.map((post: any) => (
              <motion.div
                key={post.perma_slug}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Link to={`/blog/${post.perma_slug}`} className='group'>
                  <div className='bg-white rounded-lg overflow-hidden shadow-lg'>
                    <div className='relative h-48'>
                      <img
                        src={post.feature_image_url || '/placeholder.svg'}
                        alt={post.title}
                        className='w-full h-full object-cover'
                      />
                    </div>
                    <div className='p-6'>
                      <div className='flex flex-wrap gap-2 mb-4'>
                        {post.tags.map((tag: any) => (
                          <Badge key={tag} variant='secondary'>
                            {tag}
                          </Badge>
                        ))}
                      </div>
                      <h3 className='text-xl font-semibold mb-4 group-hover:text-primary transition-colors'>
                        {highlightText(post.title)}
                      </h3>
                      <div className='flex items-center justify-between text-sm text-gray-600'>
                        <span>
                          {formatDate(post.created_dt)} | {post.author_name}
                        </span>
                        <ArrowRight className='h-4 w-4 text-primary transform group-hover:translate-x-2 transition-transform' />
                      </div>
                    </div>
                  </div>
                </Link>
              </motion.div>
            ))}
          </div>

          {/* Pagination */}
          {/* {filteredPosts.length > 0 && (
            <div className='flex justify-center items-center gap-2 mt-12'>
              {[1, 2, 3, 4, 5].map((page) => (
                <Button
                  key={page}
                  variant={currentPage === page ? 'default' : 'ghost'}
                  onClick={() => setCurrentPage(page)}
                  className='w-10 h-10 p-0'
                >
                  {page}
                </Button>
              ))}
              <Button variant='ghost' className='w-10 h-10 p-0'>
                <ArrowRight className='h-4 w-4' />
              </Button>
            </div>
          )} */}
        </div>
      </div>
    </div>
  )
}
