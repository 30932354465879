'use client'

import { cn } from '@/lib/utils'
import { Check } from 'lucide-react'

export interface StepItem {
  label: string
  description?: string
}

interface StepperProps {
  steps: StepItem[]
  currentStep: number
  className?: string
}

export function Stepper({ steps, currentStep, className }: StepperProps) {
  return (
    <div className={cn('w-full', className)}>
      {/* Progress bar */}
      {/* <div className='w-full h-2 bg-gray-100 rounded-full mb-8'>
        <div
          className='h-full bg-primary rounded-full transition-all duration-300'
          style={{ width: `${((currentStep + 1) / steps.length) * 100}%` }}
        />
      </div> */}

      {/* Step indicators */}
      <div className='flex justify-between mb-4'>
        {steps.map((step, index) => (
          <div
            key={index}
            className={cn(
              'flex items-center',
              index < steps.length - 1 && 'flex-1'
            )}
          >
            <div className='flex items-center justify-center'>
              <div
                className={cn(
                  'w-8 h-8 rounded-full border-2 flex items-center justify-center text-sm font-medium',
                  index === currentStep && 'border-lime-500 text-lime-500',
                  index < currentStep &&
                    'border-lime-500 bg-lime-500 text-white',
                  index > currentStep && 'border-gray-300 text-gray-300'
                )}
              >
                {index < currentStep ? (
                  <Check className='w-5 h-5' />
                ) : (
                  index + 1
                )}
              </div>
              <span
                className={cn(
                  'md:block hidden ml-2 text-sm',
                  index === currentStep && 'text-lime-500 font-medium',
                  index < currentStep && 'text-lime-500',
                  index > currentStep && 'text-muted-foreground'
                )}
              >
                {step.label}
              </span>
            </div>
            {index < steps.length - 1 && (
              <div
                className={cn(
                  'h-[2px] flex-1 mx-4',
                  index < currentStep
                    ? 'bg-foreground'
                    : 'bg-muted-foreground/70'
                )}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
