'use client'

import { useState } from 'react'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from '@/components/ui/card'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { CreditCard, ShoppingCartIcon as Paypal, Apple } from 'lucide-react'

interface PaymentFormProps {
  onSubmit: (data: PaymentFormData) => void
  isLoading?: boolean
}

export interface PaymentFormData {
  paymentMethod: 'card' | 'paypal' | 'apple'
  name: string
  city: string
  cardNumber?: string
  expiryMonth?: string
  expiryYear?: string
  cvc?: string
}

const MONTHS = Array.from({ length: 12 }, (_, i) => {
  const month = (i + 1).toString().padStart(2, '0')
  return { value: month, label: month }
})

const YEARS = Array.from({ length: 10 }, (_, i) => {
  const year = (new Date().getFullYear() + i).toString()
  return { value: year, label: year }
})

export function PaymentForm({ onSubmit, isLoading = false }: PaymentFormProps) {
  const [selectedMethod, setSelectedMethod] =
    useState<PaymentFormData['paymentMethod']>('card')
  const [formData, setFormData] = useState<PaymentFormData>({
    paymentMethod: 'card',
    name: '',
    city: '',
    cardNumber: '',
    expiryMonth: '',
    expiryYear: '',
    cvc: '',
  })

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit(formData)
  }

  const handleInputChange = (field: keyof PaymentFormData, value: string) => {
    setFormData((prev) => ({ ...prev, [field]: value }))
  }

  const PaymentMethods = [
    { id: 'card', label: 'Card', icon: CreditCard },
    { id: 'paypal', label: 'Paypal', icon: Paypal },
    { id: 'apple', label: 'Apple', icon: Apple },
  ] as const

  return (
    <Card className='w-full max-w-md mx-auto'>
      <CardHeader>
        <CardTitle>Payment Method</CardTitle>
        <CardDescription>
          Add a new payment method to your account.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className='space-y-6'>
          <div className='grid grid-cols-3 gap-4'>
            {PaymentMethods.map(({ id, label, icon: Icon }) => (
              <button
                key={id}
                type='button'
                onClick={() => {
                  setSelectedMethod(id)
                  handleInputChange('paymentMethod', id)
                }}
                className={`p-4 rounded-lg border-2 flex flex-col items-center justify-center gap-2 hover:border-primary transition-colors ${
                  selectedMethod === id ? 'border-primary' : 'border-muted'
                }`}
              >
                <Icon className='w-6 h-6' />
                <span className='text-sm font-medium'>{label}</span>
              </button>
            ))}
          </div>

          <div className='space-y-4'>
            <div>
              <label htmlFor='name' className='text-sm font-medium'>
                Name
              </label>
              <Input
                id='name'
                placeholder='First Last'
                value={formData.name}
                onChange={(e) => handleInputChange('name', e.target.value)}
                required
              />
            </div>

            <div>
              <label htmlFor='city' className='text-sm font-medium'>
                City
              </label>
              <Input
                id='city'
                placeholder='Enter your city'
                value={formData.city}
                onChange={(e) => handleInputChange('city', e.target.value)}
                required
              />
            </div>

            {selectedMethod === 'card' && (
              <>
                <div>
                  <label htmlFor='cardNumber' className='text-sm font-medium'>
                    Card number
                  </label>
                  <Input
                    id='cardNumber'
                    placeholder='1234 5678 9012 3456'
                    value={formData.cardNumber}
                    onChange={(e) =>
                      handleInputChange('cardNumber', e.target.value)
                    }
                    required
                  />
                </div>

                <div className='grid grid-cols-3 gap-4'>
                  <div className='col-span-1'>
                    <label
                      htmlFor='expiryMonth'
                      className='text-sm font-medium'
                    >
                      Expires
                    </label>
                    <Select
                      value={formData.expiryMonth}
                      onValueChange={(value) =>
                        handleInputChange('expiryMonth', value)
                      }
                    >
                      <SelectTrigger id='expiryMonth'>
                        <SelectValue placeholder='Month' />
                      </SelectTrigger>
                      <SelectContent>
                        {MONTHS.map((month) => (
                          <SelectItem key={month.value} value={month.value}>
                            {month.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>

                  <div className='col-span-1'>
                    <label htmlFor='expiryYear' className='text-sm font-medium'>
                      Year
                    </label>
                    <Select
                      value={formData.expiryYear}
                      onValueChange={(value) =>
                        handleInputChange('expiryYear', value)
                      }
                    >
                      <SelectTrigger id='expiryYear'>
                        <SelectValue placeholder='Year' />
                      </SelectTrigger>
                      <SelectContent>
                        {YEARS.map((year) => (
                          <SelectItem key={year.value} value={year.value}>
                            {year.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>

                  <div className='col-span-1'>
                    <label htmlFor='cvc' className='text-sm font-medium'>
                      CVC
                    </label>
                    <Input
                      id='cvc'
                      placeholder='CVC'
                      value={formData.cvc}
                      onChange={(e) => handleInputChange('cvc', e.target.value)}
                      required
                      maxLength={4}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <Button type='submit' className='w-full' disabled={isLoading}>
            {isLoading ? 'Processing...' : 'Continue'}
          </Button>
        </form>
      </CardContent>
    </Card>
  )
}
