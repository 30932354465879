import { createListenerMiddleware } from '@reduxjs/toolkit'
import { addNewIdea, addNewIdeaSucess, removeIdea } from './idea/ideaSlice'
import { addIdea } from '@/service/ideas'

export const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  actionCreator: addNewIdea,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(addNewIdeaSucess(action.payload))
    try {
      const result = await addIdea({
        idea: action.payload,
      })
      console.log(result.data)
    } catch (error) {
      listenerApi.dispatch(removeIdea(action.payload))
    }
  },
})
