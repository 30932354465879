import { useDialog } from '@/components/global-dialog/DialogContext'
import { Button } from '@/components/ui/button'
import { Textarea } from '@/components/ui/textarea'
import { useToast } from '@/components/ui/use-toast'
import {
  GET_IDEA_BY_ID,
  GET_IDEA_DETAILS,
  GET_IDEA_DETAILS_FOR_ADMIN,
  UpdateIdeaStatus,
} from '@/graphql/ideaQuery'
import { hideLoader, showLoader } from '@/store/idea/loaderSlice'
import { RootState } from '@/store/store'
import { ACTION } from '@/suhrt/idea_status'
import { Idea } from '@/types/idea'
import { useMutation } from '@apollo/client'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

type FormValues = {
  reviewStatus: string
  note: string
}

const ReviewIdeaForm = ({
  ideaDetails,
  action,
}: {
  ideaDetails: Idea
  action: ACTION
}) => {
  const { control, handleSubmit, reset, watch } = useForm<FormValues>({
    defaultValues: {
      reviewStatus: '',
      note: '',
    },
  })

  const { currentUser } = useSelector((state: RootState) => state.auth)
  const { closeDialog } = useDialog()

  const [updateIdeaStatus] =
    currentUser?.role_id === 1
      ? useMutation(UpdateIdeaStatus, {
          refetchQueries: [
            {
              query: GET_IDEA_BY_ID,
              variables: { id: ideaDetails.id },
            },
          ],
        })
      : currentUser?.role_id === 2
      ? useMutation(UpdateIdeaStatus, {
          refetchQueries: [
            {
              query: GET_IDEA_DETAILS_FOR_ADMIN,
              variables: {
                idea_id: ideaDetails.id,
                org_id: currentUser?.org_id,
              },
            },
          ],
        })
      : useMutation(UpdateIdeaStatus, {
          refetchQueries: [
            {
              query: GET_IDEA_DETAILS,
              variables: {
                idea_id: ideaDetails.id,
                org_id: currentUser?.org_id,
                user_id: currentUser?.id,
              },
            },
          ],
        })

  const dispatch = useDispatch()
  const { toast } = useToast()

  const onSubmit = async (data: FormValues) => {
    //const statusId = data.reviewStatus === 'approve' ? 3 : 5
    const statusId = action === ACTION.APPROVE ? 3 : 5
    try {
      dispatch(showLoader())
      const response = await updateIdeaStatus({
        variables: {
          idea_id: ideaDetails.id,
          status_id: statusId,
          note: data.note,
          user_id: ideaDetails.idea_by,
          action: statusId === 5 ? 'Rejected' : 'Approved',
        },
      })
      console.log('Mutation response:', response.data)
      reset()
      dispatch(hideLoader())
      closeDialog()
      toast({
        variant: 'success',
        description:
          statusId === 5
            ? 'Sent back for correction successfully!'
            : 'Approved successfully!',
      })
    } catch (error) {
      console.error('Error updating idea status:', error)
      dispatch(hideLoader())
      closeDialog()
    }
  }
  // Watch form values
  // const reviewStatus = watch('reviewStatus')
  const note = watch('note')

  // Check if both fields are filled
  // const isSubmitDisabled = !reviewStatus || !note

  const isSubmitDisabled = !note

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className='space-y-4 px-6 py-4 w-auto text-right'
    >
      <Controller
        name='note'
        control={control}
        render={({ field }) => (
          <Textarea
            {...field}
            placeholder='Add notes here...'
            className='w-full min-w-[300px] border border-gray-300 rounded-md p-2'
          />
        )}
      />

      <Button
        type='submit'
        className=' text-white px-4 py-2'
        size='sm'
        disabled={isSubmitDisabled}
      >
        {action === ACTION.APPROVE ? 'Approve' : 'Send Back'}
      </Button>
    </form>
  )
}

export default ReviewIdeaForm
