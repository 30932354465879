import React from 'react'
import { Card } from '@/components/ui/card'
import { ScrollArea } from '@/components/ui/react-scroll-area'
import { FaMinimize } from 'react-icons/fa6'
import { Idea } from '@/types/idea'
import { IdeaCard } from './IdeaCard'

interface MaximizedMonthViewProps {
  ideas: Idea[]
  month: string
  newlyAddedIdeas: Set<string>
  onIdeaClick: (id: string | number) => void
  onMinimizeClick: () => void
}

export const MaximizedMonthView: React.FC<MaximizedMonthViewProps> = ({
  ideas,
  month,
  newlyAddedIdeas,
  onIdeaClick,
  onMinimizeClick,
}) => (
  <>
    <Card className='flex flex-col border-primary dark:bg-transparent dark:border-primary/30 relative h-auto p-4'>
      <FaMinimize
        className='absolute top-0 right-0 pr-1 pt-1 text-xl text-slate-300 cursor-pointer hover:text-primary'
        onClick={onMinimizeClick}
      />
      <ScrollArea className='h-auto'>
        <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
          {ideas.map((idea) => (
            <IdeaCard
              key={idea.id}
              {...idea}
              newlyAdded={newlyAddedIdeas.has(idea.id)}
              onClick={onIdeaClick}
            />
          ))}
        </div>
      </ScrollArea>
    </Card>
    <div className='text-center pt-4'>{month}</div>
  </>
)
