import { useEffect, useState, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useEmblaCarousel from 'embla-carousel-react'
import { RootState } from '@/store/store'
import { UTILITY } from '@/util/utility'
import { CarouselNavigation } from './CarouselNavigation'
import { MonthCard } from './MonthCard'
import { MaximizedMonthView } from './MaximizedMonthView'
import { Idea } from '@/types/idea'

interface MonthData {
  isVisible: boolean
  ideas: Idea[]
  year: number
}

export default function GridView() {
  const { ideas, filteredIdeas, filterQuery } = useSelector(
    (state: RootState) => state.idea
  )
  const navigate = useNavigate()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])
  const [maximizedMonth, setMaximizedMonth] = useState<string | null>(null)
  const [newlyAddedIdeas, setNewlyAddedIdeas] = useState<Set<string>>(new Set())

  const computeNewIdeas = useMemo(() => {
    const recentTimestamp = parseInt(
      sessionStorage.getItem('lastUpdateTimestamp') ||
        sessionStorage.getItem('pageLoadTimestamp') ||
        Date.now().toString(),
      10
    )

    return new Set(
      ideas
        .filter((idea) => new Date(idea.idea_dt).getTime() > recentTimestamp)
        .map((idea) => idea.id)
    )
  }, [ideas])

  useEffect(() => {
    if (!sessionStorage.getItem('pageLoadTimestamp')) {
      sessionStorage.setItem('pageLoadTimestamp', Date.now().toString())
    }
  }, [])

  const displayedIdeas =
    filterQuery.category || filterQuery.tags.length ? filteredIdeas : ideas

  const monthMap = useMemo(() => {
    const map = new Map<string, MonthData>()
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    displayedIdeas.forEach((idea) => {
      if (!idea.idea_dt) return

      try {
        const date = new Date(idea.idea_dt)
        const monthIndex = date.getMonth()
        const year = date.getFullYear()
        const monthKey = `${monthNames[monthIndex]}-${year}`

        if (!map.has(monthKey)) {
          map.set(monthKey, { isVisible: true, ideas: [], year })
        }
        const monthData = map.get(monthKey)
        if (monthData) {
          monthData.ideas.push(idea)
        }
      } catch (error) {
        console.error('Error processing idea date:', error)
      }
    })

    return map
  }, [displayedIdeas])

  const monthEntries = useMemo(() => {
    const entries = Array.from(monthMap.entries())
    return entries.sort((a, b) => {
      const [monthA, yearDataA] = a[0].split('-')
      const [monthB, yearDataB] = b[0].split('-')

      const yearA = parseInt(yearDataA)
      const yearB = parseInt(yearDataB)

      if (yearA !== yearB) {
        return yearB - yearA
      }

      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      return monthNames.indexOf(monthB) - monthNames.indexOf(monthA)
    })
  }, [monthMap, ideas])

  const monthCount = monthEntries.length

  // Initialize carousel with proper options
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      align: 'center',
      loop: true,
      slidesToScroll: 1,
      dragFree: false,
      containScroll: 'trimSnaps',
    },
    monthCount > 1 ? [] : []
  )

  // Modified scroll functions to not restart autoplay
  const scrollPrev = useCallback((): void => {
    if (emblaApi) {
      emblaApi.scrollPrev()
    }
  }, [emblaApi])

  const scrollNext = useCallback((): void => {
    if (emblaApi) {
      emblaApi.scrollNext()
    }
  }, [emblaApi])

  const onDotButtonClick = useCallback(
    (index: number): void => {
      if (!emblaApi) return
      emblaApi.scrollTo(index)
    },
    [emblaApi]
  )

  useEffect(() => {
    if (computeNewIdeas.size > 0) {
      sessionStorage.setItem('lastUpdateTimestamp', Date.now().toString())
      sessionStorage.setItem(
        'newlyAddedIdeas',
        JSON.stringify([...computeNewIdeas])
      )
      //setShouldAutoplay(false) // Pause when new ideas are added
    }

    emblaApi?.scrollTo(0)
    setNewlyAddedIdeas(computeNewIdeas)

    const timeout = setTimeout(() => {
      setNewlyAddedIdeas(new Set())
      sessionStorage.removeItem('newlyAddedIdeas')
      //setShouldAutoplay(true) // Resume after timeout
    }, 10000)

    return () => clearTimeout(timeout)
  }, [computeNewIdeas, emblaApi])


  const handleIdeaClick = useCallback(
    (id: string | number) => {
      navigate(`/ideas/${UTILITY.encryptedId(id)}`)
    },
    [navigate]
  )

  useEffect(() => {
    if (!emblaApi) return

    const onInit = () => {
      setScrollSnaps(emblaApi.scrollSnapList())
      setSelectedIndex(emblaApi.selectedScrollSnap())
    }

    const onSelect = () => {
      setSelectedIndex(emblaApi.selectedScrollSnap())
    }

    onInit()
    emblaApi.on('init', onInit)
    emblaApi.on('reInit', onInit)
    emblaApi.on('select', onSelect)

    return () => {
      emblaApi.off('init', onInit)
      emblaApi.off('reInit', onInit)
      emblaApi.off('select', onSelect)
    }
  }, [emblaApi, ideas])

  if (!displayedIdeas.length) {
    return (
      <h1 className='text-center text-red-500 p-4'>
        No ideas match the current filter
      </h1>
    )
  }

  const formatMonthDisplay = (monthKey: string) => {
    const [month, year] = monthKey.split('-')
    return `${month} '${year.slice(-2)}`
  }

  return (
    <div className='bg-background dark:bg-muted grid md:p-8 p-2 w-full rounded-md'>
      {!maximizedMonth ? (
        <div className='w-full overflow-hidden'>
          {monthCount <= 1 ? (
            <div className='w-full py-6 pb-0'>
              <MonthCard
                ideas={monthEntries[0][1].ideas}
                newlyAddedIdeas={newlyAddedIdeas}
                onIdeaClick={handleIdeaClick}
                onMaximizeClick={() => setMaximizedMonth(monthEntries[0][0])}
              />
              <div className='text-center pt-4 md:pt-2'>
                {formatMonthDisplay(monthEntries[0][0])}
              </div>
            </div>
          ) : (
            <div className='relative overflow-hidden'>
              <div className='embla' ref={emblaRef}>
                <div className='embla__container flex'>
                  {monthEntries.map(
                    ([monthKey, { ideas: monthIdeas }], _index) => (
                      <div
                        key={monthKey}
                        className='embla__slide px-2'
                        style={{ flex: '0 0 100%' }}
                      >
                        <div className='py-6 pb-0'>
                          <MonthCard
                            ideas={monthIdeas}
                            newlyAddedIdeas={newlyAddedIdeas}
                            onIdeaClick={handleIdeaClick}
                            onMaximizeClick={() => setMaximizedMonth(monthKey)}
                          />
                          <div className='text-center pt-4 md:pt-2'>
                            {formatMonthDisplay(monthKey)}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
              {monthCount > 1 && (
                <CarouselNavigation
                  selectedIndex={selectedIndex}
                  scrollSnaps={scrollSnaps}
                  onPrevClick={scrollPrev}
                  onNextClick={scrollNext}
                  onDotClick={onDotButtonClick}
                />
              )}
            </div>
          )}
        </div>
      ) : (
        <MaximizedMonthView
          ideas={monthMap.get(maximizedMonth)?.ideas || []}
          month={formatMonthDisplay(maximizedMonth)}
          newlyAddedIdeas={newlyAddedIdeas}
          onIdeaClick={handleIdeaClick}
          onMinimizeClick={() => setMaximizedMonth(null)}
        />
      )}
    </div>
  )
}
