import { Idea } from '@/types/idea'
import { createSlice } from '@reduxjs/toolkit'

export enum CLICK {
  SAVE = 'save',
  SUBMIT = 'submit',
  RESET = 'reset',
  NONE = '',
}

const initialState: IdeaState = {
  ideas: [],
  filteredIdeas: [],
  ideaDetails: [],
  categories: [],
  filterQuery: {
    category: '',
    tags: [],
  },
  click: CLICK.NONE,
  step: '',
  isFormValid: false,
}
interface Category {
  category_id: string
  category_name: string
}
export interface Tag {
  id: string
  text: string
}
type IdeaState = {
  ideas: Idea[]
  filteredIdeas: Idea[]
  ideaDetails: Idea[]
  categories: Category[]
  filterQuery: {
    category: string
    tags: Tag[]
  }
  click: CLICK
  step: string
  isFormValid: boolean
}

const ideaSlice = createSlice({
  name: 'Idea',
  initialState,
  reducers: {
    loadIdeas(state: IdeaState, actions) {
      state.ideas = actions.payload
    },
    loadFilteredIdeas(state: IdeaState, actions) {
      state.filteredIdeas = actions.payload
    },
    updateFilterQuery(state: IdeaState, actions) {
      state.filterQuery = actions.payload
    },
    addNewIdea(state: IdeaState, actions) {
      console.log('Reducer: addNewIdea', actions.payload, state)
    },
    addNewIdeaSucess(state: IdeaState, actions) {
      state.ideas.push(actions.payload)
    },
    removeIdea(state: IdeaState, action) {
      const index = state.ideas.findIndex(
        (idea) =>
          idea.idea_title === action.payload.name &&
          idea.idea_dt === action.payload.created
      )
      if (index > -1) state.ideas.splice(index, 1)
    },
    getIdeaDetailsById(state: IdeaState, actions) {
      state.ideaDetails = state.ideas.filter(
        (idea) => idea.id === actions.payload
      )
    },
    // updateLikes({ ideaDetails }: IdeaState, actions) {
    //   ideaDetails = {
    //     ...ideaDetails,
    //     likes: ideaDetails?.likes + actions.payload,
    //   }
    // },
    updateLikes(state: IdeaState, action) {
      const { id, likeChange } = action.payload // Expecting { id: string, likeChange: number }
      const idea = state.ideas.find((idea) => idea.id === id)
      if (idea) {
        // Ensure 'likes' is defined in your Idea type
        if (!idea.likes) {
          idea.likes = { aggregate: { count: 0 } } // Initialize likes if undefined
        }
        idea.likes.aggregate.count += likeChange // Update the like count
      }
    },
    loadCategories(state: IdeaState, actions) {
      state.categories = actions.payload
    },
    saveIdea(state: IdeaState) {
      state.click = CLICK.SAVE
    },
    submitIdea(state: IdeaState) {
      state.click = CLICK.SUBMIT
    },
    resetIdeaForm(state: IdeaState) {
      state.click = CLICK.RESET
    },
    clearEvent(state: IdeaState) {
      state.click = CLICK.NONE
    },
    updateAIIdeatorStep(state: IdeaState, action) {
      state.step = action.payload
    },
    checkFormIsValid(state: IdeaState, action) {
      state.isFormValid = action.payload
    },
  },
})

export const {
  loadIdeas,
  loadFilteredIdeas,
  updateFilterQuery,
  addNewIdea,
  getIdeaDetailsById,
  updateLikes,
  addNewIdeaSucess,
  removeIdea,
  loadCategories,
  saveIdea,
  submitIdea,
  resetIdeaForm,
  clearEvent,
  updateAIIdeatorStep,
  checkFormIsValid,
} = ideaSlice.actions
export const ideaReducer = ideaSlice.reducer
