import { gql } from '@apollo/client'

export const GET_TOKEN_DETAILS_BY_ORG_ID = gql`
  query GetTokenDetails($org_id: Int!) {
    suhrt_tokens(where: { org_id: { _eq: $org_id } }) {
      total_tokens
      used_tokens
      remaining_tokens # This is your computed field coming from FUNCTION suhrt.calculate_remaining_token
    }
  }
`
export const DEDUCT_TOKEN = gql`
  mutation DeductToken($org_id: Int!) {
    update_suhrt_tokens(
      where: { org_id: { _eq: $org_id } }
      _inc: { used_tokens: 2 }
      _set: { updated_dt: "now()" }
    ) {
      affected_rows
      returning {
        org_id
        total_tokens
        used_tokens
        remaining_tokens
      }
    }
  }
`

export const GET_TIER_DETAILS = gql`
  query GetTiers {
    suhrt_tiers {
      id
      name
      token_allocation
      monthly_price
      features
    }
  }
`
