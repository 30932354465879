import { HasuraGraphQL } from '@/hasura/hasura-config'
import {
  ApolloClient,
  // gql,
  // ApolloLink,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'

/*---CREATE HTTP LINK---*/
const httpLink = new HttpLink({ ...HasuraGraphQL.httpConfig })

/*---CREATE WS LINK---*/
const wsLink = new GraphQLWsLink(createClient({ ...HasuraGraphQL.wsConfig }))

/*---CREATE Split Link---*/
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  // ApolloLink.from([wsLink]),
  wsLink,
  httpLink // Use HTTP for queries and mutations
)

//Create a logging link
// const loggingLink = new ApolloLink((operation, forward) => {
//   return forward(operation).map((response) => {
//     return response
//   });
// });

// Create the Apollo Client
// const client = new ApolloClient({
//   link: ApolloLink.from([loggingLink, splitLink]),
//   cache: new InMemoryCache(),
// });

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
})

// client.subscribe({
//     query: gql `subscription {
//       suhrt_notifications_by_user_aggregate(where: {user_id: {_eq: 249}}) {
//         aggregate {
//           count
//         }
//       }
//     }`

// }).subscribe({
//   next: (data) => {
//     console.log('received subscription data: ', data);
//   },
//   error: (error) => {
//     console.error('Subscription error: ', error);
//   }
// });

export default client
