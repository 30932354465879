import React from 'react'

interface DownloadAttachmentProps {
  attachments?: string[]
}

const hexToString = (hex: string): string => {
  const hexArray = hex.match(/.{1,2}/g)
  const byteArray = new Uint8Array(
    hexArray ? hexArray.map((byte) => parseInt(byte, 16)) : []
  )
  let binary = ''
  byteArray.forEach((byte) => (binary += String.fromCharCode(byte)))
  return binary
}

export const cleanBase64String = (base64String: string): string => {
  if (base64String.startsWith('\\x')) {
    const hexString = base64String.slice(2)
    return hexToString(hexString)
  }
  return base64String
}

export const decodeMetadata = (
  encodedString: string
): { filename: string; mimeType: string; data: string } => {
  try {
    const jsonString = atob(encodedString)
    const metadata = JSON.parse(jsonString)
    return metadata
  } catch (error) {
    console.error('Error decoding metadata:', error)
    return {
      filename: 'unknown',
      mimeType: 'application/octet-stream',
      data: '',
    }
  }
}

export const handleDownload = (cleanedBase64: string) => {
  try {
    const metadata = decodeMetadata(cleanedBase64)
    const { filename, mimeType, data } = metadata

    if (!data) {
      throw new Error('No data found in attachment.')
    }

    const byteCharacters = atob(data)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)

    const blob = new Blob([byteArray], { type: mimeType })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (error) {
    console.error('Error downloading attachment:', error)
  }
}

const DownloadAttachment: React.FC<DownloadAttachmentProps> = ({
  attachments = [],
}) => {
  if (!attachments || attachments.length === 0) {
    console.log('No attachments provided or attachments are invalid.')
    return null
  }

  return (
    <div>
      {attachments.map((file, index) => {
        const cleanedBase64 = cleanBase64String(file)
        return (
          <button
            key={index}
            onClick={() => handleDownload(cleanedBase64)}
            className='hover:underline hover:text-primary mr-2'
          >
            {`Attachment ${index + 1}`}
          </button>
        )
      })}
    </div>
  )
}

export default DownloadAttachment
